import { Check, Save } from '@mui/icons-material';
import { Button, Select, MenuItem, CircularProgress, TextField } from '@mui/material';
// import { ChangeEventArgs, Inject, Link, RichTextEditorComponent } from '@syncfusion/ej2-react-richtexteditor';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { APIOldWealthClient } from '../../../ApiOld/ApiOldWealthClient';
import { EmployeeGroupNameDTO, GetWealthDetailsResponse } from '../../../ApiOld/ApiServerVov';
import BuildingSelect from '../../Shared/BuildingSelect';
import EmployeeSelect from '../../Shared/EmployeeSelect';
import delay from '../../Shared/Functions/Delay';
import WarehouseSelect from '../../Shared/WarehouseSelect';
import { ChangeEventArgs, RichTextEditorComponent, Inject as InjectRT, Toolbar as ToolbarRT, Link, HtmlEditor, QuickToolbar, } from '@syncfusion/ej2-react-richtexteditor';
import InitSfRTBLocale from '../../../Localization/SFRichEditLanguageIntializer';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface WealthDetailProps {

}

InitSfRTBLocale();

function WealthDetail(props: WealthDetailProps) {
    const param = useParams();
    const [wealthId, setWealthId] = useState<number | null>(null);
    const [saved, setSaved] = useState<boolean>(false);
    const [wealthData, setWealthData] = useState<Partial<GetWealthDetailsResponse> | null>(null);
    const [employeeGroupsNames, setEmployeeGroupsNames] = useState<EmployeeGroupNameDTO[] | null>(null);

    const { t, i18n } = useTranslation();
    function reloadData() {
        if (wealthId)
            new APIOldWealthClient().GetWealthDetails(wealthId).then((data) => { setWealthData(data); });
    }
    useEffect(() => {
        let num = parseInt(param['id']!);
        if (!isNaN(num))
            setWealthId(num);
    }, []);
    useEffect(() => {
        reloadData();
    }, [wealthId]);
    useEffect(() => {
        if (saved) delay(1000).then(() => { setSaved(false) })
    }, [saved]);
    async function save() {
        if (wealthData) {
            let ok = await new APIOldWealthClient().SaveWealthDetails(wealthData);
            if (ok !== undefined && ok === true) { setSaved(true) }
        }
    }


    const rtb = React.useRef<RichTextEditorComponent>(null);
    var toolbarSettings: object = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
            'Outdent', 'Indent', '|',
            // 'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
            'SourceCode', '|', 'Undo', 'Redo'
        ],
        type: 'Expand'
    }


    if (wealthData === null)
        return <div>{t('loading...')}</div>;
    return (

        <div>
            <ValidationGroup>
                <div>
                    <div style={{ display: 'flex' }}>
                        <AutoDisabler>
                            <Button variant='contained' size='small' sx={{ margin: '8px' }} startIcon={<Save />} onClick={save}>
                                {t('save')}
                            </Button>
                        </AutoDisabler>
                        {saved && <Check sx={{ marginTop: '10px' }} color='success' />}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Validate name='code-wealth-validate' initialValidation='silent' required={[true, t('FieldRequired')]}>
                            <TextField
                                sx={{ margin: '8px' }}
                                size='small'
                                label={'xxx kod'}
                                value={wealthData.code}
                                onChange={(e) => { setWealthData({ ...wealthData, code: e.currentTarget.value }) }}
                            />
                        </Validate>
                        <TextField
                            sx={{ margin: '8px' }}
                            size='small'
                            label={'xxx sériové číslo'}
                            value={wealthData.serialNumber}
                            onChange={(e) => { setWealthData({ ...wealthData, serialNumber: e.currentTarget.value }) }}
                        />
                        <TextField
                            sx={{ margin: '8px' }}
                            size='small'
                            label={'xxx vyrobce'}
                            value={wealthData.manufacturer}
                            onChange={(e) => { setWealthData({ ...wealthData, manufacturer: e.currentTarget.value }) }}
                        />

                        <TextField
                            sx={{ margin: '8px' }}
                            size='small'
                            label={'xxx 1-3'}
                            value={wealthData.priceA}
                            onChange={(e) => {
                                let num = parseInt(e.currentTarget.value);
                                if (!isNaN(num)) {
                                    setWealthData({ ...wealthData, priceA: num })
                                }
                            }}
                        />
                        <TextField
                            sx={{ margin: '8px' }}
                            size='small'
                            label={'xxx 4-10'}
                            value={wealthData.priceB}
                            onChange={(e) => {
                                let num = parseInt(e.currentTarget.value);
                                if (!isNaN(num)) {
                                    setWealthData({ ...wealthData, priceB: num })
                                }
                            }}
                        />
                        <TextField
                            sx={{ margin: '8px' }}
                            size='small'
                            label={'xxx 10 a vice'}
                            value={wealthData.priceC}
                            onChange={(e) => {
                                let num = parseInt(e.currentTarget.value);
                                if (!isNaN(num)) {
                                    setWealthData({ ...wealthData, priceC: num })
                                }
                            }}
                        />
                        <TextField
                            sx={{ margin: '8px' }}
                            size='small'
                            label={'xxx zaloha'}
                            value={wealthData.priceForRent}
                            onChange={(e) => {
                                let num = parseInt(e.currentTarget.value);
                                if (!isNaN(num)) {
                                    setWealthData({ ...wealthData, priceForRent: num })
                                }
                            }}
                        />
                        <TextField
                            label="xxx Zobrazit skupinam"
                            select
                            value={wealthData.employeeGroupsIds}
                            SelectProps={{ multiple: true }}
                            size='small'
                            color='secondary'
                            onChange={(e) => {
                                const value = e.target.value;
                                const valueAsStrings = typeof value === 'string' ? value.split(',') : value;
                                const valueAsNums = valueAsStrings.map(i => Number(i));
                                setWealthData({ ...wealthData, employeeGroupsIds: valueAsNums })
                            }}
                        >
                            {employeeGroupsNames && employeeGroupsNames.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>

                        <Validate name='name-wealth-validate' initialValidation='noisy' required={[true, t('FieldRequired')]}>
                            <TextField
                                sx={{ margin: '8px' }}
                                size='small'
                                label={t('Name')}
                                value={wealthData.name}
                                onChange={(e) => { setWealthData({ ...wealthData, name: e.currentTarget.value }) }}
                            />
                        </Validate>

                        <div style={{ height: '200px' }} >
                            <RichTextEditorComponent
                                // ref={rtb}
                                // actionBegin={(e) => {
                                //     if (e.type === 'drop' || e.type === 'dragstart') {
                                //         e.cancel = true;
                                //     }
                                // }}
                                toolbarSettings={toolbarSettings}
                                height={200}
                                locale={i18n.language}
                                value={wealthData.description}
                                change={(eve: ChangeEventArgs) => { setWealthData({ ...wealthData, description: eve.value }) }}

                            >
                                {/* <Inject services={[Image, Link]} /> */}
                                <InjectRT services={[ToolbarRT, Link, HtmlEditor, QuickToolbar]} />
                            </RichTextEditorComponent>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                value={wealthData.datetimeImage}
                                inputFormat="DD. MM. YYYY HH:mm"
                                ampm={false}
                                onChange={(e) => {
                                    setWealthData({ ...wealthData, datetimeImage: e !== null && e !== undefined ? e! : undefined })
                                }}
                                renderInput={(props) =>
                                    <TextField size='small' {...props} style={{ backgroundColor: '#FFFFFF', width: '280px' }} />
                                }
                            />
                        </LocalizationProvider>

                        <TextField
                            sx={{ margin: '8px' }}
                            size='small'
                            label={'xxx gps x'}
                            value={wealthData.gpsXImage}
                            type='number'
                            inputProps={{
                                maxLength: 13,
                                step: "0.1"
                            }}
                            onChange={(e) => {
                                setWealthData({ ...wealthData, gpsXImage: parseFloat(e.currentTarget.value) })
                            }}
                        />
                        <TextField
                            sx={{ margin: '8px' }}
                            size='small'
                            label={'xxx gps y'}
                            value={wealthData.gpsYImage}
                            type='number'
                            inputProps={{
                                maxLength: 13,
                                step: "0.1"
                            }}
                            onChange={(e) => {
                                setWealthData({ ...wealthData, gpsYImage: parseFloat(e.currentTarget.value) })
                            }}
                        />

                        <div style={{ margin: '8px' }}>
                            <EmployeeSelect selectedEmployeeId={wealthData.employeeId} onChange={(id) => { if (id !== null) setWealthData({ ...wealthData, employeeId: id.id }) }} />
                        </div>
                        <div style={{ margin: '8px' }}>
                            <BuildingSelect selectedProjectId={wealthData.buildingId} onChange={(id) => { if (id !== null) setWealthData({ ...wealthData, buildingId: id.id }) }} />
                        </div>
                        <div style={{ margin: '8px' }}>
                            <WarehouseSelect selectedWarehouseId={wealthData.warehouseId} onChange={(id) => { if (id !== null) setWealthData({ ...wealthData, warehouseId: id.id }) }} />
                        </div>
                    </div>
                </div>

            </ValidationGroup>

        </div>
    );
};
export default WealthDetail;