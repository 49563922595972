
import { BookOutlined, CarRental, DocumentScanner, History, Info, MoneyOff, Newspaper, Task } from '@mui/icons-material';
import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { TabPanel } from '../../Shared/Tabs/TabPanel';
import tabProps from '../../Shared/Tabs/TabProps';
import WealthDetail from './WealthDetail';
import WealthExpendituresDetail from './WealthExpenditures';

interface WealthTabsProps {

}

function WealthTabs(props: WealthTabsProps) {
    const param = useParams();
    const [value, setValue] = React.useState(0);
    const { t } = useTranslation();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }
    const biggerThan700 = useMediaQuery('(min-width:700px)');
    return (
        <div>

            <Box sx={{ borderBottom: 3, borderColor: 'lightgray', background: '#FFC600', width: '100%', position: 'fixed', zIndex: 5000 }}>
                <Tabs variant='fullWidth' value={value} onChange={handleChange} aria-label="basic tabs example"
                    sx={{
                        "& button": { color: 'black' },
                        "& button:hover": { color: 'white' },
                        "& button:focus": { color: 'white' },
                        "& button:active": { color: 'white' },
                        "& button.Mui-selected": { color: 'white' },
                        height: biggerThan700 ? '50px' : '25px',


                    }}
                    TabIndicatorProps={{ sx: { backgroundColor: 'white', height: '5px' } }}
                >

                    <Tab icon={<Info style={{ height: biggerThan700 ? '16px' : '24px', width: biggerThan700 ? '16px' : '24px', marginTop: biggerThan700 ? '-16px' : '-2px' }} />} label={biggerThan700 && <div style={{ fontSize: 12, marginTop: '-16px' }}>xxx Základní informace</div>} {...tabProps(0)} iconPosition='start' />
                    <Tab icon={<DocumentScanner style={{ height: biggerThan700 ? '16px' : '24px', width: biggerThan700 ? '16px' : '24px', marginTop: biggerThan700 ? '-16px' : '-2px' }} />} label={biggerThan700 && <div style={{ fontSize: 12, marginTop: '-16px' }}>xxx Dokumenty</div>} {...tabProps(1)} iconPosition='start' />
                    <Tab icon={<History style={{ height: biggerThan700 ? '16px' : '24px', width: biggerThan700 ? '16px' : '24px', marginTop: biggerThan700 ? '-16px' : '-2px' }} />} label={biggerThan700 && <div style={{ fontSize: 12, marginTop: '-16px' }}>xxx Historie</div>} {...tabProps(2)} iconPosition='start' />
                    <Tab icon={<MoneyOff style={{ height: biggerThan700 ? '16px' : '24px', width: biggerThan700 ? '16px' : '24px', marginTop: biggerThan700 ? '-16px' : '-2px' }} />} label={biggerThan700 && <div style={{ fontSize: 12, marginTop: '-16px' }}>xxx Výdaje</div>} {...tabProps(3)} iconPosition='start' />
                    <Tab icon={<Task style={{ height: biggerThan700 ? '16px' : '24px', width: biggerThan700 ? '16px' : '24px', marginTop: biggerThan700 ? '-16px' : '-2px' }} />} label={biggerThan700 && <div style={{ fontSize: 12, marginTop: '-16px' }}>xxx Úkoly</div>} {...tabProps(4)} iconPosition='start' />
                    <Tab icon={<CarRental style={{ height: biggerThan700 ? '16px' : '24px', width: biggerThan700 ? '16px' : '24px', marginTop: biggerThan700 ? '-16px' : '-2px' }} />} label={biggerThan700 && <div style={{ fontSize: 12, marginTop: '-16px' }}>xxx Půjčit majetek</div>} {...tabProps(5)} iconPosition='start' />
                    <Tab icon={<Newspaper style={{ height: biggerThan700 ? '16px' : '24px', width: biggerThan700 ? '16px' : '24px', marginTop: biggerThan700 ? '-16px' : '-2px' }} />} label={biggerThan700 && <div style={{ fontSize: 12, marginTop: '-16px' }}>xxx Vytvořit novinku</div>} {...tabProps(6)} iconPosition='start' />

                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '50px' }}>
                    <WealthDetail />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '50px' }}>
                    Dokumenty nehotovo
                </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '50px' }}>
                    Historie nehotovo
                </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '50px' }}>
                    <WealthExpendituresDetail />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '50px' }}>
                    Úkoly nehotovo
                </Box>
            </TabPanel>
            <TabPanel value={value} index={5}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '50px' }}>
                    Majetek nehotovo
                </Box>
            </TabPanel>
            <TabPanel value={value} index={6}>
                <Box component="main" sx={{ flexGrow: 0, p: 0, height: '100%', marginTop: '50px' }}>
                    Novinky nehotovo
                </Box>
            </TabPanel>
        </div>
    );
};
export default WealthTabs;