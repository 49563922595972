
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Search, Sort, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, FilterSettingsModel, Page } from '@syncfusion/ej2-react-grids';
import { Switch, useTheme } from '@mui/material';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import { EmployeeListDTO, WealthDTO } from '../../../ApiOld/ApiServerVov';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { Engineering, LocalShipping } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import { APIVovEmployeeHelper } from '../../../ApiOld/ApiOldEmployeeClient';

interface EmployeesListProps {

}

function EmployeesList(props: EmployeesListProps) {
    InitSfGridLocale();

    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Zaměstnanců</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };
    const [employees, setEmployees] = useState<EmployeeListDTO[] | null>(null);
    const [trashOpen, setTrashOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const grid = useRef<GridComponent>(null);


    useEffect(() => {
        refreshData();
    }, []);


    function refreshData() {
        new APIVovEmployeeHelper().GetEmployeesList().then((d) => {
            setEmployees(d); if (grid.current) grid.current.refresh();
        });
    }
    function gotoDetail(id: number) {
        navigate(id.toString());
    }
    function gridNameTemplate(props: EmployeeListDTO) {
        return (<div /* style={{ cursor: 'pointer' }} */>
            {props.firstname + " " + props.lastname}
        </div >);
    }

    function gridPhoneTemplate(props: EmployeeListDTO) {
        return (<div>
            <span style={{ fontSize: 'smaller', color: 'gray' }}>{props.phonePrefix}</span>{" " + props.phoneNumber}
        </div >);
    }


    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }

    function gridIsActiveTemplate(props: EmployeeListDTO) {
        return (<div>
            <Switch checked={props.isActive}
                onChange={(e, checked) => {
                    changeIsActive(e);
                }}
            />
        </div >);
    }

    const changeIsActive = (args: any) => {
        if (grid.current) {
            let rowDetails = grid.current.getRowInfo(args.currentTarget);
            if (rowDetails && rowDetails.rowIndex !== undefined && rowDetails.rowData) {
                (rowDetails.rowData as EmployeeListDTO).isActive = args.target.checked;

                grid.current.updateRow(rowDetails.rowIndex, rowDetails.rowData); //it helps to update the changes in datasource
                grid.current.refresh();

                new APIVovEmployeeHelper().SetIsEmployeeActive((rowDetails.rowData as EmployeeListDTO).id!, args.target.checked);
            }
        }
    }

    if (employees !== null)
        return (
            <div style={{ display: "flex", flexDirection: 'column' }}>
                <UpperMenu moduleName={t('employees')}
                    gridType={GridType.Todos}
                    canAdd
                    canTrash
                    onThrashClicked={() => { setTrashOpen(true); }}
                    canDelete
                    onDelete={(e) => { new APIVovEmployeeHelper().DeleteEmployees(e as number[]).then((e) => { if (e) refreshData(); }) }}
                    canChangeEyeView={false}
                    grid={grid} canChangeGroup canToolbarGrid
                    menuName={t('View')}
                    icon={<Engineering />}
                />
                <GridComponent ref={grid} id="grid-component-employees" allowSorting={true}
                    allowReordering={true} allowResizing={true} showColumnChooser={true}
                    allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                    locale={i18n.language} enablePersistence={true}
                    recordDoubleClick={(e) => {
                        gotoDetail(e.rowData.id); //xx
                    }}
                    filterSettings={filterOptions}
                    allowPaging={true}
                    style={{ backgroundColor: '#F5F5F5' }}
                    pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                    toolbarClick={toolbarClickExcel}
                    selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                    allowFiltering={true} groupSettings={groupOptions}
                    dataSource={employees}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='50' toolTip='checkbox' />
                        <ColumnDirective field='id' width='30' headerText="ID" textAlign="Right" visible={false} toolTip='' />
                        <ColumnDirective field='code' headerText={t('code')} toolTip='' />
                        <ColumnDirective template={gridNameTemplate} headerText={t('Name')} toolTip={t('Name')} />
                        <ColumnDirective field='groupName' headerText={t('employee.group')} toolTip={t('employee.group')} />
                        <ColumnDirective template={gridPhoneTemplate} headerText={t('employee.phone')} toolTip={t('employee.phone')} />
                        <ColumnDirective field='wage' headerText={t('employee.wage')} toolTip={t('employee.wage')} />
                        <ColumnDirective template={gridIsActiveTemplate} field='isActive' headerText={t('active')} toolTip={t('active')} />


                        {/* <ColumnDirective field='processingAdminUserName' headerText='xxx vyrizuje' toolTip='' />
                        <ColumnDirective field='employeeNames' headerText='xxx resitele' toolTip='' /> */}

                    </ColumnsDirective>
                    <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                </GridComponent>
                <TrashGridDialog trashType={TrashType.Employees} open={trashOpen} onClose={(e) => { setTrashOpen(false); if (e) refreshData(); }} />
            </div>
        );
    return <div>{t('noRecords')}</div>;
};
export default EmployeesList;