import { Book, Clear, Done } from '@mui/icons-material';
import { Button, Link, useTheme } from '@mui/material';
import { ColumnDirective, ColumnsDirective, FilterSettingsModel, GridComponent, Inject, RecordDoubleClickEventArgs, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import { GetDiariesForUserDTO } from '../../../ApiOld/ApiServerVov';
import { useDiaryStore } from '../../../Contexts/DiaryZContext';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';


interface DiariesListProps {
    fromExternal?: boolean;
    isSubdodavatel?: boolean;
}

export default function DiariesList(props: DiariesListProps) {
    InitSfGridLocale();
    const { fromExternal, isSubdodavatel } = props;
    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };
    const [diaries, setDiaries] = useState<GetDiariesForUserDTO[] | null>(null);
    const [trashOpen, setTrashOpen] = useState<boolean>(false);
    const [showClearFilter, setShowClearFilter] = useState<boolean>(false);
    const navigate = useNavigate();
    const permissionsState = useDiaryStore((state) => state.permissions);
    const theme = useTheme();
    const params = useParams();
    const { t, i18n } = useTranslation();
    function toDiaryDetail(props: GetDiariesForUserDTO) {
        // grid.current?.filterModule.
        if (isSubdodavatel) {
            navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/diaryDetail/' + props.id);
            window.parent.postMessage("diarydetailchanged:" + props.id, '*');
        }
        else {
            if (fromExternal === undefined || fromExternal === false)
                navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryDetail/' + props.id);
            else navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryolderpnewdetail/' + props.id);
            window.parent.postMessage("diarydetailchanged:" + props.id, '*');
        }
    }
    function gridTemplate(props: GetDiariesForUserDTO) {
        if (permissionsState && permissionsState.canViewDiaryDetail)
            return (<div>
                <a style={{ color: theme.palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                        toDiaryDetail(props);


                    }}>{props.name}</a>
            </div >);
        else return <div>{props.name}</div>
    }
    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }
    function createDiary() {
        if (isSubdodavatel) {
            //navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/diaries/new');
        }
        else {
            if (fromExternal === undefined || fromExternal === false)
                navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryDetail/new');
            else navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryolderpnewdetail/new');
        }
    }
    function createSubdDiary() {
        if (fromExternal === undefined || fromExternal === false)
            navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryDetail/newSubd');
        else navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryolderpnewdetail/newSubd');
    }
    const grid = useRef<GridComponent>(null);
    function reloadData() {
        new ApiDiaryBaseHelper(props.isSubdodavatel == undefined ? false : props.isSubdodavatel, false).GetDiaries(false, props.isSubdodavatel ? true : false).then((r) => { setDiaries(r); if (grid.current) grid.current.refresh(); });
    }
    useEffect(() => {
        if (diaries === null)
            reloadData();
    }, []);


    function cellTemplateSubcontractor(props: GetDiariesForUserDTO) {
        if (props.isSubcontractorDiary !== undefined && props.isSubcontractorDiary === true)
            return (<Done color='action' />);
        else return (<div></div>);

    }

    if (diaries !== null)
        return <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'start' }}>
            <UpperMenu moduleName={t('diary.diaryList')}
                gridType={GridType.Users}
                gridId='grid-component-diaries'
                canAdd={isSubdodavatel !== true && permissionsState ? permissionsState.canCreateDiary : false}
                canAddSecond={isSubdodavatel !== true && permissionsState ? permissionsState.canCreateDiary : false}
                addLabel={t('Main')}
                addSecondLabel={t('AddSubd')}
                canDelete={isSubdodavatel !== true && permissionsState ? permissionsState.canDeleteDiary : false}
                onCreate={createDiary}
                onCreateSecond={createSubdDiary}
                onDelete={(e) => {
                    new ApiDiaryBaseHelper(props.isSubdodavatel == undefined ? false : props.isSubdodavatel, false).DeleteDiaries(e as number[]).then((e) => {
                        if (e)
                            reloadData();
                    })
                }}
                canChangeEyeView={false}
                grid={grid} canChangeGroup canToolbarGrid
                menuName={t('View')}
                canTrash={isSubdodavatel !== true}
                onThrashClicked={() => { setTrashOpen(true) }}
                icon={<Book />}
                isInvestor={false}
                isSubdodavatel={isSubdodavatel}
                fromExternal={fromExternal}
            />
            <div>
                <GridComponent ref={grid}
                    id="grid-component-diaries" allowSorting={true}
                    allowReordering={true} allowResizing={true} showColumnChooser={true}
                    allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                    locale={i18n.language} enablePersistence={true}
                    recordDoubleClick={(e: RecordDoubleClickEventArgs) => {
                        toDiaryDetail(e.rowData as GetDiariesForUserDTO)
                    }}
                    //actionComplete={(e) => { console.log(e) }}
                    filterSettings={filterOptions}
                    allowPaging={true}
                    onChange={() => { console.log('grid changed'); }}
                    pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                    toolbarClick={toolbarClickExcel}
                    //  columnDataStateChange={(e) => { console.log(e) }}
                    selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                    allowFiltering={true} groupSettings={groupOptions}
                    dataSource={diaries}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='40' />
                        <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" visible={false} />
                        <ColumnDirective field='name' template={gridTemplate} headerText={t('Name')} width='120' />
                        <ColumnDirective field='buildingName' headerText={t('Projekt')} width='100' />
                        {isSubdodavatel !== true && <ColumnDirective field='isSubcontractorDiary' type='boolean' template={cellTemplateSubcontractor} headerText={t('diary.subdiary')} width='46' />}
                    </ColumnsDirective>
                    <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                </GridComponent></div>
            <TrashGridDialog open={trashOpen} onClose={(e) => { setTrashOpen(false); if (e) reloadData(); }} trashType={TrashType.Diaries} />
        </div >
    return <div>{t('noRecords')}</div>;
}