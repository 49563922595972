import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DiaryPrint from './DiaryPrint';

export interface DiaryPrintProps {
    signDigitally?: boolean;
    onClose: () => void;
    open: boolean;
    isInvestor: boolean;
    isSubdodavatel?: boolean;
    recordId: number;
    subcontractorPrintId?: number;
    onlyMine: boolean;
}
export default function DiaryPrintDialog(props: DiaryPrintProps, ref: any) {
    const { onClose, open, recordId, signDigitally, onlyMine, subcontractorPrintId, isInvestor, isSubdodavatel } = props;
    const { t } = useTranslation();

    return (
        <Dialog
            fullWidth
            maxWidth="xl"
            open={open}
            sx={{ marginTop: '80px' }}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('PrintPreview')}
            </DialogTitle>
            <DialogContent>
                <DiaryPrint isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} subcontractorPrintId={subcontractorPrintId} onlyMine={onlyMine} localRecordId={recordId} signDigitally={signDigitally} isFromLocalEnv />
            </DialogContent>
        </Dialog>
    );
}