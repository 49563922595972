import { Box, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../../Shared/Tabs/TabPanel';
import tabProps from '../../Shared/Tabs/TabProps';


import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDiaryStore, loadDiaryStore } from '../../../Contexts/DiaryZContext';
import NoRights from '../../Shared/Layouts/NoRights';
import KrosAdminApplicationDetailScene from './Details/KrosAdminApplicationDetailsScene';
import KrosAdminApplicationPermissionScene from './Details/KrosAdminApplicationPermissionScene';
import KrosAdminUpperMenu from './Details/KrosAdminUpperMenu';

interface KrosAdminApplicationTabSceneProps {
    selected?: number;
}

export default function KrosAdminApplicationTabScene(props: KrosAdminApplicationTabSceneProps) {
    const { t, i18n } = useTranslation();
    const { selected } = props;
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    // const diaryPermissionsLoader = useDiaryStore(state => state.load);
    // const [permissions, setPermissions] = useState<GetDiaryPermissionsResponse>();

    // const [permissions, setPermissions] = useState<GetDiaryPermissionsResponse>();
    const params = useParams();
    useEffect(() => {
        if (selected !== undefined)
            setSelectedTabIndex(selected);
    }, [selected]);
    useEffect(() => {
        /* if (isSubdodavatel) {
            if (selectedTabIndex === 0)
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/subcontractor/diaries");
            else if (selectedTabIndex === 1)
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/subcontractor/diariesDiary");
        }
        else {
            if (selectedTabIndex === 0 && window.location.pathname.endsWith('diaries')) {
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/diaryvov");
            }
            else if (selectedTabIndex === 1 && window.location.pathname.endsWith('diaryvov')) {
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/diaryvovdiaries");
            }
        } */
    }, [selectedTabIndex]);
    useEffect(() => {

    }, []);
    return (
        <div style={{ marginTop: '60px' }}>
            <KrosAdminUpperMenu />
            {/* <div>
            <div>hledání</div>
            <div>Nový záznam</div>
            <div>Tisk dropdown</div>
            <div>Smazat </div>
        </div> */}
            <Box >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={(e, n) => { setSelectedTabIndex(n) }} aria-label="basic tabs example">
                        <Tab label={'Aplikácia'} {...tabProps(0)} />
                        <Tab label={'Práva'} {...tabProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={selectedTabIndex} index={0}>
                    <div>
                        <KrosAdminApplicationDetailScene />
                    </div>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <div>
                        <KrosAdminApplicationPermissionScene />
                    </div>
                </TabPanel>
            </Box>
        </div>);
}