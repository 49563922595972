import { Book, Clear, Delete, Done } from '@mui/icons-material';
import { Button, IconButton, Link, Switch, useTheme } from '@mui/material';
import { ColumnDirective, ColumnsDirective, FilterSettingsModel, GridComponent, Inject, RecordDoubleClickEventArgs, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import { AdminApplicationDTO, GetDiariesForUserDTO } from '../../../ApiOld/ApiServerVov';
import { useDiaryStore } from '../../../Contexts/DiaryZContext';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';
import { APIVovKrosAdminHelper } from '../../../ApiOld/ApiKrosAdminOldClient';
import DeleteDialog from '../../Shared/Layouts/DeleteDialog';


interface KrosAdminApplicationsSceneProps {
    fromExternal?: boolean;
    isSubdodavatel?: boolean;
}

export default function KrosAdminApplicationsScene(props: KrosAdminApplicationsSceneProps) {
    InitSfGridLocale();
    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };
    const [applications, setApplications] = useState<Partial<AdminApplicationDTO>[] | null>(null);
    const [trashOpen, setTrashOpen] = useState<boolean>(false);
    const [deleteData, setDeleteData] = useState<AdminApplicationDTO | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const navigate = useNavigate();
    const permissionsState = useDiaryStore((state) => state.permissions);
    const theme = useTheme();
    const params = useParams();
    const { t, i18n } = useTranslation();
    function toApplicationDetail(props: AdminApplicationDTO) {
        navigate('/' + params['lang'] + '/kros/admin/applications/' + props.id);
    }

    const changeIsActive = (args) => {
        if (grid.current) {
            let rowDetails = grid.current.getRowInfo(args.currentTarget);
            if (rowDetails && rowDetails.rowIndex !== undefined && rowDetails.rowData) {
                (rowDetails.rowData as AdminApplicationDTO).isActive = args.target.checked;

                grid.current.updateRow(rowDetails.rowIndex, rowDetails.rowData); //it helps to update the changes in datasource

                grid.current.refresh();
                new APIVovKrosAdminHelper().SetIsAppActiveKrosAdmin((rowDetails.rowData as AdminApplicationDTO).id!, args.target.checked);
            }
        }
    }
    const changeIsPaying = (args) => {
        if (grid.current) {
            let rowDetails = grid.current.getRowInfo(args.currentTarget);
            if (rowDetails && rowDetails.rowIndex !== undefined && rowDetails.rowData) {
                (rowDetails.rowData as AdminApplicationDTO).isPaying = args.target.checked;

                grid.current.updateRow(rowDetails.rowIndex, rowDetails.rowData); //it helps to update the changes in datasource

                grid.current.refresh();
                new APIVovKrosAdminHelper().SetIsAppPayingKrosAdmin((rowDetails.rowData as AdminApplicationDTO).id!, args.target.checked);
            }
        }
    }
    const changeShowPaying = (args) => {
        if (grid.current) {
            let rowDetails = grid.current.getRowInfo(args.currentTarget);
            if (rowDetails && rowDetails.rowIndex !== undefined && rowDetails.rowData) {
                (rowDetails.rowData as AdminApplicationDTO).showNotPaying = args.target.checked;

                grid.current.updateRow(rowDetails.rowIndex, rowDetails.rowData); //it helps to update the changes in datasource

                grid.current.refresh();
                new APIVovKrosAdminHelper().SetShowPayingKrosAdmin((rowDetails.rowData as AdminApplicationDTO).id!, args.target.checked);
            }
        }
    }

    function gridTemplateIsActive(props: AdminApplicationDTO) {
        return (<div>
            <Switch checked={props.isActive}
                onChange={(e, checked) => {
                    changeIsActive(e);
                }}
            />
        </div >);
    }
    function gridTemplateIsPaying(props: AdminApplicationDTO) {
        return (<div>
            <Switch checked={props.isPaying} onChange={async (e, checked) => {
                changeIsPaying(e);
            }} />
        </div >);
    }
    function gridTemplateShowPaying(props: AdminApplicationDTO) {
        return (<div>
            <Switch checked={props.showNotPaying} onChange={async (e, checked) => {
                changeShowPaying(e);
            }} />
        </div >);
    }
    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }

    const grid = useRef<GridComponent>(null);
    function reloadData() {
        new APIVovKrosAdminHelper().GetApplications().then((r) => {
            setApplications(r);
            if (grid.current)
                grid.current.refresh();
        });
    }
    useEffect(() => {
        if (applications === null)
            reloadData();
    }, []);

    function deleteDialogClose(e: any) {
        // e.cancel = true;
        setDeleteDialogOpen(false);
        setDeleteData(null);
    }

    function appDelete(args: any) {
        if (deleteData && deleteData.id) {
            new APIVovKrosAdminHelper().DeleteApplicationKrosAdmin(deleteData.id).then(e => {
                if (e === true) {
                    setDeleteDialogOpen(false);
                    setDeleteData(null);
                }
            });
        }
    }

    if (applications !== null)
        return <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'start' }}>
            <UpperMenu moduleName='Prehľad aplikácií'
                gridType={GridType.Users}
                canAdd={false}
                canAddSecond={false}
                addLabel={t('Main')}
                canDelete={false}
                onDelete={(e) => {
                    /* new ApiDiaryBaseHelper(props.isSubdodavatel == undefined ? false : props.isSubdodavatel, false).DeleteDiaries(e as number[]).then((e) => {
                        if (e)
                            reloadData();
                    }) */
                }}
                canChangeEyeView={false}
                grid={grid} canChangeGroup canToolbarGrid
                menuName={t('View')}
                canTrash={false}
                onThrashClicked={() => { setTrashOpen(true) }}
                icon={<Book />}
            />
            <div>
                <div style={{ display: 'flex' }}>
                    <Link sx={{ cursor: 'pointer', marginLeft: 'auto', marginRight: '0px' }} onClick={() => {
                        grid.current?.clearFiltering();
                    }}>{t('clearFiltering')}</Link>
                </div>
                <GridComponent ref={grid}
                    id="grid-component-kros-app" allowSorting={true}
                    allowReordering={true} allowResizing={true} showColumnChooser={true}
                    allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                    locale={i18n.language} enablePersistence={false}
                    recordDoubleClick={(e: RecordDoubleClickEventArgs) => {
                        toApplicationDetail(e.rowData as AdminApplicationDTO);
                    }}
                    filterSettings={filterOptions}
                    allowPaging={true}
                    pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                    toolbarClick={toolbarClickExcel}
                    selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                    allowFiltering={true} groupSettings={groupOptions}
                    dataSource={applications}>
                    <ColumnsDirective>
                        <ColumnDirective field='id' width='80' headerText="ID" textAlign="Right" visible={true} />
                        <ColumnDirective field='name' headerText={t('Name')} width='120' />
                        <ColumnDirective field='url' headerText={'URL'} width='120' />
                        <ColumnDirective field='key' headerText={'Aplikácia'} width='100' />
                        <ColumnDirective field='dataAmount' headerText={'Objem dát'} width='100' />
                        <ColumnDirective field='createdDate' headerText={'Dátum vytvorenia'} width='100' type='date' format={i18n.language === 'en' ? 'MM/dd/yyyy hh:mm' : 'dd.MM.yyyy hh:mm'} />
                        {/* <ColumnDirective field='createdSource' headerText={'Zdroj vytvorenia'} width='100' /> */}
                        <ColumnDirective field='isActive' headerText={'Aktívne'} width='90' template={gridTemplateIsActive} editTemplate={gridTemplateIsActive} />
                        <ColumnDirective field='isPaying' headerText={'Platiaci'} width='90' template={gridTemplateIsPaying} editTemplate={gridTemplateIsPaying} />
                        <ColumnDirective field='showNotPaying' headerText={'Hláška neplatiči'} width='90' template={gridTemplateShowPaying} editTemplate={gridTemplateShowPaying} />
                    </ColumnsDirective>
                    <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                </GridComponent></div>
            {/* <TrashGridDialog open={trashOpen} onClose={(e) => { setTrashOpen(false); if (e) reloadData(); }} trashType={TrashType.Diaries} /> */}

            <DeleteDialog onClose={deleteDialogClose} open={deleteDialogOpen} data={deleteData} onDeleteAgree={event => appDelete(event)} />
        </div >
    return <div>{t('noRecords')}</div>;
}