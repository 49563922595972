import { ArrowBack, ArrowForward, Download } from '@mui/icons-material';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import 'dayjs/locale/cs';
import 'dayjs/locale/pl';
import 'dayjs/locale/en';
import 'dayjs/locale/sk';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { DiaryViewerItemDTO } from '../../../../ApiOld/ApiServerVov';
import PDFViewer from '../../../Shared/FileViewers/PDFViewer';
import downloadFile from '../../../Shared/Functions/DownloadFile';
import DiaryPrint from './DiaryPrint';
import fileDownload from 'js-file-download'
import { useMediaPredicate } from 'react-media-hook';
import saveBlob from '../../../Shared/Files/SaveBlob';
import { useLoginAdminStore } from '../../../../Contexts/LoginAdminZContext';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import { useLoginSubcontractorStore } from '../../../../Contexts/LoginSubcontractorZContext';
interface DiariesPagerProps {
    diaryId?: number;
    recordId?: number;
    isSubdodavatel?: boolean;
}
function handleDownload(url: string, filename: string, isSubdodavatel?: boolean) {
    new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).DownloadFile(url).then((fil) => {
        if (fil !== undefined)
            saveBlob(fil.data, filename);
    });
}
export default function DiaryPager(props: DiariesPagerProps) {
    const { t, i18n } = useTranslation();
    const { diaryId, recordId, isSubdodavatel } = props;
    const [diaryRecords, setDiaryRecords] = useState<DiaryViewerItemDTO[] | null>();
    const [actualDiaryRecord, setActualDiaryRecord] = useState<DiaryViewerItemDTO | null>();
    const [actualDate, setActualDate] = useState<Date>();
    const [actualIndex, setActualIndex] = useState<number>(1);
    const [reloadPreview, setReloadPreview] = useState<boolean>(false);
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    useEffect(() => {
        // console.log('changing');
        if (diaryRecords) {
            // console.log('setting');
            setActualDiaryRecord(diaryRecords[actualIndex]);
            setReloadPreview(!reloadPreview);
            setActualDate(diaryRecords[actualIndex].date);
        }
    }, [actualIndex, diaryRecords]);

    useEffect(() => {
        if (diaryRecords !== undefined && diaryRecords !== null) {
            if (recordId !== undefined) {
                let index = diaryRecords.findIndex(x => x.id === recordId);
                if (index > -1) {
                    setActualIndex(index);
                }
            }
            else if (diaryRecords.length > 0) {
                // setDiaryRecords(diaryRecords![diaryRecords.length - 1]!);
                setActualIndex(diaryRecords.length - 1);
            }

        }

    }, [diaryRecords, recordId]);



    useEffect(() => {
        if (diaryId) {
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetRecordsForViewing(diaryId).then((d) => {
                if (d !== undefined && d.viewerRecords !== undefined && d.viewerRecords.length > 0) {

                    setDiaryRecords(d.viewerRecords);
                    // setActualDiaryRecord(d.viewerRecords[d.viewerRecords.length - 1]);
                    // setActualIndex(d.viewerRecords.length - 1);
                }
            });
        }
    }, [diaryId]);
    const params = useParams();
    const { load } = useLoginAdminStore();
    const { loadSub } = useLoginSubcontractorStore();
    useEffect(() => {
        if (diaryId) {
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetRecordsForViewing(diaryId).then((d) => {
                if (d !== undefined && d.viewerRecords !== undefined && d.viewerRecords.length > 0) {

                    setDiaryRecords(d.viewerRecords);
                    // setActualDiaryRecord(d.viewerRecords[d.viewerRecords.length - 1]);
                    setActualIndex(d.viewerRecords.length - 1);
                    setActualDate(d.viewerRecords[d.viewerRecords.length - 1].date);
                }


            });
        }
        else {
            let adminUserId = parseInt(params['userId']!);
            let diaryId = parseInt(params['id']!);
            let token = params['token'];
            i18n.changeLanguage(params['lang']!);
            if (token !== undefined) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).LoginErp(token, adminUserId, params['domain']!).then((s) => {
                    if (s != undefined && s.userName) {
                        if (isSubdodavatel === true)
                            loadSub(s.userName, adminUserId);
                        else
                            load(s.userName, adminUserId);
                    }
                    new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetRecordsForViewing(diaryId).then((d) => {
                        if (d !== undefined && d.viewerRecords !== undefined && d.viewerRecords.length > 0) {

                            setDiaryRecords(d.viewerRecords);
                            // setActualDiaryRecord(d.viewerRecords[d.viewerRecords.length - 1]);
                            setActualIndex(d.viewerRecords.length - 1);
                            setActualDate(d.viewerRecords[d.viewerRecords.length - 1].date);
                        }


                    });
                });
            }
        }

    }, []);
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button variant='contained' startIcon={<ArrowBack />}
                    disabled={diaryRecords !== undefined && (actualIndex < 1)}
                    sx={{ margin: '8px' }} onClick={(e) => { setActualIndex(actualIndex - 1); }}>{biggerThan700 ? t('Back') : ''}</Button>
                {actualDate !== undefined && actualDate !== null /* && actualDiaryRecord!.date !== undefined */ &&
                    <LocalizationProvider locale={i18n.language} dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD.MM.YYYY"

                            shouldDisableDate={(d) => {
                                if (d) {
                                    const currentDate = d.toISOString().split("T")[0];
                                    if (diaryRecords!.some(x => x.date && x.date!.toISOString().split("T")[0] === currentDate))
                                        return false;
                                    else return true;
                                }
                                else return true;
                            }} value={actualDate} onChange={(e) => {
                                if (e !== null) {
                                    const dateValue = new Date(e);
                                    if (!isNaN(dateValue.getTime())) {
                                        let item = diaryRecords!.find(x => x.date && x.date!.toISOString().split("T")[0] === e.toISOString().split("T")[0]);
                                        if (item) {
                                            let a = diaryRecords!.indexOf(item!);
                                            console.log(a);
                                            setActualDiaryRecord(item!);
                                            setActualIndex(a);
                                            setActualDate(item!.date);
                                        }
                                        else {
                                            setActualDiaryRecord(undefined);
                                            setActualDate(dateValue);

                                        }
                                    }
                                }
                            }} renderInput={(params) => <TextField sx={{ maxWidth: '160px', margin: '8px' }} size='small' {...params} />} />
                    </LocalizationProvider>}
                {/* {actualDiaryRecord !== undefined && actualDiaryRecord !== null && actualDiaryRecord!.date !== undefined && <p>{actualDiaryRecord!.date!.toDateString()}</p>} */}
                <Button endIcon={<ArrowForward />} disabled={diaryRecords !== undefined && (actualIndex > diaryRecords!.length - 2)}
                    variant='contained' sx={{ margin: '8px' }} onClick={(e) => { setActualIndex(actualIndex + 1); }}>{biggerThan700 ? t('Forward') : ''}</Button>
                {actualDiaryRecord !== undefined && actualDiaryRecord !== null && actualDiaryRecord!.signatureType !== undefined && actualDiaryRecord!.signatureType === 3 && <IconButton onClick={(e) => { handleDownload(actualDiaryRecord.downloadUri!, 'diary' + actualDiaryRecord.date!.toDateString()! + '.pdf', isSubdodavatel) }} ><Download /></IconButton>}
            </div>
            <div>
                {actualDiaryRecord !== undefined && actualDiaryRecord !== null && actualDiaryRecord!.signatureType !== undefined && actualDiaryRecord!.signatureType === 3 && <PDFViewer newApi={false} address={actualDiaryRecord !== null && actualDiaryRecord !== undefined ? actualDiaryRecord.downloadUri : undefined} />}
                {/* {actualDiaryRecord !== undefined && actualDiaryRecord !== null && actualDiaryRecord!.digitallySigned !== undefined && actualDiaryRecord!.digitallySigned === true && <div >je digítál</div>} */}
                {actualDiaryRecord !== undefined && actualDiaryRecord !== null && actualDiaryRecord!.signatureType !== undefined && actualDiaryRecord!.signatureType !== 3 && <div style={{ marginTop: '20px' }}><DiaryPrint isInvestor={false} reload={reloadPreview} hidePrinter isFromLocalEnv localRecordId={actualDiaryRecord!.id} /></div>}
                {(actualDiaryRecord === undefined || actualDiaryRecord === null) &&
                    <div style={{ marginTop: '24px', marginLeft: '48px' }}>
                        <Typography>{t('diary.noSignedPDF')}</Typography>
                    </div>
                }
            </div>
        </div>);
}