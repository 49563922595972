import { Close } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { t } from 'i18next';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook'
import { useParams } from 'react-router';
import ProductFruitsAdminUser from '../../../product-fruits/ProductFruitsAdminUser';

export default function MoveBackToStavarioToolbar() {
    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const params = useParams();
    const handleBackToStavario = () => {
        window.location.href = 'https://' + params['domain']! + '.vimovsem.cz/admin';
    }
    return (
        <div style={{
            position: 'fixed', display: 'flex',
            zIndex: '10000',
            justifyContent: 'space-between', height: '52px',
            left: '0', top: '0', width: '100%', background: 'white',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
        }}>
            <div className="logo-up">
                <img

                    onClick={handleBackToStavario}
                    style={{ marginTop: '10px', marginLeft: '20px' }}
                    width="160px"
                    height="30px"
                    src="https://stavariokluc.vimovsem.cz/images/login-logo-stavario.svg"
                    alt="stavario"
                ></img>
            </div>
            {biggerThan700 && <ProductFruitsAdminUser />}
            <div>
                {biggerThan700 && <Button onClick={handleBackToStavario} sx={{ marginTop: '10px' }} color='secondary' endIcon={<Close />}>{t('backToOldStavario')}</Button>}
                {!biggerThan700 && <IconButton onClick={handleBackToStavario} sx={{ marginTop: '10px' }} color='secondary' ><Close /></IconButton>}
            </div>
        </div>
    )
}
