import { ContentPasteOffSharp, TodaySharp } from "@mui/icons-material";
import axios from "axios";
import { ApiBaseHelper } from "./ApiBaseHelper";
import { APIUsersHelper } from "./ApiUsersHelper";
import { AddTodoAttachmentEndRequest, AddTodoAttachmentResponse, AddTodoRequest, DeleteTodoDTO, DeleteTodosRequest, GetTodoHistoryResponse, SuccessUpdateResponse, TodoAttachmentDTO, TodoClient, TodoDTO, TodoGanttDTO, UpdateTaskPositionsDTO, UpdateTaskPositionsRequest, UpdateTodoKanbanDTO, UpdateTodoKanbanRequest, UpdateTodoRequest, UserResponsibleDTO } from "./TodoMicroserviceClient";

export class APITodoHelper extends ApiBaseHelper {

    private GetAdressedAuthRefreshableClient(): TodoClient {

        return new TodoClient(this.GetApiAddress() + '/todos', this.GetAuthClient());
    }
    public async GetTodos(): Promise<TodoDTO[]> {
        let t = (await this.GetAdressedAuthRefreshableClient().getTodos()).todos!;
        // console.log(t);
        return t;
    }
    public async GetTodoHistory(todoId: number): Promise<GetTodoHistoryResponse> {
        let t = (await this.GetAdressedAuthRefreshableClient().history(todoId));
        return t;
    }
    public async GetGanttTodos(): Promise<TodoGanttDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getGanttTodos()).todos!;
    }
    public async DeleteTodos(data: DeleteTodoDTO[]): Promise<void> {
        await this.GetAdressedAuthRefreshableClient().deleteMany(new DeleteTodosRequest({ selectedTodos: data }));
    }
    public async GetUsers(): Promise<UserResponsibleDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().usersResponsible()).users!;
    }
    public async GetAttachmentFileDownloadLink(todoId: number): Promise<string> {
        return (await this.GetAdressedAuthRefreshableClient().getDownloadUri(todoId, false)).link!;
    }
    public async GetAttachmentFileDownloadToOfficeEmbed(todoId: number): Promise<string> {
        return (await this.GetAdressedAuthRefreshableClient().getDownloadUri(todoId, true)).link!;
    }
    public async AddTodoAttachment(filename: string, todoId: number): Promise<AddTodoAttachmentResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getSaveFileToken(filename, todoId));
    }
    public async AddTodoAttachmentEnd(todoAttachmentId: number): Promise<void> {
        (await this.GetAdressedAuthRefreshableClient().endUploadTodoAttachment(new AddTodoAttachmentEndRequest({ attachmentId: todoAttachmentId })));
    }
    public async GetTodosAttachments(todoId: number): Promise<TodoAttachmentDTO[] | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().listAttachmentsTodoId(todoId)).attachments;
    }
    public async DeleteTodoAttachment(todoAttachmentId: number): Promise<void> {
        (await this.GetAdressedAuthRefreshableClient().deleteAttachmentTodo(todoAttachmentId));
    }
    public async LoadPDF(): Promise<void> {
        (await this.GetAdressedAuthRefreshableClient().load({}));
    }
    public async CreateTodo(todo: Partial<TodoDTO>): Promise<TodoDTO | undefined> {
        let t = await this.GetAdressedAuthRefreshableClient().addTodo(new AddTodoRequest({
            name: todo.name,
            responsibleId: todo.assigneId,
            state: todo.state,
            description: todo.description,
            start: todo.start,
            end: todo.end,
            deadline: todo.deadline,
            assignees: todo.assignees,
            buildingId: todo.buildingId,
            leftMarker: todo.markerLeft,
            topMarker: todo.markerTop,
            markerColor: todo.color
        }));
        return new TodoDTO({ id: t.id, rowVersion: t.rowVersion });
    }
    public async UpdateTodosPositions(updates: UpdateTaskPositionsDTO[]): Promise<void> {
        await this.GetAdressedAuthRefreshableClient().updateTaskPositions(new UpdateTaskPositionsRequest({ positionsUpdates: updates }));
    }
    public async UpdateKanbanTodos(updates: UpdateTodoKanbanDTO[]): Promise<void> {
        (await this.GetAdressedAuthRefreshableClient().updateKanban(new UpdateTodoKanbanRequest({ updates: updates })));
    }
    public async UpdateTodo(todo: Partial<TodoDTO>): Promise<TodoDTO | SuccessUpdateResponse | undefined> {

        try {
            let success = await this.GetAdressedAuthRefreshableClient().updateTodo(new
                UpdateTodoRequest({
                    id: todo.id,
                    name: todo.name,
                    responsibleId: todo.assigneId,
                    state: todo.state,
                    description: todo.description,
                    start: todo.start,
                    end: todo.end,
                    deadline: todo.deadline,
                    assignees: todo.assignees,
                    buildingId: todo.buildingId,
                    markerColor: todo.color,
                    rowVersion: todo.rowVersion
                }));
            return success;
        }
        catch (e: any) {
            console.log('catch concurrency', e.data);
            if (e.state === 2)
                return new TodoDTO({
                    id: e.data.id,
                    name: e.data.name,
                    assigneId: e.data.assigneId,
                    assigneName: e.data.assigneName,
                    stateLocalizedString: e.data.stateLocalizedString,
                    state: e.data.state,
                    rowVersion: e.data.rowVersion
                });
            // return new TodoDTO({ id: e.result602.id });

        }
        // })).catch((e) => {
        //     console.log(e.result602);
        //     return new TodoDTO({ id: e.result602.id });
        // });

    }
}