import { create } from 'zustand'
import { GetSubcontractorInfoResponse } from '../ApiOld/ApiServerVov'
import { APIVovSubcontractorHelper } from '../ApiOld/ApiSubcontractorOldClient'

interface SubcontractorStoreState {
    credentials?: GetSubcontractorInfoResponse
    load: (perms: GetSubcontractorInfoResponse) => void
}
export const loadSubcontractorStore = () => {
    return new APIVovSubcontractorHelper().GetSubcontractorInfo();
}
export const useSubcontractorStore = create<SubcontractorStoreState>()((set) => ({
    credentials: undefined,
    load: (by) => set((state) => ({ credentials: by })),
}))