import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { JsxElement } from 'typescript';
import { useMediaPredicate } from 'react-media-hook';

// const options = ['xxx Uložit', 'xxx Uložit a zavřít'];
export interface SplitButtonNoPustProps {
    startIconSplitButton?: React.ReactNode;
    selectedIndexInput: number;
    buttonText: string;
    // open: boolean;
    options: SplitButtonItem[];
    onMenuClick: (value: number) => void;
    disabled?: boolean;
    selectOnClick?: boolean;
    size?: "small" | "medium" | "large";
    variant?: "outlined" | "contained";
}
export interface SplitButtonItem {
    text: string;
    isEnabled: boolean;
}
export default function SplitButton(props: SplitButtonNoPustProps) {
    const { onMenuClick, options, disabled, startIconSplitButton, size, selectedIndexInput, selectOnClick, variant, buttonText } = props;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(selectedIndexInput);
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    const handleClick = () => {
        // console.info(`You clicked ${options[selectedIndex]}`);
        onMenuClick(selectedIndex);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
        if (selectOnClick)
            onMenuClick(index);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup size={size} disabled={disabled} variant={variant} ref={anchorRef} aria-label="split button">
                <Button size='small' startIcon={startIconSplitButton} style={{ border: variant === 'outlined' ? '2px solid' : '0px solid' }} onClick={handleToggle}>{biggerThan700 ? buttonText : ''}</Button>
                <Button size='small'
                    style={{
                        border: variant === 'outlined' ? '2px solid' : '0px solid',
                        borderLeft: variant === 'outlined' ? '0px solid' : '1px solid', maxWidth: '36px', minWidth: '36px'
                    }}
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 20,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            disabled={!option.isEnabled}
                                            key={option.text}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}                                        >
                                            {option.text}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}