
import { Check, Save } from '@mui/icons-material';
import { Button, CircularProgress, TextField } from '@mui/material';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { APIOldWealthClient } from '../../../ApiOld/ApiOldWealthClient';
import { SaveWealthExpenditureReqDTO, WealthExpenditureDTO } from '../../../ApiOld/ApiServerVov';
import delay from '../../Shared/Functions/Delay';
import InitSfRTBLocale from '../../../Localization/SFRichEditLanguageIntializer';
import { DataManager, Query } from '@syncfusion/ej2-data';

import { ColumnDirective, ColumnsDirective, Edit, EditSettingsModel, GridComponent, IEditCell, Inject, Resize, Sort, Toolbar } from '@syncfusion/ej2-react-grids';

interface WealthExpendituresProps {

}

InitSfRTBLocale();

function WealthExpenditures(props: WealthExpendituresProps) {
    const param = useParams();
    const [wealthId, setWealthId] = useState<number>(58011705);
    const [saved, setSaved] = useState<boolean>(false);
    const [wealthExpenditures, setWealthExpenditures] = useState<WealthExpenditureDTO[] | null>(null);
    const { t, i18n } = useTranslation();

    // useEffect(() => {
    //     let num = parseInt(param['id']!);
    //     if (!isNaN(num))
    //         setWealthExpenditureId(num);
    // }, []);
    useEffect(() => {
        refresh();
    }, [wealthId]);
    useEffect(() => {
        if (saved) delay(1000).then(() => { setSaved(false) })
    }, [saved]);



    const editSettings: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch', showConfirmDialog: false };

    const toolbarOptions: object[] = [{ text: 'Přidat', id: 'grid-component-expenditures_add', prefixIcon: 'e-add' },
    { text: 'Smazat', id: 'grid-component-expenditures_delete', prefixIcon: 'e-delete' },
    { text: 'Updates', id: 'grid-component-expenditures_update', prefixIcon: 'e-update' },
    { text: 'Zrusit', prefixIcon: 'e-cancel', id: 'grid-component-expenditures_cancel' }];


    const grid = useRef<GridComponent>(null);


    function refresh() {
        if (wealthId)
            new APIOldWealthClient().GetWealthExpenditures(wealthId).then((r) => { if (r.wealthExpenditures) setWealthExpenditures(r.wealthExpenditures); if (grid.current) grid.current.refresh(); });
    }

    function clickHandler(e: any) {
        let instance = (document.getElementById("grid-component-expenditures") as HTMLElement);
        if ((e.target as HTMLElement).classList.contains("e-rowcell")) {

            //@ts-ignore
            let index: number = parseInt((e.target as HTMLElement).parentElement.getAttribute("aria-rowindex"));
            console.log((e.target as HTMLElement));
            //@ts-ignore
            let colindex: number = parseInt((e.target as HTMLElement).getAttribute("data-colindex"));
            //@ts-ignore
            let field: string = instance.ej2_instances[0].getColumns()[colindex].field;
            // if (index === grid.current!.recordsCount)
            //     delay(2500).then(() => { grid.current!.editCell(index - 1, field); });
            // else
            grid.current!.editCell(index - 1, field);
            // if ((grid.current!.isEdit === true)) {
            //     console.log('edit');
            //     grid.current!.editCell(index, field);
            //     // grid.current!.editModule.closeEdit();
            // }
            // else {
            //     // console.log('not edit');
            //     grid.current!.editModule.closeEdit();
            //     grid.current!.editCell(index, field);



            // }

            // grid.current!.editCell(index - 1, field);

            // instance.ej2_instances[0].editModule.editCell((index - 1), field);
        };
    }

    const loaded = useRef(false);

    const singleClickLoad = (id: string): void => {
        //@ts-ignore
        let instance = (document.getElementById(id) as HTMLElement);
        if (instance) {
            if (!loaded.current) {
                instance.removeEventListener('mouseup', clickHandler);
                instance.addEventListener('mouseup', clickHandler);
                loaded.current = true;
            }
        }
    }

    function save(e: any) {
        e.cancel = true;

        if (wealthId) {
            let added = e.batchChanges.addedRecords.map((add: any) => {
                return new SaveWealthExpenditureReqDTO({
                    id: 0,
                    date: add.date,
                    name: add.name,
                    description: add.description,
                    price: add.price,
                    // wealthName: add.wealthName
                })
            });
            let changed = e.batchChanges.changedRecords.map((chang: any) => {
                return new SaveWealthExpenditureReqDTO({
                    id: chang.id,
                    date: chang.date,
                    name: chang.name,
                    description: chang.description,
                    price: chang.price,
                    // wealthName: chang.wealthName
                })
            });
            let deleted = e.batchChanges.deletedRecords.map((del: any) => {
                return new SaveWealthExpenditureReqDTO({
                    id: -del.id,
                    date: del.date,
                    name: del.name,
                    description: del.description,
                    price: del.price,
                    // wealthName: del.wealthName
                })
            });
            let b: SaveWealthExpenditureReqDTO[] = [...added, ...changed, ...deleted];
            if (wealthId)
                new APIOldWealthClient().saveWealthExpenditures(wealthId, b).then((r) => { if (r) refresh(); })

            // e.batch
        }
        else {
            let added = e.batchChanges.addedRecords.map((add: any) => {
                return new WealthExpenditureDTO({
                    id: 0,
                    // date: add.date,
                    name: add.name,
                    // description: add.description,
                    price: add.price
                })
            });
            let changed = e.batchChanges.changedRecords.map((chang: any) => {
                return new WealthExpenditureDTO({
                    id: chang.id,
                    // date: chang.date,
                    name: chang.name,
                    // description: chang.description,
                    price: chang.price
                })
            });
            let deleted = e.batchChanges.deletedRecords.map((del: any) => {
                return new WealthExpenditureDTO({
                    id: -del.id,
                    // date: del.date,
                    name: del.name,
                    // description: del.description,
                    price: del.price
                })
            });
            let b: WealthExpenditureDTO[] = [...added, ...changed, ...deleted];
            // if (onChanged)
            // onChanged(b);
        }
    }

    if (wealthExpenditures === null)
        return <div>{t('loading...')}</div>;
    return (
        <div>
            <GridComponent
                style={{ borderColor: 'transparent' }} id="grid-component-expenditures"
                allowSorting={true}
                beforeBatchAdd={(e) => { e.defaultData.id = 0; e.defaultData.date = new Date(); }}
                beforeBatchSave={(e) => { save(e) }}
                ref={grid}
                load={(e) => { singleClickLoad("grid-component-expenditures") }}
                editSettings={editSettings}
                toolbar={toolbarOptions}
                allowReordering={false} allowResizing={true} showColumnChooser={true}
                locale={i18n.language}
                enablePersistence={false}
                resizeSettings={{ mode: 'Normal' }}
                allowFiltering={false}
                dataSource={wealthExpenditures}
            >
                <ColumnsDirective>
                    <ColumnDirective field='id' headerText='ID' width='46' textAlign="Right" isPrimaryKey visible={false} />
                    <ColumnDirective field='date' headerText={t('Date')} type='Date' editType='datepickeredit' format={{ type: 'date', format: 'dd. MM. yyyy' }} maxWidth='200' width='200' minWidth='200' />
                    <ColumnDirective field='name' headerText={t('Name')} validationRules={{ required: true }} maxWidth='800' width='300' minWidth='200' />
                    {/* <ColumnDirective field='employee' headerText='xxx zamestnanec' editType='dropdownedit' edit={countryParams} maxWidth='300' width='300' minWidth='200' /> */}
                    <ColumnDirective field='description' headerText={t('Description')} maxWidth='800' width='300' minWidth='200' />
                    <ColumnDirective field='price' headerText={t('Price')} format='N2' editType='numericedit' maxWidth='300' width='300' minWidth='200' />
                </ColumnsDirective>
                <Inject services={[Sort, Toolbar, Resize, Edit]} />
            </GridComponent>
        </div>
    );
};
export default WealthExpenditures;