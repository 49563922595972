
export class DiaryTimelineModel {
    date: string;
    recordId?: number | undefined;
    dateOrigin: Date;
    isFilled: boolean;
    hasPhotos: boolean;
    hasWorkers: boolean;
    hasCompletedWorks: boolean;
    signatureType: number;
    hasWeathers: boolean;
    isLocked: boolean;

    constructor(_date: string, _dateOrigin: Date, _isFilled: boolean, _hasPhotos: boolean,
        _hasWorkers: boolean,
        _hasCompletedWorks: boolean,
        _signatureType: number,
        _hasWeathers: boolean, _isLocked: boolean, _recordId?: number) {
        this.date = _date;
        this.recordId = _recordId;
        this.dateOrigin = _dateOrigin;
        this.isFilled = _isFilled;
        this.hasCompletedWorks = _hasCompletedWorks;
        this.hasPhotos = _hasPhotos;
        this.hasWeathers = _hasWeathers;
        this.signatureType = _signatureType;
        this.hasWorkers = _hasWorkers;
        this.isLocked = _isLocked;
    }
}
