
import { DriveFileRenameOutline, Engineering, Construction, People, PhotoCamera, Cloud, Lock } from "@mui/icons-material";
import { Badge, SvgIconPropsColorOverrides, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { padding } from "@mui/system";
import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { useTranslation } from "react-i18next";
import parseDateSafe from "../../../Shared/DateTime/ParseDateTime";

export function TimelineCard({ date, recordId, title, itemId, selected, isFilled, onClick, hasPhotos,
    hasWorkers, hasWeathers, signatureType, hasCompletedWorks, isLocked, disabled }:
    {
        date: Date, recordId?: number | undefined, title: string; itemId: string, selected: boolean, isFilled: boolean,
        onClick: (id: string) => void, hasPhotos: boolean, hasWorkers: boolean, hasWeathers: boolean, signatureType: number, hasCompletedWorks: boolean,
        isLocked: boolean, disabled: boolean
    }) {
    const visibility = React.useContext(VisibilityContext);
    const { t, i18n } = useTranslation();
    const visible = visibility.isItemVisible(itemId);
    const theme = useTheme();
    function getColor() {
        if (recordId === undefined)
            return 'gray';
        else {
            if (isFilled)
                return theme.palette.success.main;
            else return theme.palette.secondary.main;
        }
        // return recordId === undefined ? theme.palette.secondary.main : theme.palette.success.main
    }
    function getSignatureColor() {
        if (signatureType === 0)
            return 'transparent';
        else if (signatureType === 3)
            return theme.palette.success.main;
        else if (signatureType === 2)
            return theme.palette.secondary.main;
        else return theme.palette.info.main;
    }
    return (
        <div
            onClick={() => { onClick(itemId); }}
            role="button"
            style={{
                opacity: disabled ? .5 : 1,
                fontFamily: 'Roboto',
                border: "1px solid",
                borderColor: '#ECECEC',
                display: "inline-block",
                margin: '0px 4px',
                width: "112px",
                userSelect: "none",
                height: "42px",
                backgroundColor: selected ? theme.palette.primary.main : '#ECECEC'
            }}
            tabIndex={0}
            className="card"
        >

            <div >
                {/* {i18n.language} */}
                <div style={{ color: '#404040', marginLeft: '0px', overflow: 'hidden' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', float: 'left' }}>
                        <div style={{ marginLeft: isLocked ? '16px' : '20px', display: 'flex' }}>
                            {i18n.language === 'en' && <Typography style={{ color: getColor(), fontWeight: 'bold', marginTop: recordId === undefined ? '10px' : '2px', fontSize: recordId === undefined ? '13px' : '13px' }}>
                                {parseDateSafe(title).toLocaleString(i18n.language, { month: "numeric" })}-{parseDateSafe(title).toLocaleString(i18n.language, { day: "numeric" })}

                            </Typography>}
                            {(i18n.language === 'cs' || i18n.language === 'cs-CZ') && <Typography style={{ color: getColor(), fontWeight: 'bold', marginTop: recordId === undefined ? '10px' : '2px', fontSize: recordId === undefined ? '13px' : '13px' }}>
                                {parseDateSafe(title).toLocaleString(i18n.language, { day: "numeric" })}{parseDateSafe(title).toLocaleString(i18n.language, { month: "numeric" })}.

                            </Typography>}
                            {(i18n.language === 'sk') && <Typography style={{
                                color: getColor(), fontWeight: 'bold',
                                marginTop: recordId === undefined ? '10px' : '2px', fontSize: recordId === undefined ? '13px' : '13px'
                            }}>
                                {parseDateSafe(title).toLocaleString(i18n.language, { day: "numeric" })}{parseDateSafe(title).toLocaleString(i18n.language, { month: "numeric" })}

                            </Typography>}

                            {i18n.language === 'pl' && <Typography style={{ color: getColor(), fontWeight: 'bold', marginTop: recordId === undefined ? '10px' : '2px', fontSize: recordId === undefined ? '13px' : '13px' }}>
                                {parseDateSafe(title).toLocaleString(i18n.language, { day: "numeric" })}.{parseDateSafe(title).toLocaleString(i18n.language, { month: "numeric" })}.

                            </Typography>}
                            {recordId && isLocked && <Lock sx={{ color: getColor(), height: '13px', width: '13px', marginTop: '4px', marginLeft: '3px' }} />}
                        </div>
                        <div style={{ display: 'flex', marginLeft: '10px' }}>
                            {recordId && <Tooltip title={t('diary.Signed')}><DriveFileRenameOutline sx={{ color: getSignatureColor(), height: '12px', width: '12px', marginTop: '4px' }} /></Tooltip>}
                            {recordId && hasWorkers && <Tooltip title={t('diary.EmployeesInserted')}><Engineering sx={{ height: '12px', width: '12px', marginTop: '4px', marginLeft: '2px' }} /></Tooltip>}
                            {recordId && hasCompletedWorks && <Tooltip title={t('diary.WorksInserted')}><Construction sx={{ height: '12px', width: '12px', marginTop: '4px', marginLeft: '2px' }} /></Tooltip>}
                            {/* {recordId && hasWeathers && <Tooltip title='Vyplněny práce'><Cloud sx={{ height: '12px', width: '12px', marginTop: '4px', marginLeft: '1px' }} /></Tooltip>} */}
                            {recordId && hasPhotos && <Tooltip title={t('diary.PhotosInserted')}><PhotoCamera sx={{ height: '12px', width: '12px', marginTop: '4px', marginLeft: '2px' }} /></Tooltip>}
                        </div>
                    </div>



                    <div style={{
                        display: 'flex', flexDirection: 'column', backgroundColor: 'white', float: 'right',
                        // border: '1px solid',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderColor: '#404040',
                        width: '32px', margin: '2px', borderRadius: '4px',
                        marginRight: '4px',
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}>
                        <div>
                            <Typography style={{ color: getColor(), fontSize: '11px', marginTop: '4px', fontWeight: 'bold' }}> {parseDateSafe(title).toLocaleString(i18n.language, { weekday: 'short' }).toUpperCase()}</Typography>
                        </div>
                        {/* </p>
                        <p style={{ fontWeight: 'bold',marginTop: recordId === undefined ? '6px' : '2px', fontSize: recordId === undefined ? '13px' : '12px' }}> */}
                        <Typography style={{ color: getColor(), fontSize: '10px' }}>{parseDateSafe(title).toLocaleString(i18n.language, { year: "numeric" })}</Typography>
                        {/* </p> */}
                    </div>


                </div>

            </div>

        </div >
    );
}
