import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AddCircleOutline, Check, Forum, Handyman, LocalShipping, Lock, MarkEmailUnread, More, MoreHoriz, Note, NotInterested, Print, QuestionMark, RateReview, Reply, Save, Search, SettingsOutlined, SignLanguage, Widgets } from '@mui/icons-material';
import './Styles/DiaryMainScene.css'
import { useLocation, useNavigate, useParams } from 'react-router';
import WeatherWidget from './DiaryComponents/Widgets/WeatherWidget';
import WorkersWidget from './DiaryComponents/Widgets/WorkersWidget';
import CompletedWorksWidget from './DiaryComponents/Widgets/CompletedWorksWidget';
import { GetDailyRecordsForUserAndDiaryByRecordIdResponse, GetDiaryRecordDataNotTablesResponse, GroupAdminDTO, GroupDTO, ImageDiaryDTO, RequestsDTO, SignatureDTO, SubDiaryDTO, UserDTO } from '../../ApiOld/ApiServerVov';
import { Button, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { ChangeEventArgs, HtmlEditor, Image as RTImage, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { useTranslation } from 'react-i18next';
import '../Scenes/DiaryComponents/Styles/DiaryMainScene.css';
import { SignatureComponent } from '@syncfusion/ej2-react-inputs';
import { useMediaPredicate } from 'react-media-hook';
import { unstable_usePrompt } from 'react-router-dom';
import { Timeline } from './DiaryComponents/Widgets/Timeline';
import { DiaryTimelineModel } from './DiaryComponents/Models/DiaryTimelineModel';
import moment from 'moment';
import withLoading, { WithLoadingProps } from '../Shared/Loader';
import MaterialsWidget from './DiaryComponents/Widgets/MaterialsWidget';
import MachinesWidget from './DiaryComponents/Widgets/MachinesWidget';
import NoDataImageHolder from '../Shared/Layouts/NoDataImageHolder';
import DiarySearchDialog from './DiaryComponents/DiarySearchDialog';
import DiaryRecordCopyDialog from './DiaryComponents/DiaryRecordCopyDialog';
import ReallyLockUnlockDialog from './DiaryComponents/Widgets/ReallyLockUnlockDiaryRecord';
import SignaturesWidget from './DiaryComponents/Widgets/SignaturesWidget';
import { Accordion, AccordionDetails, AccordionSummary } from './DiaryComponents/Styles/AccordionStyles';
import PhotoWidget from './DiaryComponents/Widgets/PhotoWidget';
import { loadDiaryStore, useDiaryStore } from '../../Contexts/DiaryZContext';
import SplitButtonNoPush from '../Shared/SplitButtonNoPush';
import DiaryPrintDialog from './DiaryComponents/DiaryPrints/DiaryPrintDialog';
import InvestorChatWidget from './DiaryComponents/Widgets/InvestorChatWidget';
import SelectGroupDialog from './DiaryComponents/Widgets/SelectGroupDialog';
import TasksInDiaryWidget from './DiaryComponents/Widgets/TasksInDiaryWidget';
import AttachmentsWidget from './DiaryComponents/Widgets/AttachmentsWidget';
import { ApiDiaryBaseHelper } from '../../Api/ApiDiaryBaseHelper';
import SubcontractorChatWidget from './DiaryComponents/Widgets/SubcontractorChatWidget';
import MainContractorChatWidget from './DiaryComponents/Widgets/MainContractorChatWidget';
import CreateUserDialog from './DiaryComponents/Widgets/CreateUserDialog';
import CompletedWorksWidgetOld from './DiaryComponents/Widgets/CompletedWorksWidgetOld';
import LogWidget from './DiaryComponents/Widgets/LogWidget';
import saveBlob from '../Shared/Files/SaveBlob';
import { type } from '@testing-library/user-event/dist/type';
import InvestorTaskCreateDialog from '../Investor/Components/InvestorTaskCreateDialog';
import SplitLoadingButton from '../Shared/Buttons/SplitLoadingButton';
import MessageDialog from '../Shared/MessageDialog';
import delay from '../Shared/Functions/Delay';
import SignaturesUserInfoDialog from './DiaryComponents/Signatures/SignaturesUserInfoDialog';
import DiaryUsersPrintDialog from './DiaryComponents/DiaryPrints/DiaryUsersPrintDialog';
import { loadInvestorPermissionStore, useInvestorPermissionStore } from '../../Contexts/InvestorPermissionZContext';


interface DiaryMainSceneProps extends WithLoadingProps {
    buildingDiaryRecordIdProps?: number;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    fromExternal?: boolean;
}

function DiaryMainScene(props: DiaryMainSceneProps) {

    const [expanded, setExpanded] = React.useState<number[]>([]);
    const completedWorks = React.useRef<string | undefined>();

    const [completedWorksConcurrency, setCompletedWorksConcurrency] = React.useState<string | undefined>();


    const [materials, setMaterials] = React.useState<string | undefined>();
    const [machines, setMachines] = React.useState<string | undefined>();
    const [weatherFallback, setWeatherFallback] = React.useState<string | undefined>();
    const [workersFallback, setWorkersFallback] = React.useState<string | undefined>();
    const [notes, setNotes] = React.useState<string | undefined>();
    const [buildingName, setBuildingName] = React.useState<string | undefined>();
    const [buildingId, setBuildingId] = React.useState<number | undefined>();
    const [diaryName, setDiaryName] = React.useState<string | undefined>();
    const [recordId, setRecordId] = React.useState<number | undefined>(undefined);
    const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(undefined);
    const [diaryId, setDiaryId] = React.useState<number | undefined>(undefined);
    const [subcontractorPrintId, setSubcontractorPrintId] = React.useState<number | undefined>(undefined);
    const [saveGrids, setSaveGrids] = React.useState<boolean>(false);
    const [printOpen, setPrintOpen] = React.useState<boolean>(false);
    const [refreshLog, setRefreshLog] = React.useState<boolean>(false);
    const [saveButonDisabled, setSaveButonDisabled] = React.useState<boolean>(false);
    const [refreshSignatures, setRefreshSignatures] = React.useState<boolean>(false);


    const [reloadTasks, setReloadTasks] = React.useState<boolean>(false);

    const [dataChanged, setDataChanged] = React.useState<boolean>(false);
    const [dataToSaveChanged, setDataToSaveChanged] = React.useState<boolean>(false);
    const [workersChanged, setWorkersChanged] = React.useState<boolean>(false);
    const [weathersChanged, setWeathersChanged] = React.useState<boolean>(false);
    const [materialsChanged, setMaterialsChanged] = React.useState<boolean>(false);
    const [machinesChanged, setMachinesChanged] = React.useState<boolean>(false);

    const [signDigitally, setSignDigitally] = React.useState<boolean>(false);
    const [printOnlyMine, setPrintOnlyMine] = React.useState<boolean>(false);
    const [subcontractorDialogOpen, setSubcontractorDialogOpen] = React.useState<boolean>(false);
    const [isDataValid, setIsDataValid] = React.useState<boolean>(true);
    const [showDashboard, setShowDashboard] = React.useState<boolean>(false);

    const [subdiaries, setSubdiaries] = React.useState<SubDiaryDTO[]>([]);

    const groups = React.useRef<GroupDTO[]>([]);

    const privateContents = React.useRef<GroupAdminDTO[]>([]);
    const [showGroups, setShowGroups] = React.useState<boolean>(true);
    const [showWorkHours, setShowWorkHours] = React.useState<boolean>(true);
    const [saveTimeStamp, setSaveTimeStamp] = React.useState<number | undefined>(undefined);

    const [searchText, setSearchText] = React.useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const [copyOpen, setCopyOpen] = useState(false);
    const [investorNewTaskOpen, setInvestorNewTaskOpen] = useState(false);
    const [createUserOpen, setCreateUserOpen] = useState(false);
    const [lockDialogOpen, setLockDialogOpen] = useState(false);
    const [locked, setLocked] = useState(false);
    const [readOnlySubcontractor, setReadOnlySubcontractor] = useState(false);
    const [records, setRecords] = useState<DiaryTimelineModel[]>([]);
    const [currentDate, setCurrentDate] = useState<Date>();
    const [refresh, setRefresh] = useState(false);
    const { permissions, load } = useDiaryStore();
    const investorPerm = useInvestorPermissionStore();
    const [messageOpen, setMessageOpen] = useState<boolean>(false);
    const [printOthersDiaryOpen, setPrintOthersDiaryOpen] = useState<boolean>(false);
    const [copyCompletedWork, setCopyCompletedWork] = useState<string>('');
    const [printing, setPrinting] = useState<boolean>(false);

    const [loading, setLoading] = useState(false);

    const [isSigniDialogOpen, setIsSigniDialogOpen] = useState(false);

    const { buildingDiaryRecordIdProps, fireLoading, stopLoading, isInvestor, fromExternal, isSubdodavatel } = props;
    const { t, i18n } = useTranslation();
    const loc = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const handleChange =
        (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            console.log(panel);
            if (newExpanded)
                setExpanded([...expanded, panel]);
            else {
                setExpanded(expanded.filter(t => t !== panel));
            }

        };
    const beforeUnLoad = (e: any) => {
        if (dataChanged) {
            return e.returnValue = 'Really want to leave?';
        }
    }
    useEffect(() => {
        if (dataToSaveChanged || workersChanged || weathersChanged || materialsChanged || machinesChanged)
            setDataChanged(true);
        else setDataChanged(false);
    }, [dataToSaveChanged, workersChanged, weathersChanged, machinesChanged, materialsChanged]);
    useEffect(() => {
        if (!dataChanged || locked || readOnlySubcontractor) {
            setWorkersChanged(false);
            setWeathersChanged(false);
            setMaterialsChanged(false);
            setMachinesChanged(false);
            setDataToSaveChanged(false);
        }
        else {
            window.addEventListener('beforeunload', beforeUnLoad);
            return () => {
                window.removeEventListener('beforeunload', beforeUnLoad);
            };
        }

    }, [dataChanged]);

    unstable_usePrompt({ message: 'Opravdu chcete opustit stránku bez uložení?', when: dataChanged });

    // UseBackListener(({ location }) => { console.log("Navigated Back", { location }); navigate("/", { replace: true }); });
    function handleStateFullLoad(): DiaryTimelineModel[] | undefined {
        setRecordId(loc.state["id"]);
        setBuildingName(loc.state["buildingName"]);
        setDiaryName(loc.state["diaryName"]);
        setDiaryId(loc.state["diaryId"]);
        if (loc.state["inputRecords"] !== undefined && loc.state["inputRecords"] !== null) {
            var newRecords: DiaryTimelineModel[] = [];
            loc.state["inputRecords"].reverse().forEach((element: any) => {
                if (element.diaryId === loc.state["diaryId"]) {
                    // console.log(element);
                    newRecords.push({
                        date: moment(element.date).format('MM-DD-YYYY'), dateOrigin: element.date, isFilled: element.isFilled,
                        hasCompletedWorks: element.hasCompletedWorksRecords, hasPhotos: element.hasPhotos, hasWeathers: element.hasWeatherRecords,
                        hasWorkers: element.hasWorkerRecords, signatureType: element.signatureType, recordId: element.id, isLocked: element.isLocked
                    });
                }
            });
            return newRecords;
        }
    }
    function loadDataStatelessTimeline(id: number, d: GetDailyRecordsForUserAndDiaryByRecordIdResponse) {
        setRecordId(id);
        setBuildingName(d.buildingName);
        setDiaryId(d.diaryId);
        setDiaryName(d.diaryName);
        setCurrentDate(d.diaryDate);
        if (d.viewerRecords !== undefined && d.viewerRecords !== null) {
            var newRecords: DiaryTimelineModel[] = [];
            d.viewerRecords.reverse().forEach((element: any) => {
                // if (element.diaryId === d.diaryId) {
                // console.log(element);
                newRecords.push({
                    date: moment(element.date).format('MM-DD-YYYY'), dateOrigin: element.date, isFilled: element.isFilled,
                    hasCompletedWorks: element.hasCompletedWorksRecords, hasPhotos: element.hasPhotos, hasWeathers: element.hasWeatherRecords,
                    hasWorkers: element.hasWorkerRecords, signatureType: element.signatureType, recordId: element.id, isLocked: element.isLocked
                });
                // }
            });
            console.log('to timeline', newRecords);
            setRecords(newRecords);
        }
    }
    function handleStateLessLoad(id: number) {
        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetRecordsForViewingStateless(id).then((d) => {
            loadDataStatelessTimeline(id, d);
        });
    }

    useEffect(() => {
        if (saveGrids)
            setSaveGrids(false);
    }, [saveGrids]);
    useEffect(() => {
        if (subcontractorPrintId) {
            setSignDigitally(false);
            setPrintOnlyMine(false);
            setPrintOpen(true);
        }
    }, [subcontractorPrintId]);

    useEffect(() => {
        fireLoading!();
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }


        console.log(loc.state);
        if (buildingDiaryRecordIdProps !== undefined)
            setRecordId(buildingDiaryRecordIdProps);
        else if (loc.state !== undefined && loc.state !== null) {
            var stateLoaded = handleStateFullLoad();
            if (stateLoaded)
                setRecords(stateLoaded);

        }
        else if (params['recordId'] !== undefined) {
            console.log("stateless");
            setRecordId(parseInt(params['recordId']));
            handleStateLessLoad(parseInt(params['recordId']));

        }

        if (permissions === undefined && (isInvestor === undefined || isInvestor === false))
            loadDiaryStore(isSubdodavatel === undefined ? false : isSubdodavatel).then((d) => {
                load(d);
            });
        else if (investorPerm.permissions === undefined && (isInvestor === true))
            loadInvestorPermissionStore().then((d) => {
                investorPerm.load(d);
            });

    }, []);



    function reloadData() {
        fireLoading!();
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }

        console.log(loc.state);
        if (buildingDiaryRecordIdProps !== undefined)
            setRecordId(buildingDiaryRecordIdProps);
        else if (loc.state !== undefined && loc.state !== null) {
            var stateLoaded = handleStateFullLoad();
            if (stateLoaded)
                setRecords(stateLoaded);

        }
        else if (params['recordId'] !== undefined) {
            console.log("stateless");
            setRecordId(undefined/* parseInt(params['recordId']) */);
            handleStateLessLoad(parseInt(params['recordId']));

        }
        if (permissions === undefined && (isInvestor === undefined || isInvestor === false))
            loadDiaryStore(isSubdodavatel === undefined ? false : isSubdodavatel).then((d) => {
                load(d);
            });
        else if (investorPerm.permissions === undefined && (isInvestor === true))
            loadInvestorPermissionStore().then((d) => {
                investorPerm.load(d);
            });
    }

    useEffect(() => {
        refreshDiary();
        if (recordId) {
            if (isSubdodavatel === true) {
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/subcontractor/diaries/" + recordId);
            }
            else if (isInvestor === undefined || isInvestor === false) {
                if (fromExternal === undefined || fromExternal === false)
                    window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/diaryvov/" + recordId);
                else window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/diaryolderpnew/" + recordId);
                window.parent.postMessage("diaryrecordchanged:" + recordId, '*');
            }
            else {
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/investor/tabs/diary/" + recordId);

            }
        }
        else {
            if (isSubdodavatel === true) {
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/subcontractor/diaries/" + params['recordId']!);
            }
            else if (isInvestor === undefined || isInvestor === false) {
                if (fromExternal === undefined || fromExternal === false)
                    window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/diaryvov/" + params['recordId']!);
                else window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/diaryolderpnew/" + params['recordId']!);

                window.parent.postMessage('diaryrecordchanged:' + params['recordId']!, '*');
            }
            else {
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/investor/tabs/diary/" + params['recordId']!);
            }
        }
    }, [recordId]);
    function setDefaultState() {
        setSaveTimeStamp(0);

        completedWorks.current = undefined;
        setMaterials('');
        setMachines('');
        setWeatherFallback('');
        setWorkersFallback('');
        setNotes('');

        setSubdiaries([]);

        groups.current = [];
        privateContents.current = [];
    }
    function getContentsToSave(): SubDiaryDTO[] | GroupAdminDTO[] | undefined {
        if (isSubdodavatel !== true && (subdiaries === null || subdiaries.length === 0)) {
            let admins = groups.current.filter(a => a !== undefined).flatMap(a => a.members!);
            return admins.filter(x => x !== undefined && x.changed);
        }
        else {
            let admins = privateContents.current.filter(a => a !== undefined).map(a => a);
            return admins.filter(x => x !== undefined && x.changed);
        }
    }

    function setDataAfterReload(data: GetDiaryRecordDataNotTablesResponse) {
        setLocked(data.isLocked!);
        setReadOnlySubcontractor(data.isReadOnly ? data.isReadOnly : false);
        setSaveTimeStamp(data.timeStamp);

        setBuildingId(data.buildingId);

        completedWorks.current = data.completedWork;
        setMaterials(data.materials);
        setMachines(data.machines);
        setWeatherFallback(data.weather);
        setWorkersFallback(data.workers);
        setNotes(data.additionalNotes);
        if (data.subdiaries)
            setSubdiaries(data.subdiaries);

        if (data.groups)
            groups.current = data.groups;
        if (data.privateContents)
            privateContents.current = data.privateContents;
        if (data.showWorkHours !== undefined)
            setShowWorkHours(data.showWorkHours);
        else setShowWorkHours(false);
        if (data.showEmployeesGroup !== undefined)
            setShowGroups(data.showEmployeesGroup);
        else setShowGroups(false);

        setRefreshSignatures(true);
    }
    function refreshDiary() {
        setIsDataValid(true);
        setCopyCompletedWork('');
        fireLoading!();
        setDefaultState();
        if (recordId) {
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetFullDailyRecordNotTables(recordId, false).then((data) => {
                stopLoading!();
                setDataAfterReload(data);
            }).catch(() => { stopLoading!(); setIsDataValid(false); console.log('err'); });
        }
        else stopLoading!();
    }
    function saveDiary(navigateAfter: boolean) {
        setDataChanged(false);
        setDataToSaveChanged(false);
        setSaveGrids(true);
        console.log(recordId, saveTimeStamp);
        // signRef.current!.save('Png')
        if (recordId && saveTimeStamp !== undefined) {
            setLoading(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).saveDiaryContents(recordId, saveTimeStamp, completedWorks.current, machines, materials, notes, getContentsToSave()).then((e) => {
                if (isSubdodavatel === true) {
                    if (e && e.ok) {
                        refreshDiary();
                        setCompletedWorksConcurrency(undefined);
                        handleStateLessLoad(recordId);
                        setDataChanged(false);
                        setDataToSaveChanged(false);
                        if (navigateAfter)
                            navigate('/' + params['lang']! + '/' + params['domain']! + '/subcontractor/diaries');
                    }
                    else if (e && e.ok === false && e.errors === 'update concurrency') {
                        setCompletedWorksConcurrency(e.actualCompletedWorks);
                        if (e.actualTimeStamp)
                            setSaveTimeStamp(e.actualTimeStamp);
                    }
                }
                else {
                    if (e && e.ok) {
                        refreshDiary();
                        setCompletedWorksConcurrency(undefined);
                        handleStateLessLoad(recordId);
                        setDataChanged(false);
                        setDataToSaveChanged(false);
                        if (navigateAfter) {
                            if (fromExternal === undefined || fromExternal === false)
                                navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryvov');
                            else
                                navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryolderpnew');
                        }
                    }
                    else if (e && e.ok === false && e.errors === 'update concurrency') {
                        setCompletedWorksConcurrency(e.actualCompletedWorks);
                        if (e.actualTimeStamp)
                            setSaveTimeStamp(e.actualTimeStamp);
                    }
                }
                setRefreshLog(refreshLog == true ? false : true);
                setSaveButonDisabled(false);
                setLoading(false);
            }).catch(() => { setSaveButonDisabled(false); setLoading(false); });
        }
    }
    const signRef = useRef<SignatureComponent>(null);
    const theme = useTheme();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const biggerThan1000 = useMediaPredicate("(min-width: 1000px)");


    const handleKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            // console.log(searchText);
            setSearchOpen(true);
        }
    }

    function handleMenuItemClick(event: any) {
        if (event === 0) {
            // if (locked)
            setLockDialogOpen(true);
            // else {

            //     new APIVovHelper().lockUnlockDiaryRecord(recordId!, false).then((e) => {
            //         if (e)
            //             refreshDiary();
            //     });
            // }

        }
        else if (event === 1) {
            setCopyOpen(true);
        }
        else if (event === 2) {
            setExpanded([]);
        }
        else if (event === 3) {
            setExpanded([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
        }
        else if (event === 4) {
            if (isSubdodavatel === true) {
                setCreateUserOpen(true);
            }
            else if (recordId) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).RingOthersDiary(recordId);
            }
        }
        else if (event === 5) {
            setCreateUserOpen(true);
            //setShowDashboard(!showDashboard);

            // setExpanded([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
        }

    }



    function searchClosed() { setSearchOpen(false); }
    function createUserClosed() { setCreateUserOpen(false); }
    function copyClosed(refresh: boolean) { setCopyOpen(false); if (refresh) { reloadData(); } setRefresh(true); }
    useEffect(() => {
        if (refresh)
            setRefresh(false);
    }, [refresh]);
    // function panelContentPhotos() {
    //     return <PhotoWidget isLocked={locked} recordId={recordId} />;
    // }
    // function panelContentWorkers() {
    //     return <WorkersWidget isLocked={locked} buildingDiaryRecordId={recordId} isCopying={false} />;
    // }
    // function panelCompletedWorks() {
    //     return <CompletedWorksWidget buildingDiaryRecordId={recordId!} isCopying={false} />;
    // }

    function getTopCoordinate() {
        if (isInvestor !== undefined && isInvestor === true)
            return 100;
        else if (isSubdodavatel !== undefined && isSubdodavatel === true)
            return 52;
        else if (fromExternal !== undefined && fromExternal === true)
            return 52;
        else return 0;
    }

    const onTiskClick = (e: number) => {
        if (isInvestor === true)
            onTiskInvestorClick(e);
        else onTiskUserClick(e);
    }

    const onTiskInvestorClick = (e: number) => {
        if (!recordId)
            return;
        let formattedDate = "";
        if (selectedDate) {
            formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
        }
        // TISK SDILENEHO ZAZNAMU
        if (e === 0) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintDiaryRecord(recordId, false, false, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK PRO DIGITALNI PODPIS
        else if (e === 1) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintDiaryRecord(recordId, false, true, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK CELÉHO DENÍKU
        else if (e === 2) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintStatus(diaryId, false, false, false).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiary(diaryId, false, false, false, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiaryBackground(diaryId, false, false, false, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }
        }
        // TISK CELEHO DENIKU PRO DIG PODPIS
        else if (e === 3) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintStatus(diaryId, false, true, false).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiary(diaryId, false, true, false, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiaryBackground(diaryId, false, true, false, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }
        }
        // TISK CELEHO DENIKU S PŘÍLOHAMI
        else if (e === 4) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintStatus(diaryId, false, false, true).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiary(diaryId, false, false, true, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiaryBackground(diaryId, false, false, true, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }
        }
    }

    const onTiskUserClick = (e: number) => {
        if (!recordId)
            return;
        let formattedDate = "";
        if (selectedDate) {
            formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
        }
        // TISK SDILENEHO ZAZNAMU
        if (e === 0) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintDiaryRecord(recordId, false, false, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK PRO DIGITALNI PODPIS
        else if (e === 1) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PrintDiaryRecord(recordId, false, true, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK MYCH ZAZNAMU
        else if (e === 2) {
            setPrinting(true);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiaryRecord(recordId, true, false, 0).then((d) => {
                if (d) {
                    saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                }
                setPrinting(false);
            });
        }
        // TISK MYCH ZAZNAMU
        else if (e === 3) {
            setPrintOthersDiaryOpen(true);
        }
        // TISK CELEHO DENIKU
        else if (e === 4) {
            setPrinting(true);
            if (diaryId !== undefined) {
                new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintStatus(diaryId, false, false, false).then((d) => {
                    if (d && d.canDirectlyPrint) {
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiary(diaryId, false, false, false, 0).then((d) => {
                            if (d) {
                                saveBlob(d.data, diaryName + ".pdf");
                            }
                            setPrinting(false);
                        });
                    }
                    else {
                        setPrinting(false);
                        new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).PrintDiaryBackground(diaryId, false, false, false, undefined, undefined);
                        setMessageOpen(true);
                    }
                });
            }

        }
        else if (e === 5) {
            setIsSigniDialogOpen(true);
            // setPrinting(true);
            // if (diaryId !== undefined) {
            // new ApiDiaryBaseHelper(false, isInvestor === undefined ? false : isInvestor).GetUsersForSignFlow(recordId).then((d) => {

            // });
            // }
        }
    }
    return (
        <div>
            {recordId && <SignaturesUserInfoDialog isInvestor={isInvestor === undefined ? false : isInvestor} diaryRecordId={recordId} isOpen={isSigniDialogOpen} onClose={() => { setIsSigniDialogOpen(false) }} />}
            {/* <AlertDialog isBlocking={dataChanged} /> */}
            <div style={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light }}>

                <div style={{
                    minHeight: '60px', marginBottom: "8px", width: '100%', position: 'fixed',
                    top: getTopCoordinate() + 'px', right: '0px',
                    backgroundColor: 'white', zIndex: 5000,
                    borderBottom: '1px solid',
                    borderColor: 'lightgray',
                }}>
                    <Timeline disabled={dataChanged} inputRecordId={recordId}
                        isInvestor={isInvestor !== undefined && isInvestor === true}
                        isSubdodavatel={isSubdodavatel === undefined ? false : isSubdodavatel}
                        onSelected={(e, d) => {
                            fireLoading!();
                            setRecordId(e);
                            console.log(e);
                            setSelectedDate(d);
                            stopLoading!();
                        }}
                        inputRecords={records}
                        fromExternal={fromExternal} />

                </div>
                {recordId !== undefined &&
                    <div style={{ padding: '8px' }}>
                        <div style={{
                            marginBottom: "8px", position: 'fixed',
                            top: (getTopCoordinate() + 60) + 'px', maxHeight: '48px', left: '0', right: 'auto',
                            zIndex: 5000, backgroundColor: theme.palette.primary.light, width: '100%', boxShadow: '3px 3px 4px lightgray'
                        }}>

                            <div style={{ overflow: 'hidden', width: '100%' }} >
                                <div style={{ display: 'flex', float: 'left' }}>
                                    <div>
                                        <div style={{ marginLeft: '12px', marginTop: '8px', marginBottom: '6px' }}>
                                            <IconButton color='primary' sx={{ marginTop: '-4px' }} onClick={(e) => {
                                                if (isSubdodavatel === true) {
                                                    navigate('/' + i18n.language + '/' + params['domain']! + '/subcontractor/diaries')
                                                }
                                                else if (isInvestor === undefined || isInvestor === false) {
                                                    if (fromExternal === undefined || fromExternal === false)
                                                        navigate('/' + i18n.language + '/' + params['domain']! + '/diaryvov');
                                                    else navigate('/' + i18n.language + '/' + params['domain']! + '/diaryolderpnew');
                                                }
                                                else navigate('/' + i18n.language + '/' + params['domain']! + '/investor/tabs/diary');
                                            }}>
                                                <Reply />
                                            </IconButton>

                                            {((permissions && permissions.canEditDiaryRecord && !locked && !readOnlySubcontractor) || (isInvestor && investorPerm && investorPerm.permissions && investorPerm.permissions.buildingDiaryRecordsEdit)) && <SplitLoadingButton disabled={saveButonDisabled} variant='contained' options={[t('save'), t('saveAndClose')]}
                                                startIcon={<Save />}
                                                onMenuItemClick={(e, index) => {
                                                    if (index === 0) {
                                                        saveDiary(false);
                                                    }
                                                    else if (index === 1) {
                                                        saveDiary(true);
                                                    }
                                                }} loading={loading} size={'small'}
                                            ></SplitLoadingButton>}
                                            {/* {permissions && permissions.canEditDiaryRecord && !locked && !readOnlySubcontractor && <SplitButton disabled={saveButonDisabled} selectedIndexInput={0} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                                                startIconSplitButton={<Save />}
                                                onMenuClick={(e) => {
                                                    if (e === 0) {
                                                        setSaveButonDisabled(true);
                                                        saveDiary(false);
                                                    }
                                                    else if (e === 1) {
                                                        setSaveButonDisabled(true);
                                                        saveDiary(true);
                                                    }
                                                }}></SplitButton>} */}
                                            {!locked && ((permissions === undefined || permissions.canEditDiaryRecord === undefined || permissions.canEditDiaryRecord === false) || readOnlySubcontractor || (isInvestor && investorPerm && investorPerm.permissions && !investorPerm.permissions.buildingDiaryRecordsEdit)) && <NotInterested style={{ width: '18px', height: '18px', marginTop: '-4px' }} />}
                                            {locked && <Lock sx={{ marginTop: '4px' }} />}
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '8px', marginTop: '8px', marginBottom: '6px' }}>
                                        <SplitButtonNoPush selectedIndexInput={0} variant='contained'
                                            selectOnClick
                                            startIconSplitButton={<Handyman />}
                                            buttonText={t('action')}
                                            options={isSubdodavatel === true ?
                                                [
                                                    { text: locked ? t('unlock') : t('lock'), isEnabled: (locked ? (permissions !== undefined && permissions.canUnlockDailyRecords === true) : (permissions !== undefined && permissions.canLockDailyRecords === true)) },
                                                    { text: t('copy'), isEnabled: !isInvestor },
                                                    { text: t('collapseAll'), isEnabled: true },
                                                    { text: t('expandAll'), isEnabled: true },
                                                    { text: t('createSubcontractor'), isEnabled: true }
                                                ]
                                                : [
                                                    { text: locked ? t('unlock') : t('lock'), isEnabled: (locked ? (permissions !== undefined && permissions.canUnlockDailyRecords === true) : (permissions !== undefined && permissions.canLockDailyRecords === true)) },
                                                    { text: t('copy'), isEnabled: !isInvestor },
                                                    { text: t('collapseAll'), isEnabled: true },
                                                    { text: t('expandAll'), isEnabled: true },
                                                    {
                                                        text: t('diary.AlertOthers'), isEnabled: true
                                                    },
                                                    { text: t('createSubcontractor'), isEnabled: isInvestor === true ? false : true }
                                                    // { text: 'nástěnka', isEnabled: true }
                                                ]}
                                            // options=[{}]
                                            // options={['akce', locked ? 'odemknout' : 'zamknout', 'sbalit vše', 'rozbalit vše', 'kopírování']}
                                            // startIconSplitButton={<MoreHoriz />}
                                            onMenuClick={(e) => { handleMenuItemClick(e) }}></SplitButtonNoPush>
                                    </div>
                                    {/* <Button sx={{ margin: '8px 8px 8px 32px' }} size='small' startIcon={<Save />} variant='contained' onClick={saveDiary}>{t('save')}</Button> */}
                                    {/* <Button sx={{ margin: '8px 8px 8px 32px' }} size='small' startIcon={<SaveTwoTone />} variant='contained' onClick={() => { saveDiary(); navigate('/diaryvov'); }}>{t('saveAndClose')}</Button> */}
                                    <div style={{ marginLeft: '8px', marginTop: '8px', marginBottom: '6px' }}>
                                        {printing && <CircularProgress />}
                                        {!printing && <SplitButtonNoPush buttonText={t('Print')} selectedIndexInput={0} variant='outlined' selectOnClick
                                            options={isInvestor === true ?
                                                [
                                                    { text: t('diary.printSharedRecords'), isEnabled: true },
                                                    { text: t('diary.printForDigitalSignature'), isEnabled: true },
                                                    { text: t('diary.printDiaryInvestor'), isEnabled: true },
                                                    { text: t('diary.printDiaryInvestorSignatures'), isEnabled: true },
                                                    { text: t('diary.printDiaryInvestorAttachments'), isEnabled: true },
                                                ] :
                                                [
                                                    { text: t('diary.printSharedRecords'), isEnabled: ((permissions !== undefined && permissions.canPrintDiaryRecord === true) ? true : false) },
                                                    { text: t('diary.printForDigitalSignature'), isEnabled: ((permissions !== undefined && permissions.canPrintDiaryRecord === true) ? true : false) },
                                                    { text: t('diary.printMyRecords'), isEnabled: permissions && permissions.canPrintDiaryRecordOwnRecords !== undefined && permissions.canPrintDiaryRecordOwnRecords ? true : false },
                                                    { text: t('PrintOthers'), isEnabled: permissions && permissions.canPrintDiaryRecordOthers !== undefined && permissions.canPrintDiaryRecordOthers ? true : false },
                                                    { text: t('Print'), isEnabled: permissions && permissions.canPrintDiary !== undefined && permissions.canPrintDiary ? true : false },
                                                    { text: t('diary.tiskSigni'), isEnabled: permissions && permissions.hasSigni !== undefined && permissions.hasSigni ? true : false },
                                                ]
                                            }
                                            startIconSplitButton={<Print />}
                                            onMenuClick={(e) => onTiskClick(e)}></SplitButtonNoPush>}
                                    </div>

                                    {biggerThan700 && <div style={{ display: 'flex', margin: '8px 20px', flexDirection: 'column', height: '32px' }}>
                                        <h6 style={{ margin: '0px' }}>{buildingName}</h6>
                                        <p style={{ fontSize: '10px' }}>{diaryName}</p>
                                    </div>}
                                </div>
                                <div style={{ float: 'right', marginTop: '8px', marginRight: biggerThan700 ? '20px' : '0px' }}>

                                    {biggerThan700 && <TextField onKeyDown={handleKeyDown} onChange={(e) => { setSearchText(e.target.value) }}
                                        placeholder={t('SearchDots')} variant='standard' sx={{ height: '20px' }} InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton onClick={() => { setSearchOpen(true) }}>
                                                        <Search />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} size='small' />}
                                    {!biggerThan700 && <IconButton onClick={() => { setSearchOpen(true) }}>
                                        <Search />
                                    </IconButton>}
                                    {/* <Button color='secondary' sx={{ marginTop: '-4px', marginLeft: '12px' }} startIcon={<SettingsOutlined />}>{t('SettingsL')}</Button> */}

                                </div>
                            </div>
                            {/* {!biggerThan700 && <Button sx={{ position: 'fixed', bottom: '20px', right: '40px' }} onClick={saveDiary} startIcon={<Save />} size='small' variant='contained'>{t('save')}</Button>} */}

                            {/* <div style={{ float: 'right' }}>
                            <Button style={{ fontSize: 9, fontWeight: 'bold', marginLeft: '32px', marginTop: biggerThan700 ? '0px' : '12px' }} size='small' onClick={() => { setExpanded(Array.from(Array(10).keys())) }}>expand all</Button>
                            <Button style={{ fontSize: 9, fontWeight: 'bold', marginTop: biggerThan700 ? '0px' : '12px' }} size='small' onClick={() => { setExpanded([]) }}>collapse all</Button>
                            {!biggerThan700 && <More sx={{ right: '16px', left: 'auto', float: 'right', margin: '12px' }}></More>}
                        </div> */}

                        </div>
                        <div style={{
                            display: "flex", flexDirection: "row", flexWrap: "wrap-reverse",
                            justifyContent: "flex-start", marginTop: '78px'
                        }}>
                            {/* {isDataValid && locked && <DiaryPrint localRecordId={recordId} hidePrinter isFromLocalEnv />} */}
                            {isDataValid && !showDashboard
                                && <div style={{ minWidth: "800px", maxWidth: biggerThan700 ? '100%' : '600px', margin: '12px', marginTop: '32px' }}>
                                    <SignaturesWidget
                                        onSigniClicked={() => { setIsSigniDialogOpen(true) }}
                                        refresh={refreshSignatures}
                                        onRefreshed={() => setRefreshSignatures(false)}
                                        isInvestor={isInvestor}
                                        isSubdodavatel={isSubdodavatel}
                                        isExpanded={expanded.indexOf(0) > -1}
                                        recordId={recordId}
                                        isLocked={locked}
                                        onSigned={() => { handleStateLessLoad(recordId); setRefreshLog(refreshLog === true ? false : true); }}
                                    />
                                    {isSubdodavatel !== true && (subdiaries === null || subdiaries.length === 0) ? <CompletedWorksWidgetOld
                                        copyCompletedWork={copyCompletedWork}
                                        concurrency={completedWorksConcurrency}
                                        isInvestor={isInvestor}
                                        isCopying={false}
                                        showGroups={true}
                                        buildingDiaryRecordId={recordId} groups={groups.current}
                                        onHtmlChanged={(text, group, member) => {

                                            if (group === null || group === undefined || member === null || member === undefined) {
                                                if (text !== completedWorks.current)
                                                    setDataToSaveChanged(true);
                                                completedWorks.current = text;
                                            }
                                            else {
                                                let groupNew = groups.current.find(x => x.groupId === group.groupId);
                                                if (groupNew && groupNew.members) {
                                                    let memberRemove = groupNew.members.find(x => x.userId === member.userId);

                                                    if (memberRemove) {
                                                        if (text !== memberRemove.content)
                                                            setDataToSaveChanged(true);
                                                        let gruopToUse = new GroupDTO({
                                                            groupId: groupNew.groupId, groupName: groupNew.groupName,
                                                            members: [...groupNew.members!.filter(x => x.userId !== member.userId), new GroupAdminDTO({ content: text, name: memberRemove!.name, userId: memberRemove!.userId, contentId: memberRemove!.contentId, changed: true })]
                                                        })
                                                        groups.current = [...groups.current.filter(x => x.groupId !== group.groupId), gruopToUse];
                                                    }
                                                }

                                            }
                                        }}
                                        completedWorksInput={completedWorks.current}
                                        isExpanded={expanded.indexOf(1) > -1} />
                                        :
                                        <CompletedWorksWidget
                                            copyCompletedWork={copyCompletedWork}
                                            concurrency={completedWorksConcurrency}
                                            isInvestor={isInvestor}
                                            isSubdodavatel={isSubdodavatel}
                                            isCopying={false}
                                            showSubdiaries={true}
                                            buildingDiaryRecordId={recordId} subdiaries={subdiaries} privateContents={privateContents.current}
                                            onHtmlChanged={(text, privContent) => {
                                                if (privContent === null || privContent === undefined) {
                                                    if (text !== completedWorks.current)
                                                        setDataToSaveChanged(true);
                                                    completedWorks.current = text;
                                                }
                                                else if (privContent !== null && privContent !== undefined) {
                                                    let contNew = privateContents.current.find(x => x.contentId === privContent.contentId);
                                                    if (contNew) {
                                                        if (text !== contNew.content)
                                                            setDataToSaveChanged(true);

                                                        let contToUse = new GroupAdminDTO({
                                                            contentId: contNew.contentId, name: contNew.name,
                                                            content: text, userId: contNew.userId,
                                                            changed: true
                                                        })
                                                        privateContents.current = [...privateContents.current.filter(x => x.contentId !== privContent.contentId), contToUse]
                                                    }
                                                }
                                            }}
                                            completedWorksInput={completedWorks.current}
                                            isExpanded={expanded.indexOf(1) > -1} />
                                    }
                                    <PhotoWidget
                                        isInvestor={isInvestor}
                                        isSubdodavatel={isSubdodavatel}
                                        isLocked={locked || readOnlySubcontractor}
                                        recordId={recordId}
                                        isExpanded={expanded.indexOf(2) > -1}
                                        domain={params['domain']}
                                        recordDate={currentDate}
                                        onCopy={(text) => { setCopyCompletedWork(text); }}
                                    />
                                    <AttachmentsWidget
                                        isInvestor={isInvestor}
                                        isSubdodavatel={isSubdodavatel}
                                        isLocked={locked || readOnlySubcontractor}
                                        isExpanded={expanded.indexOf(3) > -1}
                                        recordId={recordId}
                                    />
                                    <WeatherWidget
                                        date={selectedDate}
                                        isInvestor={isInvestor}
                                        isSubdodavatel={isSubdodavatel}
                                        onSaved={() => { setWeathersChanged(false); setRefreshLog(refreshLog === true ? false : true); }}
                                        onSaveChanged={() => { setWeathersChanged(true) }}
                                        isLocked={locked || readOnlySubcontractor}
                                        saveChanged={saveGrids}
                                        isExpanded={expanded.indexOf(4) > -1}
                                        buildingDiaryRecordId={recordId}
                                        weatherNote={weatherFallback}
                                        noteChanged={(e) => {
                                            setDataToSaveChanged(true);
                                            setWeatherFallback(e);
                                        }}
                                    />
                                    <WorkersWidget
                                        isInvestor={isInvestor}
                                        isSubdodavatel={isSubdodavatel}
                                        buildingName={buildingName}
                                        date={selectedDate}
                                        isLocked={locked || readOnlySubcontractor}
                                        isCopying={false}
                                        workersNote={workersFallback}
                                        noteChanged={(e) => {
                                            setDataToSaveChanged(true);
                                            setWorkersFallback(e);
                                        }}
                                        onChanged={() => { setWorkersChanged(false); }}
                                        onChangeToSave={() => { setWorkersChanged(true); }}
                                        onSaved={() => { setRefreshLog(refreshLog === true ? false : true); }}
                                        saveChanged={saveGrids}
                                        isExpanded={expanded.indexOf(5) > -1}
                                        buildingDiaryRecordId={recordId}
                                        showGroups={showGroups}
                                        showWorkHours={showWorkHours}
                                        refresh={refresh}
                                    />
                                    <MaterialsWidget
                                        currentDate={currentDate ? currentDate : new Date()}
                                        buildingDiaryId={diaryId}
                                        isInvestor={isInvestor}
                                        isSubdodavatel={isSubdodavatel}
                                        isLocked={locked || readOnlySubcontractor}
                                        saveChanged={saveGrids}
                                        isCopying={false}
                                        materialsNote={materials}
                                        onChanged={() => { setMaterialsChanged(false); }}
                                        onChangeToSave={() => { setMaterialsChanged(true) }}
                                        onSaved={() => { setRefreshLog(refreshLog === true ? false : true); }}
                                        noteChanged={(e) => {
                                            setDataToSaveChanged(true);
                                            setMaterials(e);
                                        }}
                                        buildingDiaryRecordId={recordId}
                                        isExpanded={expanded.indexOf(6) > -1}
                                        refresh={refresh}
                                    />
                                    <MachinesWidget
                                        isInvestor={isInvestor}
                                        isSubdodavatel={isSubdodavatel}
                                        isLocked={locked || readOnlySubcontractor}
                                        noteChanged={(e) => {
                                            setDataToSaveChanged(true);
                                            setMachines(e);
                                        }}
                                        onChanged={() => { setMachinesChanged(false); }}
                                        onChangeToSave={() => { setMachinesChanged(true) }}
                                        onSaved={() => { setRefreshLog(refreshLog === true ? false : true); }}
                                        saveChanged={saveGrids}
                                        isCopying={false}
                                        machinesNote={machines}
                                        buildingDiaryRecordId={recordId}
                                        isExpanded={expanded.indexOf(7) > -1}
                                        refresh={refresh}
                                    />


                                    <Accordion className='expander' expanded={expanded.indexOf(8) > -1} onChange={handleChange(8)}>
                                        <AccordionSummary className='expander-header' aria-controls="panel6d-content" id="panel6d-header">
                                            <RateReview color='secondary' className='accordion-icon' />
                                            <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('diary.anotherNotes')}</Typography>
                                            {(notes !== undefined && notes !== '') && <Check style={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} color='success' />}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {(!isInvestor || (isInvestor && investorPerm && investorPerm.permissions && investorPerm.permissions.buildingDiaryRecordsEdit === true)) && <RichTextEditorComponent saveInterval={100} height={400} locale={i18n.language} value={notes} change={(eve: ChangeEventArgs) => {
                                                if (notes !== eve.value) {
                                                    setNotes(eve.value);
                                                    setDataToSaveChanged(true);
                                                }
                                            }} >
                                                <Inject services={[Toolbar, RTImage, Link, HtmlEditor, QuickToolbar]} />
                                            </RichTextEditorComponent>}
                                            {(isInvestor && investorPerm && investorPerm.permissions && investorPerm.permissions.buildingDiaryRecordsEdit !== true) && <div style={{ margin: '10px' }} dangerouslySetInnerHTML={{ __html: notes ? notes : "" }} />}
                                        </AccordionDetails>
                                    </Accordion>

                                    {isSubdodavatel !== true && <Accordion className='expander' expanded={expanded.indexOf(9) > -1} onChange={handleChange(9)}>
                                        <AccordionSummary className='expander-header'>
                                            <Forum color='secondary' className='accordion-icon' />
                                            <Typography color={theme.palette.text.primary} fontWeight='bold' >{t('dashboard.tasks')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {isInvestor === true && <Button sx={{ marginLeft: '32px', marginBottom: '4px', width: '150px' }} startIcon={<AddCircleOutline />} aria-label="create" variant='contained' size='small' onClick={(event) => {
                                                setReloadTasks(false); setInvestorNewTaskOpen(true);
                                            }} disabled={false} color="primary">
                                                {t('Add')}
                                            </Button>
                                            }
                                            {isInvestor !== true && <Button sx={{ marginLeft: '32px', marginBottom: '4px', width: '150px' }} startIcon={<AddCircleOutline />} aria-label="create" variant='contained' size='small' onClick={(event) => {
                                                window.open('https://' + params['domain']! + '.vimovsem.cz/admin/requests/new', '_blank', 'noreferrer');
                                            }} disabled={false} color="primary">
                                                {t('Add')}
                                            </Button>
                                            }
                                            {recordId && <TasksInDiaryWidget isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} domain={params['domain']!} recordId={recordId} reloadData={reloadTasks} />}
                                        </AccordionDetails>
                                    </Accordion>}
                                    {((permissions && permissions.investorsComments) || (isInvestor)) && isSubdodavatel !== true && <InvestorChatWidget recordIdInput={recordId} isExpanded={expanded.indexOf(10) > -1} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} />}
                                    {isSubdodavatel !== true && isInvestor !== true && (subdiaries === null || subdiaries.length > 0) && <SubcontractorChatWidget recordIdInput={recordId} isExpanded={expanded.indexOf(11) > -1} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} />}
                                    {isSubdodavatel === true && <MainContractorChatWidget recordIdInput={recordId} isExpanded={expanded.indexOf(12) > -1} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} />}
                                    {isInvestor !== true && isSubdodavatel !== true && permissions && permissions.canSeeChangelog && <LogWidget refresh={refreshLog} buildingDiaryRecordId={recordId} isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} />}
                                </div>}
                            {/* {isDataValid && showDashboard
                                && <DashboardLayoutComponent style={{ marginTop: '48px' }} allowResizing resizableHandles={['e-south-east', 'e-east', 'e-west', 'e-north', 'e-south']} mediaQuery='max-width: 900px'
                                    id='defaultLayout' cellSpacing={[5, 5]} cellAspectRatio={100 / 50} columns={5} >
                                    <PanelsDirective>
                                        <PanelDirective sizeX={4} minSizeX={4} sizeY={3} minSizeY={3} row={0} col={0} content={panelContentPhotos} header="<div></div>" />
                                        <PanelDirective sizeX={3} sizeY={2} row={3} col={0} content={panelContentWorkers} header="<div></div>" />
                                        <PanelDirective sizeX={3} sizeY={2} row={3} col={3} content={panelCompletedWorks} header="<div></div>" />

                                    </PanelsDirective>
                                </DashboardLayoutComponent>} */}
                        </div >
                    </div >}

                {
                    recordId === undefined &&
                    <div style={{
                        display: 'flex', justifyContent: 'center', marginTop: '120px',
                        flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '300', left: 'calc(50% - 150px)'
                    }}>
                        <NoDataImageHolder title='Pro vybranný den jsme nenašli Váš denní záznam' />
                        <div style={{ display: 'flex' }}><Typography>{t('diary.site')}</Typography><Typography sx={{ fontWeight: 'bold', marginLeft: '4px' }}> {buildingName}</Typography></div>
                        <div style={{ display: 'flex' }}><Typography>{t('diary.diary')}</Typography><Typography sx={{ fontWeight: 'bold', marginLeft: '4px' }}> {diaryName}</Typography></div>

                        {permissions && permissions.canCreateDiaryRecord && <Button sx={{ margin: '20px' }} startIcon={<AddCircleOutline />} onClick={(e) => {
                            if (diaryId && selectedDate)
                                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).createDiaryRecord(diaryId, selectedDate).then((d) => {
                                    if (typeof d === "string") {

                                    }
                                    else if (typeof d === "number") {
                                        setRecordId(d);
                                        handleStateLessLoad(d);
                                        //refresh timeline
                                        // setRecords([...records.filter(x => x.dateOrigin === selectedDate), new ])
                                    }
                                })
                        }} variant='contained'>{t('Create')}</Button>}
                    </div>
                }
                {
                    !isDataValid && <div style={{
                        display: 'flex', justifyContent: 'center', marginTop: '120px',
                        flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '300', left: 'calc(50% - 150px)'
                    }}><NoDataImageHolder title='Data jsou chybná' /></div>
                }
                {recordId && <DiaryPrintDialog isInvestor={isInvestor !== undefined && isInvestor === true} isSubdodavatel={isSubdodavatel} subcontractorPrintId={subcontractorPrintId}
                    signDigitally={signDigitally} onlyMine={printOnlyMine}
                    recordId={recordId} open={printOpen} onClose={() => { setPrintOpen(false); setSubcontractorPrintId(undefined); }} />}
                <DiarySearchDialog isInvestor={isInvestor} isSubdodavatel={isSubdodavatel} onRefresh={() => { }} searchText={searchText} open={searchOpen} onClose={searchClosed} diaryId={diaryId} diaryDate={undefined} fromExternal={fromExternal === true ? true : false} />
                <SelectGroupDialog groups={groups.current} open={subcontractorDialogOpen} onClose={(e) => {
                    setSubcontractorDialogOpen(false);
                    if (e)
                        setSubcontractorPrintId(e);
                }} />
                <DiaryRecordCopyDialog isSubdodavatel={isSubdodavatel} open={copyOpen} diaryId={diaryId} onClose={copyClosed} onRefresh={() => { }} diaryRecordId={recordId} />
                <CreateUserDialog showGroups={true} diaryId={diaryId} isSubdodavatel={isSubdodavatel} open={createUserOpen} onClose={createUserClosed} />
                <ReallyLockUnlockDialog open={lockDialogOpen} locked={locked} onClose={(e) => {
                    setLockDialogOpen(false);
                    if (recordId && e === true) {
                        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).lockUnlockDiaryRecord(recordId, !locked).then((d) => { setLockDialogOpen(false); refreshDiary(); });
                    }
                }} />
                <InvestorTaskCreateDialog topMargin={200} onRefresh={() => { /* setRefresh(true)  */ }} todo={new RequestsDTO({ buildingId: buildingId, buildingDiaryId: diaryId })} open={investorNewTaskOpen} selectedValue={t('singleTodo.newTask')} onClose={() => { setInvestorNewTaskOpen(false); refreshDiary(); setReloadTasks(true); }} />
                <MessageDialog onClose={() => setMessageOpen(false)} text={t('diary.diaryPrintLargeText')} title={t('diary.diaryPrintLargeTitle')} open={messageOpen} />
                {diaryId && <DiaryUsersPrintDialog diaryId={diaryId} onClose={(e) => {
                    setPrintOthersDiaryOpen(false);
                    if (e !== undefined && e !== null && recordId) {
                        setPrinting(true);
                        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).PrintDiaryRecord(recordId, true, false, e.id!).then((d) => {
                            if (d) {
                                let formattedDate = "";
                                if (selectedDate) {
                                    formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
                                }
                                saveBlob(d.data, diaryName + " " + formattedDate + ".pdf");
                            }
                            setPrinting(false);
                        });

                    }
                }
                } open={printOthersDiaryOpen} />}
            </div >
        </div>
    );
}
export default withLoading(DiaryMainScene);
