export const ContainsFolder = (event: React.DragEvent<HTMLDivElement>): boolean => {
    const { items } = event.dataTransfer;

    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file') {
            const entry = item.webkitGetAsEntry();
            if (entry && entry.isDirectory) {
                return true;
            }
        }
    }

    return false;
};