import { create } from 'zustand'
import { APIVovInvestorHelper } from '../ApiOld/ApiInvestorOldClient'
import { GetInvestorInfoResponse } from '../ApiOld/ApiServerVov'

interface InvestorStoreState {
    credentials?: GetInvestorInfoResponse
    load: (perms: GetInvestorInfoResponse) => void
}
export const loadInvestorStore = () => {
    return new APIVovInvestorHelper().GetInvestorInfo();
}
export const useInvestorStore = create<InvestorStoreState>()((set) => ({
    credentials: undefined,
    load: (by) => set((state) => ({ credentials: by })),
}))