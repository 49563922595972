import { create } from 'zustand'
import { makeRandomKey } from '../Components/Shared/MakeRandomKey';
import { FileParameter } from '../ApiOld/ApiServerVov';
import { APIOldBuildingsClient } from '../ApiOld/ApiOldBuildingsClient';
import delay from '../Components/Shared/Functions/Delay';
// export const loadDiaryStore = () => {
//     return new APIVovHelper().GetDiaryPermissions();
// }
interface UploadFileContext {
    uploadingFiles: UploadFileDC[];
    //loadUploadingFiles: (uf: UploadFileDC[]) => void;
}

interface UploadFileActions {
    addFile: (file: UploadFileDC) => void;
    removeFile: (file: UploadFileDC) => void;
    clearDoneFiles: () => void;
    clearFiles: () => void;
    uploadFiles: () => Promise<void>;
}

export const useUploadFileStore = create<UploadFileContext & UploadFileActions>()((set) => ({
    uploadingFiles: [],
    //loadUploadingFiles: (uf) => set((state) => ({ uploadingFiles: uf })),

    addFile: (file) => set((state) => ({ uploadingFiles: [...state.uploadingFiles, file] })),
    removeFile: (file) => set((state) => ({ uploadingFiles: state.uploadingFiles.filter((f) => f !== file) })),
    clearDoneFiles: () => set((state) => ({ uploadingFiles: state.uploadingFiles.filter(p => !p.isDone) })),
    clearFiles: () => set({ uploadingFiles: [] }),

    uploadFiles: async () => {
        const { uploadingFiles } = useUploadFileStore.getState();

        const uploadFile = async (selectedFile: UploadFileDC) => {
            const chunkSize = 1024 * 1024 * 2; // 2 MB
            const chunks: Blob[] = [];
            let offset = 0;

            while (offset < selectedFile.uploadFile.size) {
                const chunk = selectedFile.uploadFile.slice(offset, offset + chunkSize);
                chunks.push(chunk);
                offset += chunkSize;
            }

            let key = makeRandomKey(16, []);
            for (let i = 0; i < chunks.length; i++) {
                const fileData = new File([chunks[i]], selectedFile.uploadFile.name);
                let fileParameter: FileParameter = { data: chunks[i], fileName: selectedFile.uploadFile.name };
                await new APIOldBuildingsClient().UploadProjectDocumentChunk(selectedFile.uploadFile.name, fileParameter, i, chunks.length, key, selectedFile.documentId!, selectedFile.path ? selectedFile.path : '-');
            }
            set((state) => ({ uploadingFiles: state.uploadingFiles.map((f, i) => f.uploadFile === selectedFile.uploadFile ? new UploadFileDC(f.uploadFile, true, false, f.documentId, true, '') : f) }));
        }

        for (const file of uploadingFiles.filter(p => !p.isRunning && !p.isDone)) {
            set((state) => ({ uploadingFiles: state.uploadingFiles.map((f, i) => f.uploadFile === file.uploadFile ? new UploadFileDC(f.uploadFile, false, true, f.documentId, true, '') : f) }));
            await uploadFile(file);
        }

        delay(2000).then(p => { useUploadFileStore.getState().clearDoneFiles(); });
    }
}))

export class UploadFileDC {
    // Properties
    public uploadFile: File;
    public isDone: boolean;
    public isRunning: boolean;
    public show: boolean;

    public documentId: number;
    public path: string;

    // Constructor
    constructor(uploadFile: File, isDone: boolean, isRunning: boolean, documentId: number, show: boolean, path: string) {
        this.uploadFile = uploadFile;
        this.isDone = isDone;
        this.isRunning = isRunning;
        this.documentId = documentId;
        this.show = show;
        this.path = path;

        if (isDone) {
            this.setAutoHide();
        }
    }

    // Method to set a timeout for hiding after 3 seconds
    private setAutoHide() {
        setTimeout(() => {
            this.show = false;
        }, 3000); // 3000 milliseconds (3 seconds)
    }

    // Method to manually trigger hiding after 3 seconds
    public hideAfter3Seconds() {
        setTimeout(() => {
            this.show = false;
        }, 3000); // 3000 milliseconds (3 seconds)
    }

    // Method
    /* getFullName(): string {
        return `${this.firstName} ${this.lastName}`;
    } */
}