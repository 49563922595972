import React, { useEffect } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { CircularProgress, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetBuildingDataDetailResponse } from '../../../../ApiOld/ApiServerVov';


interface GoogleMap4SceneProps {
    selected: any;
    projectData: Partial<GetBuildingDataDetailResponse> | null;
    setSelected: (set: any) => void;
    setProjectData: (set: any) => void;
}

export default function GoogleMap4Scene(props: GoogleMap4SceneProps) {
    const { selected, projectData, setSelected, setProjectData } = props;
    const { t, i18n } = useTranslation();
    const { isLoaded, loadError } = useLoadScript({
        id: `google-map4`,
        googleMapsApiKey: "AIzaSyAUicHcR0cgVPe3nY0bjbrUHMlwk3EVYPU",
        libraries: ["places"]
    });

    const mapContainerStyle = { width: '600px', height: '400px' };

    useEffect(() => {
        if (projectData?.address4 !== undefined && projectData?.gpsX4 !== undefined && projectData?.gpsY4 !== undefined) {
            setSelected({ lat: projectData.gpsX4, lng: projectData.gpsY4 });
        }
    }, []);

    useEffect(() => {
        if (projectData?.address4 !== undefined && projectData?.gpsX4 !== undefined && projectData?.gpsY4 !== undefined) {
            setSelected({ lat: projectData.gpsX4, lng: projectData.gpsY4 });
        }
    }, [projectData]);

    useEffect(() => {
    }, []);
    const theme = useTheme();
    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={selected}
            zoom={15}
        >
            {selected && <MarkerF onDragEnd={(e) => {
                const lat = e.latLng?.lat()
                const lng = e.latLng?.lng()

                // Update the coordinates state
                if (lat && lng) {
                    setSelected({ lat: lat, lng: lng });
                    setProjectData((projectData) => ({ ...projectData, address4: '', gpsX4: lat, gpsY4: lng }));

                }
            }} draggable position={selected} />}
        </GoogleMap>

    ) : <div><CircularProgress /></div>
}