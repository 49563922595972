import { Box, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../../Shared/Tabs/TabPanel';
import tabProps from '../../Shared/Tabs/TabProps';
import DiariesList from './DiariesList';


import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import { GetDiaryPermissionsResponse } from '../../../ApiOld/ApiServerVov';
import { useDiaryStore, loadDiaryStore } from '../../../Contexts/DiaryZContext';
import NoRights from '../../Shared/Layouts/NoRights';
import DiaryRecords from './DiaryRecords';
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';

interface DiariesTabsProps {
    selected?: number;
    fromExternal?: boolean;
    isSubdodavatel?: boolean;
}

export default function DiaryTabs(props: DiariesTabsProps) {
    const { t, i18n } = useTranslation();
    const { selected, fromExternal, isSubdodavatel } = props;
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    // const diaryPermissionsLoader = useDiaryStore(state => state.load);
    const { permissions, load } = useDiaryStore();
    // const [permissions, setPermissions] = useState<GetDiaryPermissionsResponse>();

    // const [permissions, setPermissions] = useState<GetDiaryPermissionsResponse>();
    const params = useParams();
    useEffect(() => {
        if (selected !== undefined)
            setSelectedTabIndex(selected);
    }, [selected]);
    useEffect(() => {
        if (isSubdodavatel) {
            if (selectedTabIndex === 0)
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/subcontractor/diaries");
            else if (selectedTabIndex === 1)
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/subcontractor/diariesDiary");
        }
        else {
            if (selectedTabIndex === 0 && window.location.pathname.endsWith('diaries')) {
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/diaryvov");
            }
            else if (selectedTabIndex === 1 && window.location.pathname.endsWith('diaryvov')) {
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/diaryvovdiaries");
            }
        }
    }, [selectedTabIndex]);
    useEffect(() => {
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }

        if (permissions === undefined)
            loadDiaryStore(isSubdodavatel === undefined ? false : isSubdodavatel).then((d) => {
                load(d);
            })
    }, []);

    useEffect(() => {
        if (permissions === undefined)
            loadDiaryStore(isSubdodavatel === undefined ? false : isSubdodavatel).then((d) => {
                load(d);
            })
    });

    if (permissions)
        return (
            <div>
                <Box >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedTabIndex} onChange={(e, n) => { setSelectedTabIndex(n) }} aria-label="basic tabs example">
                            <Tab label={t('diary.dailyRecord')} {...tabProps(0)} />
                            <Tab label={t('diary.diaryList')} {...tabProps(1)} />
                            <Tab label={'EYETOWERS'} {...tabProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={selectedTabIndex} index={0}>
                        <div>
                            {permissions.canViewDiaryRecordsList && <DiaryRecords isSubdodavatel={isSubdodavatel} fromExternal={fromExternal} />}
                            {!permissions.canViewDiaryRecordsList && <NoRights title={t('noViewingPermissions')} />}

                        </div>

                    </TabPanel>
                    <TabPanel value={selectedTabIndex} index={1}>
                        <div>
                            {permissions.canViewDiaryList && <DiariesList isSubdodavatel={isSubdodavatel} fromExternal={fromExternal} />}
                            {!permissions.canViewDiaryList && <NoRights title={t('noViewingPermissions')} />}

                        </div>
                    </TabPanel>
                    <TabPanel value={selectedTabIndex} index={2}>
                        {/* {document.body.scrollHeight} */}
                        <div style={{ width: 'calc(100vw - 40px)' }}>
                            <iframe width={'100%'} height={document.body.scrollHeight / 1.4} src='https://portal.eyetowers.io/portal/login?embedded=true&mode=light' />
                        </div>
                    </TabPanel>
                </Box></div>);
    else return <div>{t('loading...')}</div>;
}