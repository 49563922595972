import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { TabPanel } from "../../Shared/Tabs/TabPanel";
import tabProps from '../../Shared/Tabs/TabProps';
import DiaryPager from "./DiaryPrints/DiaryPager";
import DiaryDetailScene from "./Widgets/DiaryDetailScene";

interface DiaryDetailProps {
    fromExternal?: boolean;
    isSubdodavatel?: boolean;
}

export default function DiaryDetail(props: DiaryDetailProps) {
    const { t } = useTranslation();
    const { fromExternal, isSubdodavatel } = props;
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [diaryId, setDiaryId] = useState(0);
    const [isCreating, setIsCreating] = useState(false);
    const params = useParams();
    useEffect(() => {
        if (params['id']) {
            console.log('params', params);
            if (params['id'] !== 'new' && params['id'] !== 'newSubd')
                setDiaryId(parseInt(params['id']));
            else { setIsCreating(true); }
        }
    }, []);
    return (
        <div style={{ marginTop: fromExternal ? '56px' : '0px' }}>

            <Box >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={(e, n) => { setSelectedTabIndex(n) }} aria-label="basic tabs example">
                        <Tab label={t('Info')} {...tabProps(0)} />
                        {!isCreating && <Tab label={t('diary.viewing')} {...tabProps(1)} />}
                    </Tabs>
                </Box>
                <TabPanel value={selectedTabIndex} index={0}>
                    <DiaryDetailScene fromExternal={fromExternal} isSubdodavatel={isSubdodavatel} />
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    {diaryId > 0 && <DiaryPager diaryId={diaryId} />}
                </TabPanel>
            </Box>
        </div>);
}