

import React from 'react';
import noDiaryPic from '../../../Assets/No_data.jpg'; // with import
interface NoDataImageHolderProps {
    title?: string;
}

export default function NoDataImageHolder(props: NoDataImageHolderProps) {
    const { title } = props;
    return (<div><img src={noDiaryPic} width={300} height={200} style={{ margin: '20px', border: '2px solid', borderColor: '#C3C3C3', borderRadius: '16px' }}></img>
        <h6>{title}</h6></div>);
}