import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { ProductFruits } from 'react-product-fruits'
import { useLoginAdminStore } from '../../Contexts/LoginAdminZContext';

export default function ProductFruitsAdminUser() {
  const { i18n } = useTranslation();
  const { userName, } = useLoginAdminStore();
  const [userInfo, setUserInfo] = React.useState<any | null>(null);
  useEffect(() => {
    // console.log('userName', userName);
    if (userName === undefined) return;
    const path = new URL(window.location.href).pathname;
    const appName = path.split('/')[2];
    setUserInfo({
      username: userName !== undefined ? userName + ' - ' + appName : '', // REQUIRED - any unique user identifier
      email: '==REPLACE==',
      firstname: '==REPLACE==',
      lastname: '==REPLACE==',
      signUpAt: '==REPLACE==',
      role: '==REPLACE==',
      props: { customProp1: '==REPLACE==' }
    });
  }, [userName]);
  return (
    <div>{userInfo !== null && <ProductFruits workspaceCode="ZiMbAP924A5fVHPI" language={i18n.language} user={userInfo} />}</div>
  )
}
