

import React, { SetStateAction, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement
} from '@stripe/react-stripe-js';
import { Button, Dialog, FormControl, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { APIUsersHelper } from '../../Api/ApiUsersHelper';
import { useNavigate } from 'react-router';
import { PaymentRecuringType, SubscriptionPlans } from '../../Api/UsersMicroserviceClient';
import { useTranslation } from 'react-i18next';
interface PaymentProps {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
};

const CheckoutForm = () => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    useEffect(() => {
        console.log('tady');
        if (elements) {
            console.log('updatein');
            // elements!.getElement(CardElement)!.update({ style: { base: { fontSize: '24px' } } });
        }
    }, [elements]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (elements == null) {
            return;
        }

        stripe!.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)!,

        }).then((e) => {
            console.log(e);
            // e.paymentMethod.
            // e.paymentMethod.
        })
    };

    return (
        <form style={{ width: '280px', height: '300px' }} onSubmit={handleSubmit}>
            <CardElement options={{ style: { base: { fontSize: '18px' } } }} />
            <button type="submit" disabled={!stripe || !elements}>
                {t('sales.pay')}
            </button>
        </form>
    );
};

const stripePromise = loadStripe('pk_test_51LuEIVEeCPX3mO27dKo5h7ChuRKDF2Ir3sFSwhICYNvdFZFCPP27bG7wfIRGHQSeiHH315R9qbT268b7kYWq4Ye200uFRQXYBG');

export default function Payment(props: PaymentProps) {
    const { t } = useTranslation();
    const { open } = props;
    const handleSubscriptionSelected = (
        event: React.MouseEvent<HTMLElement>,
        newFormats: SubscriptionPlans,
    ) => {
        setSelectedSubscription(newFormats);
    };
    const [selectedSubscription, setSelectedSubscription] = React.useState<SubscriptionPlans>(SubscriptionPlans.Business);
    const [selectedPaymentRecuring, setSelectedPaymentRecuring] = React.useState<PaymentRecuringType>(PaymentRecuringType.Yearly);
    const [selectedCurrency, setSelectedCurrency] = React.useState<string>('CZK');
    const czkSinglePrice = 1990;
    const czkSinglePriceBusiness = 12990;
    const plnSinglePrice = 599;
    const plnSinglePriceBusiness = 2999;
    const eurSinglePrice = 80;
    const eurSinglePriceBusiness = 450;
    function getCurrentStartPrice() {
        switch (selectedCurrency) {
            case 'EUR':
                return eurSinglePrice * (selectedPaymentRecuring === PaymentRecuringType.Yearly ? 12 : 1);
            case 'PLN':
                return plnSinglePrice * (selectedPaymentRecuring === PaymentRecuringType.Yearly ? 12 : 1);
            case 'CZK':
                return czkSinglePrice * (selectedPaymentRecuring === PaymentRecuringType.Yearly ? 12 : 1);

        }
    }
    function getCurrentBusinessPrice() {
        switch (selectedCurrency) {
            case 'EUR':
                return eurSinglePriceBusiness * (selectedPaymentRecuring === PaymentRecuringType.Yearly ? 12 : 1);
            case 'PLN':
                return plnSinglePriceBusiness * (selectedPaymentRecuring === PaymentRecuringType.Yearly ? 12 : 1);
            case 'CZK':
                return czkSinglePriceBusiness * (selectedPaymentRecuring === PaymentRecuringType.Yearly ? 12 : 1);

        }
    }
    return (
        <div>
            <Dialog open={open}>

                <div style={{ width: '600px', height: '400px' }}>
                    <ToggleButtonGroup
                        size='small'
                        color="primary"
                        fullWidth
                        exclusive
                        value={selectedSubscription}
                        onChange={handleSubscriptionSelected}
                        aria-label="Platform"
                    >
                        <ToggleButton value={SubscriptionPlans.Start}>Start</ToggleButton>
                        <ToggleButton value={SubscriptionPlans.Business}>Business</ToggleButton>
                    </ToggleButtonGroup>
                    <div>
                        <div><Typography color={selectedSubscription === SubscriptionPlans.Start ? "primary" : "action"}>Start  {getCurrentStartPrice()}</Typography> </div>
                        <div><Typography color={selectedSubscription === SubscriptionPlans.Start ? "action" : "primary"}>Business {getCurrentBusinessPrice()}</Typography></div>
                    </div>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-helper-label">{t('sales.currency')}</InputLabel>
                        <Select
                            value={selectedCurrency}
                            label={t('sales.currency')}
                            onChange={(e) => { setSelectedCurrency(e.target.value) }}
                        >
                            <MenuItem value={'EUR'}>EUR</MenuItem>
                            <MenuItem value={'CZK'}>CZK</MenuItem>
                            <MenuItem value={'PLN'}>ZLT</MenuItem>
                            <MenuItem value={'USD'}>USD</MenuItem>
                            <MenuItem value={'GBP'}>GBP</MenuItem>
                        </Select>

                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-helper-label">{t('paymentType')}</InputLabel>
                        <Select
                            value={selectedPaymentRecuring}
                            label={t('paymentType')}
                            onChange={(e, t) => { setSelectedPaymentRecuring(e.target.value as (PaymentRecuringType)); console.log(e); console.log(t); }}
                        >
                            <MenuItem value={PaymentRecuringType.None}>{t('oneTime')}</MenuItem>
                            <MenuItem value={PaymentRecuringType.Monthly}>{t('monthly')}</MenuItem>
                            <MenuItem value={PaymentRecuringType.Yearly}>{t('annually')}</MenuItem>
                        </Select>

                    </FormControl>
                    <Button onClick={() => {
                        // new APIUsersHelper().Subscribe().then((t) => {
                        //     window.location.href = t;
                        // });
                        // new APIUsersHelper().PaymentSession(selectedSubscription, selectedCurrency, selectedPaymentRecuring).then((ad) => {
                        //     window.location.href = ad;
                        // });
                    }} variant='contained'>{t('sales.pay')}</Button>
                </div>
            </Dialog>
        </div>);
}
