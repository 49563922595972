import { Checkbox, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
interface FileRecordProps {
    id?: number;
    name?: string;
    isSelected?: boolean;
    onSelected?: (id: number, isSelect: boolean) => void;
}
export default function FileRecord(props: FileRecordProps) {
    const { name, isSelected, id, onSelected } = props;
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        if (isSelected !== undefined)
            setChecked(isSelected);
    }, [isSelected]);
    return <div style={{ display: 'flex', paddingTop: '2px' }}>

        {/* <Checkbox checked={checked} onChange={(e, chek) => {
            setChecked(chek);
            if (id !== undefined && onSelected !== undefined)
                onSelected(id, chek);
        }} style={{ marginTop: '-8px' }} /> */}
        <Typography sx={{ marginLeft: '8px' }}>{name}</Typography>

    </div>;
}