import { Grading } from '@mui/icons-material';
import { ColumnsDirective, GridComponent, Group, RecordDoubleClickEventArgs, Sort, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, ColumnDirective, Inject, FilterSettingsModel, Page, ExcelQueryCellInfoEventArgs, Column } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { BuildingForProjectsListPageDTO } from '../../../ApiOld/ApiServerVov';
import { loadDiaryStore, useDiaryStore } from '../../../Contexts/DiaryZContext';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import { APIOldBuildingsClient } from '../../../ApiOld/ApiOldBuildingsClient';

InitSfGridLocale();
interface ProjectsListProps {
}

export default function ProjectsList(props: ProjectsListProps) {

    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };


    const [projects, setProjects] = useState<BuildingForProjectsListPageDTO[] | undefined>();
    const [trashOpen, setTrashOpen] = useState(false);


    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { permissions, load } = useDiaryStore();
    const params = useParams();

    useEffect(() => {

        if (permissions === undefined)
            loadDiaryStore(false).then((d) => {
                load(d);
            })
    }, []);


    function GridTemplate(props: BuildingForProjectsListPageDTO) {
        const [hover, setHover] = useState(false);
        if (permissions && permissions.canViewDiaryRecordsDetail)
            return (<div style={{ cursor: 'pointer' }} onClick={() => {
                navigateToDetail(props);
            }} onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <a style={{ color: '#5762D5', textDecoration: 'underline', fontWeight: 'bold' }}>{props.name!}</a>
            </div>);
        else return <div>{props.name!}</div>
    }


    function navigateToDetail(data: BuildingForProjectsListPageDTO) {
        navigate(
            { pathname: '/' + i18n.language + '/' + params['domain']! + '/projectDetail/' + data.id!.toString(), },
            { state: { id: data.id, inputRecords: projects } }
        );
    }

    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }

    const excelQueryCellInfo = (args: ExcelQueryCellInfoEventArgs): void => {
        if ((args.column as Column).field === 'isActive') {
            if ((args.data as BuildingForProjectsListPageDTO).isActive) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'showToEmployees') {
            if ((args.data as BuildingForProjectsListPageDTO).showToEmployees) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }

    }

    const grid = useRef<GridComponent>(null);

    function renderAssignedUsers(arrayOfObjects: any) {
        let concatenatedNames = '';
        for (let i = 0; i < arrayOfObjects.length; i++) {
            concatenatedNames += arrayOfObjects[i].name;
            if (i < arrayOfObjects.length - 1) {
                concatenatedNames += ', ';
            }
        }
        return concatenatedNames;
    }

    useEffect(() => {
        if (projects === null || projects === undefined)
            refresh();
        else setProjects(undefined);
    }, []);

    function refresh() {
        new APIOldBuildingsClient().GetBuildingsForProjectsListPage(false).then((r) => {
            setProjects(r);
            console.log('projects', r);
        });
    }


    // 1. DNES google mapa vyhledavani
    // 2. na projectDetail nefunguje onChange na checkboxy (ukladani zmen)
    // 3. projectList - better layout (podle diaryRecords)

    // 1. CreateProject new page?
    // 2. fotky


    if (projects)
        return <div style={{ display: "flex", flexDirection: 'column' }}>

            <UpperMenu moduleName={t('buildings.projects')}
                gridType={GridType.Projects}
                icon={<Grading />}
                canAdd={true}
                onCreate={(e) => {
                    navigate({ pathname: '/' + i18n.language + '/' + params['domain']! + '/projectDetail/' });
                }}
                canTrash
                canDelete={permissions ? permissions.canDeleteDiaryRecord : false}
                onDelete={(e) => { new APIOldBuildingsClient().deleteBuildings(e as number[]).then((e) => { if (e) refresh(); }) }}
                canChangeEyeView={false}
                onThrashClicked={() => { setTrashOpen(true); }}
                grid={grid} canChangeGroup canToolbarGrid
                menuName={t('View')}
            />


            <GridComponent ref={grid} id="grid-component-projects" allowSorting={true}
                allowReordering={true} allowResizing={true} showColumnChooser={true}
                allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                locale={i18n.language} enablePersistence={true}
                excelQueryCellInfo={excelQueryCellInfo}
                recordDoubleClick={(e: RecordDoubleClickEventArgs) => {
                    navigateToDetail(e.rowData as BuildingForProjectsListPageDTO)
                }}
                filterSettings={filterOptions}
                allowPaging={true}
                style={{ backgroundColor: '#F5F5F5' }}
                pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                toolbarClick={toolbarClickExcel}
                columnDataStateChange={(e) => { console.log(e) }}
                selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                allowFiltering={true} groupSettings={groupOptions}
                dataSource={projects}>
                <ColumnsDirective>
                    <ColumnDirective type='checkbox' width='40' />
                    <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" visible={false} />
                    <ColumnDirective field='code' headerText={t('buildings.buildingCode')} width='46' />
                    <ColumnDirective field='name' template={GridTemplate} headerText={t('buildings.buildingName')} width='100' />
                    <ColumnDirective template={(props: any) => renderAssignedUsers(props.assignedUsersForProjectListPage)} field='assignedUsers' headerText={t('buildings.assignedUsers')} width='46' />
                    <ColumnDirective field='isActive' type='boolean' displayAsCheckBox headerText={t('active')} width='36' />
                    <ColumnDirective field='showToEmployees' type='boolean' displayAsCheckBox headerText={t('showToEmployees')} width='46' />
                </ColumnsDirective>
                <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
            </GridComponent>
            <div>
                <TrashGridDialog trashType={TrashType.Projects} open={trashOpen} onClose={(e) => { setTrashOpen(false); if (e) refresh(); }} />
            </div>
        </div>

    return <div>{t('noRecords')}</div>;
}