import React, { useEffect } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { CircularProgress, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetBuildingDataDetailResponse } from '../../../../ApiOld/ApiServerVov';


interface GoogleMap2SceneProps {
    selected: any;
    projectData: Partial<GetBuildingDataDetailResponse> | null;
    setSelected: (set: any) => void;
    setProjectData: (set: any) => void;
}

export default function GoogleMap2Scene(props: GoogleMap2SceneProps) {
    const { selected, projectData, setSelected, setProjectData } = props;
    const { t, i18n } = useTranslation();
    const { isLoaded } = useLoadScript({
        id: `google-map2`,
        googleMapsApiKey: "AIzaSyAUicHcR0cgVPe3nY0bjbrUHMlwk3EVYPU",
        libraries: ["places"]
    });

    const mapContainerStyle = { width: '600px', height: '400px' };

    useEffect(() => {
        if (projectData?.address2 !== undefined && projectData?.gpsX2 !== undefined && projectData?.gpsY2 !== undefined) {
            setSelected({ lat: projectData.gpsX2, lng: projectData.gpsY2 });
        }
    }, []);

    useEffect(() => {
        if (projectData?.address2 !== undefined && projectData?.gpsX2 !== undefined && projectData?.gpsY2 !== undefined) {
            setSelected({ lat: projectData.gpsX2, lng: projectData.gpsY2 });
        }
    }, [projectData]);

    const theme = useTheme();
    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={selected}
            zoom={15}
        >
            {selected && <MarkerF onDragEnd={(e) => {
                const lat = e.latLng?.lat()
                const lng = e.latLng?.lng()

                // Update the coordinates state
                if (lat && lng) {
                    setSelected({ lat: lat, lng: lng });
                    setProjectData((projectData) => ({ ...projectData, address2: '', gpsX2: lat, gpsY2: lng }));

                }
            }} draggable position={selected} />}
        </GoogleMap>

    ) : <div><CircularProgress /></div>
}