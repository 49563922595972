import { Download, Folder } from '@mui/icons-material';
import { Checkbox, IconButton, Typography } from '@mui/material';
import { DrawNodeEventArgs, FieldSettingsModel, NodeCheckEventArgs, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import React, { useEffect, useState } from 'react';
import { FileDirectoryDTO } from '../../../ApiOld/ApiServerVov';
import saveBlob from '../../Shared/Files/SaveBlob';

interface InvestorDocumentsTreeProps {

    files?: FileDirectoryDTO[];
}
export default function InvestorDocumentsTree(props: InvestorDocumentsTreeProps) {
    const { files } = props;

    let cssClass: string = "custom";
    function nodeTemplate(data: any): JSX.Element {
        if (data.isDirectory)
            return (
                <div style={{ display: 'flex', paddingTop: '2px' }}>


                    <Folder />
                    <div style={{ marginLeft: '8px' }}>{data.name}</div>

                </div >

            )
        else {
            return <div style={{ display: 'flex', paddingTop: '2px' }}>

                <IconButton onClick={(e) => { window.open(data.url, '_blank'); }}>
                    <Download />
                </IconButton>
                <div style={{ marginLeft: '8px' }}>{data.name}</div>

            </div>
            // fileOriginal.isSelected = selected;
            // setFiles(filesCopy);



        }
    }
    function handleDownload(url: string, filename: string) {
        saveBlob(url, filename);
    }
    function drawNode(args: DrawNodeEventArgs) {
        // console.log(args);
        let ele = args.node.querySelector('.e-checkbox-wrapper');
        if (ele && args.nodeData.isDirectory) {
            ele.setAttribute('style', 'display: none');
            // console.log(ele);
        }
        // if (permissions === undefined || permissions.deleteAttachments === undefined || !permissions.deleteAttachments)
        //     if (ele && args.nodeData.isSelected)
        //         ele.setAttribute('style', 'display: none');
        // ele[0].style.setPropery('display', 'none');
        // ele.classList.add('e-checkbox-disabled');
    }
    const fields: object = { dataSource: files, id: 'id', text: 'name', child: 'children', hasChildern: 'hasChildren', expanded: 'hasChildren' };

    return <TreeViewComponent cssClass={cssClass} nodeTemplate={nodeTemplate} drawNode={drawNode} fields={fields} />

}