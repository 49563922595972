import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
export interface QuestionDialogProps {
    onClose: (res: boolean) => void;
    text: string;
    title: string;
    open: boolean;
}
export default function QuestionDialog(props: QuestionDialogProps) {
    const { onClose, open, text, title } = props;
    const theme = useTheme();

    const { t } = useTranslation();
    return (<Dialog
        open={open}
        onClose={() => { onClose(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            {text}
        </DialogContent>
        <DialogActions>
            <Button sx={{ background: theme.palette.primary.main, color: 'black' }} onClick={() => { onClose(true) }}>{t('yesL')}</Button>
            <Button sx={{ background: theme.palette.primary.main, color: 'black' }} onClick={() => { onClose(false) }}>{t('noL')}</Button>
        </DialogActions>
    </Dialog>

    );
}