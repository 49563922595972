import { create } from 'zustand'
import { APIVovInvestorHelper } from '../ApiOld/ApiInvestorOldClient';
import { GetDiaryPermissionsInvestorResponse } from '../ApiOld/ApiServerVov';

interface InvestorPermissionStoreState {
    permissions?: GetDiaryPermissionsInvestorResponse;
    load: (perms: GetDiaryPermissionsInvestorResponse) => void
}
export const loadInvestorPermissionStore = () => {
    return new APIVovInvestorHelper().GetPermissions();
}
export const useInvestorPermissionStore = create<InvestorPermissionStoreState>()((set) => ({
    permissions: undefined,
    load: (by) => set((state) => ({ permissions: by })),
}))