import { APIOldBuildingsClient } from "../../../ApiOld/ApiOldBuildingsClient";
import { APIVovHelper } from "../../../ApiOld/ApiOldClient";
import { APIVovEmployeeHelper } from "../../../ApiOld/ApiOldEmployeeClient";
import { ApiOldInvoicesClient } from "../../../ApiOld/ApiOldInvoicesClient";
import { APIOldWealthClient } from "../../../ApiOld/ApiOldWealthClient";
import { APIVovSubcontractorHelper } from "../../../ApiOld/ApiSubcontractorOldClient";
import { TrashType } from "./TrashType";

export default async function GetTrashItems(trashType: TrashType, isSubdodavatel: boolean): Promise<any[]> {
    switch (trashType) {
        case TrashType.Projects:
            return await GetProjectsTrashItems();
        case TrashType.Diaries:
            return await GetDiariesTrashItems();
        case TrashType.DailyRecords:
            return await GetDailyRecordsTrashItems(isSubdodavatel);
        case TrashType.Wealths:
            return await GetWealthsTrashItems();
        case TrashType.Invoices:
            return await GetInvoicesTrashItems();
        case TrashType.Todos:
            return await GetTodosTrashItems();
        case TrashType.Employees:
            return await GetEmployeesTrashItems();
        default:
            return [];
    }

}


async function GetProjectsTrashItems(): Promise<any[]> {
    return await (new APIOldBuildingsClient().GetBuildingsForProjectsListPage(true));
}

async function GetDiariesTrashItems(): Promise<any[]> {
    return await (new APIVovHelper().GetDiaries(true, false));
}
async function GetDailyRecordsTrashItems(isSubdodavatel: boolean): Promise<any[]> {
    if (isSubdodavatel === true)
        return await new APIVovSubcontractorHelper().GetDiaryRecordsSubcontractor(true);
    else
        return await (new APIVovHelper().GetDiaryRecords(true));
}

async function GetWealthsTrashItems(): Promise<any[]> {
    let t = (await (new APIOldWealthClient().GetWealthsForAdminUser(true))).wealths;
    if (t !== undefined) {
        return t;
    } else {
        return [];
    }
}

async function GetInvoicesTrashItems(): Promise<any[]> {
    return await (new ApiOldInvoicesClient().GetBuildingBillsForInvoicesListPage(true));
}


async function GetTodosTrashItems(): Promise<any[]> {
    return await (new APIVovHelper().GetDeletedTodos());
}

async function GetEmployeesTrashItems(): Promise<any[]> {
    return await (new APIVovEmployeeHelper().GetDeletedEmployees());
}
