import { People } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GroupDTO } from '../../../../ApiOld/ApiServerVov';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import saveBlob from '../../../Shared/Files/SaveBlob';
import { useTranslation } from 'react-i18next';


interface SelectGroupDialogProps {
    open: boolean;
    onClose: (e: number | undefined) => void;
    groups?: GroupDTO[];
}

export default function SelectGroupDialog(props: SelectGroupDialogProps) {
    const { open, onClose, groups } = props;
    const { t, i18n } = useTranslation();
    return (<Dialog
        open={open}
        sx={{ marginTop: '40px' }}
        maxWidth="xl"
        onClose={() => { onClose(undefined) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {t('chooseSubcontractor')}
        </DialogTitle>
        <DialogContent>

            {groups && <div style={{ display: 'flex', flexDirection: 'column' }}>
                {groups.map((group) => {
                    // return <div style={{ display: 'flex', flexDirection: 'column' }}><Button sx={{ marginBottom: '10px' }} variant='contained'>{group.groupName}</Button>
                    return <div key={group.groupId} style={{ display: 'flex', flexDirection: 'column' }}><div style={{ marginBottom: '10px' }}>{group.groupName}</div>
                        {group.members && group.members.map((member) => {
                            return <Button key={member.userId} size='small'
                                onClick={(e) => { onClose(member.userId) }}
                                startIcon={<People />} sx={{ marginLeft: '20px', marginBottom: '10px' }} variant='contained'>{member.name}</Button>
                        })}
                    </div>
                })}</div>}
            {(groups === undefined || groups === null || groups.length === 0) && <div>{t('buildings.noSubcontractorsOnProject')}</div>}

        </DialogContent>
    </Dialog>)

}
