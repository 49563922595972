import { Box, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '../../Shared/Tabs/TabPanel';
import tabProps from '../../Shared/Tabs/TabProps';


import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDiaryStore, loadDiaryStore } from '../../../Contexts/DiaryZContext';
import NoRights from '../../Shared/Layouts/NoRights';
import SingleTodoSceneOld from './SingleTodoSceneOld';
import TodoChatSceneOld from './TodoChatSceneOld';

interface SingleTodoTabSceneOldProps {
    selected?: number;
}

export default function SingleTodoTabSceneOld(props: SingleTodoTabSceneOldProps) {
    const { t, i18n } = useTranslation();
    const { selected } = props;
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const params = useParams();
    useEffect(() => {
        if (selected !== undefined)
            setSelectedTabIndex(selected);
    }, [selected]);
    useEffect(() => {
        /* if (isSubdodavatel) {
            if (selectedTabIndex === 0)
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/subcontractor/diaries");
            else if (selectedTabIndex === 1)
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/subcontractor/diariesDiary");
        }
        else {
            if (selectedTabIndex === 0 && window.location.pathname.endsWith('diaries')) {
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/diaryvov");
            }
            else if (selectedTabIndex === 1 && window.location.pathname.endsWith('diaryvov')) {
                window.history.replaceState(null, "Stavario denní záznamy", "/" + params['lang']! + "/" + params['domain']! + "/diaryvovdiaries");
            }
        } */
    }, [selectedTabIndex]);
    useEffect(() => {
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }
    }, []);

    return (
        <div>
            <Box >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={(e, n) => { setSelectedTabIndex(n) }} aria-label="basic tabs example">
                        <Tab label={t('Základní informace')} {...tabProps(0)} />
                        <Tab label={t('Chat')} {...tabProps(1)} />
                        <Tab label={t('Přílohy')} {...tabProps(2)} />
                        <Tab label={t('Log změn')} {...tabProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={selectedTabIndex} index={0}>
                    <div>
                        <SingleTodoSceneOld />
                    </div>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <div>
                        <TodoChatSceneOld />
                    </div>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={2}>
                    <div>
                    </div>
                </TabPanel>
            </Box></div>);
}