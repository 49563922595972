import { AccessTimeOutlined, AssignmentInd, PersonOutline, Send, Settings } from '@mui/icons-material';
import { Box, IconButton, TextField, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AddTodoConversationRequest, ConversationDto } from '../../../ApiOld/ApiServerVov';
import { APITodoOldHelper } from '../../../ApiOld/ApiToDoOldHelper';
import { useParams } from 'react-router';
import ChatMessageDetailDialog from './ChatMessageDetailDialog';

export interface TodoChatScenePropsOld {
}
export default function TodoChatSceneOld(props: TodoChatScenePropsOld) {
    const theme = useTheme();
    const [selectedChatGroups, setSelectedChatGroups] = React.useState(() => ['1']);

    const [workersToggle, setWorkersToggle] = React.useState(true);
    const [officeToggle, setOfficeToggle] = React.useState(false);
    const [investorToggle, setInvestorToggle] = React.useState(true);
    const [newMessage, setNewMessage] = React.useState('');

    const [conversations, setConversations] = React.useState<ConversationDto[]>([]);
    const [requestId, setRequestId] = React.useState<number | undefined>();
    const [detailOpen, setDetailOpen] = React.useState(false);
    const [selectedMessage, setSelectedMessage] = React.useState<ConversationDto>();

    useEffect(() => {
        setNewMessage('');
    }, []);

    useEffect(() => {
        if (requestId)
            new APITodoOldHelper().GetTodoChatConversations(requestId).then((result) => {
                setConversations(result);
            });
        else setConversations([]);
    }, [requestId]);
    const handleChatGroupSelected = (
        event: React.MouseEvent<HTMLElement>,
        newFormats: string[],
    ) => {
        setSelectedChatGroups(newFormats);
    };
    const { t } = useTranslation();

    const params = useParams();

    useEffect(() => {
        if (params['id'] && !isNaN(parseInt(params['id']))) {
            setRequestId(parseInt(params['id']));
        }
        else if (params['id'] === 'new') {
            setRequestId(undefined);
        }
    }, [params]);

    const sendConversation = () => {
        if (newMessage.length > 0) {
            new APITodoOldHelper().AddTodoConversation(new AddTodoConversationRequest({ requestId: requestId, text: newMessage, toEmployee: !workersToggle, toInvestor: !investorToggle })).then((result) => {
                if (result && result.sent) {
                    setNewMessage('');
                    new APITodoOldHelper().GetTodoChatConversations(requestId!).then((result) => {
                        setConversations(result);
                    });
                }
            });
        }
    }

    return (
        <div style={{ margin: '0px', background: 'white' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '33%', backgroundColor: theme.palette.primary.main }}>
                    <Typography fontSize={18} fontWeight='bold' sx={{ m: '8px', float: 'left' }} color={theme.palette.secondary.main}>{t('mobileApp')}</Typography>
                    <AssignmentInd sx={{ float: 'right', margin: '10px' }} color='secondary' />
                </div>
                <div style={{ width: '33%', backgroundColor: theme.palette.secondary.main }} >
                    <Typography fontSize={18} fontWeight='bold' sx={{ m: '8px', float: 'left' }} color='white'>{t('office')}</Typography>
                    <AssignmentInd sx={{ float: 'right', margin: '10px' }} color='primary' />
                </div>
                <div style={{ width: '33%', backgroundColor: theme.palette.success.main }} >
                    <Typography sx={{ float: 'left', m: '8px' }} fontSize={18} fontWeight='bold' color='white'>{t('Investor')}</Typography>
                    <AssignmentInd sx={{ float: 'right', margin: '10px' }} color='primary' />
                </div>
            </div>
            <div style={{ minHeight: '200px', maxHeight: '75vh', overflow: 'auto', padding: '5px' }}>
                {conversations.map((conversation, index) => {
                    if (conversation.employeeId)
                        return <div style={{ display: 'flex', minHeight: '64px' }} key={index}>
                            <div style={{ fontSize: '14px', width: '33%', background: 'lightgray', border: '1px solid white', borderRadius: '4px', padding: '4px' }} >
                                <div style={{ display: 'flex' }}>
                                    <div style={{ fontWeight: '500', width: 'calc(100% - 30px)' }}>{conversation.text}</div>
                                    <IconButton sx={{ width: '30px', alignSelf: 'flex-start' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}><Settings /></IconButton>
                                </div>
                                <hr style={{ height: '2px', marginTop: '4px', marginBottom: '2px' }} />
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    <PersonOutline fontSize='small' sx={{ float: 'left', color: 'black' }} color='primary' />
                                    <div>
                                        {conversation.employee ? conversation.employee : ''}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', fontSize: '12px' }}>
                                    <AccessTimeOutlined fontSize='small' sx={{ height: '16px', float: 'left', color: 'black' }} color='primary' />
                                    <div>
                                        {conversation.datetime ? conversation.datetime.toLocaleString() : ''}
                                    </div>
                                </div>
                                <IconButton sx={{ position: 'absolute', top: '2px', right: '2px' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setSelectedMessage(conversation); setDetailOpen(true); }}><Settings /></IconButton>
                            </div>
                            <div style={{ width: '32%', background: conversation.toAdmin ? 'lightgray' : 'white', border: '1px solid white', borderRadius: '4px', padding: '4px' }} ></div>
                            <div style={{ width: '33%', background: conversation.toInvestor ? 'lightgray' : 'white', border: '1px solid white', borderRadius: '4px', padding: '4px' }} ></div>
                        </div>
                    else if (conversation.adminUserId)
                        return <div style={{ display: 'flex', minHeight: '64px', margin: '4px' }} key={index}>
                            <div style={{ width: '33%', background: conversation.toEmployee ? 'lightgray' : 'white', border: '1px solid white', borderRadius: '4px', padding: '4px' }} ></div>
                            <div style={{ fontSize: '18px', width: '33%', background: 'lightgray', border: '1px solid white', borderRadius: '4px', padding: '4px' }} >
                                <div style={{ display: 'flex' }}>
                                    <div style={{ fontWeight: '500', width: 'calc(100% - 30px)' }}>{conversation.text}</div>
                                    <IconButton sx={{ width: '30px', alignSelf: 'flex-start' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setSelectedMessage(conversation); setDetailOpen(true); }}><Settings /></IconButton>
                                </div>
                                <hr style={{ height: '2px', marginTop: '4px', marginBottom: '2px' }} />
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    <PersonOutline fontSize='small' sx={{ float: 'left', color: 'black' }} color='primary' />
                                    <div>
                                        {conversation.adminUser ? conversation.adminUser : ''}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', fontSize: '12px' }}>
                                    <AccessTimeOutlined fontSize='small' sx={{ height: '16px', float: 'left', color: 'black' }} color='primary' />
                                    <div>
                                        {conversation.datetime ? conversation.datetime.toLocaleString() : ''}
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '33%', background: conversation.toInvestor ? 'lightgray' : 'white', border: '1px solid white', borderRadius: '4px', padding: '4px' }} ></div>
                        </div>
                    else if (conversation.investorId)
                        return <div style={{ display: 'flex', minHeight: '64px' }} key={index}>
                            <div style={{ width: '33%', background: conversation.toEmployee ? 'lightgray' : 'white', border: '1px solid white', borderRadius: '4px', padding: '4px' }} ></div>
                            <div style={{ width: '32%', background: conversation.toAdmin ? 'lightgray' : 'white', border: '1px solid white', borderRadius: '4px', padding: '4px' }} ></div>
                            <div style={{ fontSize: '14px', width: '33%', background: 'lightgray', border: '1px solid white', borderRadius: '4px', padding: '4px' }} >
                                <div style={{ display: 'flex' }}>
                                    <div style={{ fontWeight: '500', width: 'calc(100% - 30px)' }}>{conversation.text}</div>
                                    <IconButton sx={{ width: '30px', alignSelf: 'flex-start' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setSelectedMessage(conversation); setDetailOpen(true); }}><Settings /></IconButton>
                                </div>
                                <hr style={{ height: '2px', marginTop: '4px', marginBottom: '2px' }} />
                                <div style={{ display: 'flex', fontSize: '14px' }}>
                                    <PersonOutline fontSize='small' sx={{ float: 'left', color: 'black' }} color='primary' />
                                    <div>
                                        {conversation.investor ? conversation.investor : ''}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', fontSize: '12px' }}>
                                    <AccessTimeOutlined fontSize='small' sx={{ height: '16px', float: 'left', color: 'black' }} color='primary' />
                                    <div>
                                        {conversation.datetime ? conversation.datetime.toLocaleString() : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    return <div style={{ display: 'flex' }} key={index}>
                        <div style={{ width: '33%', background: 'white' }} ></div>
                        <div style={{ width: '33%', background: 'white' }} ></div>
                        <div style={{ width: '33%', background: 'white' }} ></div>
                    </div>
                })}
                <div style={{ display: 'flex', background: 'white', height: '8px', marginRight: '10px' }}></div>
            </div>
            <div style={{ display: 'flex', marginTop: '16px' }}>
                <div style={{ width: '33%' }}>
                    <ToggleButtonGroup
                        size='small'
                        color="primary"

                        value={selectedChatGroups}
                        onChange={handleChatGroupSelected}
                        aria-label="Platform"
                        style={{ width: '100%' }}
                    >
                        <div style={{ width: '33%', justifyContent: 'center', display: 'flex' }}>
                            <ToggleButton selected={workersToggle} sx={{ backgroundColor: workersToggle ? theme.palette.secondary.main : theme.palette.primary.main, width: '80%' }} size='small' value="0" onChange={() => setWorkersToggle(!workersToggle)}>{t('workers')}</ToggleButton>
                        </div>
                        <div style={{ width: '33%', justifyContent: 'center', display: 'flex' }}>
                            <ToggleButton onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} selected={officeToggle} sx={{ backgroundColor: officeToggle ? theme.palette.secondary.main : theme.palette.primary.main, width: '80%', cursor: 'not-allowed !important' }} size='small' value="1" onChange={() => setOfficeToggle(true)}>{t('office')}</ToggleButton>
                        </div>
                        <div style={{ width: '33%', justifyContent: 'center', display: 'flex' }}>
                            <ToggleButton selected={investorToggle} sx={{ backgroundColor: investorToggle ? theme.palette.secondary.main : theme.palette.primary.main, width: '80%' }} size='small' value="2" onChange={() => setInvestorToggle(!investorToggle)}>{t('Investor')}</ToggleButton>
                        </div>
                    </ToggleButtonGroup>
                </div>
                <div style={{ width: '66%' }}>
                    <Box sx={{ borderRadius: '16px', marginLeft: '12px', padding: '6px', border: 1, borderColor: 'primary.main' }}>
                        <div style={{ display: 'flex' }}>
                            <TextField value={newMessage} onChange={(e) => { setNewMessage(e.currentTarget.value) }} sx={{ marginLeft: '16px', marginRight: '16px', width: 'calc(100% - 80px)' }} size='small' variant='standard'></TextField>
                            <div><IconButton disabled={!requestId || newMessage.length === 0} onClick={(e) => { e.preventDefault(); e.stopPropagation(); sendConversation(); }}><Send /></IconButton></div>
                        </div>
                    </Box>
                </div>
            </div>
            {selectedMessage && <ChatMessageDetailDialog conversation={selectedMessage} open={detailOpen} onClose={() => setDetailOpen(false)} onSave={(data) => {
                setConversations(conversations.map(x => x.id === data.id ? data : x));
            }} />}
        </div>);
}