import { create } from 'zustand'
import { GetWorkerForRecordDTO } from '../ApiOld/ApiServerVov'

interface DiaryWorkersCopyState {
    workersStore?: GetWorkerForRecordDTO[] | undefined
    loadWorkersStore: (wrks: GetWorkerForRecordDTO[] | undefined) => void
}
export const useDiaryWorkersCopyStore = create<DiaryWorkersCopyState>()((set) => ({
    workersStore: undefined,
    loadWorkersStore: (by) => set((state) => ({ workersStore: by })),
}))