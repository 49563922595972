import React from 'react'
import MoveBackToStavarioToolbar from './MoveBackToStavarioToolbar'
import DiaryMainScene from '../../DiaryMainScene'

export default function DailyRecordsExternalWithMenu() {

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MoveBackToStavarioToolbar />
            <div style={{ marginTop: '52px' }}>
                <DiaryMainScene fromExternal />
            </div>
        </div>)
}
