
import { useTranslation } from 'react-i18next';

import React, { useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Autocomplete, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, useTheme } from '@mui/material';
import { useMediaPredicate } from 'react-media-hook';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { BuildingDTO, GetDailyRecordsForUserDTO, GetDiariesForUserDTO } from '../../../ApiOld/ApiServerVov';
import { ApiOldInvoicesClient } from '../../../ApiOld/ApiOldInvoicesClient';
import BuildingSelect from '../../Shared/BuildingSelect';
import { on } from 'events';

interface CreateDiaryRecordProps {
    open: boolean;
    onClose: (refresh: boolean, newId?: GetDiariesForUserDTO) => void;
}

export default function CreateInvoice(props: CreateDiaryRecordProps) {
    const { t, i18n } = useTranslation();
    const { open, onClose } = props;
    const [invoiceName, setInvoiceName] = useState<string>();
    const [changed, setChanged] = useState<boolean>(false);
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    useEffect(() => {
        setChanged(false);
        setInvoiceName("");
    }, [open]);

    function save(close: boolean) {
        if (invoiceName) {
            new ApiOldInvoicesClient().CreateInvoice(invoiceName).then((r) => {
                setInvoiceName("");
                onClose(close, r);
            })
        }
    }
    return (

        <Dialog

            maxWidth='lg'
            open={open}
            onClose={(e) => { onClose(changed) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{t('invoices.createNewInvoice')}
                <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>
                    <IconButton sx={{ marginRight: '-12px', marginTop: '-8px' }} onClick={(e) => onClose(changed)} >
                        <Close />
                    </IconButton>
                </div></DialogTitle>
            <DialogContent>
                <ValidationGroup>
                    <div style={{ minHeight: '180px', minWidth: biggerThan700 ? '500px' : '200px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ margin: '10px 20px' }}>
                                <TextField label={t('Name')} variant="outlined" fullWidth size='small'
                                    name='name'
                                    required
                                    value={invoiceName}
                                    onChange={(e) => { setInvoiceName(e.target.value) }}
                                />
                            </div>
                        </div>
                        <div style={{
                            position: 'absolute', bottom: '0', width: '90%', display: 'flex', flexDirection: 'column',
                            justifyContent: 'center', alignContent: 'center', alignItems: 'center'
                        }}>
                            <Button disabled={invoiceName === null} sx={{ width: '200px', margin: '0px' }} variant='outlined' onClick={(e) => { save(false) }}>{t('save')}</Button>
                            <Button disabled={invoiceName === null} sx={{ width: '200px', margin: '12px' }} variant='contained' onClick={(e) => { save(true) }}>{t('saveAndClose')}</Button>
                        </div>
                    </div>

                </ValidationGroup>

            </DialogContent></Dialog>);
}