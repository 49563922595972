import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
interface TimeSelectorProps {
    date: Date;
    onChange: (date: Date, hour: number) => void;
}

export default function TimeSelector(props: TimeSelectorProps) {
    const { date, onChange } = props;
    const [hour, setHour] = React.useState(8);
    const times = [{ id: 0, time: "0:00" },
    { id: 1, time: "1:00" }, { id: 2, time: "2:00" }, { id: 3, time: "3:00" }, { id: 4, time: "4:00" },
    { id: 5, time: "5:00" }, { id: 6, time: "6:00" }, { id: 7, time: "7:00" }, { id: 8, time: "8:00" },
    { id: 9, time: "9:00" }, { id: 10, time: "10:00" }, { id: 11, time: "11:00" }, { id: 12, time: "12:00" },
    { id: 13, time: "13:00" }, { id: 14, time: "14:00" }, { id: 15, time: "15:00" }, { id: 16, time: "16:00" },
    { id: 17, time: "17:00" }, { id: 18, time: "18:00" }, { id: 19, time: "19:00" }, { id: 20, time: "20:00" },
    { id: 21, time: "21:00" }, { id: 22, time: "22:00" }, { id: 23, time: "23:00" }];

    const { t } = useTranslation();

    useEffect(() => {
        onChange(date, 8);
    }, []);

    return (
        <div>
            <div style={{ minHeight: '200px' }}>
                {/* <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={times}
                    value={times[hour]}
                    onChange={(e, v) => { if (v) { setHour(v.id); if (onChange) onChange(date, v.id); } }}
                    getOptionLabel={(option) => option.time}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label={t('diary.time')} />}
                />

            </div></div>
    )
}
