
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { APIVovInvestorHelper } from '../ApiOld/ApiInvestorOldClient';
import { APIVovHelper } from '../ApiOld/ApiOldClient';
import { APIVovEmployeeHelper } from '../ApiOld/ApiOldEmployeeClient';
import { APIVovWarehouseHelper } from '../ApiOld/ApiOldWarehouseClient';
import { BuildingDTO, EmployeeDTO, UserGroupDTO, WarehouseDTO } from '../ApiOld/ApiServerVov';
import { APIVovSubcontractorHelper } from '../ApiOld/ApiSubcontractorOldClient';


interface StavarioOldContextInterface {
    name?: string;
    companyName?: string;
    email?: string;
    projects?: BuildingDTO[];
    warehouses?: WarehouseDTO[];
    employees?: EmployeeDTO[];
    groups?: UserGroupDTO[];
}
export const StavarioOldUpdateContext = React.createContext<((data: StavarioOldContextInterface) => void) | undefined>(undefined);
export const StavarioOldGlobalContext = React.createContext<StavarioOldContextInterface>({});
const sampleAppContext: StavarioOldContextInterface = {

    projects: []
};


export async function SolveProjectsContext(g: StavarioOldContextInterface, c: (data: StavarioOldContextInterface) => void): Promise<BuildingDTO[]> {
    // console.log('solving users');
    if (g.projects === undefined || g.projects === null || g.projects!.length === 0) {
        let u = await new APIVovHelper().GetBuildingsForAdminUser();
        g.projects = u;
        c(g);
        //   .then((usrs) => {
        //         g.users = usrs;
        //         c(g);
        //     })
    }
    return g.projects!;
}

export async function SolveWarehousesContext(g: StavarioOldContextInterface, c: (data: StavarioOldContextInterface) => void): Promise<BuildingDTO[]> {
    // console.log('solving users');
    if (g.warehouses === undefined || g.warehouses === null || g.warehouses!.length === 0) {
        let u = await new APIVovWarehouseHelper().GetWarehousesForAdminUser();
        g.warehouses = u;
        c(g);
        //   .then((usrs) => {
        //         g.users = usrs;
        //         c(g);
        //     })
    }
    return g.warehouses!;
}

export async function SolveEmployeesContext(g: StavarioOldContextInterface, c: (data: StavarioOldContextInterface) => void): Promise<BuildingDTO[]> {
    // console.log('solving users');
    if (g.employees === undefined || g.employees === null || g.employees!.length === 0) {
        let u = await new APIVovEmployeeHelper().GetEmployeesForAdminUser();
        g.employees = u;
        c(g);
        //   .then((usrs) => {
        //         g.users = usrs;
        //         c(g);
        //     })
    }
    return g.employees!;
}


export async function SolveInvestorProjectsContext(g: StavarioOldContextInterface, c: (data: StavarioOldContextInterface) => void): Promise<BuildingDTO[]> {
    // console.log('solving users');
    if (g.projects === undefined || g.projects === null || g.projects!.length === 0) {
        let u = await new APIVovInvestorHelper().GetInvestorBuildings();
        g.projects = u;
        c(g);
        return u;
        //   .then((usrs) => {
        //         g.users = usrs;
        //         c(g);
        //     })
    }
    return g.projects!;
}

export async function SolveUserGroupContext(g: StavarioOldContextInterface, c: (data: StavarioOldContextInterface) => void): Promise<UserGroupDTO[]> {

    if (g.groups === undefined || g.groups === null || g.groups!.length === 0) {
        let u = await new APIVovHelper().UserGroups();
        g.groups = u;
        c(g);
        return u;
    }
    return g.groups!;
}


export async function SolveSubcontractorGroupContext(g: StavarioOldContextInterface, c: (data: StavarioOldContextInterface) => void): Promise<UserGroupDTO[]> {

    if (g.groups === undefined || g.groups === null || g.groups!.length === 0) {
        let u = await new APIVovSubcontractorHelper().SubcontractorGroups();
        g.groups = u;
        c(g);
        return u;
    }
    return g.groups!;
}

const LoginOldContext = ({ subPages }: any) => {

    function setGlobalState(data: StavarioOldContextInterface) {
        setContext(data);

    }
    const [context, setContext] = useState<StavarioOldContextInterface>({});
    return (
        <StavarioOldGlobalContext.Provider value={context}>
            <StavarioOldUpdateContext.Provider value={setGlobalState}>
                {subPages}
            </StavarioOldUpdateContext.Provider>
        </StavarioOldGlobalContext.Provider>
    )
}
export default LoginOldContext;