import React, { useEffect, useState } from 'react';
import './DiaryPrint.css';
import { useParams } from 'react-router';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { FileParameter, GetDailyFullRecordResponse, GetMachinesForRecordTableDTO, GetMaterialsForRecordTableDTO, GetWeatherForRecordDTO, GetWorkerForRecordDTO, ImageDiaryDTO, InvestorsChatMessageDTO, SignatureDTO } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { Button, useTheme } from '@mui/material';
//@ts-ignore
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";

import { Print } from '@mui/icons-material';
import LoaderComponent from '../../../Shared/LoaderComponent';
import saveBlob from '../../../Shared/Files/SaveBlob';
import printDocumentPDF from '../../../Shared/Files/PrintPdfFromHTML';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
interface DiaryPrintProps {
    localRecordId?: number;
    isFromLocalEnv?: boolean;
    hidePrinter?: boolean;
    reload?: boolean;
    onLoadFinished?: () => void;
    onLoadBegin?: () => void;
    signDigitally?: boolean;
    onlyMine?: boolean;
    subcontractorPrintId?: number;
    isInvestor: boolean;
    isSubdodavatel?: boolean;
}

export default function DiaryPrint(props: DiaryPrintProps) {

    function printDocumentPDFDiaryPage() {
        printDocumentPDF(printCallback);
    }

    const params = useParams();
    const theme = useTheme();
    function printCallback(doc: any) {

        doc.setLanguage('cs');
        let a = doc.output('blob');
        let fil = new File([a], 'aFileName.pdf');
        // saveBlob(a, "stavebni_denik.pdf");
        // console.log(fil);
        if (signDigitally === true) {
            let fileParameter: FileParameter = { data: fil, fileName: fil.name };
            if (isFromLocalEnv) {
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor).UploadPdfFileForSignatureLogged(fileParameter, localRecordId!).then((fileNew) => {
                    if (fileNew !== undefined)
                        saveBlob(fileNew.data, "stavebni_denik.pdf");
                });
            }
            else {
                new APIVovHelper().UploadPdfFileForSignature(fileParameter, parseInt(params["id"]!), i18n.language,
                    params["token"]!, parseInt(params["userId"]!), params["domain"]!).then((fileNew) => {
                        saveBlob(fileNew.data, "stavebni_denik.pdf");

                    });
            }
        }
        else {
            saveBlob(a, "stavebni_denik.pdf");
        }
    }
    const { isFromLocalEnv, localRecordId, hidePrinter, reload, signDigitally, onlyMine, subcontractorPrintId, isInvestor, isSubdodavatel } = props;
    const { t, i18n } = useTranslation();
    const [weather, setWeather] = useState<GetWeatherForRecordDTO[] | undefined>();
    const [workers, setWorkers] = useState<GetWorkerForRecordDTO[] | undefined>();
    const [investorsChatMessages, setInvestorsChatMessages] = useState<InvestorsChatMessageDTO[] | undefined>();
    const [images, setImages] = useState<ImageDiaryDTO[] | undefined>();
    const [signatures, setSignatures] = useState<SignatureDTO[] | undefined>();
    const [machinesTable, setMachinesTable] = useState<GetMachinesForRecordTableDTO[] | undefined>();
    const [materialsTable, setMaterialsTable] = useState<GetMaterialsForRecordTableDTO[] | undefined>();
    const [buildingName, setBuildingName] = useState<string | undefined>();
    const [diaryName, setDiaryName] = useState<string | undefined>();
    const [date, setDate] = useState<string | undefined>();
    const [completedWork, setCompletedWork] = useState<string | undefined>();
    const [machines, setMachines] = useState<string | undefined>();
    const [subcontractorPrintIdLocal, setSubcontractorPrintIdLocal] = useState<number | undefined>();
    const [materials, setMaterials] = useState<string | undefined>();
    const [additionalNotes, setAdditionalNotes] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    function reloadData(recordId: number, domain: string) {
        //použít withloading co jsem vyrobil
        setIsLoading(true);
        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor).GetFullDailyRecord(recordId, domain ?? 'stavariokluc', true, true, onlyMine, subcontractorPrintId).then((data) => {
            if (data !== undefined)
                loadData(data);
        }).catch((e) => { console.log('catch', e); })


    }
    function loadData(data: GetDailyFullRecordResponse) {
        setBuildingName(data.buildingName);
        setDiaryName(data.diaryName);
        if (data.date)
            setDate(data.date.toLocaleDateString(i18n.language));
        setImages(data.images);
        setWeather(data.weathers);
        setWorkers(data.workers);
        setInvestorsChatMessages(data.messages);
        if (data.materialsTable)
            setMaterialsTable(data.materialsTable);
        if (data.machinesTable)
            setMachinesTable(data.machinesTable);
        setCompletedWork(data.completedWork);
        setMachines(data.machines);
        setMaterials(data.materials);
        setAdditionalNotes(data.additionalNotes);
        setIsLoading(false);
        if (data.signatures)
            setSignatures(data.signatures.signatures);
    }
    useEffect(() => {
        if (!isFromLocalEnv) {
            i18n.changeLanguage(params['lang']!);
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor).LoginErp(params['token']!, parseInt(params['userId']!), params['domain']!).then((d) => {

                reloadData(parseInt(params['id']!), params['domain']!);
            });
        }
        else {
            if (localRecordId)
                reloadData(localRecordId, params['domain']!);
        }

    }, []);
    useEffect(() => {
        if (localRecordId)
            reloadData(localRecordId, params['domain']!);
        else if (!isFromLocalEnv) reloadData(parseInt(params['id']!), params['domain']!);
    }, [reload, params]);
    if (isLoading)
        return <LoaderComponent />;
    else
        return (
            <div className="home-container">
                <div style={{ height: '30px', backgroundColor: 'white', position: 'relative', right: '20px', bottom: '20px', margin: '0 auto' }}>
                    {(hidePrinter === undefined || hidePrinter === null || hidePrinter === false) &&
                        <Button onClick={printDocumentPDFDiaryPage} startIcon={<Print />}
                            sx={{ minWidth: '280px', margin: '20px' }} variant='contained'>{t('Print')}</Button>}
                </div>

                <div id="pdfToPrint" className="some-other-class" style={{ minWidth: 1200 }}>
                    <div style={{
                        margin: '20px',
                        marginTop: '40px',
                        fontFamily: 'Roboto',
                    }}>

                        <div style={{ backgroundColor: '#FCC600', marginTop: '10px', marginRight: '18px', marginLeft: '18px', padding: '16px', borderRadius: '20px' }}>
                            <h6 style={{ float: 'right' }}>{date}</h6>
                            <div>
                                <h5>{t('buildings.buildingName')}{': '} {buildingName} </h5>
                                <h6>{t('buildings.diaryName')}{': '} {diaryName} </h6>
                            </div>

                        </div>
                        <div className='obal'>
                            <div><h4 style={{
                                backgroundColor: '#DBDBDB',
                                borderRadius: '20px 20px 0px 0px',
                                margin: '-25px',
                                padding: '16px'
                            }}>{t('diary.weatherL')}</h4></div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>{t('diary.weather')}</th>
                                        <th>{t('diary.temperature')}</th>
                                        <th>{t('diary.humidity')}</th>
                                        <th>{t('diary.precipitation')}</th>
                                        <th>{t('diary.wind')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {weather && weather.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.description}</td>
                                                <td>{item.temperature}</td>
                                                <td>{item.humidity}</td>
                                                <td>{item.precipitation}</td>
                                                <td>{item.wind}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className='obal'>
                            <div><h4 style={{
                                backgroundColor: '#DBDBDB',
                                borderRadius: '20px 20px 0px 0px',
                                margin: '-25px',
                                padding: '16px'
                            }}>{t('diary.workersL')}</h4></div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>{t('HumansName')}</th>
                                        <th>{t('diary.hoursHeadings')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {workers && workers.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{item.name}</td>
                                                <td>{item.workHours}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className='obal'>
                            <div>
                                <h4 style={{
                                    backgroundColor: '#DBDBDB',
                                    borderRadius: '20px 20px 0px 0px',
                                    margin: '-25px',
                                    padding: '16px'
                                }}>{t('diary.completedWorksL')}</h4>
                                <div style={{ marginTop: '28px' }} dangerouslySetInnerHTML={{ __html: completedWork! }}></div>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div className='obal' style={{ width: '50%' }}>
                                <div>
                                    <h4 style={{
                                        backgroundColor: '#DBDBDB',
                                        borderRadius: '20px 20px 0px 0px',
                                        margin: '-25px',
                                        padding: '16px'
                                    }}>{t('diary.machinesL')}</h4>
                                    <div style={{ marginTop: '28px' }} >
                                        {machinesTable && machinesTable.length > 0 && <table>
                                            <thead>
                                                <tr>
                                                    <th>{t('code')}</th>
                                                    <th>{t('Name')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {machinesTable && machinesTable.map(item => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{item.code} </td>
                                                            <td>{item.name}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>}
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: machines! }}></div>
                                </div>
                            </div>
                            <div className='obal' style={{ width: '50%' }}>
                                <div>
                                    <h4 style={{
                                        backgroundColor: '#DBDBDB',
                                        borderRadius: '20px 20px 0px 0px',
                                        margin: '-25px',
                                        padding: '16px'
                                    }}>{t('diary.materialsL')}</h4>
                                    <div style={{ marginTop: '28px' }} >
                                        {materialsTable && materialsTable.length > 0 && <table>
                                            <thead>
                                                <tr>
                                                    <th>{t('Name')}</th>
                                                    <th>{t('Amount')}</th>
                                                    <th>{t('Unit')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {materialsTable && materialsTable.map(item => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{item.name}</td>
                                                            <td>{item.amount}</td>
                                                            <td>{item.unit}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>}
                                    </div>
                                    <div style={{ fontFamily: 'montserrat' }} dangerouslySetInnerHTML={{ __html: materials! }}></div>
                                </div>

                            </div>
                        </div>
                        {additionalNotes && additionalNotes !== '' && <div className='obal'>
                            <div>
                                <h4 style={{
                                    backgroundColor: '#DBDBDB',
                                    borderRadius: '20px 20px 0px 0px',
                                    margin: '-25px',
                                    padding: '16px'
                                }}>{t('diary.anotherNotesL')}</h4>
                                <div style={{ marginTop: '28px' }} dangerouslySetInnerHTML={{ __html: additionalNotes! }}></div>
                            </div>
                        </div>}
                        <div>
                            <div style={{ pageBreakInside: 'avoid' }}>
                                <h3 style={{
                                    marginLeft: '20px', marginTop: '20px', marginRight: '16px',
                                    backgroundColor: '#DBDBDB', padding: '16px', borderRadius: '20px', pageBreakInside: 'avoid'
                                }}>{t('diary.photosL')}</h3>
                                <div style={{ marginLeft: '28px', marginRight: '28px' }}>
                                    {images !== undefined && images.map((image, i) => {
                                        if (i % 3 === 0) {

                                            return (<div style={{ display: 'flex', justifyContent: 'start' }}>
                                                <img src={image.base64} className='img-noover' style={{ marginLeft: '40px', marginTop: '20px' }} ></img>
                                                {images.length > (i + 1) && <img src={images[i + 1].base64} style={{ marginLeft: '60px', marginTop: '20px' }} className='img-noover' ></img>}
                                                {images.length > (i + 2) && <img src={images[i + 2].base64} style={{ marginLeft: '60px', marginTop: '20px' }} className='img-noover' ></img>}
                                                {/* <img src={image.base64} className='img-noover'></img> */}
                                            </div>)
                                        }
                                    })}

                                </div>

                            </div>
                        </div>

                        <div>
                            <div className='obal'>
                                <div><h4 style={{
                                    backgroundColor: '#DBDBDB',
                                    borderRadius: '20px 20px 0px 0px',
                                    margin: '-25px',
                                    padding: '16px'
                                }}>{t('diary.investorChatL')}</h4></div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '35px', justifyContent: 'start', alignItems: 'start' }}>
                                    {investorsChatMessages && investorsChatMessages.map(message => {
                                        return (
                                            <div key={message.id}>
                                                <div style={{ fontSize: '12px', fontWeight: 'bold', border: '1px solid', borderColor: '#DBDBDB', borderRadius: '5px', padding: '2px 10px' }}>{message.userName} {message.isInvestor ? ' (Investor)' : ' (Uživatel ERP)'} {message.datetime!.toLocaleDateString(i18n.language)} {message.datetime!.toLocaleTimeString(i18n.language)}</div>
                                                <div style={{ marginLeft: '8px', whiteSpace: 'pre-wrap' }}>{message.text}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        {/* {images !== undefined && images.length % 2 === 1 && <div className='two' style={{ width: '300px', height: '300px' }}></div>} */}
                        {(signDigitally === undefined || signDigitally === false) && <div style={{ float: 'left', width: '100%', marginTop: '20px' }}>
                            <h6 style={{
                                pageBreakInside: 'avoid'
                            }}>{t('diary.signaturesL')}</h6>
                            <div style={{ width: '50%' }}>
                                {signatures !== undefined && signatures.map((sign, i) => {
                                    // if (i % 2 === 0)
                                    return (<div ><div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                        <div style={{ display: 'inline-block', borderRadius: '6px', background: sign.isInvestor ? theme.palette.success.main : theme.palette.primary.main }}>
                                            <div style={{ display: 'flex', fontWeight: 'bold' }}>
                                                <div style={{ marginLeft: '10px' }}>{sign.userName}</div>
                                                <div style={{ marginLeft: '4px' }}>{sign.dateTime!.toLocaleDateString(i18n.language)}</div>
                                                <div style={{ marginLeft: '4px' }}>{sign.dateTime!.toLocaleTimeString(i18n.language)}</div>
                                            </div>
                                        </div>
                                        <img src={sign.base64} ></img>
                                    </div></div>)
                                })}</div>
                        </div>}
                    </div >
                </div>
            </div >);
}