import { AttachFile, Clear, Cloud, Construction, Engineering, Grading, Lock, Photo } from '@mui/icons-material';
import { Button, Link, Typography, useTheme } from '@mui/material';
import { ColumnsDirective, GridComponent, Group, RecordDoubleClickEventArgs, Sort, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, ColumnDirective, Inject, FilterSettingsModel, Page, ExcelQueryCellInfoEventArgs, Column } from '@syncfusion/ej2-react-grids';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import { GetDailyRecordsForUserDTO } from '../../../ApiOld/ApiServerVov';
import { useDiaryStore } from '../../../Contexts/DiaryZContext';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import CreateDiaryRecord from './Widgets/CreateDiaryRecord';
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';
import { useLoginAdminStore } from '../../../Contexts/LoginAdminZContext';
import { useLoginSubcontractorStore } from '../../../Contexts/LoginSubcontractorZContext';
InitSfGridLocale();
interface DiaryRecordsProps {
    fromExternal?: boolean;
    isSubdodavatel?: boolean;
}

export default function DiaryRecords(props: DiaryRecordsProps) {

    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };
    const navigate = useNavigate();
    const [newOpen, setNewOpen] = useState(false);
    const [trashOpen, setTrashOpen] = useState(false);
    const [records, setRecords] = useState<GetDailyRecordsForUserDTO[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const { permissions } = useDiaryStore();
    const theme = useTheme();
    const params = useParams();
    const { userName, serverLoad } = useLoginAdminStore();
    const { subcontractorName, serverLoadSub } = useLoginSubcontractorStore();
    useEffect(() => {

        if (props.isSubdodavatel) {
            if (subcontractorName === undefined) {
                serverLoadSub();
            }
        }
        else {
            if (userName === undefined) {
                serverLoad();
            }
        }

    }, [userName, subcontractorName, props.isSubdodavatel]);

    function GridTemplate(props: GetDailyRecordsForUserDTO) {
        const [hover, setHover] = useState(false);
        if (permissions && permissions.canViewDiaryRecordsDetail)
            return (<div style={{ cursor: 'pointer' }} onClick={() => {
                navigateToDetail(props);
            }} onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <a style={{
                    // color: '#008BF8', textDecoration: hover ? 'underline' : 'none',
                    color: '#5762D5', textDecoration: 'underline',
                    // color: theme.palette.success.main, textDecoration: hover ? 'underline' : 'none',
                    fontWeight: 'bold',

                }}

                >{props.date!.toLocaleDateString(i18n.language)}</a>
            </div >);
        else return <div>{props.date!.toLocaleDateString(i18n.language)}</div>
        // return (<div>
        //     <a style={{ color: theme.palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { navigateToDetail(props) }}>{props.date!.toLocaleDateString(/* i18n.language */)}</a>

        // </div >);
    }
    // function gridTemplate(props: GetDailyRecordsForUserDTO) {
    //     if (permissions && permissions.canViewDiaryRecordsDetail)
    //         return (<div>
    //             <a style={{ color: theme.palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { navigateToDetail(props) }}>{props.date!.toLocaleDateString(i18n.language)}</a>

    //         </div >);
    //     else return <div>{props.date!.toLocaleDateString(i18n.language)}</div>
    // }
    function headerTemplatePhotos(props: GetDailyRecordsForUserDTO) {
        return (<Photo />);

    }
    function cellTemplatePhotos(props: GetDailyRecordsForUserDTO) {
        if (props.hasPhotos !== undefined && props.hasPhotos === true)
            return (<Photo color='action' />);
        else return (<div></div>);

    }
    function headerTemplateLock(props: GetDailyRecordsForUserDTO) {
        return (<Lock />);

    }
    function cellTemplateLock(props: GetDailyRecordsForUserDTO) {
        if (props.isLocked !== undefined && props.isLocked === true)
            return (<Lock color='action' />);
        else return (<div></div>);

    }
    function headerTemplateWorkers(props: GetDailyRecordsForUserDTO) {
        return (<Engineering />);

    }
    function cellTemplateWorkers(props: GetDailyRecordsForUserDTO) {
        if (props.hasWorkerRecords !== undefined && props.hasWorkerRecords === true)
            return (<Engineering color='action' />);
        else return (<div></div>);

    }
    function headerTemplateWorks(props: GetDailyRecordsForUserDTO) {
        return (<Construction />);

    }
    function cellTemplateWorks(props: GetDailyRecordsForUserDTO) {
        if (props.hasCompletedWorksRecords !== undefined && props.hasCompletedWorksRecords === true)
            return (<Construction color='action' />);
        else return (<div></div>);

    }
    function headerTemplateWeathers(props: GetDailyRecordsForUserDTO) {
        return (<Cloud />);
    }
    function headerTemplateAttachment(props: GetDailyRecordsForUserDTO) {
        return (<AttachFile />);
    }
    function cellTemplateWeathers(props: GetDailyRecordsForUserDTO) {
        if (props.hasWeatherRecords !== undefined && props.hasWeatherRecords === true)
            return (<Cloud color='action' />);
        else return (<div></div>);
    }
    function cellTemplateAttachment(props: GetDailyRecordsForUserDTO) {
        if (props.hasAttachment !== undefined && props.hasAttachment === true)
            return (<AttachFile color='action' />);
        else return (<div></div>);
    }
    function gridTemplateSignatures(props: GetDailyRecordsForUserDTO) {
        if (props.signatures)
            return <div > {props.signatures!.map((item) => {
                return <div key={item.id?.toString()} style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 0.5 }}>
                    <div style={{
                        backgroundColor: item.isInvestor !== undefined && item.isInvestor === true ? theme.palette.success.main : ((item.isSubcontractor === undefined || item.isSubcontractor === false) ? theme.palette.primary.main : '#F5F5F5'),
                        zIndex: '200', border: '1px solid', borderRadius: '4px', padding: '3px 1px 3px 1px', margin: '0px 4px'
                    }} >
                        <Typography style={{ fontSize: '10px', fontWeight: 'bold', margin: '0px 6px' }}>{item.name}</Typography>
                    </div>
                </div>
            })}</div>;
        else
            return (<div>

            </div>);
    }
    function navigateToDetail(data: GetDailyRecordsForUserDTO) {
        if (props.isSubdodavatel) {
            navigate(
                { pathname: '/' + i18n.language + '/' + params['domain']! + '/subcontractor/diaries/' + data.id!.toString(), },
                { state: { id: data.id, inputRecords: records, diaryId: data.diaryId, diaryName: data.diaryName, buildingName: data.buildingName } }
            );
        }
        else {
            if (props.fromExternal === true) {
                navigate(
                    { pathname: '/' + i18n.language + '/' + params['domain']! + '/diaryolderpnew/' + data.id!.toString(), },
                    { state: { id: data.id, inputRecords: records, diaryId: data.diaryId, diaryName: data.diaryName, buildingName: data.buildingName } }
                );
            }
            else {
                navigate(
                    { pathname: '/' + i18n.language + '/' + params['domain']! + '/diaryvov/' + data.id!.toString(), },
                    { state: { id: data.id, inputRecords: records, diaryId: data.diaryId, diaryName: data.diaryName, buildingName: data.buildingName } }
                );
            }
        }
    }
    const grid = useRef<GridComponent>(null);
    useEffect(() => {
        if (records === null)
            refresh();
    }, []);
    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }
    const excelQueryCellInfo = (args: ExcelQueryCellInfoEventArgs): void => {
        if ((args.column as Column).field === 'hasPhotos') {
            console.log(args.data);
            if ((args.data as GetDailyRecordsForUserDTO).hasPhotos) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'isLocked') {
            if ((args.data as GetDailyRecordsForUserDTO).isLocked) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'hasCompletedWorksRecords') {
            if ((args.data as GetDailyRecordsForUserDTO).hasCompletedWorksRecords) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'hasWeatherRecords') {
            if ((args.data as GetDailyRecordsForUserDTO).hasWeatherRecords) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'hasWorkerRecords') {
            if ((args.data as GetDailyRecordsForUserDTO).hasWorkerRecords) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'hasClientSigned') {
            if ((args.data as GetDailyRecordsForUserDTO).hasClientSigned) {
                args.value = t('yesL');
            }
            else {
                args.value = t('noL');
            }
        }
        else if ((args.column as Column).field === 'signatures') {
            var striny = (args.data as GetDailyRecordsForUserDTO).signatures!.map((item) => {
                return item.name;
            });
            args.value = striny.join(', ');
        }
    }
    function refresh() {
        setRecords(null);
        setLoading(true);
        new ApiDiaryBaseHelper(props.isSubdodavatel == undefined ? false : props.isSubdodavatel, false).GetDiaryRecords(false).then((r) => {
            setRecords(r);
            setLoading(false);
        });
    }

    if (records !== null)
        return <div style={{ display: "flex", flexDirection: 'column' }}>
            <UpperMenu moduleName={t('diary.dailyRecord')}
                gridType={GridType.Users}
                gridId='grid-component-daily-records'
                canTrash
                canAdd={permissions ? permissions.canCreateDiaryRecord : false}
                canDelete={permissions ? permissions.canDeleteDiaryRecord : false}
                icon={<Grading />}
                onCreate={(e) => { setNewOpen(true) }}
                onDelete={(e) => { new ApiDiaryBaseHelper(props.isSubdodavatel == undefined ? false : props.isSubdodavatel, false).DeleteDiaryRecords(e as number[]).then((e) => { if (e) refresh(); }) }}
                canChangeEyeView={false}
                onThrashClicked={() => { setTrashOpen(true); }}
                grid={grid} canChangeGroup canToolbarGrid
                menuName={t('View')}
                isInvestor={false}
                isSubdodavatel={props.isSubdodavatel}
                fromExternal={props.fromExternal}
                canDiarySearch={true}
            />
            <div>
                <GridComponent ref={grid} id="grid-component-daily-records" allowSorting={true}
                    allowReordering={true} allowResizing={true} showColumnChooser={true}
                    allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                    locale={i18n.language} enablePersistence={true}
                    excelQueryCellInfo={excelQueryCellInfo}
                    recordDoubleClick={(e: RecordDoubleClickEventArgs) => {
                        navigateToDetail(e.rowData as GetDailyRecordsForUserDTO)
                    }}
                    filterSettings={filterOptions}
                    allowPaging={true}
                    style={{ backgroundColor: '#F5F5F5' }}
                    pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                    toolbarClick={toolbarClickExcel}
                    //  columnDataStateChange={(e) => { console.log(e) }}
                    selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                    allowFiltering={true} groupSettings={groupOptions}
                    dataSource={records}>
                    <ColumnsDirective>
                        <ColumnDirective type='checkbox' width='40' />
                        <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" visible={false} />
                        <ColumnDirective field='date' type='date' format={i18n.language === 'en' ? 'MM/dd/yyyy' : 'dd.MM.yyyy'} template={GridTemplate} headerText={t('Date')} width='60' minWidth='60' />
                        <ColumnDirective field='buildingName' headerText={t('Projekt')} width='100' />
                        <ColumnDirective field='diaryName' headerText={t('diary.diary')} width='100' />
                        <ColumnDirective field='mainDiaryName' headerText={t('diary.mainDiary')} width='100' />
                        <ColumnDirective field='isLocked' headerTemplate={headerTemplateLock} template={cellTemplateLock} headerText={t('diary.locked')} width='46' />
                        <ColumnDirective field='hasClientSigned' visible={false} displayAsCheckBox headerText={t('diary.signByInvestor')} width='80' />
                        <ColumnDirective field='signatures' template={gridTemplateSignatures} headerText={t('diary.signatures')} width='120' />
                        <ColumnDirective field='hasPhotos' type='boolean' headerTemplate={headerTemplatePhotos} template={cellTemplatePhotos} headerText={t('dashboard.photos')} width='46' />
                        <ColumnDirective field='hasWorkerRecords' type='boolean' template={cellTemplateWorkers} headerTemplate={headerTemplateWorkers} headerText={t('diary.humans')} width='46' />
                        <ColumnDirective field='hasCompletedWorksRecords' type='boolean' template={cellTemplateWorks} headerTemplate={headerTemplateWorks} headerText={t('diary.works')} width='46' />
                        <ColumnDirective field='hasWeatherRecords' type='boolean' template={cellTemplateWeathers} headerTemplate={headerTemplateWeathers} headerText={t('dashboard.weather')} width='46' />
                        <ColumnDirective field='HasAttachment' type='boolean' template={cellTemplateAttachment} headerTemplate={headerTemplateAttachment} headerText={t('Attachments')} width='46' />

                    </ColumnsDirective>
                    <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Page]} />
                </GridComponent>
            </div>
            <div>
                <CreateDiaryRecord isSubdodavatel={props.isSubdodavatel} diaryRecords={records} open={newOpen} onClose={(e, newId) => {

                    setNewOpen(false);
                    // if (e) {
                    // if (newId === undefined) příprava na refreshe, které jsou víc "lightweight"
                    refresh();
                    // else
                    // }
                }} />
                <TrashGridDialog isSubdodavatel={props.isSubdodavatel} trashType={TrashType.DailyRecords} open={trashOpen} onClose={(e) => { console.log(e); setTrashOpen(false); if (e) refresh(); }} />

            </div></div>

    return <div>{loading ? t('loading...') : t('noRecords')}</div>;
}