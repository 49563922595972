import { L10n, loadCldr } from '@syncfusion/ej2-base';
import n3 from '../../node_modules/cldr-numbers-full/main/cs/numbers.json';
import n3sk from '../../node_modules/cldr-numbers-full/main/sk/numbers.json';
import n3pl from '../../node_modules/cldr-numbers-full/main/pl/numbers.json';
export default function InitSfGridLocale() {
    L10n.load({
        'cs': {
            'grid': {
                'EmptyDataSourceError': 'Datový zdroj je prázdný. Chyba!',
                'EmptyRecord': 'Prázdný záznam',
                'GroupDropArea': 'Přetáhněte sem hlavičku sloupce pro seskupení',
                'Item': 'Položka',
                'Items': 'Položky',
                'UnGroup': 'klikněte sem pro odebrání seskupení',
                'Search': 'Hledání...',
                'Columnchooser': 'Sloupce',
                'Add': 'Přidat',
                'Edit': 'Změnit',
                'Update': 'Uložit',
                'Delete': 'Smazat',
                'Cancel': 'Zrušit',
                "SortAtoZ": "Seřadit od A do Z",
                "SortZtoA": "Seřadit od Z do A",
                "SortByNewest": "Seřadit od nejnovějších",
                "SortByOldest": "Seřadit od nejstarších",
                "SortLargestToSmallest": "Od největší po nejmenší",
                "SortSmallestToLargest": "Od nejmenší po největší",
                "FilterTrue": "Ano",
                "FilterFalse": "Ne",
                "SelectAll": "Vybrat vše",
                "ClearFilter": "Smazat filtry",
                "TextFilter": "Textové filtry",
                "CancelButton": "Zrušit",
                "ChooseColumns": "Vyberte sloupce"

            },
            'pager': {
                'pagerDropDown': 'Položek na stránku',
                'currentPageInfo': '{0} z {1} stran',
                'totalItemsInfo': '({0} položek)',
                'totalItemInfo': '({0} položka)',
                'lastPageTooltip': 'Na poslední stránku'
            }
        },
        'pl': {
            'grid': {
                'EmptyDataSourceError': 'Źródło danych jest puste. Błąd!',
                'EmptyRecord': 'Pusty rekord',
                'GroupDropArea': 'Przeciągnij nagłówek kolumny tutaj, aby pogrupować',
                'Item': 'Przedmiot',
                'Items': 'Przedmiotów',
                'UnGroup': 'Kliknij tutaj, aby usunąć grupy',
                'Search': 'Szukaj...',
                'Columnchooser': 'Kolumny',
                'Add': 'Dodać',
                'Edit': 'Zmieniać',
                'Update': 'Narzucać',
                'Delete': 'Usuwać',
                'Cancel': 'Anulować',
                "SortAtoZ": "Sortuj od A do Z",
                "SortZtoA": "Sortuj od Z do A",
                "SortByNewest": "Sortuj według najnowszych",
                "SortByOldest": "Sortuj od najstarszych",
                "SortLargestToSmallest": "Od największego do najmniejszego",
                "SortSmallestToLargest": "Od najmniejszego do największego",
                "FilterTrue": "Tak",
                "FilterFalse": "Nie",
                "SelectAll": "Zaznacz wszystko",
                "ClearFilter": "Usuń filtry",
                "TextFilter": "Filtry tekstowe",
                "ChooseColumns": "Wybierz kolumny"
            },

            'pager': {
                'pagerDropDown': 'Pozycje na stronie',
                'currentPageInfo': '{0} z {1} bok',
                'totalItemsInfo': '({0} rzeczy)',
                'totalItemInfo': '({0} przedmiot)',
                'lastPageTooltip': 'Na ostatnią stronę'
            }
        },
        'sk': {
            'grid': {
                'EmptyDataSourceError': 'Dátový zdroj je prázdny. Chyba!',
                'EmptyRecord': 'Prázdny záznam',
                'GroupDropArea': 'Pretiahnite sem hlavičku stĺpca pre zoskupenie',
                'Item': 'Položka',
                'Items': 'Položky',
                'UnGroup': 'kliknite sem pre odstránenie zoskupenia',
                'Search': 'Hľadanie...',
                'Columnchooser': 'Stĺpce',
                'Add': 'Pridať',
                'Edit': 'Zmeniť',
                'Update': 'Uložiť',
                'Delete': 'Zmazať',
                'Cancel': 'Zrušiť',
                "SortAtoZ": "Zoradiť od A do Z",
                "SortZtoA": "Zoradiť od Z do A",
                "SortByNewest": "Zoradiť od najnovších",
                "SortByOldest": "Zoradiť od najstarších",
                "SortLargestToSmallest": "Od najväčšej po najmenšiu",
                "SortSmallestToLargest": "Od najmenšej po najväčšiu",
                "FilterTrue": "Áno",
                "FilterFalse": "Nie",
                "SelectAll": "Vybrať všetko",
                "ClearFilter": "Zmazať filtre",
                "TextFilter": "Textové filtre",
                "CancelButton": "Zrušiť",
                "ChooseColumns": "Vyberte stĺpce"

            },
            'pager': {
                'pagerDropDown': 'Položiek na stránku',
                'currentPageInfo': '{0} z {1} strán',
                'totalItemsInfo': '({0} položiek)',
                'totalItemInfo': '({0} položka)',
                'lastPageTooltip': 'Na poslednú stránku'
            }
        }
    });
    loadCldr(n3);
    loadCldr(n3sk);
    loadCldr(n3pl);
}
