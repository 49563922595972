
import { AccountCircle, AddCircleOutline, Check, CheckBox, NotInterested, Psychology, Reply, Save } from '@mui/icons-material';
import { Autocomplete, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, IconButton, Popover, TextareaAutosize, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SplitButton from '../../../Shared/SplitButton';
import stavarioPic from '../../../../Assets/login-logo-stavario.svg'; // with import
import stavarioPicSmall from '../../../../Assets/logo512.png'; // with import
import SplitButtonNoPush from '../../../Shared/SplitButtonNoPush';
import { AdminUsersDTO, GetKrosAdminUserInfoResponse } from '../../../../ApiOld/ApiServerVov';
import { APIVovKrosAdminHelper } from '../../../../ApiOld/ApiKrosAdminOldClient';
import { id } from 'date-fns/locale';
import LanguagePicker from '../../../Shared/LanguagePicker';
import { useMediaPredicate } from 'react-media-hook';
import { loadKrosAdminStore, useKrosAdminStore } from '../../../../Contexts/KrosAdminZContext';


interface KrosAdminUpperMenuProps {
}

export default function KrosAdminUpperMenu(props: KrosAdminUpperMenuProps) {
    const { t, i18n } = useTranslation();
    const [saving, setSaving] = useState<boolean>(false);
    const { credentials, load } = useKrosAdminStore();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = React.useState(false);
    const id = open ? 'simple-popover' : undefined;
    const openUserMenu = Boolean(anchorEl);
    const [creditsOpen, setCreditsOpen] = React.useState(false);
    const [passOpen, setPassOpen] = React.useState(false);
    /*  const [messageData, setMessageData] = useState<{ title: string, text: string }>(); */

    const theme = useTheme();
    const navigate = useNavigate();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    const params = useParams();
    useEffect(() => {
        if (credentials === undefined || credentials.user === undefined)
            loadKrosAdminStore().then((d: GetKrosAdminUserInfoResponse) => {
                load(d);
            })

        /* if (params['id']) {
            if (!Number.isNaN(parseInt(params['id']))) {
                new APIVovKrosAdminHelper().GetUserData(parseInt(params['id'])).then(e => {
                    setUserData(e);
                });
            }
        }
        else setUserData(undefined); */
    }, []);

    useEffect(() => {
    }, [credentials]);

    const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    function logout(): void {
        localStorage.removeItem('krosadmin_token_old');
        navigate({
            pathname: '/kros',
            // search: '?sort=date&order=newest',
        });
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<div>
        <div style={{ display: 'flex', position: 'fixed', top: 0, width: '100%', backgroundColor: 'white', height: '50px', zIndex: 5000 }}>
            <Grid container spacing={6}>
                <Grid item xs={8}>
                    <div style={{ float: 'left', margin: '5px', marginTop: '10px', marginLeft: '10px' }}>
                        <img src={biggerThan700 ? stavarioPic : stavarioPicSmall}
                            alt="stavario" width={biggerThan700 ? "128px" : "35px"}
                            height={biggerThan700 ? "26px" : "35px"} ></img>
                    </div>
                    <div style={{ fontWeight: 'bold', float: 'left', margin: '5px', marginTop: '14px', marginLeft: '12px' }}>KROS ADMIN SEKCE</div>
                </Grid>
                <Grid item xs={4}>
                    <div style={{ float: 'right', margin: '5px', marginTop: '10px', marginRight: '10px', display: 'flex' }}>
                        <div style={{ marginRight: '16px', marginLeft: '10px', marginTop: '4px' }}>
                            <LanguagePicker showText={biggerThan700} onLanguageChange={(e) => {
                                navigate('/' + e + '/kros/admin/users');
                            }}></LanguagePicker>
                        </div>
                        <div style={{ textAlign: 'end', marginTop: '6px', minWidth: '120px' }}>
                            <Typography variant={biggerThan700 ? "subtitle2" : "caption"} sx={{ margin: '-4px' }} noWrap component="div">
                                {credentials === undefined || credentials.user === undefined ? '' : credentials.user.name}
                            </Typography>
                            <Typography variant="caption" fontWeight={'400'} color={theme.palette.primary.main}
                                noWrap component="div" sx={{ margin: '-4px' }}>
                                {credentials === undefined || credentials.user === undefined ? '' : credentials.user.application}
                            </Typography>

                        </div>

                        <div style={{

                            width: "40px",
                            height: "40px",
                            marginTop: '-2px'
                        }}>
                            <Button onClick={handleUserMenuClick} style={{
                                width: "40px",
                                height: "40px"
                            }} aria-describedby={id}>
                                <div className="rounded-image">
                                    <AccountCircle sx={{
                                        width: "40px",
                                        height: "40px"
                                    }} />
                                </div>
                            </Button>
                            <Popover
                                id={id}
                                sx={{ zIndex: 50002 }}
                                open={openUserMenu}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                <div className="popup-content-div" >
                                    {/* <div>
                                        <Button color="secondary" sx={{ width: "120px", paddingRight: "12px", justifyContent: "flex-start" }} onClick={() => { setCreditsOpen(true); }}>
                                            {t('myProfile')}
                                        </Button>
                                    </div>
                                    <div>
                                        <Button color="secondary" sx={{ width: "120px", paddingRight: "12px", justifyContent: "flex-start" }} onClick={() => { setPassOpen(true); }}>
                                            {t('login.changeOfPassword')}
                                        </Button>
                                    </div>
                                    <Divider sx={{ bgcolor: "primary.main" }} /> */}

                                    <Button color="secondary" onClick={logout} sx={{ width: "120px", paddingRight: "12px", justifyContent: "flex-start" }} >
                                        {t('login.logout')}
                                    </Button>

                                </div>
                            </Popover>
                        </div>



                    </div>
                </Grid>
            </Grid>


        </div>

    </div>
    );
}