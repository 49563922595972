import React, { DependencyList, SyntheticEvent, useCallback, useContext, useEffect, useState } from "react"
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { useRef } from 'react';
import { APIUsersHelper } from "../../Api/ApiUsersHelper";
import { ApiException } from "../../Api/UsersMicroserviceClient";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, AlertTitle, Button, createTheme, Slide, Snackbar, TextField, ThemeProvider } from "@mui/material";
import "./LoginScene.css";
import Passwordbox from "../Shared/Passwordbox";
import { StavarioGlobalContext, StavarioUpdateContext } from "../../Contexts/LoginMainContext";
import Payment from "./Payment";
import { APITodoHelper } from "../../Api/ApiTodoHelper";
import NewApplication from "./NewApplication";
import SignUp from "./SignUp";
import LanguagePicker from "../Shared/LanguagePicker";
import delay from "../Shared/Functions/Delay";


//mohlo by být jako partial state object, ale kvůli dvoum props se mi to dělat nechce
interface LoginState {
    userName: string;
    password: string;
};
export default function (props: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    let [authMode, setAuthMode] = useState("signin");
    let [userName, setUserName] = useState("");
    let [password, setPassword] = useState("");
    const [openLoginError, setOpenLoginError] = React.useState(false);
    const changeAuthMode = () => {
        setAuthMode(authMode === "signin" ? "signup" : "signin")
    }

    const globalContext = React.useContext(StavarioGlobalContext);
    const setGlobal = React.useContext(StavarioUpdateContext);
    const CallApiWithLogin = async (e: any) => {
        try {

            console.log('start auth');
            if (e)
                e.preventDefault();
            var authResponse = await (new APIUsersHelper().CallApiLogin(userName, password));
            globalContext.name = authResponse.username;
            globalContext.initials = authResponse.initials;
            globalContext.companyName = authResponse.companyName;
            globalContext.isAdmin = authResponse.isAdmin;
            globalContext.users = [];
            globalContext.projects = [];
            setGlobal!(globalContext!);
            navigate({
                pathname: '/projects/todos',
            });

        }
        catch (ex) {
            if (ex instanceof ApiException) {
                setOpenLoginError(true);
            }
            else {

            }
        }
    }
    const CallApiToSignUp = (e: React.SyntheticEvent) => {
    };
    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }
    const onTextChange = (event: any) => {
        setUserName(event.currentTarget.value);
    };
    const onPassChange = (event: string) => {
        setPassword(event);
    };
    const handleClose = (event: any) => {
        setOpenLoginError(false);
    };
    if (authMode === "signin") {
        return (
            // <ThemeProvider theme={theme}>
            <div>
                <div className="Auth-form-container">
                    <div className="Auth-form">
                        <div className="logo-up">
                            <img
                                width="200px"
                                height="54px"
                                src="https://stavariokluc.vimovsem.cz/images/login-logo-stavario.svg"
                                alt="stavario"
                            ></img>
                        </div>
                        <div className="Auth-form-content">
                            <h3 className="Auth-form-title" >{t('login.signIn')}</h3>
                            <div className="text-center">
                                {t('login.notRegistredYet')}{" "}
                                <span style={{ color: '#FFC600' }} onClick={changeAuthMode}>
                                    {t('login.signUp')}
                                </span>
                            </div>
                            <div className="form-group mt-3">

                                <div>
                                    <TextField
                                        id="outlined-basic"
                                        label={t('login.enterEmail')}
                                        className="text-field"
                                        value={userName}
                                        onChange={onTextChange}
                                        variant="outlined" />
                                </div>

                            </div>
                            <div className="form-group mt-3">

                                <Passwordbox onEnter={() => { console.log('enter'); CallApiWithLogin(undefined); }} password={password}
                                    onPasswordChange={onPassChange}></Passwordbox>
                            </div>
                            <div className="d-grid gap-2 mt-3">
                                <Button onClick={(e) => { CallApiWithLogin(e) }} variant="contained" type="submit">
                                    {t('login.sign')}
                                </Button>
                            </div>
                            <div style={{ display: 'block', textAlign: 'center', width: '100%' }}>
                                <p style={{ marginTop: '26px' }}>
                                    {t('login.forgot')} <a onClick={(e) => {
                                        navigate(
                                            { pathname: '/' + i18n.language + '/' + params['domain']! + '/resetpassword', },
                                            { state: { email: userName } }
                                        );
                                    }} style={{ color: '#ffc107', textAlign: 'center', cursor: 'pointer' }}>{t('login.password')}</a>

                                </p>
                                <div style={{ marginTop: '-10px' }}>
                                    <LanguagePicker showText={false} />
                                </div>
                            </div>
                            {/* <p className="text-center mt-2" >
                                {t('login.forgot')} <a href="#" style={{ color: '#ffc107' }}>{t('login.password')}</a><LanguagePicker showText={false} />
                            </p> */}
                        </div>
                    </div>

                </div>
                <div className="Auth-form-popup">
                    <Snackbar
                        TransitionComponent={TransitionLeft}
                        open={openLoginError}
                        autoHideDuration={5000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                        <Alert onClose={handleClose} severity="warning">
                            <AlertTitle>{t('login.loginFailed')}</AlertTitle>
                            {t('login.loginFailedDetail')}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
            // </ThemeProvider>
        )
    }

    return (
        <SignUp changeAuthMode={changeAuthMode}></SignUp>
    )
}