import { Box, Button, CssBaseline, Divider, Grid, IconButton, Popover, Typography } from '@mui/material';
import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigation, Route, Routes, useNavigate, useParams } from 'react-router';
import { AccountCircle, BookOutlined, CameraAltOutlined, DocumentScanner, MenuOutlined, NewspaperOutlined, NotificationAdd, Psychology, Search } from '@mui/icons-material';
import { useMediaPredicate } from 'react-media-hook';
import stavarioPic from '../../Assets/login-logo-stavario.svg'; // with import
import stavarioPicSmall from '../../Assets/logo512.png'; // with import
import LanguagePicker from '../Shared/LanguagePicker';
import createThemeStavario from '../Shared/Themes/CreateTheme';
import GptChatScene from '../Scenes/GptChat/GptChatScene';
import { loadSubcontractorStore, useSubcontractorStore } from '../../Contexts/SubcontractorZContext';
import { GetSubcontractorInfoResponse } from '../../ApiOld/ApiServerVov';
import SubcontractorPasswordChangeScene from './SubcontractorPasswordChangeScene';


interface SubcontratorMainSceneProps {
    children: ReactNode
}

export default function SubcontratorMainScene(props: SubcontratorMainSceneProps) {

    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const navigate = useNavigate();
    //const [selectedTab, setSelectedTab] = React.useState(0);
    const [value, setValue] = React.useState(0);
    const { t, i18n } = useTranslation();
    const theme = createThemeStavario();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const openUserMenu = Boolean(anchorEl);
    const [open, setOpen] = React.useState(false);
    const id = open ? 'simple-popover' : undefined;
    const params = useParams();
    const [creditsOpen, setCreditsOpen] = React.useState(false);
    const [diaryRecordId, setDiaryRecordId] = React.useState<number>();

    const [passOpen, setPassOpen] = React.useState(false);
    const { credentials, load } = useSubcontractorStore();

    const drawerWidth = 240;

    useEffect(() => {
    });

    useEffect(() => {
        if (credentials === undefined)
            loadSubcontractorStore().then((d: GetSubcontractorInfoResponse) => {
                load(d);
            })
    }, []);

    function logout(): void {
        localStorage.removeItem('subcontractor_token_old');
        navigate({
            pathname: '/' + params['lang'] + '/' + params['domain'] + '/subcontractor',
            // search: '?sort=date&order=newest',
        });
    }


    const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    function passOpenClosed() { setPassOpen(false); }

    return (
        <div >
            <div style={{ display: 'flex', position: 'fixed', top: 0, width: '100%', backgroundColor: '#FFFFFF', height: '50px', zIndex: 5000, boxShadow: 'rgb(0 0 0 / 40%) 1px 1px 10px' }}>
                <Grid container spacing={6}>
                    <Grid item xs={8}>
                        <div style={{ float: 'left', margin: '5px', marginTop: '10px', marginLeft: '10px' }}>
                            <img src={biggerThan700 ? stavarioPic : stavarioPicSmall}
                                alt="stavario" width={biggerThan700 ? "128px" : "35px"}
                                height={biggerThan700 ? "26px" : "35px"} ></img>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{ float: 'right', margin: '5px', marginTop: '10px', marginRight: '10px', display: 'flex' }}>
                            {/* <div style={{ marginRight: '16px', marginLeft: '10px', marginTop: '4px' }}>
                                <IconButton sx={{ width: '40px', height: '40px', marginTop: '-10px', marginBottom: '10px' }} onClick={() => { navigate('/' + i18n.language + '/' + params['domain'] + '/investor/ai'); }}>
                                    <Psychology sx={{ width: '36px', height: '36px' }} />
                                </IconButton>
                            </div> */}
                            <div style={{ marginRight: '16px', marginLeft: '10px', marginTop: '4px' }}>
                                <LanguagePicker showText={biggerThan700} onLanguageChange={(e) => {
                                    navigate('/' + e + '/' + params['domain'] + '/subcontractor/diaries');
                                }}></LanguagePicker>
                            </div>
                            <div style={{ textAlign: 'end', marginTop: '6px' }}>
                                <Typography variant={biggerThan700 ? "subtitle2" : "caption"} sx={{ margin: '-4px' }} noWrap component="div">
                                    {credentials === undefined ? '' : credentials.userName}
                                </Typography>
                                <Typography variant="caption" fontWeight={'400'} color={theme.palette.primary.main}
                                    noWrap component="div" sx={{ margin: '-4px' }}>
                                    {credentials === undefined ? '' : credentials!.companyName}
                                </Typography>

                            </div>

                            <div style={{

                                width: "40px",
                                height: "40px",
                                marginTop: '-2px',
                                marginRight: '20px'
                            }}>
                                <Button onClick={handleUserMenuClick} style={{
                                    width: "40px",
                                    height: "40px"
                                }} aria-describedby={id}>
                                    <div className="rounded-image">
                                        {/* <Typography color='white'>{globalContext.name}</Typography> */}
                                        <AccountCircle sx={{
                                            width: "40px",
                                            height: "40px"
                                        }} />
                                    </div>
                                </Button>
                                <Popover
                                    id={id}
                                    sx={{ zIndex: 50002 }}
                                    open={openUserMenu}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                >
                                    <div className="popup-content-div" >
                                        {/* <div>
    <Button color="secondary" sx={{ width: "160px", paddingRight: "12px", justifyContent: "flex-start" }}>

        <NotificationAdd />
        <Typography sx={{ marginLeft: "10px", marginRight: "6px" }}>Notifikace</Typography>

    </Button>
</div> */}
                                        {/* <div>
                                            <Button color="secondary" sx={{ width: "120px", paddingRight: "12px", justifyContent: "flex-start" }} onClick={() => { setCreditsOpen(true); }}>
                                                {t('myProfile')}
                                            </Button>
                                        </div> */}
                                        <div>
                                            <Button color="secondary" sx={{ width: "120px", paddingRight: "12px", justifyContent: "flex-start" }} onClick={() => { setPassOpen(true); }}>
                                                {t('login.changeOfPassword')}
                                            </Button>
                                        </div>
                                        <Divider sx={{ bgcolor: "primary.main" }} />

                                        <Button color="secondary" onClick={logout} sx={{ width: "120px", paddingRight: "12px", justifyContent: "flex-start" }} >
                                            {t('login.logout')}
                                        </Button>

                                    </div>
                                </Popover>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div style={{ marginTop: '50px' }}>
                {props.children}
            </div>

            <SubcontractorPasswordChangeScene onRefresh={() => { }} open={passOpen} onClose={passOpenClosed} />
            {/* <DiaryTabs isSubdodavatel={true} /> */}
        </div >
    );
}

