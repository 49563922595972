import React, { useEffect } from "react";
import { Autocomplete, FormControl, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserDTO, UserGroupDTO } from "../../ApiOld/ApiServerVov";
import { SolveSubcontractorGroupContext, SolveUserGroupContext, StavarioOldGlobalContext, StavarioOldUpdateContext } from "../../Contexts/LoginOldContext";
import { useMediaPredicate } from "react-media-hook";
import { ApiDiaryBaseHelper } from "../../Api/ApiDiaryBaseHelper";

export interface UserPrintSelectProps {
    diaryId: number;
    selectedUserId?: number | null;
    onChange: (event: UserDTO | null) => void;
    isMandatory?: boolean;
    isSubdodavatel?: boolean;
    readOnly?: boolean;
    alertFontSize?: number;
}
export default function UserPrintSelect(props: UserPrintSelectProps) {
    const { onChange, selectedUserId, isMandatory, isSubdodavatel, readOnly, alertFontSize, diaryId } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [selectedUser, setSelectedUser] = React.useState<UserDTO | null>(null);
    const [users, setUsers] = React.useState<UserDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const globalContext = React.useContext(StavarioOldGlobalContext);
    const setGlobal = React.useContext(StavarioOldUpdateContext)!;

    /* const handleChange = (event: SelectChangeEvent<typeof selectedGroup>) => {
        if (event.target.value !== undefined) {
            setSelectedGroup(event.target.value as (UserGroupDTO | null));

            onChange(event.target.value as (UserGroupDTO | null));
        }

    }; */
    useEffect(() => {
        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetUsersForDiaryPrint(diaryId).then((res) => {
            setUsers(res === undefined ? [] : res);
        });

    }, [diaryId]);
    useEffect(() => {
        if (users !== undefined && users !== null && users.length > 0
            && selectedUserId !== undefined &&
            selectedUserId !== null && selectedUserId > 0) {
            let q = users.find(x => x.id == selectedUserId);
            if (q !== undefined)
                setSelectedUser(q);
        }

    }, [users]);
    return (
        <div>

            <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                onChange={(e, v) => {
                    setSelectedUser(v);
                    onChange(v);
                }}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                value={selectedUser}
                options={users}
                readOnly={readOnly}
                fullWidth={biggerThan700}
                sx={{ minWidth: 288 }}
                renderInput={(params) => <TextField error={isMandatory === true && selectedUser === null}  {...params} label={t('user')}

                />}
            />
            {selectedUser === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '2px', marginTop: '14px', color: theme.palette.error.main, fontSize: alertFontSize ? alertFontSize : 14, fontFamily: 'Roboto' }}>{t('FieldRequired')}</p>}
        </div>
    );
}
