
import { AccountCircle, AddCircleOutline, Check, CheckBox, NotInterested, Psychology, Reply, Save } from '@mui/icons-material';
import { Autocomplete, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, IconButton, Popover, TextareaAutosize, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SplitButton from '../../../Shared/SplitButton';
import { AdminUsersDTO } from '../../../../ApiOld/ApiServerVov';
import { APIVovKrosAdminHelper } from '../../../../ApiOld/ApiKrosAdminOldClient';
import { useMediaPredicate } from 'react-media-hook';
import KrosAdminUpperMenu from './KrosAdminUpperMenu';
import QuestionDialog from '../../../Shared/QuestionDialog';


interface KrosAdminUserDetailSceneProps {
}

export default function KrosAdminUserDetailScene(props: KrosAdminUserDetailSceneProps) {
    const { t, i18n } = useTranslation();
    const [userData, setUserData] = useState<Partial<AdminUsersDTO>>();
    const [saving, setSaving] = useState<boolean>(false);
    const [resetOpen, setResetOpen] = useState<boolean>(false);
    /*  const [messageData, setMessageData] = useState<{ title: string, text: string }>(); */

    const theme = useTheme();
    const navigate = useNavigate();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    const params = useParams();
    useEffect(() => {
        if (params['id']) {
            if (!Number.isNaN(parseInt(params['id']))) {
                new APIVovKrosAdminHelper().GetUserData(parseInt(params['id'])).then(e => {
                    setUserData(e);
                });
            }
        }
        else setUserData(undefined);
    }, []);

    function saveUser() {
        // a ukládat změny?
        if (userData) {
            setSaving(true);
            new APIVovKrosAdminHelper().SaveUserData(new AdminUsersDTO({ id: userData.id, name: userData.name, email: userData.email, application: 'kros' })).then((d) => {
                setSaving(false);
            });

        }

    }
    const redirectToList = () => {
        navigate('/' + params['lang'] + '/kros/admin/users');
    }

    function isEmail(email: string): boolean {
        const regexp = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        return regexp.test(email);
    }
    if (userData)
        return (<div>
            <KrosAdminUpperMenu />

            <div style={{ display: 'block', padding: '32px', paddingTop: '0px', marginTop: '50px' }}>
                <div style={{ display: 'flex', marginTop: '50px' }}>
                    <div>
                        <IconButton color='primary' onClick={redirectToList}>
                            <Reply />
                        </IconButton>
                    </div>
                    {/* <div style={{ paddingTop: '12px', background: 'white', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px' }}>
                        <h6>Uživatelé - základní informace</h6>
                    </div> */}
                    <div style={{ marginLeft: 'auto', marginRight: '56px', paddingTop: '16px' }}>
                        <SplitButton selectedIndexInput={0} disabled={userData.name === undefined || userData.name === '' || !userData.email || !isEmail(userData.email)} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                            startIconSplitButton={<Save />}
                            onMenuClick={(e) => {
                                if (e === 0) {
                                    saveUser();
                                }
                                else if (e === 1) {
                                    saveUser();
                                    redirectToList();

                                }
                            }}></SplitButton>
                    </div>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '8px' }}>
                        <h4>Užívatelia - základné informácie</h4>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                            <div style={{ marginTop: '32px' }}>
                                <TextField label={t('z')} variant='outlined' sx={{ margin: '0px 20px' }} size='small' value={userData?.name} onChange={(e) => { setUserData({ ...userData, name: e.target.value }); }} />

                            </div>
                            <div style={{ marginTop: '16px' }}>
                                <TextField label={'Email'} variant='outlined' sx={{ margin: '0px 20px' }} size='small' value={userData?.email} onChange={(e) => { setUserData({ ...userData, email: e.target.value }); }} />

                            </div>
                            <div style={{ marginTop: '32px' }}>
                                <Button variant='contained' sx={{ margin: '0px 20px' }} size='small' onClick={() => {
                                    if (userData.id) {
                                        setResetOpen(true);
                                    }
                                }} >RESET HESLA</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <QuestionDialog onClose={function (res: boolean): void {
                if (res && userData && userData.id) {
                    new APIVovKrosAdminHelper().ResetPasswordKrosAdmin(userData.id).then((d) => {
                        setSaving(false);
                    });
                }
                setResetOpen(false);
            }} text={'Naozaj resetovať heslo?'} title={'Reset hesla'} open={resetOpen} />
        </div>
        );
    else return <div>Loading ...</div>
}