import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import DiaryMainScene from '../../DiaryMainScene';
import { useTranslation } from 'react-i18next';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { useLoginAdminStore } from '../../../../Contexts/LoginAdminZContext';
import DiaryTabs from '../DiaryTabs';
import MoveBackToStavarioToolbar from './MoveBackToStavarioToolbar';

export default function DiaryExternalWithMenu() {


    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MoveBackToStavarioToolbar />
            <div style={{ position: 'absolute', top: '52px' }}>
                <DiaryTabs fromExternal />
            </div>
        </div>
    )

}
