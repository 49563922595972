import { create } from 'zustand'
import { APIVovHelper } from '../ApiOld/ApiOldClient';
// export const loadDiaryStore = () => {
//     return new APIVovHelper().GetDiaryPermissions();
// }
interface LoginAdminZContext {
    userName?: string;
    userId?: number;
    load: (by: string, cd: number) => void;
    serverLoad: () => void;
    getUserNameAndId: () => Promise<{ userName: string | undefined, userId: number | undefined }>;
}
export const useLoginAdminStore = create<LoginAdminZContext>()((set, actual) => ({
    userName: undefined,
    id: undefined,
    serverLoad: async () => {
        let a = await new APIVovHelper().GetUser();
        if (a && a.userId && a.userName)
            set((state) => ({ userName: a.userName, userId: a.userId }))
    },
    load: (by, cd) => set((state) => ({ userName: by, userId: cd })),
    getUserNameAndId: async () => {
        if (actual().userId && actual().userName)
            return { userName: actual().userName, userId: actual().userId };
        else {
            await actual().serverLoad();
            return { userName: actual().userName, userId: actual().userId };
        }
    }
}))