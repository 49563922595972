import { L10n } from '@syncfusion/ej2-base';
export default function InitSfRTBLocale() {
    L10n.load({
        'cs': {
            'richtexteditor': {
                alignments: 'Zarovnání',
                justifyLeft: 'Zarovnat doleva',
                justifyCenter: 'Zarovnat centrum ',
                justifyRight: 'Zarovnat doprava',
                justifyFull: 'Zarovnat do bloku',
                fontName: 'Název písma',
                fontSize: 'Velikost písma',
                fontColor: 'Barva fontu',
                backgroundColor: 'Barva pozadí',
                bold: 'Tučně',
                italic: 'Kurzíva',
                underline: 'Podtrhnout',
                strikethrough: 'Přešrktnout',
                clearFormat: 'Smazat formát',
                clearAll: 'Vymazat vše',
                cut: 'Vyjmout',
                copy: 'Kopírovat',
                paste: 'Vložit',
                unorderedList: 'Odrážky',
                orderedList: 'Číslování',
                indent: 'Increase Indent',
                outdent: 'Decrease Indent',
                undo: 'Undo',
                redo: 'Redo',
                superscript: 'Superscript',
                subscript: 'Subscript',
                createLink: 'Insert Link',
                openLink: 'Open Link',
                editLink: 'Edit Link',
                removeLink: 'Remove Link',
                image: 'Insert Image',
                replace: 'Replace',
                align: 'Align',
                caption: 'Image Caption',
                remove: 'Remove',
                insertLink: 'Insert Link',
                display: 'Display',
                altText: 'Alternative Text',
                dimension: 'Change Size',
                fullscreen: 'Maximize',
                maximize: 'Maximize',
                minimize: 'Minimize',
                lowerCase: 'Lower Case',
                upperCase: 'Upper Case',
                print: 'Print',
                formats: 'Formats',
                sourcecode: 'Code View',
                preview: 'Preview',
                viewside: 'ViewSide',
                insertCode: 'Insert Code',
                linkText: 'Display Text',
                linkTooltipLabel: 'Title',
                linkWebUrl: 'Web Address',
                linkTitle: 'Enter a title',
                linkurl: 'http://example.com',
                linkOpenInNewWindow: 'Open Link in New Window',
                linkHeader: 'Insert Link',
                dialogInsert: 'Insert',
                dialogCancel: 'Cancel',
                dialogUpdate: 'Update',
                imageHeader: 'Insert Image',
                imageLinkHeader: 'You can also provide a link from the web',
                mdimageLink: 'Please provide a URL for your image',
                imageUploadMessage: 'Drop image here or browse to upload',
                imageDeviceUploadMessage: 'Click here to upload',
                imageAlternateText: 'Alternate Text',
                alternateHeader: 'Alternative Text',
                browse: 'Browse',
                imageUrl: 'http://example.com/image.png',
                imageCaption: 'Caption',
                imageSizeHeader: 'Image Size',
                imageHeight: 'Height',
                imageWidth: 'Width',
                textPlaceholder: 'Enter Text',
                inserttablebtn: 'Insert Table',
                tabledialogHeader: 'Insert Table',
                tableWidth: 'Width',
                cellpadding: 'Cell Padding',
                cellspacing: 'Cell Spacing',
                columns: 'Number of columns',
                rows: 'Number of rows',
                tableRows: 'Table Rows',
                tableColumns: 'Table Columns',
                tableCellHorizontalAlign: 'Table Cell Horizontal Align',
                tableCellVerticalAlign: 'Table Cell Vertical Align',
                createTable: 'Create Table',
                removeTable: 'Remove Table',
                tableHeader: 'Table Header',
                tableRemove: 'Table Remove',
                tableCellBackground: 'Table Cell Background',
                tableEditProperties: 'Table Edit Properties',
                styles: 'Styles',
                insertColumnLeft: 'Insert Column Left',
                insertColumnRight: 'Insert Column Right',
                deleteColumn: 'Delete Column',
                insertRowBefore: 'Insert Row Before',
                insertRowAfter: 'Insert Row After',
                deleteRow: 'Delete Row',
                tableEditHeader: 'Edit Table',
                TableHeadingText: 'Heading',
                TableColText: 'Col',
                imageInsertLinkHeader: 'Insert Link',
                editImageHeader: 'Edit Image',
                alignmentsDropDownLeft: 'Align Left',
                alignmentsDropDownCenter: 'Align Center',
                alignmentsDropDownRight: 'Align Right',
                alignmentsDropDownJustify: 'Align Justify',
                imageDisplayDropDownInline: 'Inline',
                imageDisplayDropDownBreak: 'Break',
                tableInsertRowDropDownBefore: 'Insert row before',
                tableInsertRowDropDownAfter: 'Insert row after',
                tableInsertRowDropDownDelete: 'Delete row',
                tableInsertColumnDropDownLeft: 'Insert column left',
                tableInsertColumnDropDownRight: 'Insert column right',
                tableInsertColumnDropDownDelete: 'Delete column',
                tableVerticalAlignDropDownTop: 'Align Top',
                tableVerticalAlignDropDownMiddle: 'Align Middle',
                tableVerticalAlignDropDownBottom: 'Align Bottom',
                tableStylesDropDownDashedBorder: 'Dashed Borders',
                tableStylesDropDownAlternateRows: 'Alternate Rows',
                pasteFormat: 'Paste Format',
                pasteFormatContent: 'Choose the formatting action',
                plainText: 'Plain Text',
                cleanFormat: 'Clean',
                keepFormat: 'Keep',
                pasteDialogOk: 'OK',
                pasteDialogCancel: 'Cancel',
                fileManager: 'File Manager',
                fileDialogHeader: 'File Browser',
                formatsDropDownParagraph: 'Paragraph',
                formatsDropDownCode: 'Code',
                formatsDropDownQuotation: 'Quotation',
                formatsDropDownHeading1: 'Heading 1',
                formatsDropDownHeading2: 'Heading 2',
                formatsDropDownHeading3: 'Heading 3',
                formatsDropDownHeading4: 'Heading 4',
                fontNameSegoeUI: 'Segoe UI',
                fontNameArial: 'Arial',
                fontNameGeorgia: 'Georgia',
                fontNameImpact: 'Impact',
                fontNameTahoma: 'Tahoma',
                fontNameTimesNewRoman: 'Times New Roman',
                fontNameVerdana: 'Verdana',
                numberFormatListNumber: 'Number',
                numberFormatListLowerAlpha: 'LowerAlpha',
                numberFormatListUpperAlpha: 'UpperAlpha',
                numberFormatListLowerRoman: 'LowerRoman',
                numberFormatListUpperRoman: 'UpperRoman',
                numberFormatListLowerGreek: 'LowerGreek',
                bulletFormatListDisc: 'Disc',
                bulletFormatListCircle: 'Circle',
                bulletFormatListSquare: 'Square',
                numberFormatListNone: 'None',
                bulletFormatListNone: 'None'
            }
        },
        'pl': {
            'richtexteditor': {
                alignments: 'Zarovnání',
                justifyLeft: 'Wyrównaj do lewej',
                justifyCenter: 'Wyrównaj do środka',
                justifyRight: 'Wyrównaj w prawo',
                justifyFull: 'Wyrównaj do bloku',
                fontName: 'Nazwa czcionki',
                fontSize: 'Rozmiar czcionki',
                fontColor: 'Kolor czcionki',
                backgroundColor: 'Kolor tła',
                bold: 'Pogrubione',
                italic: 'Kursywa',
                underline: 'Podkreślać',
                strikethrough: 'Skreślać',
                clearFormat: 'Usuń formatowanie',
                clearAll: 'Wyczyść wszystko',
                cut: 'Na wynos',
                copy: 'Kopiuj',
                paste: 'Wstawić',
                unorderedList: 'Kule',
                orderedList: 'Numeracja',
                indent: 'Increase Indent',
                outdent: 'Decrease Indent',
                undo: 'Undo',
                redo: 'Redo',
                superscript: 'Superscript',
                subscript: 'Subscript',
                createLink: 'Insert Link',
                openLink: 'Open Link',
                editLink: 'Edit Link',
                removeLink: 'Remove Link',
                image: 'Insert Image',
                replace: 'Replace',
                align: 'Align',
                caption: 'Image Caption',
                remove: 'Remove',
                insertLink: 'Insert Link',
                display: 'Display',
                altText: 'Alternative Text',
                dimension: 'Change Size',
                fullscreen: 'Maximize',
                maximize: 'Maximize',
                minimize: 'Minimize',
                lowerCase: 'Lower Case',
                upperCase: 'Upper Case',
                print: 'Print',
                formats: 'Formats',
                sourcecode: 'Code View',
                preview: 'Preview',
                viewside: 'ViewSide',
                insertCode: 'Insert Code',
                linkText: 'Display Text',
                linkTooltipLabel: 'Title',
                linkWebUrl: 'Web Address',
                linkTitle: 'Enter a title',
                linkurl: 'http://example.com',
                linkOpenInNewWindow: 'Open Link in New Window',
                linkHeader: 'Insert Link',
                dialogInsert: 'Insert',
                dialogCancel: 'Cancel',
                dialogUpdate: 'Update',
                imageHeader: 'Insert Image',
                imageLinkHeader: 'You can also provide a link from the web',
                mdimageLink: 'Please provide a URL for your image',
                imageUploadMessage: 'Drop image here or browse to upload',
                imageDeviceUploadMessage: 'Click here to upload',
                imageAlternateText: 'Alternate Text',
                alternateHeader: 'Alternative Text',
                browse: 'Browse',
                imageUrl: 'http://example.com/image.png',
                imageCaption: 'Caption',
                imageSizeHeader: 'Image Size',
                imageHeight: 'Height',
                imageWidth: 'Width',
                textPlaceholder: 'Enter Text',
                inserttablebtn: 'Insert Table',
                tabledialogHeader: 'Insert Table',
                tableWidth: 'Width',
                cellpadding: 'Cell Padding',
                cellspacing: 'Cell Spacing',
                columns: 'Number of columns',
                rows: 'Number of rows',
                tableRows: 'Table Rows',
                tableColumns: 'Table Columns',
                tableCellHorizontalAlign: 'Table Cell Horizontal Align',
                tableCellVerticalAlign: 'Table Cell Vertical Align',
                createTable: 'Create Table',
                removeTable: 'Remove Table',
                tableHeader: 'Table Header',
                tableRemove: 'Table Remove',
                tableCellBackground: 'Table Cell Background',
                tableEditProperties: 'Table Edit Properties',
                styles: 'Styles',
                insertColumnLeft: 'Insert Column Left',
                insertColumnRight: 'Insert Column Right',
                deleteColumn: 'Delete Column',
                insertRowBefore: 'Insert Row Before',
                insertRowAfter: 'Insert Row After',
                deleteRow: 'Delete Row',
                tableEditHeader: 'Edit Table',
                TableHeadingText: 'Heading',
                TableColText: 'Col',
                imageInsertLinkHeader: 'Insert Link',
                editImageHeader: 'Edit Image',
                alignmentsDropDownLeft: 'Align Left',
                alignmentsDropDownCenter: 'Align Center',
                alignmentsDropDownRight: 'Align Right',
                alignmentsDropDownJustify: 'Align Justify',
                imageDisplayDropDownInline: 'Inline',
                imageDisplayDropDownBreak: 'Break',
                tableInsertRowDropDownBefore: 'Insert row before',
                tableInsertRowDropDownAfter: 'Insert row after',
                tableInsertRowDropDownDelete: 'Delete row',
                tableInsertColumnDropDownLeft: 'Insert column left',
                tableInsertColumnDropDownRight: 'Insert column right',
                tableInsertColumnDropDownDelete: 'Delete column',
                tableVerticalAlignDropDownTop: 'Align Top',
                tableVerticalAlignDropDownMiddle: 'Align Middle',
                tableVerticalAlignDropDownBottom: 'Align Bottom',
                tableStylesDropDownDashedBorder: 'Dashed Borders',
                tableStylesDropDownAlternateRows: 'Alternate Rows',
                pasteFormat: 'Paste Format',
                pasteFormatContent: 'Choose the formatting action',
                plainText: 'Plain Text',
                cleanFormat: 'Clean',
                keepFormat: 'Keep',
                pasteDialogOk: 'OK',
                pasteDialogCancel: 'Cancel',
                fileManager: 'File Manager',
                fileDialogHeader: 'File Browser',
                formatsDropDownParagraph: 'Paragraph',
                formatsDropDownCode: 'Code',
                formatsDropDownQuotation: 'Quotation',
                formatsDropDownHeading1: 'Heading 1',
                formatsDropDownHeading2: 'Heading 2',
                formatsDropDownHeading3: 'Heading 3',
                formatsDropDownHeading4: 'Heading 4',
                fontNameSegoeUI: 'Segoe UI',
                fontNameArial: 'Arial',
                fontNameGeorgia: 'Georgia',
                fontNameImpact: 'Impact',
                fontNameTahoma: 'Tahoma',
                fontNameTimesNewRoman: 'Times New Roman',
                fontNameVerdana: 'Verdana',
                numberFormatListNumber: 'Number',
                numberFormatListLowerAlpha: 'LowerAlpha',
                numberFormatListUpperAlpha: 'UpperAlpha',
                numberFormatListLowerRoman: 'LowerRoman',
                numberFormatListUpperRoman: 'UpperRoman',
                numberFormatListLowerGreek: 'LowerGreek',
                bulletFormatListDisc: 'Disc',
                bulletFormatListCircle: 'Circle',
                bulletFormatListSquare: 'Square',
                numberFormatListNone: 'None',
                bulletFormatListNone: 'None'
            }
        },
    });
}