import { AddCircle, CheckCircleRounded, Close, CloseOutlined, Share, TheaterComedy, ViewKanban } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, OutlinedInput, TextField, useTheme } from '@mui/material';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import delay from '../../Shared/Functions/Delay';
import { InvitePeople } from './InvitePeople';
import { SendInvitationsRequestDTO } from './OldApi/ApiServer';
import { OldApiClient } from './OldApi/OldApiClientHelper';

interface SendInviteProps {
    adminUserId: number | null;
    tokenOldApi: string | null;
    domain: string | null;
    onClose: (refresh: boolean) => void;
    open: boolean;
}


export default function SendInvite(props: SendInviteProps) {
    const { onClose, open, adminUserId, domain, tokenOldApi } = props;
    const [sending, setSending] = useState(false);
    const [invited, setInvited] = useState<InvitePeople[]>([]);
    const [newInvited, setNewInvited] = useState('');
    const [canSend, setCanSend] = useState<boolean>(false);
    const [errorsVisible, setErrorsVisible] = useState<boolean>(false);
    const [validsInErrorVisible, setValidsInErrorVisible] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [valids, setValids] = useState<string[]>([]);
    const [acceptSend, setAcceptSend] = useState<boolean>(false);
    const biggerThan600 = useMediaPredicate("(min-width: 600px)");
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    function removeLast(removeId: number) {
        console.log(invited);
        if (newInvited === '') {
            console.log(invited.find(x => x.id === removeId));
            setNewInvited(invited.find(x => x.id === removeId)!.email!);
        }

        setInvited(invited.filter(x => x.id !== removeId));
    }
    // const [open, setOpen] = React.useState(false);
    const handleClose = (refresh: boolean) => {
        setAcceptSend(false);
        setErrorsVisible(false);
        setNewInvited('');
        setInvited([]);
        onClose(refresh);
    };
    function getCanSend() {
        return invited.some(x => x.email === undefined || x.email === null || x.email === '');
    }
    useEffect(() => {
        setInvited([]);
    }, [open]);
    useEffect(() => {
        setCanSend(getCanSend());
    }, [invited]);
    useEffect(() => {
        setCanSend(getCanSend());
    }, [valids]);
    return (
        <Dialog
            maxWidth='lg'
            open={open}
            onClose={() => { handleClose(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('sales.enterEmails')}
                <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>
                    <IconButton disabled={sending} sx={{ marginRight: '-12px', marginTop: '-8px' }} onClick={(e) => handleClose(false)} >
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                {errorsVisible &&
                    <div>
                        {errors !== undefined && errors.length > 0 && <div>
                            <h4>{t('sales.errorSentInvite')}</h4>
                            {errors.map((value) => {
                                return <div><p style={{ fontWeight: 'bold', color: theme.palette.error.main }}>{value}</p></div>;
                            })}
                        </div>}
                        {validsInErrorVisible && <div>
                            <h4>{t('sales.evenError')}</h4>
                            {valids.map((valid) => {
                                if (!(errors.indexOf(valid) > -1))
                                    return <div><p style={{ fontWeight: 'bold', color: theme.palette.success.main }}>{valid}</p></div>;
                            })}
                        </div>}
                        <Button variant='contained' onClick={(e) => { handleClose(true) }}>{t('Continue')}</Button>
                    </div>
                }
                {acceptSend &&
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <h4>{t('sales.sent')}</h4>
                        <CheckCircleRounded sx={{ width: 80, height: 80 }} color='success' />
                    </div>}
                {!acceptSend && !errorsVisible && <ValidationGroup>
                    <div>
                        {invited.map((value, i) => {
                            return (
                                <div style={{ minWidth: '380px' }}>
                                    <TextField value={value.email}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={(e) => removeLast(value.id)}>
                                                        <CloseOutlined />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        // onChange={(e) => { value.email = e.currentTarget.value; }}
                                        fullWidth={biggerThan600} size='small' sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                            m: '8px',
                                            minWidth: '268px'
                                        }} placeholder={t('sales.emailInviteText')} />
                                </div>
                            );
                        })}
                        <Validate name={'email-signup-validate-input'} regex={[/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('sales.fieldEmailReqiured')]}>
                            <TextField value={newInvited} onChange={(e) => { setNewInvited(e.currentTarget.value) }}
                                fullWidth={biggerThan600} size='small' sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                    m: '8px',
                                    minWidth: '268px'
                                }} placeholder={t('sales.emailInviteText')} />
                        </Validate>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '8px' }}>
                            {!sending && <AutoDisabler>
                                <Button size='medium' variant="outlined" onClick={(e) => {
                                    setInvited([...invited, new InvitePeople(invited.length + 1, newInvited)]);
                                    setNewInvited('');

                                }} startIcon={<AddCircle />}
                                    sx={{ m: '8px', width: '220px' }}
                                >{t('sales.addTo')}</Button>
                            </AutoDisabler>}
                            {!sending && <AutoDisabler>
                                <Button size='medium' variant="contained" onClick={(e) => {
                                    if (domain !== null && domain !== '') {
                                        setSending(true);
                                        let mails = invited.map((val) => { return val.email! });
                                        if (newInvited !== '')
                                            mails.push(newInvited);
                                        setValids(mails);
                                        new OldApiClient().GetUnauthenticatedOldClient(domain)
                                            .apiV2_7RecommendationsSendInvitations(new SendInvitationsRequestDTO({ token: tokenOldApi!, adminUserId: adminUserId!, emails: mails, language: i18n.language }))
                                            .then((t) => {
                                                if (t !== undefined && t.errors !== undefined && t.errors.length > 0) {
                                                    setSending(false);
                                                    setErrors(t.errors);
                                                    if (t.errors.length < mails.length)
                                                        setValidsInErrorVisible(true);
                                                    else setValidsInErrorVisible(false);
                                                    setErrorsVisible(true);
                                                }
                                                else {
                                                    setSending(false);
                                                    setAcceptSend(true);
                                                    delay(1500).then(() => {
                                                        handleClose(true);
                                                    })

                                                }

                                            })
                                    }


                                }} startIcon={<Share />}
                                    sx={{ m: '8px', width: '280px' }}
                                >{t('sales.sendInvite')}</Button>
                            </AutoDisabler>}
                            {sending && <CircularProgress sx={{ opacity: '1', width: '16px', height: '16px' }} />}
                        </div>
                    </div>
                </ValidationGroup>}
            </DialogContent>

        </Dialog>)
}