import React from 'react'
import DiaryDetail from '../DiaryDetail'
import MoveBackToStavarioToolbar from './MoveBackToStavarioToolbar'

export default function DiaryDetailExternalWithMenu() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <MoveBackToStavarioToolbar />
            <div style={{ top: '52px' }}>
                <DiaryDetail fromExternal />
            </div>
        </div>)
}
