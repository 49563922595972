
import { TrashItemModel } from "./TrashItemModel";
import { TrashType } from "./TrashType";

export default function GetTrashColumns(trashType: TrashType): TrashItemModel[] {

    switch (trashType) {
        case TrashType.Projects:
            return [{ field: "id", humanName: 'Id' }, { field: "code", humanName: 'buildings.buildingCode' }, { field: "name", humanName: 'Name' }];
        case TrashType.Diaries:
            return [{ field: "id", humanName: 'Id' }, { field: "name", humanName: 'Name' }, { field: "buildingName", humanName: 'Projekt' }];
        case TrashType.DailyRecords:
            return [{ field: "id", humanName: 'Id' }, { field: "date", humanName: 'Date' }, { field: "buildingName", humanName: 'Projekt' }, { field: "diaryName", humanName: 'diary.diary' }];
        case TrashType.Wealths:
            return [{ field: "id", humanName: 'Id' }, { field: "name", humanName: 'Name' }, { field: "serialNumber", humanName: 'xxx seriove cislo' }];
        case TrashType.Invoices:
            return [{ field: "id", humanName: 'Id' }, { field: "name", humanName: 'Name' }, { field: "project", humanName: 'Projekt' }];
        case TrashType.Employees:
            return [{ field: "code", humanName: 'code' }, { field: "firstname", humanName: 'z' }, { field: "lastname", humanName: 'lastName' }];
        case TrashType.Todos:
            return [{ field: "name", humanName: 'Name' }, { field: "description", humanName: 'Description' }]
        default:
            return [];
    }

}