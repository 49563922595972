import noRights from '../../../Assets/noRights.png';
import React from 'react';
interface NoRightsProps {
    title?: string;
}
export default function NoRights(props: NoRightsProps) {
    const { title } = props;
    return <div style={{
        display: 'flex', justifyContent: 'center', marginTop: '120px',
        flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '500', left: 'calc(50% - 150px)'
    }}><img src={noRights} width='320' height='320'></img>
        {title}</div>
}