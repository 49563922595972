
// @ts-nocheck
import { Button, IconButton, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ImageDiaryDTO } from '../../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { Grid, InfiniteLoader, InfiniteLoaderChildProps, List, WindowScroller } from 'react-virtualized';
import { useMediaPredicate } from 'react-media-hook';
import { AddAPhoto, AutoFixHighOutlined, Delete, Update } from '@mui/icons-material';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import ImageGallery from '../../Shared/FileViewers/ImageGallery';

interface ImageWindowScrollerProps {
    width?: number;
    canDelete?: boolean;
    images: ImageDiaryDTO[];
    buildingId: number;

    /** Callback function (eg. Redux action-creator) responsible for loading the next page of items */
    onRefresh?: (fileName: string, wasDelete: boolean) => void;
    onNewRequest: (value: ImageDiaryDTO) => void;
}

export default function ImageWindowScroller(props: ImageWindowScrollerProps) {
    const { images, canDelete, recordId, onRefresh, onNewRequest, buildingId } = props;
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const biggerThan1400 = useMediaPredicate("(min-width: 1400px)");
    const biggerThan1200 = useMediaPredicate("(min-width: 1200px)");
    const biggerThan1600 = useMediaPredicate("(min-width: 1600px)");
    const [selected, setSelected] = useState<number | undefined>();
    const { t } = useTranslation();
    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.

    function getColumnsCount() {
        if (biggerThan700 && !biggerThan1200)
            return 3;
        else if (biggerThan1400 && !biggerThan1600)
            return 5;
        else if (!biggerThan1400 && biggerThan1200)
            return 4;
        else if (biggerThan1600)
            return 6;

        else return 1;
    }
    function getRowsCount() {
        if (!biggerThan1400 && biggerThan700)
            return images.length / 4;
        else if (biggerThan1400 && biggerThan700)
            return images.length / 6;
        else return images.length / 2;
    }
    useEffect(() => {
        console.log(images.length);
        console.log((images.length / getColumnsCount) + 1);
    }, [images]);
    function cellRenderer({ columnIndex, key, rowIndex, style }) {

        if (images !== undefined && images.length > 0) {
            let imageData = images[getColumnsCount() * rowIndex + columnIndex];
            // console.log('drwaing', 4 * rowIndex + columnIndex);
            if (imageData !== undefined) {
                let image = images[getColumnsCount() * rowIndex + columnIndex];
                // var style2 = style;
                // console.log(style2);
                // style2['margin'] = '8px';
                if (image !== undefined) {
                    return (
                        <div key={key} style={style}>
                            <div className='parent' id='parent' style={{ margin: '8px', boxShadow: '2px 2px 4px gray', borderRadius: '6px', padding: '4px' }}>
                                {canDelete && <Tooltip title={t('removeImage')}>
                                    <IconButton
                                        onClick={(e) => {
                                            console.log(image);
                                            if (recordId) {
                                                //new APIVovHelper().deleteDiaryImageRel(recordId, image.fileName);
                                                if (onRefresh)
                                                    onRefresh(image.fileName, true);


                                            }
                                            console.log(image.fileName);
                                        }}
                                        className='hidden-child' color='secondary' sx={{ position: 'absolute', top: '8px', right: '5px', left: 'auto', float: 'right' }}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>}


                                <img onClick={(e) => {
                                    setSelected(images.findIndex(x => x.url === image.url));
                                }} src={image.url} height={260} width={280} loading='lazy' style={{ marginLeft: '2px', gridArea: '1/1', maxWidth: '100%', maxHeight: '100%' }}></img>
                            </div>
                        </div>
                    );
                }
            }
        }
    }
    function handleNewRequestClick(item: ImageDiaryDTO) {
        setSelected(undefined);
        onNewRequest(item);
    }

    return (
        <div>
            <ImageGallery canSaveChanged={false} canCreateTask diaryImages={images} selected={selected} open={selected !== undefined} buildingId={buildingId}
                onClose={() => { setSelected(undefined) }} newRequestButton={true} onNewRequest={handleNewRequestClick} />
            <WindowScroller>
                {({ height, isScrolling, onChildScroll, scrollTop, registerChild, width }) => (
                    <div ref={registerChild}>
                        <Grid

                            cellRenderer={cellRenderer}
                            columnCount={getColumnsCount()}
                            columnWidth={300}
                            height={images.length > getColumnsCount() ? 520 : 240}
                            rowCount={(images.length / getColumnsCount()) + 1}
                            rowHeight={300}
                            width={width - 100}

                        />

                    </div>
                )}
                {/* <List
                width={400} rowHeight={200}
                rowCount={images.length} height={400}
                rowRenderer={(e) => { return rowRenderer(e.index, e.key, e.style) }}></List> */}
            </WindowScroller>

        </div>
    );
}