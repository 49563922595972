import { Box, Tab, Tabs, IconButton } from "@mui/material";
import { Check, Reply, Save } from '@mui/icons-material';
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { TabPanel } from "../../Shared/Tabs/TabPanel";
import tabProps from '../../Shared/Tabs/TabProps';
import SplitButton from "../../Shared/SplitButton";
import delay from '../../Shared/Functions/Delay';
import { GetInvoiceDataDetailResponse, InvoiceItem } from "../../../ApiOld/ApiServerVov";
import { useNavigate } from 'react-router-dom';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import { ApiOldInvoicesClient } from "../../../ApiOld/ApiOldInvoicesClient";
import AdminUserSelect from "../../Shared/AdminUserSelect";
import BankInput from "../../Shared/BankInput";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ChangeLog from "./ChangeLog";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import InvoiceItems from "./InvoiceItems";

export default function InvoiceDetail() {
    const [invoiceData, setInvoiceData] = useState<Partial<GetInvoiceDataDetailResponse> | null>(null);
    const [invoiceItems, setInvoiceItems] = useState<InvoiceItem[] | undefined>();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [saveDone, setSaveDone] = useState<boolean>(false);
    const params = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const handleSelectChange = (property, value) => {
        setInvoiceData((prevData) => ({
            ...prevData,
            [property]: Boolean(value)
        }));
    };
    const reloadData = useCallback(() => {
        if (params['id']) {
            new ApiOldInvoicesClient().GetInvoiceDataDetail(parseInt(params['id'])).then((d) => {
                setInvoiceData(d);
                if (d.id) {
                    new ApiOldInvoicesClient().GetInvoiceItems(d.id).then((x) => {
                        if (x.invoiceItems) {
                            setInvoiceItems(x.invoiceItems);
                        }
                    });
                }
            });
        }
    }, [params]);

    useEffect(() => {
        reloadData();
    }, [reloadData]);

    useEffect(() => {
        if (saveDone === true)
            delay(2000).then((d) => { setSaveDone(false); });
    }, [saveDone]);

    function saveInvoice(updatedInvoiceItems?: InvoiceItem[]) {
        if (invoiceData && invoiceItems) {
            const validInvoiceItems = updatedInvoiceItems ? updatedInvoiceItems : invoiceItems;
            console.log(validInvoiceItems);
            new ApiOldInvoicesClient().SaveWholeInvoice(invoiceData, validInvoiceItems.map((invIt) => { return new InvoiceItem({ ...invIt }) })).then((d) => {
                if (d) {
                    setInvoiceData({ ...invoiceData });
                    setSaveDone(true);
                    reloadData();
                }
            });
        }
    }

    const redirectToList = () => {
        navigate('/' + i18n.language + '/' + params['domain']! + '/invoices')
    }

    if (invoiceData)
        return (
            <Box>
                <div style={{ position: 'absolute', left: '40px', top: '60px', zIndex: 30 }}>
                    <IconButton color="primary" onClick={redirectToList}>
                        <Reply />
                    </IconButton>
                </div>
                <div style={{ position: 'fixed', display: 'flex', right: '40px', top: '60px', zIndex: 30 }}>
                    {saveDone && <Check color='success' sx={{ marginRight: '20px' }} />}
                    <div style={{ marginRight: '10px' }}>
                        <SplitButton selectedIndexInput={0} disabled={invoiceData === undefined || invoiceData === null} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                            startIconSplitButton={<Save />}
                            onMenuClick={(e) => {
                                if (e === 0) {
                                    saveInvoice(undefined);
                                }
                                else if (e === 1) {
                                    saveInvoice(undefined);
                                    redirectToList();
                                }
                            }}></SplitButton></div>
                </div>


                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={(e, n) => { setSelectedTabIndex(n) }} aria-label="basic tabs example">
                        <Tab label={t('buildings.basicInformation')} {...tabProps(0)} />
                        <Tab label={t('additionalInformation')} {...tabProps(1)} />
                        <Tab label={t('invoices.detailsForPaymentOrder')} {...tabProps(2)} />
                        {invoiceData.id !== 0 && <Tab label={t('logOfChanges')} {...tabProps(3)} />}
                    </Tabs>
                </Box>


                <TabPanel value={selectedTabIndex} index={0}>
                    <ValidationGroup>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px' }}>
                                <h4>1. {t('invoices.Invoice')}</h4>
                                <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Validate name='email-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                                <TextField label={t('Name')} variant="outlined" fullWidth size='small'
                                                    name='name'
                                                    value={invoiceData.name}
                                                    onChange={(e) => { setInvoiceData({ ...invoiceData, name: e.target.value }) }}
                                                />
                                            </Validate>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('is_posted')}</InputLabel>
                                                <Select
                                                    size="small"
                                                    labelId="demo-simple-select-label"
                                                    id="isPosted"
                                                    value={Number(invoiceData.isPosted)}
                                                    label={t('is_posted')}
                                                    onChange={(e) => handleSelectChange('isPosted', e.target.value)}
                                                >
                                                    <MenuItem value={1}>{t('yesL')}</MenuItem>
                                                    <MenuItem value={0}>{t('noL')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('is_approved')}</InputLabel>
                                                <Select
                                                    size="small"
                                                    labelId="demo-simple-select-label"
                                                    id="isDone"
                                                    value={Number(invoiceData.isDone)}
                                                    label={t('is_approved')}
                                                    onChange={(e) => handleSelectChange('isDone', e.target.value)}
                                                >
                                                    <MenuItem value={1}>{t('yesL')}</MenuItem>
                                                    <MenuItem value={0}>{t('noL')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('is_done')}</InputLabel>
                                                <Select
                                                    size="small"
                                                    labelId="demo-simple-select-label"
                                                    id="isApproved"
                                                    value={Number(invoiceData.isApproved)}
                                                    label={t('is_done')}
                                                    onChange={(e) => handleSelectChange('isApproved', e.target.value)}
                                                >
                                                    <MenuItem value={1}>{t('yesL')}</MenuItem>
                                                    <MenuItem value={0}>{t('noL')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('is_postponed')}</InputLabel>
                                                <Select
                                                    size="small"
                                                    labelId="demo-simple-select-label"
                                                    id="isPostponed"
                                                    value={Number(invoiceData.isPostponed)}
                                                    label={t('is_postponed')}
                                                    onChange={(e) => handleSelectChange('isPostponed', e.target.value)}
                                                >
                                                    <MenuItem value={1}>{t('yesL')}</MenuItem>
                                                    <MenuItem value={0}>{t('noL')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={t('date_taxable')}
                                                    inputFormat="DD.MM.YYYY"
                                                    value={invoiceData.dateTaxable}
                                                    onChange={(newValue) => {
                                                        setInvoiceData({ ...invoiceData, dateTaxable: newValue ? newValue : undefined });
                                                    }}
                                                    renderInput={(params) => <TextField fullWidth size='small' {...params} style={{ backgroundColor: '#FFFFFF', float: 'right' }} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={t('date_expire')}
                                                    inputFormat="DD.MM.YYYY"
                                                    value={invoiceData.dateExpire}
                                                    onChange={(newValue) => {
                                                        setInvoiceData({ ...invoiceData, dateExpire: newValue ? newValue : undefined });
                                                    }}
                                                    renderInput={(params) => <TextField fullWidth size='small' {...params} style={{ backgroundColor: '#FFFFFF', float: 'right' }} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <AdminUserSelect
                                                    label={t("admin_users_id_from")}
                                                    selectedUserId={invoiceData.adminUserFrom}
                                                    onChange={(selectedUser) => {
                                                        const userId = selectedUser ? selectedUser.id : null;
                                                        setInvoiceData((prevData) => ({ ...prevData, adminUserFrom: userId } as Partial<GetInvoiceDataDetailResponse>));
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <AdminUserSelect
                                                    label={t("admin_users_id_to")}
                                                    selectedUserId={invoiceData.adminUserTo}
                                                    onChange={(selectedUser) => {
                                                        const userId = selectedUser ? selectedUser.id : null;
                                                        setInvoiceData((prevData) => ({ ...prevData, adminUserTo: userId } as Partial<GetInvoiceDataDetailResponse>));
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>

                            </div>
                        </div>
                    </ValidationGroup>
                    <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px' }}>
                        <h4>2. {t('items')}</h4>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '15px' }}>
                            <InvoiceItems
                                invoiceItems={invoiceItems}
                                saveInvoiceItems={(items) => {
                                    saveInvoice(items)
                                }}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <ValidationGroup>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField label={t('supplier')} variant="outlined" fullWidth size='small'
                                                name='name'
                                                value={invoiceData.supplier}
                                                onChange={(e) => { setInvoiceData({ ...invoiceData, supplier: e.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={t('date_recieve')}
                                                    inputFormat="DD.MM.YYYY"
                                                    value={invoiceData.dateReceive}
                                                    onChange={(newValue) => {
                                                        setInvoiceData({ ...invoiceData, dateReceive: newValue ? newValue : undefined });
                                                    }}
                                                    renderInput={(params) => <TextField fullWidth size='small' {...params} style={{ backgroundColor: '#FFFFFF', float: 'right' }} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField label={t('variable_symbol')} variant="outlined" fullWidth size='small'
                                                name='name'
                                                value={invoiceData.variableSymbol}
                                                onChange={(e) => { setInvoiceData({ ...invoiceData, variableSymbol: e.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField label={t('subject')} variant="outlined" fullWidth size='small'
                                                name='name'
                                                value={invoiceData.subject}
                                                onChange={(e) => { setInvoiceData({ ...invoiceData, subject: e.target.value }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </ValidationGroup>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={2}>
                    <ValidationGroup>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px'/* , width: '400px' */ }}>
                                <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <BankInput
                                                label={t('debitAccount')}
                                                value1={invoiceData.paymentPayerBankNumber}
                                                value2={invoiceData.paymentPayerBankCode}
                                                onValue1Change={(e) => setInvoiceData({ ...invoiceData, paymentPayerBankNumber: e.target.value })}
                                                onValue2Change={(e) => setInvoiceData({ ...invoiceData, paymentPayerBankCode: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <BankInput
                                                label={t('creditAccount')}
                                                value1={invoiceData.paymentRecipientBankNumber}
                                                value2={invoiceData.paymentRecipientBankCode}
                                                onValue1Change={(e) => setInvoiceData({ ...invoiceData, paymentRecipientBankNumber: e.target.value })}
                                                onValue2Change={(e) => setInvoiceData({ ...invoiceData, paymentRecipientBankCode: e.target.value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={t('date_expire')}
                                                    inputFormat="DD.MM.YYYY"
                                                    value={invoiceData.paymentDueDate == null ? undefined : invoiceData.paymentDueDate}
                                                    onChange={(newValue) => {
                                                        setInvoiceData({ ...invoiceData, paymentDueDate: newValue ? newValue : undefined });
                                                    }}
                                                    renderInput={(params) => <TextField fullWidth size='small' {...params} style={{ backgroundColor: '#FFFFFF', float: 'right' }} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField label={t('payment_recipient_name')} variant="outlined" fullWidth size='small'
                                                name='name'
                                                value={invoiceData.paymentRecipientName}
                                                onChange={(e) => { setInvoiceData({ ...invoiceData, paymentRecipientName: e.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField label={t('payment_message')} variant="outlined" fullWidth size='small'
                                                name='name'
                                                value={invoiceData.paymentMessage}
                                                onChange={(e) => { setInvoiceData({ ...invoiceData, paymentMessage: e.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField label={t('payment_variable_symbol')} variant="outlined" fullWidth size='small'
                                                name='name'
                                                value={invoiceData.paymentVariableSymbol}
                                                onChange={(e) => { setInvoiceData({ ...invoiceData, paymentVariableSymbol: e.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField label={t('payment_constant_symbol')} variant="outlined" fullWidth size='small'
                                                name='name'
                                                value={invoiceData.paymentConstantSymbol}
                                                onChange={(e) => { setInvoiceData({ ...invoiceData, paymentConstantSymbol: e.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField label={t('payment_specific_symbol')} variant="outlined" fullWidth size='small'
                                                name='name'
                                                value={invoiceData.paymentSpecificSymbol}
                                                onChange={(e) => { setInvoiceData({ ...invoiceData, paymentSpecificSymbol: e.target.value }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </ValidationGroup>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={3}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px'/* , width: '400px' */ }}>
                            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <ChangeLog logs={invoiceData.changeLogs}></ChangeLog>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </Box >
        );
    return <div>{t('loading...')}</div>

}