import { Close, Redeem } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { NumberFilterUI } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useState } from 'react';
import { SendEmailToStavarioRequestDTO } from './OldApi/ApiServer';
import { OldApiClient } from './OldApi/OldApiClientHelper';
interface GetRewardProps {
    rewardIndex: number;
    adminUserId: number | null;
    tokenOldApi: string | null;
    domain: string | null;
    onClose: (refresh: boolean) => void;
    open: boolean;
}

export default function GetReward(props: GetRewardProps) {
    const { onClose, open, adminUserId, domain, tokenOldApi, rewardIndex } = props;
    const handleClose = () => {
        onClose(false);
    };
    function sendRewardMail() {
        if (domain !== null) {
            let cl = new OldApiClient().GetUnauthenticatedOldClient(domain!);
            cl.apiV2_7RecommendationsSendEmailToStavario(
                new SendEmailToStavarioRequestDTO({ actionType: rewardIndex, adminUserId: adminUserId!, token: tokenOldApi! })).then((data) => {
                    if (data.message !== undefined && data.message === 'success') {
                        onClose(false);
                    }
                });
        }
    }
    return (
        <Dialog
            maxWidth='lg'
            open={open}
            onClose={() => { handleClose() }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Vyzvedněte si svou odměnu"}
                <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>
                    <IconButton sx={{ marginRight: '-12px', marginTop: '-8px' }} onClick={(e) => onClose(false)} >
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <div>
                        <p style={{ textAlign: 'center' }}>Dosáhli jste na odměnu. Pokud ji chcete nyní uplatnit, stačí potvrdit tlačítkem níže a my už se o všechno postaráme.</p>
                    </div>
                    <Redeem color='action' style={{ width: '120px', height: '120px' }} />
                    <Button sx={{ margin: '20px', width: '220px' }} variant="contained" onClick={sendRewardMail}>Potvrdit odeslání</Button>
                </div>

            </DialogContent>

        </Dialog>)
}
