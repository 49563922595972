import { CloudUpload } from '@mui/icons-material';
import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
interface SelectFolderHolderProps {
    title?: string;
}
export default function SelectFolderHolder(props: SelectFolderHolderProps) {
    const { title } = props;

    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    return <div style={{
        display: 'flex', justifyContent: 'center', marginBottom: '120px', marginTop: '120px',
        flexDirection: 'column', alignItems: 'center', position: 'relative', //top: '500', left: 'calc(50% - 150px)',
        fontSize: '32px'
    }}><div style={{ fontSize: '64px' }}><CloudUpload fontSize='inherit' width='500' height='500' /></div>
        {biggerThan700 && <div>{title}</div>}</div>
}