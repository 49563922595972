import { AddCircleOutline, Delete, Refresh, SaveAs, Share, Star, StarOutline } from '@mui/icons-material';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Tooltip } from '@mui/material';
import { height } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { APIUsersHelper } from '../../../Api/ApiUsersHelper';
import { GridSettingsDTO, GridType } from '../../../Api/UsersMicroserviceClient';
import { useTranslation } from 'react-i18next';

export interface EditGridViewsDialogProps {
    settings: GridSettingsDTO[] | null;
    onClose: () => void;
    open: boolean;
    favouritePropsId?: number | undefined;
    settingActual?: GridSettingsDTO | null;
    gridType: GridType;
}
export default function EditGridViewsDialog(props: EditGridViewsDialogProps, ref: any) {
    const { onClose, open, settings, favouritePropsId, settingActual, gridType } = props;
    const [selectedState, setSelectedState] = useState<GridSettingsDTO | null>();
    const [newSettingName, setNewSettingName] = useState("");
    const [editedSettingName, setEditedSettingName] = useState("");
    const [favouriteId, setFavouriteId] = useState<number | undefined>();
    // const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        setFavouriteId(favouritePropsId);
    }, [favouritePropsId]);
    useEffect(() => {
        setSelectedState(null);
    }, [settingActual]);
    // useEffect(() => {
    //     console.log("aktál", settingActual);
    // }, [settingActual]);

    async function SaveNewPersistence() {
        if (newSettingName !== undefined && newSettingName !== null && newSettingName !== "" && settingActual !== undefined) {
            await new APIUsersHelper().InsertOrUpdateGridSettings(
                new GridSettingsDTO({
                    id: 0,
                    name: newSettingName,
                    gridData: settingActual!.gridData,
                    isFavouriteInGroup: false, isGroupingEnabled: settingActual!.isGroupingEnabled, isToolbarEnabled: settingActual!.isToolbarEnabled
                }), gridType);
            onClose();
        }


    }
    async function SaveUpdated() {
        if (editedSettingName !== undefined && editedSettingName !== null && editedSettingName !== "" && selectedState != null) {
            await new APIUsersHelper().InsertOrUpdateGridSettings(
                new GridSettingsDTO({
                    id: selectedState!.id,
                    name: editedSettingName
                }), gridType);
            onClose();
        }
    }

    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Nastavení seznamů - stavario"}
            </DialogTitle>
            <DialogContent>
                <div>
                    <div style={{ display: 'flex' }}>
                        <div >
                            {settings &&
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {settings.map(x => (
                                        <div key={x.id}>
                                            <Button sx={{ width: '200px' }} onClick={() => {
                                                console.log("setstare", x.id);
                                                setSelectedState(x);
                                                setEditedSettingName(x.name ? x.name : "");
                                            }}>{x.name}</Button>
                                            <IconButton>
                                                <Delete />
                                            </IconButton>
                                            <Tooltip title={t('Settings')}>
                                                <Checkbox checked={favouriteId !== undefined && x.id == favouriteId} icon={<StarOutline />} checkedIcon={<Star />} />
                                            </Tooltip>
                                            <IconButton>
                                                <Share></Share>
                                            </IconButton>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                        {/* <div>{selectedState !== null && selectedState !== undefined ? <>{selectedState!.name}</> : <></>}</div> */}
                    </div>
                    <div>
                        {selectedState !== null && selectedState !== undefined ?
                            <div style={{ display: 'flex', alignContent: 'flex-start', alignItems: 'center' }}>
                                <TextField value={editedSettingName}
                                    onChange={(e) => { setEditedSettingName(e.target.value); }}
                                    size='small' variant='standard' label={t('changeSettingsName')} sx={{ marginTop: '12px', marginLeft: '12px', width: '75%' }}></TextField>
                                <IconButton onClick={SaveUpdated} style={{ marginTop: '22px' }}>
                                    <SaveAs></SaveAs>
                                </IconButton>
                                <IconButton onClick={() => setSelectedState(null)} style={{ marginTop: '22px' }}>
                                    <Refresh />
                                </IconButton>

                            </div> :
                            <div>
                                <TextField value={newSettingName} size='small' variant='standard'
                                    onChange={(e) => { setNewSettingName(e.target.value); }}
                                    label="xxx přidat nastavení (název)" sx={{ marginTop: '12px', marginLeft: '12px', width: '75%' }}></TextField>
                                <IconButton onClick={SaveNewPersistence} style={{ marginTop: '20px' }}>
                                    <AddCircleOutline />
                                </IconButton>
                            </div>}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('close')}</Button>
                {/* <Button onClick={onDeleteAgree} autoFocus>
                    Uložit
                </Button> */}
            </DialogActions>
        </Dialog >);
}