import { Grid3x3, Note, TableBar, TableView, Widgets } from '@mui/icons-material';
import { Box, Button, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { ColumnDirective, ColumnsDirective, EditSettingsModel, GridComponent, IEditCell, Inject, ToolbarItems, Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Edit } from '@syncfusion/ej2-react-grids';
import { ChangeEventArgs, RichTextEditorComponent, Inject as InjectRT, Toolbar as ToolbarRT, Link, HtmlEditor, QuickToolbar, } from '@syncfusion/ej2-react-richtexteditor';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIVovInvestorHelper } from '../../../../ApiOld/ApiInvestorOldClient';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { GetMaterialsForRecordTableDTO, SaveMaterialsTableReqDTO } from '../../../../ApiOld/ApiServerVov';
import { useDiaryMaterialsCopyStore } from '../../../../Contexts/DiaryMaterialsCopyZContext';
import { useDiaryStore } from '../../../../Contexts/DiaryZContext';
import InitSfGridLocale from '../../../../Localization/SfGridLanguageInitializer';
import standardFilterOptions from '../../../Shared/Grids/StandardFilterOptions';
import { TabPanel } from '../../../Shared/Tabs/TabPanel';
import tabProps from '../../../Shared/Tabs/TabProps';
import { Accordion, AccordionDetails, AccordionSummary } from '../Styles/AccordionStyles';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import DiaryMaterialsExportExcelDialog from '../DiaryMaterialsExportExcelDialog';
import { truncate } from 'fs/promises';

InitSfGridLocale();

interface MaterialsWidgetProps {
    buildingDiaryRecordId: number | undefined;
    buildingDiaryId: number | undefined;
    currentDate: Date;
    materialsNote?: string;
    materialsInput?: GetMaterialsForRecordTableDTO[];
    noteChanged?: (t: string) => void;
    onChanged?: (d: GetMaterialsForRecordTableDTO[]) => void;
    onChangeToSave?: () => void;
    onSaved?: () => void;
    isExpanded?: boolean;
    saveChanged?: boolean;
    isCopying: boolean;
    isLocked: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    refresh?: boolean;
}

export default function MaterialsWidget(props: MaterialsWidgetProps) {
    const { t, i18n } = useTranslation();
    const { buildingDiaryRecordId, materialsInput, materialsNote, noteChanged, onChanged,
        isExpanded, saveChanged, isCopying, isLocked, isInvestor, isSubdodavatel, onChangeToSave, onSaved, refresh, buildingDiaryId, currentDate } = props;
    const [materials, setMaterials] = useState<GetMaterialsForRecordTableDTO[]>();
    const [materialsNoteLocal, setMaterialsNoteLocal] = useState<string | undefined>();
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [showWysiwyg, setShowWysiwyg] = useState<boolean>(false);
    const [isGridEditting, setIsGridEditting] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [copying, setCopying] = useState<boolean>(false);
    const [excelExportOpen, setExcelExportOpen] = useState<boolean>(false);
    const { materialsStore } = useDiaryMaterialsCopyStore();

    const saving = useRef<boolean>(false);

    const theme = useTheme();
    const { permissions } = useDiaryStore();
    const editSettings: EditSettingsModel = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch', showConfirmDialog: false };
    /* const toolbarOptions: ToolbarItems[] = ['Add', 'Delete', 'Update', 'Cancel',,
    { text: t('diary.AddWorkers'), tooltipText: 'Načíst osoby se systému', prefixIcon: 'e-expand', id: 'loadWorkersFromSystem' }]; */
    const toolbarOptions: object[] = [
        { text: t('Add'), id: 'grid-component-materials_add', prefixIcon: 'e-add' },
        { text: t('Delete'), id: 'grid-component-materials_delete', prefixIcon: 'e-delete' },
        { text: t('save'), id: 'grid-component-materials_update', prefixIcon: 'e-update' },
        { text: t('Cancel'), prefixIcon: 'e-cancel', id: 'grid-component-materials_cancel' },
        { text: t('Export'), prefixIcon: 'e-export', id: 'grid-component-materials_excelexport' },
        { text: t('diary.exportExcelAll'), prefixIcon: 'e-export', id: 'exportExcel' }];

    const toolbarOptionsNoDelete: object[] = [
        { text: t('Add'), id: 'grid-component-materials_add', prefixIcon: 'e-add' },
        { text: t('save'), id: 'grid-component-materials_update', prefixIcon: 'e-update' },
        { text: t('Cancel'), prefixIcon: 'e-cancel', id: 'grid-component-materials_cancel' },
        { text: t('Export'), prefixIcon: 'e-export', id: 'grid-component-materials_excelexport' },
        { text: t('diary.exportExcelAll'), prefixIcon: 'e-export', id: 'exportExcel' }];

    useEffect(() => {
        if (isLocked) {
            if (document.getElementById('grid-component-materials_add'))
                document.getElementById('grid-component-materials_add')!.className += ' e-disabled';
            if (document.getElementById('grid-component-materials_delete'))
                document.getElementById('grid-component-materials_delete')!.className += ' e-disabled';
            if (document.getElementById('grid-component-materials_update'))
                document.getElementById('grid-component-materials_update')!.className += ' e-disabled';
            if (document.getElementById('grid-component-materials_cancel'))
                document.getElementById('grid-component-materials_cancel')!.className += ' e-disabled';
        }
    }, [isLocked]);

    useEffect(() => {
        if (refresh === true)
            reloadData();
    }, [refresh]);

    useEffect(() => {
        setMaterials(materialsStore);
    }, [materialsStore]);
    useEffect(() => {
        setCopying(isCopying);
    }, [isCopying]);
    useEffect(() => {
        setMaterialsNoteLocal(materialsNote);
    }, [materialsNote]);
    useEffect(() => {
        reloadData();
    }, [buildingDiaryRecordId]);
    useEffect(() => {
        if (expanded === true)
            reloadData();
    }, [expanded]);
    function reloadData() {
        if (isCopying) {
            setMaterials(materialsStore);
            saving.current = false;
        }
        else {
            if (buildingDiaryRecordId !== undefined)
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetMaterialsForRecord(buildingDiaryRecordId, false).then((d) => {
                    if (d !== undefined && d !== null) {
                        if (grid.current)
                            grid.current!.dataSource = d;
                        setMaterials(d);
                    }
                    saving.current = false;
                    // console.log('workers setted', d);
                });
            else
                saving.current = false;
        }
        // else {
        //     console.log('state is ready');
        //     console.log(grid.current!.dataSource);
        //     // grid.current!.dataSource = materials;

        // }
    }
    useEffect(() => {
        if (saveChanged && grid.current)
            grid.current!.endEdit();
    }, [saveChanged]);
    useEffect(() => {
        if (isExpanded !== undefined)
            setExpanded(isExpanded);
    }, [isExpanded]);
    useEffect(() => {
        if (materialsInput)
            setMaterials(materialsInput);
    }, [materialsInput]);
    const grid = useRef<GridComponent>(null);
    function handleEditAndSave(args: any): void {
        // console.log(args);

    }
    function beforeDeleteAsk(e: any) {
        // e.cancel = true;
    }
    function save(e: any) {
        e.cancel = true;
        if (saving.current) return;
        saving.current = true;
        if (!copying && buildingDiaryRecordId) {
            let added = e.batchChanges.addedRecords.map((add: any) => {
                return new SaveMaterialsTableReqDTO({ id: 0, name: add.name, amount: add.amount, isShared: add.isShared, unit: add.unit })
            });
            let changed = e.batchChanges.changedRecords.map((chang: any) => {
                return new SaveMaterialsTableReqDTO({ id: chang.id, name: chang.name, amount: chang.amount, isShared: chang.isShared, unit: chang.unit })
            });
            let deleted = permissions?.canDeleteInsideRecords === true ? e.batchChanges.deletedRecords.map((del: any) => {
                return new SaveMaterialsTableReqDTO({ id: -del.id, name: del.name, unit: del.unit })
            }) : [];
            let b: SaveMaterialsTableReqDTO[] = [...added, ...changed, ...deleted];
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).saveMaterials(buildingDiaryRecordId, b).then((r) => {
                if (r) reloadData(); else saving.current = false; if (onSaved) onSaved();
            })
            if (onChanged)
                onChanged(b);
            // console.log(e);

            // e.batch
        }
        else {
            let added = e.batchChanges.addedRecords.map((add: any) => {
                return new GetMaterialsForRecordTableDTO({ id: 0, name: add.name, amount: add.amount, isShared: add.isShared, unit: add.unit })
            });
            let changed = e.batchChanges.changedRecords.map((chang: any) => {
                return new GetMaterialsForRecordTableDTO({ id: chang.id, name: chang.name, amount: chang.amount, isShared: chang.isShared, unit: chang.unit })
            });
            let deleted = permissions?.canDeleteInsideRecords === true ? e.batchChanges.deletedRecords.map((del: any) => {
                return new GetMaterialsForRecordTableDTO({ id: -del.id, name: del.name, unit: del.unit })
            }) : [];
            let b: GetMaterialsForRecordTableDTO[] = [...added, ...changed, ...deleted];
            if (onChanged)
                onChanged(b);
            saving.current = false;
        }
    }

    const numericParams: IEditCell = {
        params: {
            decimals: 2,
            format: "N2",
            validateDecimalOnType: true,
            locale: i18n.language

        }
    };
    function clickHandler(e: any) {
        let instance = (document.getElementById("grid-component-materials") as HTMLElement);
        if ((e.target as HTMLElement).classList.contains("e-rowcell")) {

            //@ts-ignore

            //@ts-ignore
            let index: number = parseInt((e.target as HTMLElement).parentElement.getAttribute("aria-rowindex"));
            console.log((e.target as HTMLElement));
            //@ts-ignore
            let colindex: number = parseInt((e.target as HTMLElement).getAttribute("data-colindex"));
            //@ts-ignore
            let field: string = instance.ej2_instances[0].getColumns()[colindex].field;
            // if (index === grid.current!.recordsCount - 1)
            //     delay(800).then(() => { grid.current!.editCell(index - 1, field); });
            // else 
            grid.current!.editCell(index - 1, field);

            // instance.ej2_instances[0].editModule.editCell((index - 1), field);
        };
    }
    const loaded = useRef(false);
    const singleClickLoad = (id: string): void => {
        //@ts-ignore
        let instance = (document.getElementById(id) as HTMLElement);
        console.log(instance);
        if (instance) {
            if (!loaded.current) {
                instance.removeEventListener('mouseup', clickHandler);
                instance.addEventListener('mouseup', clickHandler);
                loaded.current = true;
            }
        }
    }
    function setDefaultData(e: any) {
        e.defaultData.id = 0;
        e.defaultData.name = '';
        e.defaultData.amount = 0;
        e.defaultData.unit = '';
        e.defaultData.isShared = true;
        e.defaultData.adminCreatorName = '';

    }
    function toolBarClick(e: any) {
        let id = e.item.properties.id;
        if (id === 'exportExcel')
            setExcelExportOpen(true);
        else if (isLocked) {
            e.cancel = true;
            return;
        }
        if (grid.current !== undefined && e.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }

    return (
        <Accordion className='expander' expanded={expanded} onChange={(e) => { setExpanded(!expanded) }}>
            <AccordionSummary className='expander-header' aria-controls="panel4d-content" id="panel4d-header">
                <Widgets color='secondary' className='accordion-icon' />
                <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('diary.materials')}</Typography>
                {materials && <Typography color={theme.palette.text.primary} sx={{ marginLeft: '10px' }} fontWeight='bold'>{' (' + materials.length + ')'}</Typography>}
            </AccordionSummary>
            <AccordionDetails>
                <Box >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedTabIndex} onChange={(e, n) => { setSelectedTabIndex(n) }} aria-label="basic tabs example">
                            <Tab label={t('table')} {...tabProps(0)} />
                            {isGridEditting === false && isSubdodavatel !== true && <Tab label={t('note')} {...tabProps(1)} />}
                        </Tabs>
                    </Box>
                    <TabPanel value={selectedTabIndex} index={0}>
                        {/* <div style={{ display: selectedTabIndex === 0 ? 'block' : 'none' }}> */}
                        <GridComponent style={{ borderColor: 'transparent', margin: '-10px -24px' }} id="grid-component-materials" allowSorting={true}
                            beforeBatchDelete={(e) => { beforeDeleteAsk(e) }}
                            dataSourceChanged={(e) => { grid.current!.refresh(); }}
                            beforeBatchAdd={(e) => { setDefaultData(e); setIsGridEditting(true); }}
                            beforeBatchSave={(e) => { save(e); setIsGridEditting(false); }}
                            cellEdit={(e) => { setIsGridEditting(true); if (onChangeToSave) onChangeToSave(); }}

                            toolbarClick={toolBarClick}
                            ref={grid}
                            load={(e) => { singleClickLoad("grid-component-materials") }}
                            // dataBound={(e) => { if (grid.current) grid.current.autoFitColumns([]); }}
                            editSettings={editSettings}
                            toolbar={(permissions && permissions.canEditDiaryRecord && !isLocked) ? (permissions.canDeleteInsideRecords ? toolbarOptions : toolbarOptionsNoDelete) : undefined}
                            allowReordering={true} allowResizing={true} showColumnChooser={true}
                            allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                            locale={i18n.language}
                            enablePersistence={false}
                            filterSettings={standardFilterOptions}
                            actionBegin={handleEditAndSave}
                            resizeSettings={{ mode: 'Normal' }}
                            selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                            allowFiltering={false}
                            dataSource={materials}

                        >
                            <ColumnsDirective>
                                <ColumnDirective field='id' width='46' headerText="ID" textAlign="Right" isPrimaryKey visible={false} />
                                <ColumnDirective field='name' headerText={t('Name')} maxWidth='800' width='300' minWidth='200' />
                                <ColumnDirective field='amount' editType='numericedit' maxWidth='400' textAlign='Right' edit={numericParams}
                                    headerText='Množství' width='100' format='N2' />
                                <ColumnDirective field='unit' headerText='Jednotka' maxWidth='800' width='300' minWidth='200' />
                                <ColumnDirective field='isShared' type='boolean' editType='booleanedit' displayAsCheckBox allowEditing headerText={t('diary.sharedEntry')} width='40' minWidth='200' />
                                <ColumnDirective field='adminCreatorName' headerText={t('CreatedBy')} allowEditing={false} maxWidth='800' width='300' minWidth='200' />
                                <ColumnDirective field='created' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Created')} maxWidth='800' width='300' minWidth='200' />
                                <ColumnDirective field='lastUpdate' type='datetime' format='dd.MM.yyyy HH:mm' allowEditing={false} headerText={t('Updated')} maxWidth='800' width='300' minWidth='200' />
                            </ColumnsDirective>
                            <Inject services={[Sort, Group, Filter, Search, Toolbar, ExcelExport, Reorder, Resize, ColumnChooser, Edit]} />
                        </GridComponent>
                        {/* </div> */}
                    </TabPanel>
                    <TabPanel value={selectedTabIndex} index={1}>
                        <div style={{ margin: '-10px -24px' }}>
                            <RichTextEditorComponent actionBegin={(e) => {
                                console.log(e);
                            }} height={400} locale={i18n.language} value={materialsNoteLocal} change={(eve: ChangeEventArgs) => {
                                if (isSubdodavatel !== true) {
                                    setMaterialsNoteLocal(eve.value);
                                    if (noteChanged)
                                        noteChanged(eve.value);
                                }
                            }} >
                                <InjectRT services={[ToolbarRT, Link, HtmlEditor, QuickToolbar]} />
                            </RichTextEditorComponent>
                        </div>
                    </TabPanel>
                    <DiaryMaterialsExportExcelDialog open={excelExportOpen} diaryId={buildingDiaryId} onClose={() => setExcelExportOpen(false)} currentDate={currentDate} />
                </Box>

            </AccordionDetails></Accordion>);

}


