import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import DiaryPager from './DiaryPager';

interface DiaryInvestorPagerProps {


}
export default function DiaryInvestorPager(props: DiaryInvestorPagerProps) {
    const [diaryId, setDiaryId] = useState<number | undefined>(undefined);
    const [recordId, setRecordId] = useState<number | undefined>(undefined);
    const params = useParams();

    useEffect(() => {
        if (params.diaryId) {
            setDiaryId(parseInt(params.diaryId));
        }
        if (params.recordId) {
            setRecordId(parseInt(params.recordId));
        }
    }, []);
    return <><DiaryPager diaryId={diaryId} recordId={recordId} /></>
}