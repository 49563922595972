import { AttachFile, Close, CloseFullscreen, ColorLens, Delete, EventRepeat, Fullscreen, History, MoreHorizOutlined, Save, SaveAs, Troubleshoot, Watch } from '@mui/icons-material';
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogTitle, IconButton, Popover, styled, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { ChangeEventArgs, HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import React, { useEffect, useRef, useState } from 'react';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { Marker } from 'react-image-marker';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import { BeforeUploadEventArgs, FileInfo, UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { APIOldBuildingsClient } from '../../../../ApiOld/ApiOldBuildingsClient';

export interface CreateFolderDialogProps {
    open: boolean;
    buildingId: number;
    parentId: number | undefined;
    onClose: (value: boolean) => void;
}


export default function CreateFolderDialog(props: CreateFolderDialogProps) {
    const { onClose, open, buildingId, parentId } = props;
    const { t, i18n } = useTranslation();
    const biggerThan600 = useMediaPredicate("(min-width: 600px)");
    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState('');

    useEffect(() => {
        setLoading(false);
    }, []);

    async function saveFolder(): Promise<boolean> {
        try {
            if (name.length > 0) {
                setLoading(true);
                let t = await (new APIOldBuildingsClient()).CreateProjectFile(buildingId, parentId, name);
                if (t) {
                    onClose(true);
                    //setTask({ ...task, id: t.id, rowVersion: t.rowVersion });
                    // setTask({ ...task, rowVersion: t.rowVersion });
                    //nesmí se volat dvakrát za sebou kvůli async chování setstate
                }
                setLoading(false);
            }
            return true;
        }
        catch
        {
            setLoading(false);
            return false;
        }
    }

    async function onSaveClicked() {
        var res = await saveFolder();
    }

    const [fullScreen, setFullScreen] = useState(false);

    interface CustomMarker extends Marker {
        type: "success" | "error" | "warning";
        content: string;
        deviceId?: string | number;
    }


    return (
        <Dialog fullWidth maxWidth="lg"
            fullScreen={fullScreen} open={open} onClose={() => onClose(false)}
            sx={{ width: biggerThan600 ? '60%' : '100%', margin: 'auto', marginTop: '40px' }}>
            <DialogTitle height='40px' sx={{ zIndex: "28", backgroundColor: '#F5F5F5' }}>
                <div style={{ backgroundColor: '#F5F5F5', zIndex: "200" }}>
                    <div style={{ float: 'left', display: 'flex' }}>
                        <Typography variant='h1' fontWeight='bold' fontSize={20}>{t('files.createFolder')} </Typography>
                    </div>
                    <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>


                        <div style={{ marginTop: '-14px' }}>
                            <IconButton onClick={() => onClose(false)} >
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </DialogTitle>
            {loading && <div style={{ margin: 'auto', minHeight: '400px', minWidth: '350px', display: 'block' }}>
                {/* <div>
                    <Save sx={{ width: '30px', height: '30px', marginTop: '80px', marginBottom: '10px' }} />
                </div> */}
                <div style={{ fontSize: '20px', marginBottom: '10px', marginTop: '80px' }}>
                    {t('savingDots')}
                </div>
                <div>
                    <CircularProgress sx={{ opacity: '1', width: '16px', height: '16px', margin: 'auto', marginLeft: '25px' }} />
                </div>
            </div>
            }
            {!loading && <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F5F5F5' }} >
                <ValidationGroup>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', margin: "10px" }}>
                            <Validate name='name-todo-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                <TextField
                                    error={name === ''}
                                    fullWidth sx={{ width: '95%', minWidth: '95%', margin: "10px", marginTop: "6px", marginBottom: '6px', backgroundColor: '#FFFFFF' }}
                                    onChange={e => setName(e.target.value)}
                                    label={(t('Name'))} value={name} />
                            </Validate>
                        </div>
                        <div style={{ height: '1px', marginTop: '4px', backgroundColor: '#F5F5F5' }}></div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ margin: 'auto' }}>
                                <Button onClick={() => { onSaveClicked() }} sx={{ backgroundColor: '#FFC600', color: 'black', justifyContent: "flex-start", margin: "16px", marginBottom: "32px" }}>{t('files.createFolder')}</Button>
                            </div>

                        </div>
                    </div>
                </ValidationGroup>
            </div >}
        </Dialog >
    );
};
