import { Check, Devices, Handshake, HourglassBottom, Mail, Phone, PhoneCallback } from '@mui/icons-material';
import { Button, Checkbox, FormControl, FormControlLabel, Input, InputAdornment, InputLabel, TextField, ThemeProvider, Typography } from '@mui/material';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';
import { ReactElement } from 'react-imask/dist/mixin';
import { useMediaPredicate } from 'react-media-hook';
import { useParams } from 'react-router';
import TelephoneSelect from '../../Shared/LanguageSupport/TelephoneSelect';
import createThemeStavario from '../../Shared/Themes/CreateTheme';
import loaderGif from '../../../Assets/loader.gif'
import logo from '../../../Assets/logo.png'
import { ApplicationCreateRequestDTO, CreateAppFromInviteRequestDTO, GetDataForInviteResponseDTO } from './OldApi/ApiServer';
import { OldApiClient } from './OldApi/OldApiClientHelper';

interface AcceptInviteProps {

}
interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}
const TextMaskCustom = React.forwardRef<ReactElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput

                {...other}
                mask="(#00) 000-0000"
                definitions={{
                    '#': /[1-9]/,
                }}

                inputRef={ref!}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

export default function AcceptInvite(props: AcceptInviteProps) {
    const theme = createThemeStavario();
    const [agree, setAgree] = useState<boolean>(true);
    const params = useParams();
    const [recomId, setRecomId] = useState<number | null>(null);
    const [domain, setDomain] = useState<string | null>(null);
    const [oldToken, setOldToken] = useState<string | null>(null);
    const [dataContext, setDataContext] = useState<GetDataForInviteResponseDTO | null>(null);
    const [saveContext, setSaveContext] = useState<Partial<CreateAppFromInviteRequestDTO>>(new CreateAppFromInviteRequestDTO());
    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const [accepting, setAccepting] = useState(false);
    const licenseAgreement = 'https://www.stavario.com/' + i18n.language + '/obchodni-podminky/';
    function acceptInvite() {
        setAccepting(true);

    }
    useEffect(() => {
        i18n.changeLanguage(params['language']);
    }, []);
    useEffect(() => {
        if (accepting) {
            /* console.log(new CreateAppFromInviteRequestDTO(
                { language: 'cs', phonePrefix: saveContext.phonePrefix, companyName: saveContext.companyName!, email: saveContext.email!, firstname: saveContext.firstname, lastname: saveContext.lastname, phone: saveContext.phone, recommendationId: recomId!, token: oldToken! })); */
            new OldApiClient().GetUnauthenticatedOldClient(params['domain']!)
                .apiV2_7RecommendationsCreateApplicationFromInvite(new CreateAppFromInviteRequestDTO(
                    {
                        source: 'invite',
                        language: i18n.language,
                        phonePrefix: saveContext.phonePrefix,
                        companyName: saveContext.companyName!,
                        email: saveContext.email!,
                        firstname: saveContext.firstname,
                        lastname: saveContext.lastname,
                        phone: saveContext.phone,
                        recommendationId: recomId!,
                        token: oldToken!
                    })).then((redirData) => {
                        window.location.href = 'https://' + redirData.url + '.vimovsem.cz/sign/autologin?key=' + redirData.key;
                    });
        }

    }, [accepting]);
    useEffect(() => {
        //console.log(params);
        setSaveContext({ ...saveContext, phonePrefix: 'CZ' });
        setRecomId(parseInt(params['id']!));
        setDomain(params['domain']!);
        setOldToken(params['token']!);
        new OldApiClient().GetUnauthenticatedOldClient(params['domain']!)
            .apiV2_7RecommendationsGetDataForInvite(parseInt(params['id']!), params['token']!)
            .then((data) => { setDataContext(data); /* console.log(data); */ });
    }, [params]);
    if (accepting)
        return (<ThemeProvider theme={theme}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: '40px' }}>
                <img src={logo} style={{ margin: '12px' }} width={200} height={52} alt="logo..." />
                <Typography sx={{ margin: '12px' }} variant='h2'>{t('sales.demoWaiting')}</Typography>
                <div style={{ display: 'flex', margin: '10px' }}>
                    <Check color='success' sx={{ marginRight: '8px', marginTop: '-2px' }} />
                    <Typography variant='body2'>{t('sales.registrationCompleted')}</Typography>
                </div>
                <div style={{ display: 'flex', margin: '10px' }}>
                    <Mail color='success' sx={{ marginRight: '8px', marginTop: '-2px' }} />
                    <Typography variant='body2'>{t('sales.loginToMail')}</Typography>
                </div>
                <div style={{ display: 'flex', margin: '10px' }}>
                    <HourglassBottom color='primary' sx={{ marginRight: '8px', marginTop: '-2px' }} />
                    <Typography variant='body2'>{t('sales.redirectTime')}</Typography>
                </div>
                <img src={loaderGif} width={190} height={160} alt="loading..." /></div></ThemeProvider>);
    else return (<ThemeProvider theme={theme}>
        <ValidationGroup>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundImage: 'url("https://www.stavario.com/wp-content/uploads/2022/09/pozadi.png")' }}>
                <div>
                    <p style={{ fontFamily: 'Uni Neue', fontSize: '40px', textAlign: 'center', fontWeight: 'bold', maxWidth: '780px' }}> {t('sales.try')} <mark style={{ color: theme.palette.primary.main, fontWeight: 'bold', background: 'white' }}>
                        Stavario</mark>{t('sales.freeDays')}</p>
                </div>
                <div style={{
                    borderWidth: '3px', padding: '32px', margin: '16px', display: 'flex', flexDirection: 'column',
                    borderStyle: 'solid', borderRadius: '8px', borderColor: theme.palette.primary.main, alignItems: 'center',
                    justifyContent: 'center', backgroundColor: 'white'
                }}>
                    <p style={{ fontFamily: 'Uni Neue', fontSize: '32px', textAlign: 'center', fontWeight: 'bold', maxWidth: '440px' }}>{t('sales.buildTogether')}<mark style={{ color: theme.palette.primary.main, fontWeight: 'bold', background: 'white', marginLeft: '-4px' }}>
                        {t('sales.createDemo')}</mark></p>

                    <TextField fullWidth
                        size='small'
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                            "& .MuiInputBase-input": {
                                overflow: "hidden",
                                textOverflow: "ellipsis",

                            },
                            '& .MuiInputBase-root': {
                                color: "black",
                                backgroundColor: theme.palette.primary.main,
                                borderColor: theme.palette.primary.main,
                                borderWidth: '3px'
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: theme.palette.primary.main,
                                borderWidth: '3px'
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: theme.palette.primary.main,
                                    borderWidth: '3px'
                                },
                                '&:hover fieldset': {
                                    borderColor: theme.palette.primary.main,
                                    borderWidth: '3px',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.primary.main,
                                    borderWidth: '3px'
                                },
                            },
                            minWidth: '288px'
                        }}
                        inputProps={{ style: { fontWeight: 'bold', color: theme.palette.text.primary } }}
                        value={'Váš parťák - ' + (dataContext !== undefined && dataContext != null && dataContext.invitedPersonName !== undefined ? dataContext!.invitedPersonName : '') + ' Vás pozval k digitalizaci stavebnictví'}
                        margin='dense'
                    ></TextField>
                    <Validate name='name-company-validate' required={[true, t('FieldRequired')]} >
                        <TextField size='small' fullWidth label={t('sales.companyName')}
                            value={saveContext!.companyName}
                            onChange={(e) => {
                                setSaveContext({ ...saveContext, companyName: e.target.value });
                            }}
                            margin='dense'></TextField>
                    </Validate>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'stretch' }}>
                        <Validate name='name-company-validate' required={[true, t('FieldRequired')]} >
                            <TextField size='small' fullWidth={!biggerThan700} sx={{ minWidth: '280px' }} label={t('YourName')}

                                value={saveContext!.firstname}
                                onChange={(e) => {
                                    setSaveContext({ ...saveContext, firstname: e.target.value });
                                }}
                                margin='dense'></TextField>
                        </Validate>
                        <Validate name='name-company-validate' required={[true, t('FieldRequired')]} >
                            <TextField size='small' fullWidth={!biggerThan700} sx={{ minWidth: '280px', marginLeft: biggerThan700 ? '12px' : '0px' }}
                                value={saveContext!.lastname}
                                onChange={(e) => {
                                    setSaveContext({ ...saveContext, lastname: e.target.value });
                                }}
                                label={t('YourLastname')} margin='dense'></TextField>
                        </Validate>
                    </div>
                    <Validate name='name-company-validate' required={[true, t('FieldRequired')]} >
                        <TextField size='small' fullWidth label={t('login.enterEmail')}
                            value={saveContext!.email}
                            onChange={(e) => {
                                setSaveContext({ ...saveContext, email: e.target.value });
                            }}
                            margin='dense'></TextField>
                    </Validate>
                    <div style={{
                        display: 'flex', flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'start', alignItems: 'start', width: '100%'
                    }}>
                        <TelephoneSelect defaultValue='CZ' onChangeCompleted={(tel) => {
                            if (tel !== null && tel != undefined) {
                                console.log(tel!.code);
                                setSaveContext({ ...saveContext, phonePrefix: tel!.code });
                            }
                            else setSaveContext({ ...saveContext, phonePrefix: undefined });
                        }} />
                        <TextField
                            onChange={(e) => {
                                setSaveContext({ ...saveContext, phone: e.target.value });
                            }}
                            fullWidth={!biggerThan700}
                            placeholder={t('EnterPhoneNumber')}
                            InputProps={{ startAdornment: (<InputAdornment position='start'><Phone /></InputAdornment>) }}
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, minWidth: '300px', marginTop: '8px', marginLeft: biggerThan700 ? '10px' : '0px', width: biggerThan700 ? 'calc(100% - 210px)' : '100%' }}
                            size='small'
                            value={saveContext!.phone}

                        >
                            {/* <InputMask mask="(0)999 999 99 99" maskChar=" " /> */}
                        </TextField>
                        {/* <FormControl variant="standard">
                            <InputLabel htmlFor="formatted-text-mask-input">react-imask</InputLabel>
                            <Input
                                value={saveContext!.phone}
                                onChange={(e) => {
                                    setSaveContext({ ...saveContext, phone: e.target.value });
                                }}
                            
                                name="textmask"
                                id="formatted-text-mask-input"
                                inputComponent={TextMaskCustom as any}
                            />
                        </FormControl> */}




                    </div>

                    <FormControlLabel control={

                        // <Validate name='name-todo-validate' custom={[() => !!agree, ""]} >
                        <Checkbox checked={agree} onChange={(e, r) => {
                            setAgree(r);
                        }} />
                        // </Validate>
                    }
                        // label={
                        //     <Typography variant="body2" color={!!agree ? theme.palette.text.secondary : theme.palette.error.main}>{t('sales.marketingAgree')}
                        //     </Typography>} />
                        label={
                            <Typography variant="body2" color={theme.palette.text.secondary}>{t('sales.marketingAgree')}
                            </Typography>} />

                    <p style={{ fontWeight: 'lighter' }}>{t('sales.byPressButton')}
                        <mark style={{ color: theme.palette.text.primary, fontWeight: 'bold', background: 'white' }}>"{t('sales.createDemo')}"</mark>{t('sales.agreeOurs')}
                        <a href={licenseAgreement}
                            style={{ color: theme.palette.primary.main, fontWeight: 'bold', background: 'white', marginLeft: '4px' }}>{t('sales.termsConditions')}</a>
                    </p>
                    <AutoDisabler>
                        <Button onClick={acceptInvite} variant='contained' >{t('sales.createDemo')}</Button>
                    </AutoDisabler>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 20, justifyContent: 'center', fontFamily: 'Uni Neue' }}>
                    <h1>{t('sales.learnDigitize')}</h1>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
                        <div style={{ margin: '20px', borderRadius: '16px', overflow: 'hidden' }}>
                            <iframe width="360"
                                height="315" src="https://www.youtube.com/embed/an6wIxwCzP8" title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                            </iframe>
                        </div>
                        <div style={{ margin: '20px', borderRadius: '16px', overflow: 'hidden' }}>
                            <iframe width="360"
                                height="315" src="https://www.youtube.com/embed/t3VknP_JCPk" title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                            </iframe>
                        </div>
                        <div style={{ margin: '20px', borderRadius: '16px', overflow: 'hidden' }}>
                            <iframe width="360"
                                height="315" src="https://www.youtube.com/embed/0dIzruBvRG8" title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                            </iframe>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 20, justifyContent: 'center', fontFamily: 'Uni Neue' }}>
                    <h1 style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>{t('sales.consultWithUs')}</h1>
                    <h5>{t('sales.menuThisYear')}</h5>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'start' }}>
                        <div style={{ minWidth: '260px', maxWidth: '360px', margin: '20px', borderRadius: '16px', overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '260px' }}>
                                <PhoneCallback style={{ width: '52px', height: '52px', color: theme.palette.primary.main, margin: '20px' }} />
                                <h2 style={{ fontWeight: 'bold' }}>{t('sales.contactSupport')}</h2>
                                <h5>{t('sales.liveSupport')}</h5>
                            </div>
                            <a href={"https://stavario.com/" + i18n.language + "/kontakt"} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
                                <Button variant='contained' sx={{ marginBottom: '20px' }}>{t('sales.openContacts')}</Button>
                            </a>
                        </div>
                        <div style={{ minWidth: '260px', maxWidth: '360px', margin: '20px', borderRadius: '16px', overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '260px' }}>
                                <Devices style={{ width: '52px', height: '52px', color: theme.palette.primary.main, margin: '20px' }} />
                                <h2 style={{ fontWeight: 'bold' }}>{t('sales.dealOnlineMeeting')}</h2>
                                <h5>{t('sales.hourOnline')}</h5>
                            </div>
                            <a href={"https://stavario.com/" + i18n.language + "/kontakt"} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
                                <Button variant='contained' sx={{ marginBottom: '20px' }}>{t('sales.wantPresentationOnline')}</Button>
                            </a>
                        </div>
                        <div style={{ minWidth: '260px', maxWidth: '360px', margin: '20px', borderRadius: '16px', overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '260px' }}>
                                <Handshake style={{ width: '52px', height: '52px', color: theme.palette.primary.main, margin: '20px' }} />
                                <h2 style={{ fontWeight: 'bold' }}>{t('sales.orPersonalConsult')}</h2>
                                <h5>{t('sales.personalFitsYou')}</h5>
                            </div>
                            <Button variant='contained' sx={{ marginBottom: '20px' }}>{t('sales.wantPersonalMeeting')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationGroup>
    </ThemeProvider >)
}