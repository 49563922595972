import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { AuthRequest, AuthResponse, UsersClient } from './Api/UsersMicroserviceClient';
import { BrowserRouter, Routes, Route, createBrowserRouter, createRoutesFromElements, RouterProvider, useLocation } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import Login from './Components/Forms/LoginScene';
import ProjectsScene from './Components/Scenes/ProjectsScene';
import ClippedDrawer from './Components/Scenes/MainScene';
import TodoScene from './Components/Scenes/TodoScene';
import BasicTabs from './Components/Scenes/DashboardScene';
import DiaryMainScene from './Components/Scenes/DiaryMainScene';
import ProtectedRoute from './Components/Shared/ProtectedRoute';
import PDFViewer from './Components/Shared/FileViewers/PDFViewer';
import ErrorHandlerScene from './Components/Shared/ErrorHandlerScene';
import { createTheme, ThemeProvider } from '@mui/material';
import AdminGrid from './Components/Scenes/Admin/AdminGrid';
import Recomendations from './Components/Scenes/Sales/RecomendationScene';
import createThemeStavario from './Components/Shared/Themes/CreateTheme';
import AcceptInvite from './Components/Scenes/Sales/AcceptInvite';
import LoginOldVov from './Components/Forms/LoginOldScene';
import DailyRecordsList from './Components/Scenes/DiaryComponents/DiaryRecords';
import DiaryTabs from './Components/Scenes/DiaryComponents/DiaryTabs';
import DiaryPrint from './Components/Scenes/DiaryComponents/DiaryPrints/DiaryPrint';
import DiaryPager from './Components/Scenes/DiaryComponents/DiaryPrints/DiaryPager';
import DiaryDetail from './Components/Scenes/DiaryComponents/DiaryDetail';
import { useTranslation } from 'react-i18next';
import LoginInvestorScene from './Components/Forms/LoginInvestorScene';
import InvestorTabs from './Components/Investor/InvestorTabs';
import DiaryExternal from './Components/Scenes/DiaryComponents/Externals/DiaryExternal';
import DiaryExternalDetail from './Components/Scenes/DiaryComponents/Externals/DiaryExternalDetail';
import DailyRecordsExternal from './Components/Scenes/DiaryComponents/Externals/DailyRecordsExternal';
import DiaryInvestorPager from './Components/Scenes/DiaryComponents/DiaryPrints/DiaryInvestorPager';
import { ProductFruits } from 'react-product-fruits';
import { useLoginAdminStore } from './Contexts/LoginAdminZContext';
import ResetPassword from './Components/Shared/Users/ResetPassword';
import ProtectedInvestorRoute from './Components/Shared/Auth/ProtectedInvestorRoute';
import WealthList from './Components/Scenes/Wealth/WealthList';
import WealthDetail from './Components/Scenes/Wealth/WealthDetail';
import WealthTabs from './Components/Scenes/Wealth/WealthTabs';
import TasksList from './Components/Scenes/Tasks/TasksList';
import GptChatScene from './Components/Scenes/GptChat/GptChatScene';
import DiaryExternalWithMenu from './Components/Scenes/DiaryComponents/Externals/DiaryExternalWithMenu';
import DiaryExternalWithMenuEntryPoint from './Components/Scenes/DiaryComponents/Externals/DiaryExternalWithMenuEntryPoint';
import DailyRecordsExternalWithMenu from './Components/Scenes/DiaryComponents/Externals/DailyRecordsExternalWithMenu';
import DiaryDetailExternalWithMenu from './Components/Scenes/DiaryComponents/Externals/DiaryDetailExternalWithMenu';
import DiaryMobileExternal from './Components/Scenes/DiaryComponents/Externals/DiaryMobileExternal';
import LoginSubcontractorScene from './Components/Forms/LoginSubcontractorScene';
import ProtectedSubcontractorRoute from './Components/Shared/Auth/ProtectedSubcontractorRoute';
import SubcontratorMainScene from './Components/Subcontractor/SubcontractorMainScene';
import ProjectsList from './Components/Scenes/ProjectComponents/ProjectsList';
import ProjectDetail from './Components/Scenes/ProjectComponents/ProjectDetail';
import AdminMainScene from './Components/Scenes/Admin/KrosAdminMainScene';
import AdminUserDetailScene from './Components/Scenes/Admin/Details/KrosAdminUserDetailScene';
import LoginKrosAdminScene from './Components/Forms/LoginKrosAdminScene';
import ProtectedKrosAdminRoute from './Components/Shared/Auth/ProtectedKrosAdminRoute';
import KrosAdminApplicationTabScene from './Components/Scenes/Admin/KrosAdminApplicationTabScene';
import UploadSnackbar from './Components/Shared/UploadSnackbar';
import { useUploadFileStore } from './Contexts/UploadFileContext';
import { jwtDecode } from 'jwt-decode';

import ToDoSceneOld from './Components/Scenes/ToDoSceneOld';
import SingleTodoSceneOld from './Components/Scenes/ToDoOld/SingleTodoSceneOld';
import SingleTodoTabSceneOld from './Components/Scenes/ToDoOld/SingleTodoTabSceneOld';

import InvoiceList from './Components/Scenes/InvoiceComponents/InvoiceList';
import InvoiceDetail from './Components/Scenes/InvoiceComponents/InvoiceDetail';
import { useInvestorStore } from './Contexts/InvestorZContext';
import EmployeesList from './Components/Scenes/Employees/EmployeesList';
import EmployeeDetail from './Components/Scenes/Employees/EmployeeDetail';

function App() {
  const { i18n } = useTranslation();
  const { uploadingFiles, clearFiles } = useUploadFileStore();
  // window.addEventListener('error', function (event) {
  //   // console.log('chyba', event);
  //   if (event.error.hasBeenCaught !== undefined) {
  //     return false
  //   }
  //   event.error.hasBeenCaught = true
  //   // ... your useful code here
  // });
  window.addEventListener('unhandledrejection', function (event: PromiseRejectionEvent) {
    // event.preventDefault();
    // event.stopPropagation();
    // console.log('asyncchyba', event);
    // if (event.error.hasBeenCaught !== undefined) {
    //   return false
    // }
    // event.error.hasBeenCaught = true
    // ... your useful code here
  });


  /* useEffect(() => {
    if (i18n.language === 'cs-CZ') {
      i18n.changeLanguage('cs');
    }
 
  }, []); */

  // window.addEventListener('message', function (event: any) {
  //   console.log(event.origin);
  //   if (event.data.toString().startsWith("languageInternalSetter:")) {
  //     i18n.changeLanguage(event.data.toString().replace("languageInternalSetter:", ""));
  //     // window.location.reload();
  //   }




  useEffect(() => {
    const path = new URL(window.location.href).pathname;
    const appName = path.split('/')[2];
    let token: string | null = "";
    let isSubcontractor: boolean = false;
    let isInvestor: boolean = false;
    if (path.length > 2 && path.split('/')[3] === 'subcontractor') {
      token = localStorage.getItem('subcontractor_token_old');
      isSubcontractor = true;
    }
    else if (path.length > 2 && path.split('/')[3] === 'investor') {
      token = localStorage.getItem('investor_token_old');
      isInvestor = true;
    }
    else {
      token = localStorage.getItem('old_bearer');
    }

    if (token) {
      let decodedToken;

      try {
        decodedToken = jwtDecode(token!);
      } catch (e) {
        console.error(e);
      }

      if (appName && decodedToken && decodedToken.ApplicationName.toLowerCase() !== appName.toLowerCase()) {
        console.log('token application missmatched');
        if (isSubcontractor)
          localStorage.removeItem('subcontractor_token_old');
        else if (isInvestor)
          localStorage.removeItem('investor_token_old');
        else
          localStorage.removeItem('old_bearer');
      }

    }

  }, []);


  const theme = createThemeStavario();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <React.Fragment>
        <Route path="/" element={<ThemeProvider theme={theme}><Login /></ThemeProvider>} />

        <Route path="recommendations/:token/:id/:domain/:language" element={<Recomendations />}></Route>
        <Route path="acceptInvite/:token/:id/:domain/:language" element={<AcceptInvite />}></Route>


        <Route path=":lang/:domain" element={<ThemeProvider theme={theme}><LoginOldVov /></ThemeProvider>} />
        <Route path="kros" element={<ThemeProvider theme={theme}><LoginKrosAdminScene /></ThemeProvider>} />

        <Route path=":lang/:domain/admin/users" element={<ProtectedKrosAdminRoute><ThemeProvider theme={theme}><AdminMainScene selected={0} /></ThemeProvider></ProtectedKrosAdminRoute>} />
        <Route path=":lang/:domain/admin/users/:id" element={<ProtectedKrosAdminRoute><ThemeProvider theme={theme}><AdminUserDetailScene /></ThemeProvider></ProtectedKrosAdminRoute>} />
        <Route path=":lang/:domain/admin/applications" element={<ProtectedKrosAdminRoute><ThemeProvider theme={theme}><AdminMainScene selected={1} /></ThemeProvider></ProtectedKrosAdminRoute>} />
        <Route path=":lang/:domain/admin/applications/:id" element={<ProtectedKrosAdminRoute><ThemeProvider theme={theme}><KrosAdminApplicationTabScene selected={0} /></ThemeProvider></ProtectedKrosAdminRoute>} />
        {/* <Route path=":lang/:domain/admin/applications/:id/permissions" element={<ProtectedKrosAdminRoute><ThemeProvider theme={theme}><KrosAdminApplicationTabScene selected={1} /></ThemeProvider></ProtectedKrosAdminRoute>} /> */}
        <Route path=":lang/:domain/admin/stats" element={<ProtectedKrosAdminRoute><ThemeProvider theme={theme}><AdminMainScene selected={2} /></ThemeProvider></ProtectedKrosAdminRoute>} />

        <Route path=":lang/:domain/resetpassword" element={<ThemeProvider theme={theme}><ResetPassword /></ThemeProvider>} />

        <Route path=":lang/:domain/projects" element={<ThemeProvider theme={theme}><ProjectsList /></ThemeProvider>} />
        <Route path=":lang/:domain/projectDetail/:id" element={<ThemeProvider theme={theme}><ProjectDetail /></ThemeProvider>}></Route>

        <Route path=":lang/:domain/invoices" element={<ThemeProvider theme={theme}><InvoiceList /></ThemeProvider>} />
        <Route path=":lang/:domain/invoiceDetail/:id" element={<ThemeProvider theme={theme}><InvoiceDetail /></ThemeProvider>}></Route>

        <Route path=":lang/:domain/diaryexternal/:token/:adminUserId" element={<ThemeProvider theme={theme}><DiaryExternal /></ThemeProvider>} />
        <Route path=":lang/:domain/diaryolderpentry/:token/:adminUserId" element={<ThemeProvider theme={theme}><DiaryExternalWithMenuEntryPoint /></ThemeProvider>} />
        <Route path=":lang/:domain/diaryolderpnew" element={<ThemeProvider theme={theme}><DiaryExternalWithMenu /></ThemeProvider>} />
        <Route path=":lang/:domain/diaryolderpnew/:recordId/" element={<ThemeProvider theme={theme}><DailyRecordsExternalWithMenu /></ThemeProvider>} />
        <Route path=":lang/:domain/diaryolderpnewdetail/:id/" element={<ThemeProvider theme={theme}><DiaryDetailExternalWithMenu /></ThemeProvider>} />


        <Route path=":lang/:domain/diaryexternal/dailyrecord/:token/:adminUserId/:recordId/" element={<ThemeProvider theme={theme}><DailyRecordsExternal /></ThemeProvider>} />
        <Route path=":lang/:domain/diaryexternal/diary/:token/:adminUserId/:iddiary" element={<ThemeProvider theme={theme}><DiaryExternalDetail /></ThemeProvider>} />
        <Route path=":lang/:domain/diaryMobile/:employeeId/:token/:adminUserId" element={<ThemeProvider theme={theme}><DiaryMobileExternal /></ThemeProvider>} />



        <Route path=":lang/:domain/diaryvov" element={<ThemeProvider theme={theme}><DiaryTabs /></ThemeProvider>} />
        <Route path=":lang/:domain/diaryvovdiaries" element={<ThemeProvider theme={theme}><DiaryTabs selected={1} /></ThemeProvider>} />
        <Route path=":lang/:domain/diaryvov/:recordId" element={<ThemeProvider theme={theme}><DiaryMainScene /></ThemeProvider>} />
        <Route path=":lang/:domain/diaryDetail/:id" element={<ThemeProvider theme={theme}><DiaryDetail /></ThemeProvider>}></Route>

        <Route path=":lang/:domain/todos" element={<ThemeProvider theme={theme}><ToDoSceneOld /></ThemeProvider>} />
        <Route path=":lang/:domain/todos/:id" element={<ThemeProvider theme={theme}><SingleTodoTabSceneOld /></ThemeProvider>}></Route>

        <Route path=":lang/:domain/wealths" element={<ThemeProvider theme={theme}><WealthList /></ThemeProvider>}></Route>
        <Route path=":lang/:domain/wealths/:id" element={<ThemeProvider theme={theme}><WealthTabs /></ThemeProvider>}></Route>

        <Route path=":lang/:domain/tasks" element={<ThemeProvider theme={theme}><TasksList /></ThemeProvider>}></Route>
        {/* <Route path=":lang/:domain/tasks/:id" element={<ThemeProvider theme={theme}><ToDoSceneOld /></ThemeProvider>}></Route> */}

        <Route path=":lang/:domain/investor/tabs/diary/:recordId" element={<ProtectedInvestorRoute><ThemeProvider theme={theme}><InvestorTabs selectedIndex={5} /></ThemeProvider></ProtectedInvestorRoute>} />

        <Route path=":lang/:domain/investor" element={<ThemeProvider theme={theme}> <LoginInvestorScene /> </ThemeProvider>} />
        <Route path=":lang/:domain/investor/resetpassword" element={<ThemeProvider theme={theme}><ResetPassword isInvestor /></ThemeProvider>} />
        <Route path=":lang/:domain/investor/ai" element={<ProtectedInvestorRoute><ThemeProvider theme={theme}><GptChatScene /></ThemeProvider></ProtectedInvestorRoute>} />
        <Route path=":lang/:domain/investor/tabs/tasks" element={<ProtectedInvestorRoute><ThemeProvider theme={theme}><InvestorTabs selectedIndex={0} /></ThemeProvider></ProtectedInvestorRoute>} />
        <Route path=":lang/:domain/investor/tabs/photos" element={<ProtectedInvestorRoute><ThemeProvider theme={theme}><InvestorTabs selectedIndex={1} /></ThemeProvider></ProtectedInvestorRoute>} />
        <Route path=":lang/:domain/investor/tabs/diary" element={<ProtectedInvestorRoute><ThemeProvider theme={theme}><InvestorTabs selectedIndex={2} /></ThemeProvider></ProtectedInvestorRoute>} />
        <Route path=":lang/:domain/investor/tabs/dochazka" element={<ProtectedInvestorRoute><ThemeProvider theme={theme}><InvestorTabs selectedIndex={3} /></ThemeProvider></ProtectedInvestorRoute>} />
        <Route path=":lang/:domain/investor/tabs/documents" element={<ProtectedInvestorRoute><ThemeProvider theme={theme}><InvestorTabs selectedIndex={4} /></ThemeProvider></ProtectedInvestorRoute>} />

        <Route path=":lang/:domain/investor/tabs/diary/:diaryId/:recordId" element={<ProtectedInvestorRoute><ThemeProvider theme={theme}><DiaryInvestorPager /></ThemeProvider></ProtectedInvestorRoute>} />

        <Route path=":lang/:domain/subcontractor" element={<ThemeProvider theme={theme}> <LoginSubcontractorScene /> </ThemeProvider>} />
        <Route path=":lang/:domain/subcontractor/diaries" element={<ProtectedSubcontractorRoute><ThemeProvider theme={theme}><SubcontratorMainScene><DiaryTabs isSubdodavatel={true} /></SubcontratorMainScene></ThemeProvider></ProtectedSubcontractorRoute>} />
        <Route path=":lang/:domain/subcontractor/diariesDiary" element={<ProtectedSubcontractorRoute><ThemeProvider theme={theme}><SubcontratorMainScene><DiaryTabs selected={1} isSubdodavatel={true} /></SubcontratorMainScene></ThemeProvider></ProtectedSubcontractorRoute>} />
        <Route path=":lang/:domain/subcontractor/diaries/:recordId" element={<ProtectedSubcontractorRoute><ThemeProvider theme={theme}><SubcontratorMainScene><DiaryMainScene isSubdodavatel={true} /></SubcontratorMainScene></ThemeProvider></ProtectedSubcontractorRoute>} />
        <Route path=":lang/:domain/subcontractor/diaryDetail/:id" element={<ThemeProvider theme={theme}><SubcontratorMainScene><DiaryDetail isSubdodavatel={true} /></SubcontratorMainScene></ThemeProvider>}></Route>
        <Route path=":lang/:domain/subcontractor/resetpassword" element={<ThemeProvider theme={theme}><ResetPassword isSubdodavatel /></ThemeProvider>} />

        <Route path=":lang/:domain/diary/print/:id/:token/:userId" element={<ThemeProvider theme={theme}><DiaryPrint isInvestor={false} /></ThemeProvider>}></Route>
        <Route path=":lang/:domain/diaryviewer/:id/:token/:userId" element={<ThemeProvider theme={theme}><DiaryPager /></ThemeProvider>}></Route>

        <Route path=":lang/:domain/employees" element={<ThemeProvider theme={theme}><EmployeesList /></ThemeProvider>}></Route>
        <Route path=":lang/:domain/employees/:id" element={<ThemeProvider theme={theme}><EmployeeDetail /></ThemeProvider>}></Route>
      </React.Fragment>
    ));
  return (
    <div>

      <RouterProvider router={router} />

      <UploadSnackbar isOpen={uploadingFiles.length > 0} onClose={() => { /* clearFiles(); */ }} />
    </div>
  );
}

export default App;