import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridRowId,
    GridRowModel,
    GridSlots,
    GridRowSelectionModel,
} from '@mui/x-data-grid';
import { ApiOldInvoicesClient } from '../../../ApiOld/ApiOldInvoicesClient';
import { BuildingDTO, InvoiceItem, TaxrateDTO } from '../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { csCZ } from '@mui/x-data-grid/locales';
import { SolveProjectsContext, StavarioOldGlobalContext, StavarioOldUpdateContext } from '../../../Contexts/LoginOldContext';
import { useContext, useEffect, useState } from 'react';

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

interface InvoiceItemsProps {
    invoiceItems: InvoiceItem[] | undefined;
    saveInvoiceItems: (invoiceItems: InvoiceItem[]) => void;
}

export default function FullFeaturedCrudGrid(props: InvoiceItemsProps) {
    const { invoiceItems, saveInvoiceItems } = props;
    const { t } = useTranslation();
    const [isSaveTriggered, setIsSaveTriggered] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [projects, setProjects] = useState<BuildingDTO[]>([]);
    const [taxRates, setTaxRates] = useState<TaxrateDTO[]>([]);
    const [rows, setRows] = useState(invoiceItems || []);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const globalContext = useContext(StavarioOldGlobalContext);
    const setGlobal = useContext(StavarioOldUpdateContext)!;

    function reloadData() {
        new ApiOldInvoicesClient().GetTaxrates().then((d) => {
            setTaxRates(d);
        });
        if (invoiceItems) setRows(invoiceItems);
        SolveProjectsContext(globalContext, setGlobal).then((res) => {
            setProjects(res);
        });
    }

    useEffect(() => {
        reloadData();
    }, [invoiceItems]);

    function EditToolbar(props: EditToolbarProps) {
        const { setRows, setRowModesModel } = props;

        const handleClick = () => {
            let id;
            do {
                id = Math.floor(Math.random() * 1000000)
            } while (rows.some((row) => row.id === id));

            setRows((oldRows) => [...oldRows, { id, name: "", priceWithoutVat: 0, isNew: true }]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
            }));
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                    {t('Add')}
                </Button>
                <Button color="primary" startIcon={<SaveIcon />} onClick={handleSaveAll} disabled={!isSaveEnabled}>
                    {t('save')}
                </Button>
                <Button color="primary" startIcon={<DeleteIcon />} onClick={handleDeleteClick} disabled={!selectionModel || selectionModel.length === 0}>
                    {t('Delete')}
                </Button>
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
        setIsSaveEnabled(invoiceItems != rows);
    }, [rows]);

    function handleDeleteClick() {
        const selectedIDs = new Set(selectionModel);
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id !== undefined && selectedIDs.has(row.id)
                    ? { ...row, isDeleted: true } as InvoiceItem
                    : row
            )
        );
    }

    function handleSaveAll() {
        rows.map((row) => {
            setRowModesModel({ ...rowModesModel, [row.id as GridRowId]: { mode: GridRowModes.View } });
        });
        setIsSaveTriggered(true);
        setIsSaveEnabled(false);
    }

    useEffect(() => {
        if (isSaveTriggered) {
            saveInvoiceItems([...rows]);
            setRows((prevRows) => prevRows.map((row) => ({ ...row, isNew: false, isUpdated: false } as InvoiceItem)));
            setIsSaveTriggered(false);
        }
    }, [rows, isSaveTriggered]);

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow } as InvoiceItem;
        setRows((prevRows) => prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('Name'),
            editable: true,
            flex: 1,
            minWidth: 100,
        },
        {
            field: 'projectId',
            headerName: t('Projekt'),
            editable: true,
            type: 'singleSelect',
            flex: 1,
            minWidth: 150,
            getOptionValue: (value: any) => value.code,
            getOptionLabel: (value: any) => value.name,
            valueOptions: projects.map((project) => ({ code: project.id, name: project.name })),
        },
        {
            field: 'priceWithoutVat',
            headerName: t('withoutVat'),
            type: 'number',
            minWidth: 150,
            editable: true,
        },
        {
            field: 'taxratesId',
            headerName: t('VatRate'),
            editable: true,
            align: 'right',
            headerAlign: 'right',
            type: 'singleSelect',
            minWidth: 200,
            getOptionValue: (value: any) => value.code,
            getOptionLabel: (value: any) => value.name,
            valueOptions: taxRates.map((taxRate) => ({ code: taxRate.id, name: `${t('Vat')} ${taxRate.value}%` })),
        },
    ];

    const totalPriceWithoutVat = rows.reduce((total, row) => total + (row.priceWithoutVat ?? 0), 0);

    return (
        <Box sx={{ height: 500, width: '100%' }}>
            <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                {`${t('totalWithoutVat')} ${totalPriceWithoutVat.toFixed(2)} Kč`}
            </div>
            <DataGrid
                columns={columns}
                rows={rows.filter((row) => !row.isDeleted)}
                rowHeight={35}
                editMode="row"
                localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                        },
                    },
                }}
                pageSizeOptions={[10, 20, 30, 50, 100]}
                checkboxSelection
                rowModesModel={rowModesModel}
                onRowSelectionModelChange={(ids) => {
                    setSelectionModel(ids);
                }}
                onRowModesModelChange={handleRowModesModelChange}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar as GridSlots['toolbar'],
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
            />
        </Box>
    );
}
