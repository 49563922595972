import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, TextareaAutosize } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PromptDTO, PromptType } from '../../../ApiOld/ApiServerVov';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
interface AddOrEditPromptDialogProps {
    open: boolean;
    prompt: PromptDTO;
    type: PromptType,
    relationId: number;
    handleClose: (refresh: boolean) => void;
}

export default function AddOrEditPromptDialog(props: AddOrEditPromptDialogProps) {
    const { open, prompt, type, relationId, handleClose } = props;
    const [saving, setSaving] = React.useState(false);
    const [promptLocal, setPromptLocal] = React.useState<Partial<PromptDTO>>();
    const { t } = useTranslation();

    useEffect(() => {
        setPromptLocal(prompt);
    }, [prompt]);

    return (
        <Dialog
            open={open}
            onClose={() => { handleClose(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('defaultPrompts')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {saving && <div style={{ margin: 'auto' }}>
                        <CircularProgress />
                    </div>}
                    {!saving && promptLocal && <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField sx={{ margin: '10px 20px' }} label={t('Name')}
                            size='small'
                            name='name'
                            variant='outlined'
                            value={promptLocal.name}
                            onChange={(e) => { setPromptLocal({ ...promptLocal, name: e.target.value }); }} />
                        {/* <TextField sx={{ margin: '10px 20px' }} label={t('Text')}
                            size='small'
                            name='name'
                            variant='outlined'
                            value={promptLocal.text}
                            onChange={(e) => { setPromptLocal({ ...promptLocal, text: e.target.value }); }} /> */}
                        <TextareaAutosize aria-label="empty textarea"
                            placeholder={t('Text')}
                            value={promptLocal.text}
                            onChange={(e) => { setPromptLocal({ ...promptLocal, text: e.target.value }); }}
                            minRows={2}
                            style={{ margin: '10px 20px', borderColor: 'lightgray', minHeight: '100px' }} />
                    </div>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' disabled={promptLocal === undefined || promptLocal.name === undefined || promptLocal.name.length === 0 || promptLocal.text === undefined || promptLocal.text.length === 0} onClick={() => {
                    setSaving(true);
                    new APIVovHelper().CreateOrUpdatePrompt(promptLocal as PromptDTO, type, relationId).then(res => {
                        setSaving(false);
                        handleClose(false);
                    });
                }}>{t('save')}</Button>
                <Button variant='contained' onClick={() => { handleClose(false) }} autoFocus>
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
