
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Navigate } from "react-router";

export interface ProtectedSubcontractorRouteProps {
    redirectPath?: string;
    children: any;
}


export default function ProtectedSubcontractorRoute(props: ProtectedSubcontractorRouteProps) {
    const { redirectPath, children } = props;
    const { t, i18n } = useTranslation();
    const params = useParams();
    // console.log(children);

    var token = localStorage.getItem('subcontractor_token_old');
    var tokenExp = localStorage.getItem('subcontractor_token_old_expires');

    // console.log(token);
    if (token === null || token === undefined || tokenExp === null || tokenExp === undefined || new Date(tokenExp) < new Date()) {


        return <Navigate to={'/' + i18n.language + '/' + params['domain']! + '/subcontractor'} replace state={{ url: window.location.pathname }} />;

    }
    return children;
}


