import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { registerLicense } from '@syncfusion/ej2-base';
import reportWebVitals from './reportWebVitals';
import './Localization/i18nInitializer';
import LoginMainContext from './Contexts/LoginMainContext';
import ErrorHandlerScene from './Components/Shared/ErrorHandlerScene';
import LoginOldContext from './Contexts/LoginOldContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
registerLicense('Mgo+DSMBaFt/QHRqVVhlWFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jTnxTdkBhWH1ecHJUQQ==;Mgo+DSMBPh8sVXJ0S0J+XE9BclRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TcUdjWXpccXZVRmdaVg==;ORg4AjUWIQA/Gnt2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkJhWX9bcnVXRmBVUUM=;MjU5MDM2NEAzMjMwMmUzNDJlMzBad2tyZ1oxcElBYWFmN2tkdUt2aHBYTEdEaDhVOWd3TDFKMWR2QmhTcGI0PQ==;MjU5MDM2NUAzMjMwMmUzNDJlMzBZbmd4ZXhIa2RhMFZrWVIxRytobUZvV1Q2WTJmdUhRS2hKOGI0T1NGNVQ0PQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFpGVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVkW39edXZURGBdVkF1;MjU5MDM2N0AzMjMwMmUzNDJlMzBRZVJhSWltazF3RGN0ZXZXYis1L0JRU0RBTVNVcHU1Vnp2bDRBUHZvQVBrPQ==;MjU5MDM2OEAzMjMwMmUzNDJlMzBnMzlWNElFc2l6VFZMR1lMU084dzNwTGkzYmZLOHdkVkdoNFhiSFR4YWRnPQ==;Mgo+DSMBMAY9C3t2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkJhWX9bcnVXRmJUVUc=;MjU5MDM3MEAzMjMwMmUzNDJlMzBjWFc5eEtaOS8zalBMRWk5ZVZmdDJPSG5nbjhGdFo5VU1PeU1Fc2R2UFJnPQ==;MjU5MDM3MUAzMjMwMmUzNDJlMzBENlVteHhEV0w0SnIvWURTaU15ZHNiTG9XanNRSUh3bUtmWXNSdEhTd3JJPQ==;MjU5MDM3MkAzMjMwMmUzNDJlMzBRZVJhSWltazF3RGN0ZXZXYis1L0JRU0RBTVNVcHU1Vnp2bDRBUHZvQVBrPQ==');
root.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
      <ErrorHandlerScene>
        <LoginOldContext subPages={(<App />)} />
      </ErrorHandlerScene>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
