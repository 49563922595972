import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useLoginAdminStore } from '../../../../Contexts/LoginAdminZContext';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { set } from 'date-fns';

export default function DiaryMobileExternal() {
    const { t, i18n } = useTranslation();
    const [error, setError] = useState<string | undefined>();
    const navigate = useNavigate();
    const params = useParams();
    const { load } = useLoginAdminStore();
    useEffect(() => {
        if (params['token'] && params['adminUserId'] && params['lang'] && params['domain']) {
            new APIVovHelper().LoginEmpAsAdmin(params['token'], parseInt(params['adminUserId']), parseInt(params['employeeId']!), params['domain']).then((e) => {
                if (e.userName)
                    load(e.userName, parseInt(params['adminUserId']!));
                navigate('/' + params['lang'] + '/' + params['domain'] + '/diaryVov');
            }).catch((e) => {
                setError(t('diary.mobileLogErrorServer'));
            });
        }
        else setError(t('diary.mobileLogErrorInput'));

        localStorage.setItem('old_app_uri', params['domain'] ? params['domain'] : 'x');
    }, [])
    return (<div>
        {error === undefined ? ('diary.loggingIntoNewVersion') : error}
    </div>);
}
