import { ContentPasteOffSharp, NoTransfer, TodaySharp } from "@mui/icons-material";
import axios from "axios";
import { ApiOldBaseHelper } from "./ApiOldBaseHelper";
import { AddTodoConversationRequest, AddTodoConversationResponse, AddTodoRequest, ConversationDto, DeleteTodosRequest, ToDoAssigneeDTO, TodoDTO, TodoGanttDTO, TodoListDTO, UpdateTodoConversationRequest, UpdateTodoConversationResponse, UpdateTodoRequest, UpdateTodoResponse } from "./ApiServerVov";
import { UserResponsibleDTO } from "../Api/TodoMicroserviceClient";

export class APITodoOldHelper extends ApiOldBaseHelper {

    public async GetTodos(): Promise<TodoListDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getTodos()).todos!;
    }

    public async GetTodo(todoId: number): Promise<TodoDTO> {
        return (await this.GetAdressedAuthRefreshableClient().getTodoDetail(todoId)).data!;
    }

    public async AddTodo(todo: TodoDTO): Promise<number> {
        let t = await this.GetAdressedAuthRefreshableClient().addTodo(new AddTodoRequest({
            data: todo
        }));
        return t.id!;
    }

    public async UpdateTodo(todo: TodoDTO): Promise<UpdateTodoResponse> {
        let success = await this.GetAdressedAuthRefreshableClient().updateTodo(new
            UpdateTodoRequest({
                data: todo
            }));
        return success;
    }

    public async DeleteTodos(data: number[]): Promise<void> {
        await this.GetAdressedAuthRefreshableClient().deleteTodos(new DeleteTodosRequest({ selectedTodos: data }));
    }

    public async GetTodoAllPossibleAssignees(buildingId: number): Promise<ToDoAssigneeDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getTodoAllPossibleAssignees(buildingId)).data!;
    }

    public async GetTodoChatConversations(requestId: number): Promise<ConversationDto[]> {
        return (await this.GetAdressedAuthRefreshableClient().getTodoChatConversations(requestId)).conversations!;
    }    

    public async AddTodoConversation(input: AddTodoConversationRequest): Promise<AddTodoConversationResponse> {
        return (await this.GetAdressedAuthRefreshableClient().addTodoConversation(input));
    }

    public async GetTodoAllPossibleMessageAssignees(requestId: number): Promise<ToDoAssigneeDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getTodoAllPossibleMessageAssignees(requestId)).data!;
    }  

    public async UpdateTodoConversation(input: UpdateTodoConversationRequest): Promise<UpdateTodoConversationResponse> {
        return (await this.GetAdressedAuthRefreshableClient().updateTodoConversation(input));
    }



    /* public async GetTodoHistory(todoId: number): Promise<GetTodoHistoryResponse> {
        let t = (await this.GetAdressedAuthRefreshableClient().history(todoId));
        console.log(t);
        return t;
    }
    public async GetGanttTodos(): Promise<TodoGanttDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getGanttTodos()).todos!;
    }
    
    public async GetUsers(): Promise<UserResponsibleDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().usersResponsible()).users!;
    }
    public async GetAttachmentFileDownloadLink(todoId: number): Promise<string> {
        return (await this.GetAdressedAuthRefreshableClient().getDownloadUri(todoId, false)).link!;
    }
    public async GetAttachmentFileDownloadToOfficeEmbed(todoId: number): Promise<string> {
        return (await this.GetAdressedAuthRefreshableClient().getDownloadUri(todoId, true)).link!;
    }
    public async AddTodoAttachment(filename: string, todoId: number): Promise<AddTodoAttachmentResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getSaveFileToken(filename, todoId));
    }
    public async AddTodoAttachmentEnd(todoAttachmentId: number): Promise<void> {
        (await this.GetAdressedAuthRefreshableClient().endUploadTodoAttachment(new AddTodoAttachmentEndRequest({ attachmentId: todoAttachmentId })));
    }
    public async GetTodosAttachments(todoId: number): Promise<TodoAttachmentDTO[] | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().listAttachmentsTodoId(todoId)).attachments;
    }
    public async DeleteTodoAttachment(todoAttachmentId: number): Promise<void> {
        (await this.GetAdressedAuthRefreshableClient().deleteAttachmentTodo(todoAttachmentId));
    }
    public async LoadPDF(): Promise<void> {
        (await this.GetAdressedAuthRefreshableClient().load({}));
    }

    public async UpdateTodosPositions(updates: UpdateTaskPositionsDTO[]): Promise<void> {
        await this.GetAdressedAuthRefreshableClient().updateTaskPositions(new UpdateTaskPositionsRequest({ positionsUpdates: updates }));
    }
    public async UpdateKanbanTodos(updates: UpdateTodoKanbanDTO[]): Promise<void> {
        (await this.GetAdressedAuthRefreshableClient().updateKanban(new UpdateTodoKanbanRequest({ updates: updates })));
    } */

}