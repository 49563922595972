import { create } from 'zustand'
import { GetDiaryPermissionsResponse, GetSubcontractorDiaryPermissionsResponse } from '../ApiOld/ApiServerVov'
import { ApiDiaryBaseHelper } from '../Api/ApiDiaryBaseHelper'

interface DiaryStoreState {
    permissions?: GetDiaryPermissionsResponse | GetSubcontractorDiaryPermissionsResponse;
    load: (perms: GetDiaryPermissionsResponse) => void
}
export const loadDiaryStore = (isSubdodavatel: boolean) => {
    return new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetPermissions();
}
export const useDiaryStore = create<DiaryStoreState>()((set) => ({
    permissions: undefined,
    load: (by) => set((state) => ({ permissions: by })),
}))