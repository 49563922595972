import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DiaryPrint from './DiaryPrint';
import UserPrintSelect from '../../../Shared/UserPrintSelect';
import { UserDTO } from '../../../../ApiOld/ApiServerVov';
import { useMediaPredicate } from 'react-media-hook';

export interface DiaryUsersPrintDialogProps {
    diaryId: number;
    onClose: (user: UserDTO | null) => void;
    open: boolean;
    isSubdodavatel?: boolean;
}
export default function DiaryUsersPrintDialog(props: DiaryUsersPrintDialogProps, ref: any) {
    const { onClose, open, diaryId, isSubdodavatel } = props;
    const [selectedUser, setSelectedUser] = React.useState<UserDTO | null>(null);
    const { t } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            sx={{ minHeight: '480px' }}
            onClose={() => onClose(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('PrintOthers')}
            </DialogTitle>
            <DialogContent>
                <div style={{ marginTop: '16px', minHeight: biggerThan700 ? '480px' : '' }}>
                    <UserPrintSelect isMandatory isSubdodavatel={isSubdodavatel} diaryId={diaryId} onChange={(e) => { setSelectedUser(e) }} />
                </div>
            </DialogContent><DialogActions>
                <Button variant='contained' disabled={selectedUser === undefined || selectedUser === null} onClick={() => {
                    onClose(selectedUser);
                }}>{t('Print')}</Button>
                <Button variant='contained' onClick={() => { onClose(null); }} autoFocus>
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}