import React, { useEffect } from "react";
import { Alert, AlertTitle, CircularProgress, Slide, Snackbar, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Check } from "@mui/icons-material";
import { getFileExtension } from "./Functions/GetFileExtension";
import { useUploadFileStore } from "../../Contexts/UploadFileContext";
import { truncateFileName } from "./TruncateFileName";

export interface UploadSnackbarProps {
    isOpen?: boolean;
    onClose: () => void;
}

export default function UploadSnackbar(props: UploadSnackbarProps) {
    const { onClose, isOpen } = props;
    const { uploadingFiles } = useUploadFileStore();

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    useEffect(() => {

    }, []);

    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    };

    const handleClose = (event: any) => {
        onClose();
    };

    return (
        <div>
            <div className="upload-snackbar">
                <Snackbar
                    TransitionComponent={uploadingFiles && uploadingFiles.some(p => p.show === true) ? undefined : TransitionLeft}
                    open={isOpen}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                    <Alert severity="success" onClose={handleClose}>
                        <AlertTitle>{t('files.uploadingFiles')}</AlertTitle>
                        <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                            {uploadingFiles && uploadingFiles.filter(p => p.show).map((v, i) => {
                                return <div key={i} style={{ display: 'flex' }}>
                                    {v.isDone && <Check sx={{ fontSize: '16px' }} />}
                                    {!v.isDone && <CircularProgress size='16px' />}
                                    <div style={{ marginLeft: '4px' }}>{truncateFileName(v.uploadFile.name, 32)}</div>
                                </div>;
                            })

                            }
                        </div>
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
}