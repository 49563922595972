import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, AlertTitle, Button, Slide, Snackbar, TextField } from "@mui/material";
import "./LoginScene.css";
import Passwordbox from "../Shared/Passwordbox";
//@ts-ignore
import jsPDF from 'jspdf';
import LanguagePicker from "../Shared/LanguagePicker";
import { APIVovHelper } from "../../ApiOld/ApiOldClient";
import { ApiException } from "../../ApiOld/ApiServerVov";
import { useLoginAdminStore } from "../../Contexts/LoginAdminZContext";

export default function LoginOldVov(props: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    let [userName, setUserName] = useState("");
    let [password, setPassword] = useState("");
    let [appUri, setAppUri] = useState("");
    const [openLoginError, setOpenLoginError] = React.useState(false);

    useEffect(() => {
        if (params['lang']) {
            if (i18n.language !== params['lang'])
                i18n.changeLanguage(params['lang']);
        }
        setAppUri(params['domain']!);
    }, []);
    const { load } = useLoginAdminStore();
    const CallOldApiWithLogin = async (e: any) => {
        try {
            console.log('start auth');
            let a = await (new APIVovHelper().Login(appUri, userName, password));
            if (a.userName && a.id)
                load(a.userName, a.id);
            navigate({
                pathname: 'diaryvov/',
            });

        }
        catch (ex) {
            if (ex instanceof ApiException) {
                setOpenLoginError(true);
            }
            else {

            }
        }
    }


    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }
    const onTextChange = (event: any) => {
        setUserName(event.currentTarget.value);
    };
    const onPassChange = (event: string) => {
        setPassword(event);
    };
    const handleClose = (event: any) => {
        setOpenLoginError(false);
    };

    return (
        // <ThemeProvider theme={theme}>
        <div>
            <div className="Auth-form-container">
                <div className="Auth-form">
                    <div className="logo-up">
                        <img
                            width="200px"
                            height="54px"
                            src="https://stavariokluc.vimovsem.cz/images/login-logo-stavario.svg"
                            alt="stavario"
                        ></img>
                    </div>
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title" >{t('login.signIn')}</h3>

                        <div className="form-group mt-3">

                            <div>
                                <TextField
                                    id="outlined-basic"
                                    label={t('login.enterEmail')}
                                    className="text-field"
                                    value={userName}
                                    onChange={onTextChange}
                                    variant="outlined" />
                            </div>

                        </div>

                        <div className="form-group mt-3">

                            <Passwordbox onEnter={() => { CallOldApiWithLogin(undefined); }} password={password}
                                onPasswordChange={onPassChange}></Passwordbox>
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <Button onClick={(e) => { CallOldApiWithLogin(undefined); }} variant="contained" type="submit">
                                {t('login.sign')}
                            </Button>
                        </div>

                        <div style={{ display: 'block', textAlign: 'center', width: '100%' }}>
                            <p style={{ marginTop: '26px' }}>
                                {t('login.forgot')} <a onClick={(e) => {
                                    navigate(
                                        { pathname: '/' + i18n.language + '/' + params['domain']! + '/resetpassword', },
                                        { state: { email: userName } }
                                    );
                                }} style={{ color: '#ffc107', textAlign: 'center', cursor: 'pointer' }}>{t('login.password')}</a>

                            </p>
                            <div style={{ marginTop: '-10px' }}>
                                <LanguagePicker showText={false} onLanguageChange={(e) => { navigate('/' + e + '/' + appUri) }} />
                            </div>
                        </div>
                        {/* <p className="text-center mt-2" >
                            {t('login.forgot')}<a href="#" style={{ color: '#ffc107' }}>{t('login.password')}</a>
                            <LanguagePicker onLanguageChange={(e) => { navigate('/' + e + '/' + appUri) }} showText={false} />
                        </p> */}
                    </div>
                </div>

            </div>
            <div className="Auth-form-popup">
                <Snackbar
                    TransitionComponent={TransitionLeft}
                    open={openLoginError}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                    <Alert onClose={handleClose} severity="warning">
                        <AlertTitle>{t('login.loginFailed')}</AlertTitle>
                        {t('login.loginFailedDetail')}
                    </Alert>
                </Snackbar>
            </div>
        </div>
        // </ThemeProvider>
    )



}