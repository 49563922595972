import { AttachFile, Close, CloseFullscreen, ColorLens, Delete, EventRepeat, Fullscreen, History, MoreHorizOutlined, Save, SaveAs, Troubleshoot, Watch } from '@mui/icons-material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogTitle, IconButton, Popover, styled, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { ChangeEventArgs, HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import React, { useEffect, useRef, useState } from 'react';
import SplitButton from '../../Shared/SplitButton';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { APITodoHelper } from '../../../Api/ApiTodoHelper';
import { FileParameter, RequestsDTO } from '../../../ApiOld/ApiServerVov';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import TodoChatScene from './ToDoInvestorChatScene';
import { Marker } from 'react-image-marker';
import getColorByBgColor from '../../Shared/Functions/TextColorConverter';
import ColorPickerDialog from '../../Shared/ColorPickerDialog';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import Concurrency from '../../Shared/Concurrency';
import BuildingSelect from './InvestorBuildingSelect';
import { BeforeUploadEventArgs, FileInfo, UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { APIVovInvestorHelper } from '../../../ApiOld/ApiInvestorOldClient';
import ToDoInvestorChatScene from './ToDoInvestorChatScene';
import { L10n } from '@syncfusion/ej2-base';
import InitSfUploaderLocale from '../../../Localization/SfUploaderInitializer';

InitSfUploaderLocale();


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
    todo: RequestsDTO | undefined;
    onRefresh: () => void;
    topMargin?: number;
}


export default function InvestorTaskDetailDialog(props: SimpleDialogProps) {
    const { onClose, selectedValue, open, todo, onRefresh, topMargin } = props;
    const { t, i18n } = useTranslation();
    const optionsStates = [
        { label: t('singleTodo.new'), id: 0 },
        { label: t('singleTodo.active'), id: 1 },
        { label: t('singleTodo.resolved'), id: 2 },
    ];
    const biggerThan600 = useMediaPredicate("(min-width: 600px)");
    const [task, setTask] = useState<Partial<RequestsDTO> | undefined>(todo);
    const upload = useRef<UploaderComponent>(null);
    const [file, setFile] = useState('');
    const [comment, setComment] = useState('');
    const [fileData, setFileData] = useState<FileInfo | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    //const [editedTask, setEditedTask] = useState<RequestsDTO | null>(null);
    useEffect(() => {
        setComment('');
        setTask(todo);
    }, [todo]);
    useEffect(() => {
        setLoading(false);

    }, []);
    useEffect(() => {
        setTask(todo);
    }, [todo]);
    // useEffect(() => {
    //     if (task !== undefined && task !== null)
    //         console.log(task!.assignees);
    // }, [open]);
    async function updateTodo(): Promise<boolean> {
        try {
            if (task !== null) {
                setLoading(true);
                if (fileData !== null) {
                    let fileParameter: FileParameter = { fileName: fileData.name, data: fileData.rawFile };
                    let t = await (new APIVovInvestorHelper()).UpdateRequest(task, comment, fileParameter);
                    if (t) {
                        onRefresh();
                        //setTask({ ...task, id: t.id, rowVersion: t.rowVersion });
                        // setTask({ ...task, rowVersion: t.rowVersion });
                        //nesmí se volat dvakrát za sebou kvůli async chování setstate
                    }
                }
                else {
                    let t = await (new APIVovInvestorHelper()).UpdateRequest(task, comment, undefined);
                    if (t) {
                        onRefresh();
                        //setTask({ ...task, id: t.id, rowVersion: t.rowVersion });
                        // setTask({ ...task, rowVersion: t.rowVersion });
                        //nesmí se volat dvakrát za sebou kvůli async chování setstate
                    }
                }
                setLoading(false);
            }
            return true;
        }
        catch
        {
            setLoading(false);
            return false;
        }
    }

    function onSaveClicked() {

        let save = updateTodo().then((saved) => {
            if (saved) {
                setFileData(null);
                setFile('');
                if (upload.current)
                    upload.current.clearAll();
                onClose("");
            }
        })
    }

    const handleKeyDown = (event: any) => {
        if (task !== undefined && task !== null && task.name !== null && task.name !== undefined && task.name !== '') {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if ((event.ctrlKey || event.metaKey) && !(event.shiftKey) && charCode === 's') {
                // console.log("CTRL+S Pressed");
                event.preventDefault();
                updateTodo();
                // onClose("");

            }
            if ((event.ctrlKey || event.metaKey) && (event.shiftKey) && charCode === 's') {
                // console.log("CTRL+SHIFT+S Pressed");
                event.preventDefault();
                updateTodo();
                setFile('');
                if (upload.current)
                    upload.current!.clearAll();
                onClose("");
            }
        }
    }


    const [fullScreen, setFullScreen] = useState(false);
    function newProjectClosed() {
        if (upload.current)
            upload.current!.clearAll();

        setFile(''); onClose("");
    }

    interface CustomMarker extends Marker {
        type: "success" | "error" | "warning";
        content: string;
        deviceId?: string | number;
    }
    async function uploadChanged(e: BeforeUploadEventArgs) {
        e.cancel = true;
        if (upload.current)
            upload.current.filesData.forEach(element => {
                setFileData(element);
                setFile(element.name);
            });
    }

    const onFileSelected = (e: any) => {
    }

    const anchorRef = React.useRef<HTMLButtonElement>(null);

    if (task !== undefined) {
        return (

            <Dialog onKeyDown={handleKeyDown} maxWidth="lg" fullScreen={fullScreen} open={open} onClose={onClose} style={{ backgroundColor: '#F5F5F5', marginTop: topMargin ? topMargin + 'px' : '80px' }}>
                <DialogTitle height='40px' sx={{ backgroundColor: '#F5F5F5', zIndex: "28" }}>
                    <div style={{ backgroundColor: '#F5F5F5', zIndex: "200" }}>
                        <div style={{ float: 'left', display: 'flex' }}>
                            <Typography variant='h1' fontWeight='bold' fontSize={20} sx={{ marginTop: '-5px', marginRight: '5px' }}>{t('investor.taskDetail')}</Typography>
                        </div>
                        <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>

                            {biggerThan600 && task!.date && <div>
                                <Typography variant='body1' sx={{ marginRight: '12px', marginTop: '2px' }} fontSize={11}>{(task.id! > 0) ? t('Created') + " " + task!.date!.toLocaleString(i18n.language) : ""} </Typography>
                            </div>}
                            <div style={{ marginTop: '-14px' }}>
                                <IconButton onClick={() => {
                                    setFullScreen(!fullScreen);
                                }} >
                                    {fullScreen ? <CloseFullscreen /> : <Fullscreen />}
                                </IconButton>
                                <IconButton onClick={newProjectClosed} >
                                    <Close />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </DialogTitle>
                {loading && <div style={{ margin: 'auto', minHeight: '500px', minWidth: '400px' }}>
                    {/* <div>
                        <Save sx={{ width: '30px', height: '30px', marginTop: '80px', marginBottom: '10px' }} />
                    </div> */}
                    <div style={{ fontSize: '20px', marginBottom: '10px', marginTop: '80px', marginLeft: '150px' }}>
                        {t('savingDots')}
                    </div>
                    <div>
                        <CircularProgress sx={{ opacity: '1', width: '16px', height: '16px', margin: 'auto', marginLeft: '165px' }} />
                    </div>
                </div>
                }
                {!loading && <div style={{ backgroundColor: '#F5F5F5' }} >
                    <ValidationGroup>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ margin: "10px" }}>
                                <Validate name='name-todo-validate' required={[true, t('FieldRequired')]}>
                                    <TextField
                                        size='small'
                                        error={task !== null && task !== undefined && task.name === ''}
                                        fullWidth sx={{ width: '95%', minWidth: '95%', margin: "10px", marginTop: "6px", marginBottom: '6px', backgroundColor: '#FFFFFF' }}
                                        onChange={e => setTask({ ...task, name: e.target.value })}
                                        label={(t('singleTodo.taskName'))} value={task.name} />
                                </Validate>
                            </div>

                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: "10px", marginBottom: '0px', marginTop: '0px' }}>
                                <TextField id="outlined-multiline-flexible"
                                    multiline
                                    maxRows={4} fullWidth sx={{ "& .MuiInputBase-root": { height: 100 }, width: '95%', minWidth: '95%', margin: "10px", marginTop: "6px", marginBottom: '6px', backgroundColor: '#FFFFFF', alignItems: "flex-start" }}
                                    onChange={e => setTask({ ...task, description: e.target.value })}
                                    label={t('Description')}
                                    value={task.description}
                                    inputProps={{
                                        sx: {
                                            alignItems: "flex-start"
                                        }
                                    }}
                                />
                            </div>
                            <div style={{ width: '250px', marginLeft: '20px', marginTop: '12px', marginRight: '24px', backgroundColor: '#F5F5F5' }}>
                                <BuildingSelect isReadOnly={false} onChange={(e) => { setTask({ ...task, buildingId: e!.id }) }} selectedProjectId={task !== null ? task.buildingId : null} />
                            </div>
                            <div style={{ margin: "20px", minWidth: "168px" }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div style={{ marginBottom: "16px", width: '280px' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker

                                                label={t('Deadline')}
                                                inputFormat="DD.MM.YYYY HH:mm"
                                                ampm={false}
                                                value={task.dateTo !== undefined ? task.dateTo! : null}
                                                onChange={(e, v) => {
                                                    setTask({ ...task, dateTo: e !== null && e !== undefined ? e! : undefined });
                                                }}
                                                renderInput={(params) => <TextField size='small' {...params} style={{ backgroundColor: '#FFFFFF', width: '280px' }} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div style={{ marginLeft: biggerThan600 ? "20px" : "0px", width: '250px' }}>
                                        <Autocomplete
                                            disablePortal
                                            disableClearable
                                            id="combo-box-demo"
                                            size="small"
                                            onChange={(e, v) => {
                                                setTask({ ...task, isDone: (v !== undefined && v !== null ? v!.id : undefined) });
                                            }}
                                            value={optionsStates.find((opt) => opt.id === task.isDone)}
                                            options={optionsStates}
                                            sx={{ width: 232, marginTop: "0px", backgroundColor: '#FFFFFF' }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label={t('state')} />}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginLeft: '20px', marginTop: '4px', marginRight: '24px' }}>
                                {task.id && <ToDoInvestorChatScene requestId={task.id} />}
                            </div>

                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: "10px" }}>

                                <TextField id="outlined-multiline-flexible"
                                    size='small'
                                    multiline
                                    maxRows={4} fullWidth sx={{ width: '95%', minWidth: '95%', margin: "10px", marginTop: "6px", marginBottom: '6px', backgroundColor: '#FFFFFF' }}
                                    onChange={e => setComment(e.target.value)}
                                    label={t('comment')}
                                />
                            </div>

                            <div style={{ marginLeft: '20px', marginTop: '4px', marginRight: '24px', backgroundColor: '#F5F5F5' }}>
                                <UploaderComponent
                                    multiple={false}
                                    maxFileSize={248888888888898}
                                    locale={i18n.language}
                                    selected={onFileSelected}
                                    showFileList={false}
                                    directoryUpload={false}
                                    autoUpload={true}
                                    ref={upload}
                                    beforeUpload={(e: BeforeUploadEventArgs) => { uploadChanged(e) }}
                                />
                                <div style={{ display: 'flex' }}>
                                    <div style={{ margin: '6px' }}>{file}</div>
                                    {file && <Button onClick={(e) => {
                                        upload.current!.clearAll();
                                        setFile('');
                                        setFileData(null);
                                    }} >x</Button>}
                                </div>
                            </div>
                            <div style={{ height: '1px', marginTop: '4px', backgroundColor: '#F5F5F5' }}></div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ margin: 'auto' }}>
                                    <AutoDisabler>
                                        <Button onClick={() => { onSaveClicked() }} sx={{ backgroundColor: '#FFC600', color: 'black', justifyContent: "flex-start", margin: "16px", marginBottom: "32px" }}>{t('saveAndClose')}</Button>
                                    </AutoDisabler>
                                </div>

                            </div>
                        </div>
                    </ValidationGroup>
                </div >
                }
            </Dialog >
        );
    }
    else return (<></>);
};
