import { ApiOldBaseHelper } from "./ApiOldBaseHelper";
import { BuildingDTO, BuildingForProjectsListPageDTO, CopyCutPasteProjectFileRequest, CopyCutPasteProjectFileResponse, CopyCutPasteType, CreateProjectFileRequest, CreateProjectFilesFromPathsRequest, CreateProjectFilesFromPathsResponse, DeleteBuildingsRequest, DeleteProjectFileDocumentRequest, DeleteProjectFileDocumentResponse, DeleteProjectFileRequest, DeleteProjectFileResponse, FileDirectoryDTO, FileParameter, GetBuildingDataDetailResponse, GetProjectFileDetailResponse, GetProjectFileDocumentsResponse, GetProjectFilesTreeResponse, MoveBuildingsFromTrashRequest, SaveBuildingRequest, SaveBuildingResponse, SaveProjectFileDetailRequest, SaveProjectFileDetailResponse, UploadProjectDocumentChunkResponse, UserResponsibleDTO } from "./ApiServerVov";

export class APIOldBuildingsClient extends ApiOldBaseHelper {


    public async GetBuildingsForProjectsListPage(deleted: boolean): Promise<BuildingForProjectsListPageDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().projects(deleted)).buildingsForProjectsListPage!;
    }

    public async GetBuildingDataDetail(buildingId: number): Promise<GetBuildingDataDetailResponse> {
        return (await this.GetAdressedAuthRefreshableClient().projectDetail(buildingId))!;
    }

    public async SaveBuilding(req: Partial<GetBuildingDataDetailResponse>): Promise<SaveBuildingResponse> {
        return (await this.GetAdressedAuthRefreshableClient().saveBuilding(new SaveBuildingRequest({
            id: req.id,
            name: req.name,
            code: req.code,
            isActive: req.isActive,
            showToEmployees: req.showToEmployees,
            contractNumber: req.contractNumber,
            gpsX1: req.gpsX1,
            gpsY1: req.gpsY1,
            gpsDiff1: req.gpsDiff1,
            address1: req.address1,
            parcelNumber1: req.parcelNumber1,
            gpsX2: req.gpsX2,
            gpsY2: req.gpsY2,
            gpsDiff2: req.gpsDiff2,
            address2: req.address2,
            parcelNumber2: req.parcelNumber2,
            gpsX3: req.gpsX3,
            gpsY3: req.gpsY3,
            gpsDiff3: req.gpsDiff3,
            address3: req.address3,
            parcelNumber3: req.parcelNumber3,
            gpsX4: req.gpsX4,
            gpsY4: req.gpsY4,
            gpsDiff4: req.gpsDiff4,
            address4: req.address4,
            parcelNumber4: req.parcelNumber4,
            city: req.city,
            client: req.client,
            architect: req.architect,
            houseType: req.houseType,
            notes: req.notes,
            assignedUsers: req.assignedUsers,
            assignedEmployees: req.assignedEmployees,
            hourlyRate: req.hourlyRate,
        })));
    }

    public async deleteBuildings(buildingsIds: number[]): Promise<boolean> {
        return ((await this.GetAdressedAuthRefreshableClient().deleteProjects(new DeleteBuildingsRequest({ buildingsIds: buildingsIds }))).ok!);
    }
    public async MoveProjectsFromTrash(buildingsIds: number[]): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().moveProjectsFromTrash(new MoveBuildingsFromTrashRequest({ deletedBuildingsIds: buildingsIds }))).ok!;
    }

    public async GetProjectFilesTree(projectId: number): Promise<FileDirectoryDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getProjectFilesTree(projectId)).files!;
    }

    public async GetProjectFileDetail(documentId: number): Promise<GetProjectFileDetailResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getProjectFileDetail(documentId));
    }

    public async SaveProjectFileDetail(data: SaveProjectFileDetailRequest): Promise<SaveProjectFileDetailResponse> {
        return (await this.GetAdressedAuthRefreshableClient().saveProjectFileDetail(data));
    }

    public async GetProjectFileDocuments(documentId: number): Promise<GetProjectFileDocumentsResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getProjectFileDocuments(documentId));
    }

    public async CreateProjectFile(buildingId: number, parentDocuemtnId: number | undefined, name: string): Promise<GetProjectFileDocumentsResponse> {
        return (await this.GetAdressedAuthRefreshableClient().createProjectFile(new CreateProjectFileRequest({ buildingId: buildingId, parentDocumentId: parentDocuemtnId, name: name })));
    }

    public async DeleteProjectFile(documentId: number): Promise<DeleteProjectFileResponse> {
        return (await this.GetAdressedAuthRefreshableClient().deleteProjectFile(new DeleteProjectFileRequest({ documentId: documentId })));
    }

    public async DeleteProjectFileDocument(documentFileId: number): Promise<DeleteProjectFileDocumentResponse> {
        return (await this.GetAdressedAuthRefreshableClient().deleteProjectFileDocument(new DeleteProjectFileDocumentRequest({ documentFileId: documentFileId })));
    }

    public async UploadProjectDocumentChunk(fileName: string, chunk: FileParameter, chunkNumber: number, totalChunks: number, key: string, buildingDocumentsId: number, path: string | undefined): Promise<UploadProjectDocumentChunkResponse> {
        return (await this.GetAdressedAuthRefreshableClient().uploadProjectDocumentChunk(fileName, chunk, chunkNumber, totalChunks, key, buildingDocumentsId, path));
    }    

    public async CreateProjectFilesFromPaths(buildingId: number, parentDocumentId: number, paths: string[]): Promise<CreateProjectFilesFromPathsResponse> {
        return (await this.GetAdressedAuthRefreshableClient().createProjectFilesFromPaths(new CreateProjectFilesFromPathsRequest({ buildingId: buildingId, parentDocumentId: parentDocumentId, paths: paths})));
    }

    public async GetUsersBuilding(buildingId: number): Promise<UserResponsibleDTO[] | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().getUsersBuilding(buildingId)).users;
    } 

    public async CopyCutPasteProjectFile(sourceBuildingDocumentId: number | undefined, targetBuildingDocumentId: number, type: CopyCutPasteType, filesIds: number[]): Promise<CopyCutPasteProjectFileResponse> {
        return (await this.GetAdressedAuthRefreshableClient().copyCutPasteProjectFile(new CopyCutPasteProjectFileRequest({ sourceBuildingDocumentId: sourceBuildingDocumentId, targetBuildingDocumentId: targetBuildingDocumentId, type: type, documentFiledIds: filesIds})));
    }
}