import axios, { AxiosInstance } from "axios";
import { ApiBaseHelper, SingletonRefreshClient } from "./ApiBaseHelper";
import { AddUsersGridSettingsRequest, AuthRequest, AuthResponse, CreateApplicationRequest, CreateApplicationResponse, CreatePaymentRequest, CreatePaymentResponse, DeleteProjectDTO, DeleteProjectsRequest, GetAdminForApplicationsDataResponse, GridSettingsDTO, GridType, PaymentRecuringType, ProjectDTO, ReauthResponse, RefreshTokenRequest, SubscriptionPlans, UpdateUsersGridSettingsRequest, UsersClient } from "./UsersMicroserviceClient";


export class APIUsersHelper extends ApiBaseHelper {

    private GetUsersAddressedAuthRefreshableClient(): UsersClient {
        let bsClient = this.GetAuthClient();
        return new UsersClient(this.GetApiAddress() + '/users', bsClient);
    }
    public async CallApiLogin(usernameIn: string, passwordIn: string): Promise<AuthResponse> {
        localStorage.removeItem('bearer');
        localStorage.removeItem('username');
        localStorage.removeItem('initials');
        localStorage.removeItem('company');
        localStorage.removeItem('refresh-token');
        let t = await this.GetClientToAuth().auth(new AuthRequest({ username: usernameIn, password: passwordIn }));
        localStorage.setItem('bearer', t.token!);
        localStorage.setItem('username', t.username!);
        localStorage.setItem('initials', t.initials!);
        localStorage.setItem('company', t.companyName!);
        localStorage.setItem('refresh-token', t.refreshToken!);
        return t;
    }
    public async Reauth(): Promise<ReauthResponse> {
        let t = await this.GetUsersAddressedAuthRefreshableClient().reauth();
        return t;

    }
    public async Subscribe(product: CreatePaymentRequest): Promise<CreatePaymentResponse> {
        let t = await this.GetClientToAuth().subscribe(product);
        return t;

    }
    public async CreateDemo(product: CreateApplicationRequest): Promise<CreateApplicationResponse> {
        let t = await this.GetClientToAuth().newDemoApplication(product);
        return t;

    }
    public async PaymentSession(subscriptionPlan: SubscriptionPlans, currency: string, reccuringType: PaymentRecuringType): Promise<string> {
        let t = await this.GetClientToAuth().subscribe(new CreatePaymentRequest({ paymentRecuringType: reccuringType, currency: currency, subscriptionPlan: subscriptionPlan }));
        return t.paymentUrl!;

    }
    public async RefreshToken(): Promise<void> {
        console.log('refreshstart');
        SingletonRefreshClient.setRefresh();
        let t = (await this.GetClientToAuth().refreshToken(new RefreshTokenRequest({ accessToken: localStorage.getItem('bearer')!, refreshToken: localStorage.getItem('refresh-token')! })));
        localStorage.setItem('bearer', t.accessToken!);
        localStorage.setItem('refresh-token', t.refreshToken!);
        SingletonRefreshClient.completeRefresh();
    }
    public async GetGridSettings(g: GridType): Promise<GridSettingsDTO[] | undefined> {
        let t = (await this.GetUsersAddressedAuthRefreshableClient().getGridSettings(g)).gridSettings;
        return t;

    }
    public async GetProjects(): Promise<ProjectDTO[]> {
        return (await this.GetUsersAddressedAuthRefreshableClient().getProjects()).projects!;
    }
    public async GetAdminData(): Promise<GetAdminForApplicationsDataResponse> {
        return (await this.GetUsersAddressedAuthRefreshableClient().getAdminData());
    }
    public async DeleteTodos(data: DeleteProjectDTO[]): Promise<void> {
        await this.GetUsersAddressedAuthRefreshableClient().deleteManyProjects(new DeleteProjectsRequest({ selectedProjects: data }));
    }
    public async InsertOrUpdateGridSettings(d: GridSettingsDTO, t: GridType): Promise<void> {
        if (d.id! > 0) {
            console.log("update");
            (await this.GetUsersAddressedAuthRefreshableClient().updateGridSetting(
                new UpdateUsersGridSettingsRequest({
                    id: d.id, name: d.name
                })));
            //update
        }
        else {
            (await this.GetUsersAddressedAuthRefreshableClient().addGridSetting(
                new AddUsersGridSettingsRequest({
                    gridData: d.gridData, name: d.name, isGroupingEnabled: d.isGroupingEnabled, isToolbarEnabled: d.isToolbarEnabled, gridType: t
                })));
            //insert
        }
    }

}