import { ChevronLeft, ChevronRight, Close, Redo, Rotate90DegreesCwOutlined, Save, Undo, ZoomIn, ZoomOut } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, IconButton } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import { ImageDiaryDTO } from '../../../ApiOld/ApiServerVov';
import ImageEditorDialog from '../Images/ImageEditorDialog';
import { ApiDiaryBaseHelper } from '../../../Api/ApiDiaryBaseHelper';

interface ImageGalleryProps {
    selected?: number;
    buildingId?: number;
    diaryImages?: ImageDiaryDTO[];
    open: boolean;
    newRequestButton: boolean;
    canSaveChanged?: boolean;
    canCreateTask?: boolean;
    onClose: (value: string) => void;
    onNewRequest?: (value: ImageDiaryDTO, buildingId?: number) => void;
    buildingDiaryRecordId?: number;
    needRefresh?: () => void;
    isSubdodavatel?: boolean;
}

export default function ImageGallery(props: ImageGalleryProps) {
    const { selected, diaryImages, open, newRequestButton, onClose, onNewRequest, buildingId, canCreateTask,
        canSaveChanged, buildingDiaryRecordId, needRefresh, isSubdodavatel } = props;
    const [selectedImage, setSelectedImage] = useState<number>(-1);
    const [scale, setScale] = useState<number>(30);
    const [rotation, setRotation] = useState<number>(0);
    const [rotateSaving, setRotateSaving] = useState<boolean>(false);
    const [imageEditorFile, setImageEditorFile] = useState<string | undefined>(undefined);
    const { t } = useTranslation();
    useEffect(() => {
        /* console.log(selected);
        console.log(diaryImages); */
        if (selected !== undefined)
            setSelectedImage(selected);
        else setSelectedImage(-1);
    }, [selected]);
    useEffect(() => {
        //console.log(selectedImage);

    }, [selectedImage]);

    const handleUserMenuClick = () => {
        if (selectedImage > -1 && onNewRequest)
            onNewRequest(diaryImages![selectedImage], buildingId);
    };

    return (
        <Dialog maxWidth="lg"
            style={{ zIndex: 1000000 }}
            PaperProps={{
                sx: {
                    maxHeight: '85%',
                    minHeight: '85%',
                    minWidth: '90%',
                    maxWidth: '90%'
                }
            }}
            open={open} onClose={onClose}>
            {imageEditorFile && <ImageEditorDialog open={imageEditorFile !== undefined ? true : false} onClose={() => { setImageEditorFile(undefined) }} imageUri={imageEditorFile!} />}
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                {!rotateSaving === true && <div style={{ height: '40px', zIndex: 10000, width: '100%', right: '0px', marginTop: '10px', background: 'white' }}>
                    <IconButton sx={{ width: '30px', height: '30px', border: '1px solid', marginLeft: '20px', marginRight: '20px' }} onClick={() => {
                        if (selectedImage > 0)
                            setSelectedImage(selectedImage - 1);
                        else setSelectedImage(diaryImages!.length - 1);
                    }}> <ChevronLeft /></IconButton>
                    <IconButton onClick={(e) => {
                        if (rotation < 270)
                            setRotation(rotation + 90);
                        else setRotation(0);
                    }}><Rotate90DegreesCwOutlined /></IconButton>
                    {canSaveChanged && buildingDiaryRecordId && <IconButton disabled={rotation === 0} onClick={(e) => {
                        setRotateSaving(true);
                        new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).SaveRotatedImageToDiary(buildingDiaryRecordId, diaryImages![selectedImage].url!, rotation).then((res) => {
                            // if (res.success) {
                            if (needRefresh)
                                needRefresh();
                            setRotateSaving(false);
                            // }
                        });
                    }}><Save /></IconButton>}
                    <IconButton onClick={(e) => { setScale(scale + 10); }}><ZoomIn /></IconButton>
                    <IconButton onClick={(e) => { setScale(scale - 10); }}><ZoomOut /></IconButton>
                    <IconButton disabled={rotation === 0 && scale === 30} onClick={(e) => { setScale(30); setRotation(0); }}><Undo /></IconButton>

                    {canCreateTask && <Button size='small' variant='contained' onClick={handleUserMenuClick}>{t('todo.createTask')}</Button>}
                    <IconButton sx={{ width: '30px', height: '30px', border: '1px solid', marginLeft: '20px', marginRight: '20px' }} onClick={() => { if (selectedImage < diaryImages!.length - 1) setSelectedImage(selectedImage + 1); else setSelectedImage(0); }}><ChevronRight /></IconButton>
                    <IconButton sx={{ width: '30px', height: '30px', border: '1px solid', marginRight: '16px', marginTop: '6px', float: 'right' }}
                        onClick={() => { onClose('') }}><Close /></IconButton>
                </div>}
                {rotateSaving && <div style={{ height: '40px', zIndex: 10000, width: '100%', right: '0px', marginTop: '10px', display: 'flex' }}>
                    <CircularProgress style={{ width: '20px', height: '20px', marginLeft: '20px' }} />
                    <div style={{ marginLeft: '8px', fontWeight: 'bold', fontSize: '12px', marginBottom: '2px', marginTop: '-2px' }}>{t('diary.savingRotatedImage')}</div>

                </div>}
                <div style={{ display: 'inline-block' }}>
                    {/* <div style={{ display: 'inline-block', margin: '10px' }}> */}
                    <div style={{ margin: '20px', display: 'flex' }}>
                        {diaryImages !== undefined && selectedImage > -1 &&
                            <img style={{ transform: `rotate(${rotation}deg)`, zoom: scale + '%', margin: '0 auto' }} loading='lazy' src={diaryImages[selectedImage].url} />}
                        {/* <img style={{ marginTop: '40px', maxWidth: scale === 0 ? '100%' : (window.innerWidth + scale) + 'px', maxHeight: scale === 0 ? '100%' : (window.innerHeight + scale) + 'px', transform: `rotate(${rotation}deg)` }} loading='lazy' src={diaryImages[selectedImage].url} />} */}
                        {/* </div> */}
                    </div>
                </div>

            </div>
            {/* {newRequestButton && <div style={{ display: 'flex-inline', position: 'absolute', bottom: '5px', right: '30px' }}>
                <Button onClick={handleUserMenuClick} sx={{ background: 'gray', color: 'white', border: "1px solid darkgray" }}>
                    Založit úkol
                </Button>
            </div>} */}

        </Dialog >);
}