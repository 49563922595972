import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { Alert, Button, CircularProgress, Snackbar } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'

import { APIOldBuildingsClient } from '../../../../ApiOld/ApiOldBuildingsClient';
import { FileDirectoryDTO } from '../../../../ApiOld/ApiServerVov';
import ProjectDocumentTreeScene from './ProjectDocumentTreeScene';
import ProjectDocumentDetailScene from './ProjectDocumentDetailScene';
import { Split } from 'ts-react-splitter';
import SelectFolderHolder from '../../../Shared/Layouts/SelectFolderHolder';
import { useMediaPredicate } from 'react-media-hook';
import { t } from 'i18next';
import { CopyPasteProvider } from '../Contexts/CopyPasteContext';


interface ProjectDocumentsSceneProps {
    projectId: number;
}

export default function ProjectDocumentsScene(props: ProjectDocumentsSceneProps) {
    const { projectId } = props;
    const [files, setFiles] = React.useState<FileDirectoryDTO[] | null>(null);
    const [selected, setSelected] = React.useState<FileDirectoryDTO>();
    const [refresh, setRefresh] = React.useState(false);

    const [error, setError] = useState('');
    const [splitPercent, setSplitPercent] = useState<number>(30);

    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    useEffect(() => { refreshTree(); }, []);

    useEffect(() => { if (refresh) setRefresh(false); }, [refresh]);

    useEffect(() => {
        if (files && selected && files.some(p => p.id === selected.id)) {
            setSelected(files.find(p => p.id === selected.id));
        }
    }, [files]);

    const refreshTree = async () => {
        if (projectId) {
            new APIOldBuildingsClient().GetProjectFilesTree(projectId).then(p => {
                setFiles(p);
            });
        }
        else
            setFiles([]);

        setRefresh(true);
    }

    if (files) {
        return (
            <div>
                <CopyPasteProvider>
                    <div style={{ padding: '8px' }}>
                        <div style={{ display: 'flex', gap: '8px' }}>
                            {biggerThan700 && <Split percent={splitPercent} setPercent={(e) => { if (e) setSplitPercent(e); }} >
                                <div style={{ paddingBottom: '0px', marginTop: '0px', marginBottom: '16px',/*  minWidth: '30%',  maxWidth: '30%',*/ height: '100%', zIndex: '30', /* overflowY: 'auto', */ textAlign: 'left', border: '1px solid lightgray', borderRadius: '5px', boxShadow: '4px 4px 16px 4px lightgray' }}>
                                    <ProjectDocumentTreeScene setFiles={setFiles} onRefresh={refreshTree} selected={selected} files={files} onSelect={(selected) => {
                                        console.log('Selected:', selected);
                                        setSelected(selected);
                                    }} projectId={projectId} />
                                </div>
                                {selected && <div style={{ paddingBottom: '0px', marginTop: '0px', /* minWidth: '70%', maxWidth: '70%', */ zIndex: '30', /* overflowY: 'auto',  */textAlign: 'left', border: '1px solid lightgray', borderRadius: '5px', boxShadow: '4px 4px 16px 4px lightgray' }}>
                                    <ProjectDocumentDetailScene onRefresh={refreshTree} onSave={() => {
                                        refreshTree();
                                    }} selectedDocumentId={selected.id} refresh={refresh} projectId={projectId} />
                                </div>}
                                {!selected && <div>
                                    <SelectFolderHolder title={t('files.chooseFolder')} />
                                </div>}

                            </Split>}
                            {!biggerThan700 && <div>
                                <div style={{ paddingBottom: '0px', marginTop: '0px', marginBottom: '16px',/*  minWidth: '30%',  maxWidth: '30%',*/ /* height: '100%', */ zIndex: '30', /* overflowY: 'auto',  */textAlign: 'left', border: '1px solid lightgray', borderRadius: '5px', boxShadow: '4px 4px 16px 4px lightgray' }}>
                                    <ProjectDocumentTreeScene setFiles={setFiles} onRefresh={refreshTree} selected={selected} files={files} onSelect={(selected) => {
                                        console.log('Selected:', selected);
                                        setSelected(selected);
                                    }} projectId={projectId} />
                                </div>
                                {selected && <div style={{ paddingBottom: '0px', marginTop: '0px', /* minWidth: '70%', maxWidth: '70%', */ zIndex: '30', /* overflowY: 'auto',  */textAlign: 'left', border: '1px solid lightgray', borderRadius: '5px', boxShadow: '4px 4px 16px 4px lightgray' }}>
                                    <ProjectDocumentDetailScene onRefresh={refreshTree} onSave={() => {
                                        refreshTree();
                                    }} selectedDocumentId={selected.id} refresh={refresh} projectId={projectId} />
                                </div>}
                                {!selected && <div>
                                    <SelectFolderHolder title={t('files.chooseFolder')} />
                                </div>}
                            </div>}
                            {/* <DeleteDialog open={pairingToDelete !== null} onClose={() => { setPairingToDelete(null) }} data={pairingToDelete} onDeleteAgree={deletePairing} /> */}
                            <Snackbar open={error !== ""} autoHideDuration={3000} onClose={() => setError("")}>
                                <Alert onClose={() => setError("")} severity="error" sx={{ width: '100%' }}>
                                    {error}
                                </Alert>
                            </Snackbar>
                        </div>
                    </div>
                </CopyPasteProvider>
            </div>)
    }
    else return <CircularProgress color="secondary" size={16} />;
}
