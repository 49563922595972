
import { AddCircleOutline, Check, CheckBox, NotInterested, Reply, Save } from '@mui/icons-material';
import { Autocomplete, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, TextareaAutosize, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SplitButton from '../../../Shared/SplitButton';
import KrosAdminUpperMenu from './KrosAdminUpperMenu';
import { APIVovKrosAdminHelper } from '../../../../ApiOld/ApiKrosAdminOldClient';
import { AdminApplicationDTO, AdminApplicationDataDTO, KrosAdminApplicationPermissionDTO } from '../../../../ApiOld/ApiServerVov';
import LanguagePicker from '../../../Shared/LanguagePicker';


interface KrosAdminApplicationPermissionSceneProps {
}

export default function KrosAdminApplicationPermissionScene(props: KrosAdminApplicationPermissionSceneProps) {
    const { t, i18n } = useTranslation();
    const [saving, setSaving] = useState<boolean>(false);
    const [appData, setAppData] = useState<Partial<KrosAdminApplicationPermissionDTO> | undefined>();
    const [appId, setAppId] = useState<number>();
    /*  const [messageData, setMessageData] = useState<{ title: string, text: string }>(); */

    const theme = useTheme();
    const navigate = useNavigate();

    const params = useParams();
    useEffect(() => {
        if (params['id']) {
            if (!Number.isNaN(parseInt(params['id']))) {
                setAppId(parseInt(params['id']));
                new APIVovKrosAdminHelper().GetApplicationPermissionsKrosAdmin(parseInt(params['id'])).then(e => {
                    if (e)
                        setAppData(e.permissions);
                    else setAppData(undefined);
                });
            }
        }
        else { setAppData(undefined); setAppId(undefined); }
    }, []);

    function saveApp() {
        // a ukládat změny?
        if (appData && appId) {
            setSaving(true);
            new APIVovKrosAdminHelper().SaveApplicationPermissionsKrosAdmin(appId, new KrosAdminApplicationPermissionDTO({
                ...appData
                /* adminButtons_detail: appData.adminButtons_detail, 
                adminButtons_list: appData.adminButtons_list,
                adminRoles_detail: appData.adminRoles_detail,
                adminRoles_list: appData.adminRoles_list */
            })).then((d) => {
                setSaving(false);
            });
        }
    }

    const redirectToList = () => {
        navigate('/' + params['lang'] + '/kros/admin/applications')
    }
    if (appData)
        return (
            <div>
                <div style={{ display: 'block', padding: '32px', paddingTop: '0px', marginTop: '8px' }}>
                    <div style={{ display: 'flex', marginTop: '8px' }}>
                        <div>
                            <IconButton color='primary' onClick={redirectToList}>
                                <Reply />
                            </IconButton>
                        </div>
                        {/* <div style={{ paddingTop: '12px', background: 'white', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px' }}>
                            <h6>Uživatelé - základní informace</h6>
                        </div> */}
                        <div style={{ marginLeft: 'auto', marginRight: '56px', paddingTop: '16px' }}>
                            <SplitButton selectedIndexInput={0} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                                startIconSplitButton={<Save />}
                                onMenuClick={(e) => {
                                    if (e === 0) {
                                        saveApp();
                                    }
                                    else if (e === 1) {
                                        saveApp();
                                        redirectToList();

                                    }
                                }}></SplitButton>
                        </div>
                    </div>
                    <div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Pridané tlačidlá</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.adminButtons_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, adminButtons_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.adminButtons_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, adminButtons_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Nastavenie / Užívatelia / Role</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.adminRoles_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, adminRoles_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.adminRoles_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, adminRoles_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Nastavenie / Užívateľské skupiny</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.adminUserGroups_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, adminUserGroups_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.adminUserGroups_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, adminUserGroups_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Nastavenie / Užívatelia</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.adminUsers_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, adminUsers_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.adminUsers_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, adminUsers_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Prispôsobenie</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.applications_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, applications_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                {/* <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.adminUsers_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, adminUsers_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                /> */}

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Projekty / Faktúry</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buildingBills_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buildingBills_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buildingBills_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buildingBills_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Stavebný denník / Prehľad denníkov</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buildingDiary_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buildingDiary_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buildingDiary_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buildingDiary_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Stavebný denník / Denné záznamy</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buildingDiaryRecords_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buildingDiaryRecords_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buildingDiaryRecords_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buildingDiaryRecords_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Projekty / Dokumenty</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buildingDocuments_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buildingDocuments_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buildingDocuments_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buildingDocuments_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Reporty / Odpracované hodiny na projekte</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buildingHours_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buildingHours_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                {/* <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buildingDocuments_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buildingDocuments_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                /> */}

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Projekty / Prehľad projektov</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buildings_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buildings_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buildings_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buildings_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Nastavenie / Pridané tlačítka</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buttons_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buttons_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buttons_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buttons_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Nástenka</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.dashboard_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, dashboard_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                {/* <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buttons_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buttons_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                /> */}

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Reporty / Zmazané záznamy</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.deleteLog_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, deleteLog_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                {/* <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.buttons_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, buttons_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                /> */}

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Dokumenty / Vzory dokumentov</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.documentTemplates_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, documentTemplates_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.documentTemplates_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, documentTemplates_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Dokumenty / Vyplnené dokumenty</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.documentTemplatesHistory_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, documentTemplatesHistory_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.documentTemplatesHistory_delete === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, documentTemplatesHistory_delete: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zmazanie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Zamestnanci / BOZP</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employeeBozps_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employeeBozps_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employeeBozps_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employeeBozps_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Zamestnanci / BOZP / Príkazy pravideľného opakovania</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employeeBozpsSettings_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employeeBozpsSettings_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employeeBozpsSettings_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employeeBozpsSettings_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Zamestnanci / Dokumenty</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employeeDocuments_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employeeDocuments_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employeeDocuments_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employeeDocuments_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Zamestnanci / Skupiny</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employeeGroups_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employeeGroups_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employeeGroups_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employeeGroups_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Zamestnanci / Dovolenky a absencie</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employeeHolidays_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employeeHolidays_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                {/* <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employeeGroups_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employeeGroups_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                /> */}

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Zamestnanci / Novinky</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employeeNews_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employeeNews_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employeeNews_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employeeNews_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Zamestnanci / Zamestnanci</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employees_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employees_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.employees_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, employees_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Projekty / Investori / Role</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.investorRoles_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, investorRoles_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.investorRoles_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, investorRoles_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Projekty / Investori</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.investors_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, investors_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.investors_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, investors_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Moje súbory</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.myFiles_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, myFiles_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.myFiles_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, myFiles_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Reporty / Zákazky</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.records_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, records_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.records_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, records_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Reporty / Zákazky</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.orders_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, orders_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                {/* <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.records_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, records_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                /> */}

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Zamestnanci / Záznamy</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.records_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, records_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.records_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, records_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Majetok / Požičovňa</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.rental_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, rental_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.rental_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, rental_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Úlohy</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.requests_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, requests_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.requests_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, requests_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Úlohy / Príkazy pravideľného opakovania</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.requestsRepeat_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, requestsRepeat_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.requestsRepeat_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, requestsRepeat_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Nastavenie / Základné nastavenie</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.settings_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, settings_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                {/*  <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.requestsRepeat_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, requestsRepeat_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                /> */}

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Reporty / Štatistiky</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.statistics_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, statistics_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                {/* <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.requestsRepeat_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, requestsRepeat_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                /> */}

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Sklad / Zostavy</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.stockAssemblies_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, stockAssemblies_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Vytvoriť a dokončiť rezerváciu</Typography>}
                                    labelPlacement="end"
                                />
                                {/* <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.requestsRepeat_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, requestsRepeat_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                /> */}

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Sklad / Výrobcovia</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.stockManufacturers_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, stockManufacturers_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.stockManufacturers_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, stockManufacturers_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Sklad / Kategórie</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.stockProductCategories_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, stockProductCategories_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.stockProductCategories_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, stockProductCategories_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Sklad / Položky</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.stockProducts_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, stockProducts_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.stockProducts_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, stockProducts_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Sklad / Prehľad skladov</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.stockWarehouses_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, stockWarehouses_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.stockWarehouses_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, stockWarehouses_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Reporty / Prechody medzi projektmi</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.transitions_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, transitions_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                {/*  <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.stockWarehouses_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, stockWarehouses_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                /> */}

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Majetok / Evidencia majetku</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.wealth_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, wealth_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.wealth_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, wealth_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Majetok / Dokumenty</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.wealthDocuments_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, wealthDocuments_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.wealthDocuments_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, wealthDocuments_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                />

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Reporty / Odpracované hodiny</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.workHours_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, workHours_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                {/* <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.wealthDocuments_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, wealthDocuments_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                /> */}

                            </div>
                        </div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '16px' }}>
                            <h4>Reporty / Odpracované hodiny zamestnanca</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zobrazenie výpisu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.workHoursPay_list === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, workHoursPay_list: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie výpisu</Typography>}
                                    labelPlacement="end"
                                />
                                {/* <FormControlLabel sx={{ marginLeft: '10px' }}
                                    value="Zmazanie záznamu"
                                    onChange={(e) => { }}
                                    control={
                                        <Checkbox sx={{ marginLeft: '10px', marginRight: '-6px' }} checked={appData.wealthDocuments_detail === false}
                                            checkedIcon={<CheckBox color='secondary' />} onChange={(e) => { setAppData({ ...appData, wealthDocuments_detail: !e.target.checked }); }}
                                        />}
                                    label={<Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>Zobrazenie záznamu</Typography>}
                                    labelPlacement="end"
                                /> */}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    else return <div>loading ....</div>;
}