import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default function printDocumentPDF(callbackFunc: (doc: any) => void) {
    //@ts-ignore
    window.html2canvas = html2canvas;
    var content = document.getElementById("pdfToPrint");
    var doc = new jsPDF('p', 'px', 'a4', true);

    doc.html(content!, {
        callback: callbackFunc,
        autoPaging: 'text',
        html2canvas: { scale: 0.287 },
        margin: [12, 8, 15, 8],
        //@ts-ignore
        pagebreak: { mode: 'avoid-all' },
        fontFaces: [{ family: "Roboto", weight: 900, stretch: "normal", src: [{ url: "/Roboto-Bold.ttf", format: 'truetype' }], }, { family: "Roboto", weight: 500, stretch: "normal", src: [{ url: "/Roboto-Regular.ttf", format: 'truetype' }], },]
    });

}