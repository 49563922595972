import { Check, Money, Send } from '@mui/icons-material';
import { Button, TextField, Typography, useTheme, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { APIVovInvestorHelper } from '../../../../ApiOld/ApiInvestorOldClient';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { InvestorsChatMessageDTO } from '../../../../ApiOld/ApiServerVov';
import { Accordion, AccordionDetails, AccordionSummary } from '../Styles/AccordionStyles';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';


interface InvestorChatWidgetProps {
    isExpanded: boolean;
    isInvestor?: boolean;
    isSubdodavatel?: boolean;
    recordIdInput: number;
}

export default function InvestorChatWidget(props: InvestorChatWidgetProps) {
    const { isExpanded, isInvestor, recordIdInput, isSubdodavatel } = props;
    const [expanded, setExpanded] = useState(false);
    const [recordId, setRecordId] = useState<number>();
    const [messages, setMessages] = useState<InvestorsChatMessageDTO[] | null>(null);
    const [newMessage, setNewMessage] = useState<string>('');
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    useEffect(() => {
        setExpanded(isExpanded);
    }, [isExpanded]);
    useEffect(() => {
        setRecordId(recordIdInput);
    }, [recordIdInput]);
    useEffect(() => {
        reloadData();
    }, [recordId]);
    function reloadData() {
        if (recordId)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).GetInvestorsChatMessages(recordId).then((d) => {
                if (d)
                    setMessages(d);
            });

    }
    return (<Accordion className='expander' expanded={expanded} onChange={(e) => { setExpanded(!expanded) }}>
        <AccordionSummary className='expander-header' aria-controls="panel1d-content" id="panel1d-header">
            <Money color='secondary' className='accordion-icon' />
            <Typography color={theme.palette.text.primary} fontWeight='bold'>{t('Investor')}</Typography>
            {(messages && messages.length > 0) && <Check style={{ width: '16px', height: '16px', marginTop: '2px', marginLeft: '8px' }} color='success' />}
        </AccordionSummary>
        <AccordionDetails>
            <div>
                <TextField placeholder='Zde můžete komunikovat v rámci denního záznamu.' InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Button variant='contained' startIcon={<Send />} onClick={() => {
                                if (recordId) {
                                    new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, isInvestor === undefined ? false : isInvestor).PostInvestorsMessage(recordId, newMessage).then((d) => {
                                        if (d) {
                                            reloadData();
                                            setNewMessage('');
                                        }
                                    });
                                }
                            }}>
                                {t('send')}
                            </Button>
                        </InputAdornment>
                    ),
                }} sx={{ margin: '8px', width: '99%' }} multiline value={newMessage} onChange={(e) => {
                    setNewMessage(e.target.value);
                }}></TextField>

            </div>
            <div>
                {messages && <div>{messages.map((message, i) => {
                    return <div key={i} style={{ padding: '3px', borderRadius: '6px', margin: '6px 2px', background: message.isMe ? 'lightgray' : '#F5F5F5' }}>
                        <div style={{
                            fontSize: '10px', fontWeight: 'bold', display: 'inline-block',
                            background: message.isInvestor ? theme.palette.success.main : theme.palette.primary.main, marginLeft: '8px',
                            borderRadius: '4px'
                        }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ margin: '2px', wordWrap: 'normal', marginLeft: '8px' }}>{message.userName}</div>
                                <div style={{ margin: '2px', marginRight: '8px' }}>{message.isInvestor ? ' (Investor)' : ' (Uživatel ERP)'}</div>
                                <div style={{ margin: '2px' }}>{message.datetime!.toLocaleDateString(i18n.language)}</div>
                                <div style={{ margin: '2px', marginRight: '8px' }}>{message.datetime!.toLocaleTimeString(i18n.language)}</div>
                            </div>
                        </div>
                        <div style={{ whiteSpace: 'pre-wrap', maxHeight: '200px', overflowY: 'auto', padding: '2px', margin: '10px', fontSize: '12px' }}>{message.text}</div>
                    </div>;
                })}</div>}
            </div>

        </AccordionDetails>
    </Accordion>);
}