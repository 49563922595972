import { CircularProgress, Typography } from '@mui/material';
import React from 'react';
import heml from '../../Assets/Engineer.gif';
export default function LoaderComponent() {
    return <div style={{ left: 'calc(50% - 70px)', top: 'calc(50% - 70px)', width: '140px', position: 'relative' }}>
        {/* <img width={150} height={150} src={heml} alt='loading...'></img> */}
        <div style={{ display: 'flex' }}>
            <div className="logo-up">
                <img
                    width="40px"
                    height="40px"
                    src="/favicon.ico"
                    alt="stavario"
                ></img>

            </div>
            <Typography fontSize={32} sx={{ color: 'black', marginLeft: '12px', marginBottom: '6px' }}>Stavario</Typography>
        </div>
        <CircularProgress sx={{ opacity: '1' }} />
    </div>;
}