import { Box, Tab, Tabs, IconButton, Checkbox, FormControlLabel, Autocomplete } from "@mui/material";
import { Check, Reply, Save } from '@mui/icons-material';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextareaAutosize, TextField } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import { ChangeEventArgs, HtmlEditor, Image as RTImage, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { useLoadScript } from "@react-google-maps/api";
import delay from "../../../Shared/Functions/Delay";
import SplitButton from "../../../Shared/SplitButton";
import { TabPanel } from "../../../Shared/Tabs/TabPanel";
import tabProps from '../../../Shared/Tabs/TabProps';
import { APIOldBuildingsClient } from "../../../../ApiOld/ApiOldBuildingsClient";
import { GetProjectFileDetailResponse, SaveProjectFileDetailRequest } from "../../../../ApiOld/ApiServerVov";
import AdminUserSelect from "../../../Shared/AdminUserSelect";
import InvestorSelect from "../../../Shared/InvestorSelect";
import EmailTemplateSelect from "../../../Shared/EmailTemplateSelect";
import BuildingSelect from "../../../Shared/BuildingSelect";
import ProjectDocumentFileScene from "./ProjectDocumentFileScene";
import { useMediaPredicate } from "react-media-hook";
import ProjectDocumentEmployeeSelect from "./ProjectDocumentEmployeeSelect";

interface ProjectDocumentDetailSceneProps {
    selectedDocumentId?: number;
    onSave: () => void;
    refresh: boolean;
    projectId: number;
    onRefresh: () => void;
}

export default function ProjectDocumentDetailScene(props: ProjectDocumentDetailSceneProps) {
    const { selectedDocumentId, onSave, refresh, projectId, onRefresh } = props;
    const [documentData, setDocumentData] = useState<Partial<GetProjectFileDetailResponse> | null>(null);

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [saveDone, setSaveDone] = useState<boolean>(false);
    const [refreshFiles, setRefreshFiles] = useState<boolean>(false);

    const params = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    useEffect(() => {
        refreshData();
    }, [selectedDocumentId]);

    const refreshData = () => {
        if (selectedDocumentId) {
            new APIOldBuildingsClient().GetProjectFileDetail(selectedDocumentId).then((d) => {
                setDocumentData(d);
            });
        }
        else setDocumentData(null);

        setRefreshFiles(true);
    }

    useEffect(() => {
        if (saveDone === true)
            delay(2000).then((d) => { setSaveDone(false); });
    }, [saveDone]);

    useEffect(() => {
        if (refresh === true)
            refreshData();

    }, [refresh]);

    useEffect(() => {
        if (refreshFiles === true)
            setRefreshFiles(false);

    }, [refreshFiles]);

    function saveDocument() {
        if (documentData)
            new APIOldBuildingsClient().SaveProjectFileDetail(new SaveProjectFileDetailRequest({
                id: documentData.id,
                adminUserFromId: documentData.adminUserFromId,
                adminUserToId: documentData.adminUserToId,
                canOrderSolution: documentData.canOrderSolution,
                emailTemplateId: documentData.emailTemplateId,
                expirationDate: documentData.expirationDate,
                investorId: documentData.investorId,
                isCategory: documentData.isCategory,
                isDone: documentData.isDone,
                name: documentData.name,
                showToClient: documentData.showToClient,
                signatureImage: documentData.signatureImage
            })).then((d) => {
                if (d)
                    setSaveDone(true);
                onSave();
            });
    }

    const redirectToList = () => {
        navigate('/' + i18n.language + '/' + params['domain']! + '/projects')
    }

    const categoriesOptions = [{ id: 0, value: t('files.folder') },
    { id: 1, value: t('files.files') }];

    const showInvestorOptions = [{ id: 0, value: t('yesL') },
    { id: 1, value: t('noL') }];

    const isDoneOptions = [{ id: 0, value: t('yesL') },
    { id: 1, value: t('noL') }];

    const orderSolutionOptions = [{ id: 0, value: t('yesL') },
    { id: 1, value: t('noL') }];

    if (documentData)
        return (
            <Box>
                <div style={{ position: 'absolute', left: '40px', top: '60px', zIndex: 30 }}>
                    <IconButton color='primary' onClick={redirectToList}>
                        <Reply />
                    </IconButton>
                </div>
                <div style={{ position: 'fixed', display: 'flex', right: '40px', top: '60px', zIndex: 30 }}>
                    {saveDone && <Check color='success' sx={{ marginRight: '20px' }} />}
                    <div style={{ marginRight: '10px' }}>
                        <SplitButton selectedIndexInput={0} disabled={documentData === undefined || documentData === null} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                            startIconSplitButton={<Save />}
                            onMenuClick={(e) => {
                                if (e === 0) {
                                    saveDocument();
                                }
                                else if (e === 1) {
                                    saveDocument();
                                    redirectToList();
                                }
                            }}></SplitButton></div>
                </div>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={(e, n) => { setSelectedTabIndex(n) }} aria-label="basic tabs example">
                        <Tab label={t('buildings.basicInformation')} {...tabProps(0)} />
                        <Tab label={t('files.files')} {...tabProps(1)} />
                    </Tabs>
                </Box>

                <TabPanel value={selectedTabIndex} index={0}>
                    {/* <ValidationGroup> */}
                    <div>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '8px' }}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', margin: '10px' }}>
                                {/* <div style={{ margin: '8px', minWidth: '172px' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-category"
                                        options={categoriesOptions}
                                        size="small"
                                        value={categoriesOptions[documentData.isCategory ? 1 : 0]}
                                        onChange={(e, v) => { if (v) { setDocumentData({ ...documentData, isCategory: v.id === 0 }) } }}
                                        getOptionLabel={(option) => option.value}
                                        renderInput={(params) => <TextField {...params} label={t('files.fileType')} />}
                                    />
                                </div> */}
                                <div style={{ margin: '8px' }}>
                                    <AdminUserSelect isSubdodavatel={false} onChange={async (e) => {
                                        setDocumentData({ ...documentData, adminUserFromId: e === null ? undefined : e.id });
                                    }} selectedUserId={documentData.adminUserFromId} label={t('files.fromUser')} />
                                </div>
                                <div style={{ margin: '8px' }}>
                                    <Validate name='name-file-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                        <TextField label={t('Name')} variant="outlined" fullWidth size='small'
                                            name='name'
                                            value={documentData.name}
                                            onChange={(e) => { setDocumentData({ ...documentData, name: e.target.value }) }}
                                        />
                                    </Validate>
                                </div>
                                <div style={{ margin: '8px', minWidth: '156px' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-show-investors"
                                        options={showInvestorOptions}
                                        size="small"
                                        disableClearable
                                        value={documentData.showToClient ? showInvestorOptions[0] : showInvestorOptions[1]}
                                        onChange={(e, v) => { if (v) { setDocumentData({ ...documentData, showToClient: v.id == 0 ? true : false }) } }}
                                        getOptionLabel={(option) => option.value}
                                        renderInput={(params) => <TextField {...params} label={t('files.showInvestors')} />}
                                    />
                                </div>
                                <div style={{ margin: '8px' }}>
                                    <AdminUserSelect isSubdodavatel={false} onChange={async (e) => {
                                        setDocumentData({ ...documentData, adminUserToId: e === null ? undefined : e.id });
                                    }} selectedUserId={documentData.adminUserToId} label={t('files.notificationForUser')} />
                                </div>
                                {/* <div style={{ margin: '8px' }}>
                                    <InvestorSelect isSubdodavatel={false} onChange={async (e) => {
                                        setDocumentData({ ...documentData, investorId: e === null ? undefined : e.id });
                                    }} selectedInvestorId={documentData.investorId} label={t('files.forInvestor')} />
                                </div> */}
                                <div style={{ margin: '8px' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-done"
                                        options={isDoneOptions}
                                        size="small"
                                        disableClearable
                                        value={documentData.isDone ? isDoneOptions[0] : isDoneOptions[1]}
                                        onChange={(e, v) => { if (v) { setDocumentData({ ...documentData, isDone: v.id == 0 ? true : false }) } }}
                                        getOptionLabel={(option) => option.value}
                                        renderInput={(params) => <TextField {...params} label={t('files.isDone')} />}
                                    />
                                </div>
                                <div style={{ margin: '8px', minWidth: biggerThan700 ? '396px' : '192px' }}>
                                    <Autocomplete
                                        disablePortal
                                        disabled
                                        id="combo-box-employee"
                                        options={[]}
                                        size="small"
                                        onChange={(e, v) => { }}
                                        renderInput={(params) => <TextField {...params} label={t('files.forEmployees')} />}
                                    />
                                    {/* <ProjectDocumentEmployeeSelect onChange={() => setDocumentData({ ...documentData, emply: v.id == 0 ? true : false })} /> */}
                                </div>
                                {/* <div style={{ margin: '8px' }}>
                                    <EmailTemplateSelect isSubdodavatel={false} onChange={async (e) => {
                                        setDocumentData({ ...documentData, emailTemplateId: e === null ? undefined : e.id });
                                    }} selectedTemplateId={documentData.emailTemplateId} label={t('files.emailTemplateExpiration')} />
                                </div> */}
                                <div style={{ margin: '8px' }}>
                                    <TextField label={t('files.expirationDate')} variant="outlined" fullWidth size='small'
                                        name='expDate'
                                        type="date"
                                        value={
                                            documentData.expirationDate &&
                                                documentData.expirationDate.toISOString() !== new Date(1, 0, 1).toISOString() ?
                                                documentData.expirationDate.toISOString().split('T')[0] :
                                                undefined
                                        }
                                        onChange={(e) => { console.log(e); setDocumentData({ ...documentData, expirationDate: e.target.value ? new Date(e.target.value) : undefined }) }}
                                    />
                                </div>
                                {/* <div style={{ margin: '8px', minWidth: '172px' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-orderSolution"
                                        options={orderSolutionOptions}
                                        size="small"
                                        value={documentData.canOrderSolution ? orderSolutionOptions[0] : orderSolutionOptions[1]}
                                        onChange={(e, v) => { if (v) { setDocumentData({ ...documentData, canOrderSolution: v.id == 0 ? true : false }) } }}
                                        getOptionLabel={(option) => option.value}
                                        renderInput={(params) => <TextField {...params} label={t('files.canOrderSolution')} />}
                                    />
                                </div> */}
                                {/* {documentData.signatureImage && <img src={documentData.signatureImage} width="200px" height="54px" style={{ margin: '16px' }} />} */}
                            </div>
                        </div>

                    </div>
                    {/* </ValidationGroup> */}
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={1}>
                    <div style={{ width: '100%' }}>
                        <ProjectDocumentFileScene onRefresh={onRefresh} selectedDocumentId={documentData.id} onSave={() => { }} refresh={refreshFiles} projectId={projectId} />
                    </div>
                </TabPanel>
            </Box>
        );
    return <div>{t('loading...')}</div>;
}