import { green } from '@mui/material/colors';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { APITodoHelper } from '../Api/ApiTodoHelper';
import { APIUsersHelper } from '../Api/ApiUsersHelper';
import { TodoClient, UserResponsibleDTO } from '../Api/TodoMicroserviceClient';
import { ProjectDTO } from '../Api/UsersMicroserviceClient';

interface StavarioContextInterface {
    isAdmin?: boolean;
    name?: string;
    initials?: string;
    companyName?: string;
    users?: UserResponsibleDTO[];
    projects?: ProjectDTO[];
}
export const StavarioUpdateContext = React.createContext<((data: StavarioContextInterface) => void) | undefined>(undefined);
export const StavarioGlobalContext = React.createContext<StavarioContextInterface>({ name: "main" });


export async function SolveUsersInContext(g: StavarioContextInterface, c: (data: StavarioContextInterface) => void): Promise<UserResponsibleDTO[]> {
    // console.log('solving users');
    if (g.users === undefined || g.users === null || g.users!.length === 0) {
        let u = await new APITodoHelper().GetUsers();
        g.users = u;
        c(g);
        //   .then((usrs) => {
        //         g.users = usrs;
        //         c(g);
        //     })
    }
    return g.users!;
}
export async function SolveProjectsContext(g: StavarioContextInterface, c: (data: StavarioContextInterface) => void): Promise<ProjectDTO[]> {
    // console.log('solving users');
    if (g.projects === undefined || g.projects === null || g.projects!.length === 0) {
        let u = await new APIUsersHelper().GetProjects();
        g.projects = u;
        c(g);
        //   .then((usrs) => {
        //         g.users = usrs;
        //         c(g);
        //     })
    }
    return g.projects!;
}
const LoginMainContext = ({ subPages }: any) => {

    function setGlobalState(data: StavarioContextInterface) {
        setContext(data);

    }
    const [context, setContext] = useState<StavarioContextInterface>({ name: "" });
    return (
        <StavarioGlobalContext.Provider value={context}>
            <StavarioUpdateContext.Provider value={setGlobalState}>
                {subPages}
            </StavarioUpdateContext.Provider>
        </StavarioGlobalContext.Provider>
    )
}
export default LoginMainContext;