import { IconButton, Button, CircularProgress, useTheme, Menu, MenuItem } from "@mui/material";
import { Article, Description, InsertDriveFile, PictureAsPdf, TableView, Image, CloudUploadOutlined, TroubleshootTwoTone } from '@mui/icons-material';
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { APIOldBuildingsClient } from "../../../../ApiOld/ApiOldBuildingsClient";
import { BuildingFileDTO, CopyCutPasteType, FileParameter } from "../../../../ApiOld/ApiServerVov";
import { useMediaPredicate } from "react-media-hook";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import UploadSnackbar from "../../../Shared/UploadSnackbar";
import delay from "../../../Shared/Functions/Delay";
import DeleteDialog from "../../../Shared/Layouts/DeleteDialog";
import { UploadFileDC, useUploadFileStore } from "../../../../Contexts/UploadFileContext";
import { makeRandomKey } from "../../../Shared/MakeRandomKey";
import { truncateFileName } from "../../../Shared/TruncateFileName";
import { ContainsFolder } from "../../../Shared/Functions/ContainsFolder";
import { getFilesFromDataTransferItems } from "datatransfer-files-promise";
import CircularProgressDialog from "../../../Shared/CircularProgressDialog";
import { CopyPasteContext } from "../Contexts/CopyPasteContext";
import ProjectDocumentAutocompleteSearchBar from "./ProjectDocumentAutocompleteSearchBar";
import ProjectDocumentFileItem from "./ProjectDocumentFileItem";

interface ProjectDocumentFileSceneProps {
    selectedDocumentId?: number;
    projectId: number;
    onSave: () => void;
    refresh: boolean;
    onRefresh: () => void;
}

export default function ProjectDocumentFileScene(props: ProjectDocumentFileSceneProps) {
    const { selectedDocumentId, onSave, refresh, projectId, onRefresh } = props;
    const [documentFiles, setDocumentFiles] = useState<Partial<BuildingFileDTO[]>>([]);
    const [selectedDocumentFiles, setSelectedDocumentFiles] = useState<BuildingFileDTO[]>([]);
    const [scrollFile, setScrollFile] = useState<BuildingFileDTO>();
    const [uploading, setUploading] = useState<boolean>(false);
    const [createFolderDialogOpen, setCreateFolderDialogOpen] = useState(false);
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const theme = useTheme();
    const { uploadingFiles, addFile, uploadFiles } = useUploadFileStore();

    const contextValue = useContext(CopyPasteContext);

    const params = useParams();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        refreshData();
    }, [selectedDocumentId]);

    useEffect(() => {
        if (!uploadingFiles.some(p => p.documentId === selectedDocumentId))
            refreshData();
    }, [uploadingFiles]);

    useEffect(() => {
        if (refresh)
            refreshData();
    }, [refresh]);

    const refreshData = useCallback(() => {
        setDocumentFiles([]);
        if (selectedDocumentId) {
            setUploading(true);

            new APIOldBuildingsClient().GetProjectFileDocuments(selectedDocumentId).then((d) => {
                if (d.files)
                    setDocumentFiles(d.files);
                else setDocumentFiles([]);
                setUploading(false);
            });
        }
    }, [selectedDocumentId, setDocumentFiles, setUploading]);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0 && selectedDocumentId) {
            if (e.target.files.length > 0) {
                /* let upFiles: UploadFileDC[] = [];
                for (let i = 0; i < e.target.files.length; i++) {
                    upFiles.push(new UploadFileDC(e.target.files[i], false, false, selectedDocumentId!));
                }
                loadUploadingFiles(upFiles); */
                for (let i = 0; i < e.target.files.length; i++) {
                    addFile(new UploadFileDC(e.target.files[i], false, false, selectedDocumentId, true, ''));
                    //await uploadFile(e.target.files[i]);
                    /* loadUploadingFiles((prev: UploadFileDC[]) => prev ? prev.map((item, ind) =>
                        i === ind ? { ...item, isDone: true } : item
                    ) : prev); */
                }
                uploadFiles();
            }
            /* setUploading(true);

            for (let i = 0; i < e.target.files.length; i++) {
                await uploadFile(e.target.files[i]);
            }

            setUploading(false); */
        }
    };



    const [isDragging, setIsDragging] = useState(false);
    const handleDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback(() => {
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback(
        async (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();
            setIsDragging(false);

            const { files } = event.dataTransfer;
            if (files.length > 0) {
                let hasFolder = ContainsFolder(event);

                if (hasFolder) {
                    var allFiles = await getFilesFromDataTransferItems(event.dataTransfer.items);
                    //let filesObject = await fileListToObject(files);
                    setCreateFolderDialogOpen(true);
                    new APIOldBuildingsClient().CreateProjectFilesFromPaths(projectId, selectedDocumentId!, allFiles.map(p => (p as any).filepath)).then(p => {
                        setCreateFolderDialogOpen(false);
                        onRefresh();
                        for (let i = 0; i < allFiles.length; i++) {
                            addFile(new UploadFileDC(allFiles[i], false, false, selectedDocumentId!, true, (allFiles[i] as any).filepath));
                        }
                        uploadFiles();
                    });
                }
                else {
                    for (let i = 0; i < files.length; i++) {
                        addFile(new UploadFileDC(files[i], false, false, selectedDocumentId!, true, ''));
                    }
                    uploadFiles();
                }
            }
        },
        []
    );

    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();

        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null
        );
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    async function onDelete() {
        for (var v of selectedDocumentFiles) {
            if (v && v.id)
                await new APIOldBuildingsClient().DeleteProjectFileDocument(v.id)/* .then(p => {
                    setDeleteDialogOpen(false);
                    refreshData();
                }); */;
        }
        setDeleteDialogOpen(false);
        refreshData();
    }

    const handlePasteFiles = useCallback(() => {
        if (contextValue !== null && contextValue.copyPasteFileIds && selectedDocumentId && contextValue.copyCutType !== undefined && contextValue.copyCutType !== null) {
            setUploading(true);
            new APIOldBuildingsClient().CopyCutPasteProjectFile(undefined, selectedDocumentId, contextValue.copyCutType as CopyCutPasteType, contextValue.copyPasteFileIds).then(p => {
                onRefresh();
                setUploading(false);
            });
        }
    }, [onRefresh, contextValue, selectedDocumentId]);

    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    const setSelectedDocumentFromSearch = (e: string) => {
        let selected = documentFiles.filter(p => p !== undefined && p.name === e);
        if (selected.length > 0 && selected[0] !== undefined) {

            setSelectedDocumentFiles([selected[0]]);
            setScrollFile(selected[0]);
        }
    }

    if (!uploading)
        return (
            <div onContextMenu={(e) => { e.preventDefault(); e.stopPropagation(); handleContextMenu(e) }} onClick={() => { handleCloseContextMenu(); }} style={{ minHeight: '128px' }}>
                <div style={{ display: 'flex' }}>
                    <Button sx={{ marginRight: '20px', marginTop: '6px', height: '24px', minWidth: biggerThan700 ? '150px' : '80px' }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (fileInputRef.current) {
                                fileInputRef.current.click();
                            }
                        }}
                        variant='contained' size='small' startIcon={biggerThan700 ? <InsertDriveFile /> : undefined}>{biggerThan700 ? t('files.addFiles') : <InsertDriveFile sx={{ height: '20px' }} />}
                    </Button>
                    <div style={{
                        border: isDragging ? '2px dashed' : '1px dashed',
                        borderColor: isDragging ? theme.palette.text.primary : 'black',
                        cursor: 'pointer',
                        height: '44px',
                        display: 'flex',
                        padding: '8px',
                        paddingTop: '10px'
                    }}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}>
                        <CloudUploadOutlined sx={{ width: '20px', height: '20px', marginLeft: '20px', marginRight: '20px' }} />
                        <div style={{ marginRight: '20px' }}>
                            {biggerThan700 ? t('files.dragFiles') : ''}
                        </div>
                    </div>
                    <div>
                        <ProjectDocumentAutocompleteSearchBar list={documentFiles} onSelected={(e) => { setSelectedDocumentFromSearch(e) }} />
                    </div>
                </div>
                <div style={{ height: '60vh', overflowY: 'auto' }}>
                    <div style={{ display: 'flex', marginTop: '16px', flexWrap: 'wrap' }}>
                        {documentFiles.map((doc, i) => {
                            return <ProjectDocumentFileItem scrollToItem={(doc && scrollFile && doc.id === scrollFile.id) === true} openDeleteDialog={() => setDeleteDialogOpen(true)} key={doc!.id} doc={doc!} onRefresh={onRefresh} selectedDocumentFiles={selectedDocumentFiles} setSelectedDocumentFiles={setSelectedDocumentFiles} setUploading={setUploading} />
                        }
                        )}
                    </div>
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                // Add any other file input attributes as needed
                />

                {/* <UploadSnackbar onClose={() => { setUploadingFiles(undefined) }} isOpen={uploadingFiles !== undefined} files={uploadingFiles} /> */}
                <DeleteDialog data={selectedDocumentFiles} onDeleteAgree={onDelete} onClose={() => setDeleteDialogOpen(false)} open={deleteDialogOpen} />
                <CircularProgressDialog open={createFolderDialogOpen} onClose={() => setCreateFolderDialogOpen(true)} title={t('creatingFolderStructure')} />

                <Menu
                    open={contextMenu !== null}
                    onClose={handleCloseContextMenu}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <MenuItem disabled={selectedDocumentFiles.length === 0} onClick={(e) => {
                        e.preventDefault(); e.stopPropagation();
                        contextValue?.setCopyPasteFileIds(selectedDocumentFiles.map(p => p.id!).filter(onlyUnique));
                        contextValue?.setCopyCutType(CopyCutPasteType._2);
                        handleCloseContextMenu();
                    }}>{t('files.copyFile')}
                    </MenuItem>
                    <MenuItem disabled={selectedDocumentFiles.length === 0} onClick={(e) => {
                        e.preventDefault(); e.stopPropagation();
                        contextValue?.setCopyPasteFileIds(selectedDocumentFiles.map(p => p.id!).filter(onlyUnique));
                        contextValue?.setCopyCutType(CopyCutPasteType._3);
                        handleCloseContextMenu();
                    }}>{t('files.cutFile')}
                    </MenuItem>
                    <MenuItem disabled={contextValue === null || contextValue.copyPasteFileIds === null || contextValue.copyPasteFileIds.length === 0} onClick={(e) => {
                        e.preventDefault(); e.stopPropagation();
                        handlePasteFiles();
                        handleCloseContextMenu();
                    }}>{t('files.pasteFile')}
                    </MenuItem>

                </Menu>

                <DeleteDialog data={selectedDocumentFiles} onDeleteAgree={onDelete} onClose={() => setDeleteDialogOpen(false)} open={deleteDialogOpen} />
            </div>
        );
    else
        return (<CircularProgress />);
}