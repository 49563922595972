import React, { useEffect } from "react";
import { Autocomplete, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { Validate, ValidationGroup } from "mui-validate";
import { GetDiariesForUserDTO } from "../../../../ApiOld/ApiServerVov";
import { APIVovHelper } from "../../../../ApiOld/ApiOldClient";
import { ApiDiaryBaseHelper } from "../../../../Api/ApiDiaryBaseHelper";

export interface DiaryListProps {
    selectedDiaryId?: number | null;
    onChange: (id: number | undefined) => void;
    buildingId?: number;
    isSubdodavatel?: boolean;
    isSelecteMain?: boolean;
    readOnly?: boolean;
    isCreating?: boolean;
}
export default function DiarySelect(props: DiaryListProps) {
    const { onChange, selectedDiaryId, isSubdodavatel, isSelecteMain, readOnly, buildingId, isCreating } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [selectedDiary, setSelectedDiary] = React.useState<GetDiariesForUserDTO | null>(null);
    const [diaries, setDiaries] = React.useState<GetDiariesForUserDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    /* const handleChange = (event: SelectChangeEvent<typeof selectedDiary>) => {
        if (event.target.value !== undefined) {
            setSelectedDiary(event.target.value as (GetDiariesForUserDTO | null));

            onChange(event.target.value. );
        }

    }; */
    useEffect(() => {
        if (!isCreating)
            new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetDiaries(false, true, buildingId).then((data) => {
                setDiaries(data);
            }).catch(() => { console.log('err loading diaries'); });
    }, []);

    useEffect(() => {
        if (isCreating) {
            setSelectedDiary(null);
            if (buildingId)
                new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).GetDiaries(false, true, buildingId).then((data) => {
                    setDiaries(data);
                }).catch(() => { console.log('err loading diaries'); });
            else
                setDiaries([]);
        }

    }, [buildingId]);

    useEffect(() => {
        if (diaries !== undefined && diaries !== null && diaries.length > 0
            && selectedDiaryId !== undefined &&
            selectedDiaryId !== null && selectedDiaryId > 0) {
            let q = diaries.find(x => x.id == selectedDiaryId);
            if (q !== undefined)
                setSelectedDiary(q);
        }

    }, [diaries]);
    return (
        <div>
            <ValidationGroup>
                <Validate name={'email-signup-validate-input'} required>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        onChange={(e, v) => {
                            setSelectedDiary(v);
                            onChange(v?.id);
                        }}
                        getOptionLabel={o => o !== null ? o!.name! : ""}
                        value={selectedDiary}
                        options={diaries}
                        readOnly={readOnly}
                        fullWidth={biggerThan700}
                        sx={{ minWidth: 288, backgroundColor: '#FFFFFF' }}
                        renderInput={(params) => <TextField {...params} label={isSelecteMain === true ? t('diary.mainDiary') : t('diary.diary')}

                        />}
                    /></Validate></ValidationGroup>
        </div>
    );
}