import { L10n, loadCldr } from '@syncfusion/ej2-base';
import n3 from '../../node_modules/cldr-numbers-full/main/cs/numbers.json';
import n3sk from '../../node_modules/cldr-numbers-full/main/sk/numbers.json';
import n3pl from '../../node_modules/cldr-numbers-full/main/pl/numbers.json';
export default function InitSfUploaderLocale() {
    L10n.load({
        "cs": {
            "uploader": {
                "Browse": "Procházet",
                "Clear": "Vyčistit",
                "Upload": "Nahrát",
                "cancel": "Zrušit",
                "dropFilesHint": "Nebo sem přetáhněte soubory.",

            }
        },
        "pl": {
            "uploader": {
                "Browse": "Przeglądaj",
                "Clear": "Czysty",
                "Upload": "Wgrywać",
                "cancel": "Anulować",
                "dropFilesHint": "Lub przeciągnij pliki tutaj...",

            }
        },
        "en": {
            "uploader": {
                "Browse": "Browse",
                "Clear": "Clear",
                "Upload": "Upload",
                "cancel": "Cancel",
                "dropFilesHint": "Or drop files here"
            }
        },
        "sk": {
            "uploader": {
                "Browse": "Prechádzať",
                "Clear": "Vyčistiť",
                "Upload": "Nahrať",
                "cancel": "Zrušiť",
                "dropFilesHint": "Alebo sem presuňte súbory"
            }
        }
    });
    loadCldr(n3);
    loadCldr(n3sk);
    loadCldr(n3pl);
}
