import { ProjectDTO } from "../../Api/UsersMicroserviceClient";
import React, { useEffect } from "react";
import { Autocomplete, FormControl, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BuildingDTO, WarehouseDTO } from "../../ApiOld/ApiServerVov";
import { SolveProjectsContext, SolveWarehousesContext, StavarioOldGlobalContext, StavarioOldUpdateContext } from "../../Contexts/LoginOldContext";
import { useMediaPredicate } from "react-media-hook";
import { Validate, ValidationGroup } from "mui-validate";

export interface WarehouseSelectProps {
    selectedWarehouseId?: number | null;
    onChange: (event: any | null) => void;
    isMandatory?: boolean;
}
export default function WarehouseSelect(props: WarehouseSelectProps) {
    const { onChange, selectedWarehouseId, isMandatory } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [selectedWarehouse, setSelectedWarehouse] = React.useState<WarehouseDTO | null>(null);
    const [warehouses, setWarehouses] = React.useState<WarehouseDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const globalContext = React.useContext(StavarioOldGlobalContext);
    const setGlobal = React.useContext(StavarioOldUpdateContext)!;

    useEffect(() => {

        SolveWarehousesContext(globalContext, setGlobal).then((res) => {
            setWarehouses(res)
        });

    }, []);
    useEffect(() => {
        if (warehouses !== undefined && warehouses !== null && warehouses.length > 0
            && selectedWarehouseId !== undefined &&
            selectedWarehouseId !== null && selectedWarehouseId > 0) {
            let q = warehouses.find(x => x.id == selectedWarehouseId);
            if (q !== undefined)
                setSelectedWarehouse(q);
        }

    }, [warehouses]);
    return (
        <div>

            <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                onChange={(e, v) => {
                    setSelectedWarehouse(v);
                    onChange(v);
                }}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                value={selectedWarehouse}
                options={warehouses}

                fullWidth={biggerThan700}
                sx={{ minWidth: 288 }}
                renderInput={(params) => <TextField error={isMandatory === true && selectedWarehouse === null}  {...params} label='xxx Sklad'

                />}
            />
            {selectedWarehouse === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '-10px', marginTop: '4px', color: theme.palette.error.main }}>{t('FieldRequired')}</p>}
        </div>
    );
}