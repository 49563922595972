import { Close, CloseFullscreen, Fullscreen } from '@mui/icons-material';
import { Button, Dialog, DialogTitle, IconButton, Popover, styled, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';

import { ChangeEventArgs, HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import validator from 'validator';
import { APIVovInvestorHelper } from '../../../ApiOld/ApiInvestorOldClient';
import { StavarioOldGlobalContext } from '../../../Contexts/LoginOldContext';
import { loadInvestorStore, useInvestorStore } from '../../../Contexts/InvestorZContext';
import { GetInvestorInfoResponse } from '../../../ApiOld/ApiServerVov';


export interface InvestorCreditsChangeProps {
    open: boolean;
    onClose: (value: string) => void;
    onRefresh: (changed: boolean) => void;
}


export default function InvestorCreditsChangeScene(props: InvestorCreditsChangeProps) {
    const { onClose, open } = props;
    const { t, i18n } = useTranslation();
    const [newEmail, setNewEmail] = React.useState('');
    const [newName, setNewName] = React.useState('');
    const { credentials, load } = useInvestorStore();
    function setCredits() {
        if (credentials === undefined)
            loadInvestorStore().then((d: GetInvestorInfoResponse) => {
                load(d);
                setNewEmail(d.email!);
                setNewName(d.name!);
            })
        else {
            setNewEmail(credentials.email!);
            setNewName(credentials.name!);
        }
    }
    useEffect(() => {
        setCredits();
    }, []);
    useEffect(() => {
        setCredits();
    }, [open]);

    async function updateInvestor(): Promise<boolean> {
        if (validator.isEmail(newEmail) && newName != '') {
            let t = await (new APIVovInvestorHelper()).UpdateInvestorCredentials(newName, newEmail);
            if (t) {
                load(new GetInvestorInfoResponse({ name: newName, email: newEmail, companyName: credentials?.companyName }));
                onClose("");
            }
        }
        return true;
    }

    function onSaveClicked() {

        let save = updateInvestor().then((saved) => {
            if (saved) {
                onClose("");
            }
        })
    }

    const handleKeyDown = (event: any) => {
        if (newName !== undefined && newName !== null && newName.length > 0 && validator.isEmail(newEmail)) {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if ((event.ctrlKey || event.metaKey) && !(event.shiftKey) && charCode === 's') {
                // console.log("CTRL+S Pressed");
                event.preventDefault();
                updateInvestor();
                // onClose("");

            }
            if ((event.ctrlKey || event.metaKey) && (event.shiftKey) && charCode === 's') {
                // console.log("CTRL+SHIFT+S Pressed");
                event.preventDefault();
                updateInvestor();
                onClose("");
            }
        }
    }


    const [fullScreen, setFullScreen] = useState(false);
    function dialogClosed() { onClose(""); }

    return (
        <Dialog onKeyDown={handleKeyDown} maxWidth="lg" fullScreen={fullScreen} open={open} onClose={onClose}>
            <DialogTitle height='40px' sx={{ zIndex: "28" }}>
                <div style={{ backgroundColor: '#F2F2F2', zIndex: "200" }}>
                    <div style={{ float: 'left', display: 'flex' }}>
                        <Typography variant='h1' fontWeight='bold' fontSize={20}>{t('yourAccount')}</Typography>
                    </div>
                    <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>

                        {/* {biggerThan600 && task!.date && <div>
                            <Typography variant='body1' sx={{ marginRight: '12px' }} fontSize={11}>{(task.id! > 0) ? t('Created') + " " + task!.date!.toLocaleString() : ""} </Typography>
                        </div>} */}
                        <div style={{ marginTop: '-14px' }}>
                            <IconButton onClick={() => {
                                setFullScreen(!fullScreen);
                            }} >
                                {fullScreen ? <CloseFullscreen /> : <Fullscreen />}
                            </IconButton>
                            <IconButton onClick={dialogClosed} >
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </DialogTitle>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F2F2F2' }} >
                <ValidationGroup>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', margin: "10px" }}>
                            <Validate name='name-investor-validate' initialValidation='noisy' required={[true, t('FieldRequired')]}>
                                <TextField
                                    size='small'
                                    fullWidth sx={{ width: '95%', minWidth: '95%', margin: "10px", marginTop: "6px", marginBottom: '6px', backgroundColor: '#FFFFFF' }}
                                    onChange={e => setNewName(e.target.value)}
                                    label={t('HumansName')} value={newName} />
                            </Validate>
                        </div>

                        <div style={{ display: 'flex', flexWrap: 'wrap', margin: "10px" }}>
                            <Validate name='email-investor-validate' required={[true, t('FieldRequired')]}>
                                <TextField
                                    size='small'
                                    fullWidth sx={{ width: '95%', minWidth: '95%', margin: "10px", marginTop: "6px", marginBottom: '6px', backgroundColor: '#FFFFFF' }}
                                    onChange={e => setNewEmail(e.target.value)}
                                    label={t('login.enterEmail')} value={newEmail} />
                            </Validate>
                        </div>

                        <div style={{ height: '1px', marginTop: '4px', backgroundColor: '#F2F2F2' }}></div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ margin: 'auto' }}>
                                <AutoDisabler>
                                    <Button onClick={() => { onSaveClicked() }} sx={{ backgroundColor: '#FFC600', color: 'black', justifyContent: "flex-start", margin: "16px", marginBottom: "32px" }}>{t('saveAndClose')}</Button>
                                </AutoDisabler>
                            </div>

                        </div>
                    </div>
                </ValidationGroup>
            </div >
        </Dialog >
    );
};
