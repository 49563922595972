
import { useTranslation } from 'react-i18next';



import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { APIVovHelper } from '../../../../ApiOld/ApiOldClient';
import { useLoginAdminStore } from '../../../../Contexts/LoginAdminZContext';

interface DiariesExternalProps {
    // selected?: number;
}

export default function DiaryExternalDetail(props: DiariesExternalProps) {
    const { t, i18n } = useTranslation();
    // const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const navigate = useNavigate();
    const params = useParams();
    const { load } = useLoginAdminStore();
    useEffect(() => {
        if (params['token'] && params['adminUserId'] && params['lang'] && params['domain'] && params['iddiary']) {
            new APIVovHelper().LoginErp(params['token'], parseInt(params['adminUserId']), params['domain']).then((e) => {
                if (e.userName)
                    load(e.userName, parseInt(params['adminUserId']!));
                navigate('/' + params['lang'] + '/' + params['domain'] + '/diaryDetail/' + params['iddiary']);
            })
        }
    }, [])
    return (<div>
        {t('diary.loggingIntoNewVersion')}
    </div>);
}