import React, { useEffect } from "react";
import { Autocomplete, FormControl, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { Validate, ValidationGroup } from "mui-validate";
import { ApplicationListDTO } from "../../../../ApiOld/ApiServerVov";
import { APIVovKrosAdminHelper } from "../../../../ApiOld/ApiKrosAdminOldClient";

export interface ApplicationSelectKrosAdminProps {
    selectedProjectId?: number | null;
    onChange: (event: ApplicationListDTO | null) => void;
    isMandatory?: boolean;
}
export default function ApplicationSelectKrosAdmin(props: ApplicationSelectKrosAdminProps) {
    const { onChange, isMandatory } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [selectedApp, setSelectedApp] = React.useState<ApplicationListDTO | null>(null);
    const [app, setApp] = React.useState<ApplicationListDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    const handleChange = (event: SelectChangeEvent<typeof selectedApp>) => {
        if (event.target.value !== undefined) {
            setSelectedApp(event.target.value as (ApplicationListDTO | null));

            onChange(event.target.value as (ApplicationListDTO | null));
        }

    };
    useEffect(() => {
        new APIVovKrosAdminHelper().GetApplicationListKrosAdmin().then((res) => {
            setApp(res)
        });

    }, []);
    /* useEffect(() => {
        if (projects !== undefined && projects !== null && projects.length > 0
            && selectedProjectId !== undefined &&
            selectedProjectId !== null && selectedProjectId > 0) {
            let q = projects.find(x => x.id == selectedProjectId);
            if (q !== undefined)
                setSelectedProject(q);
        }

    }, [projects]); */
    return (
        <div>

            <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                onChange={(e, v) => {
                    setSelectedApp(v);
                    onChange(v);
                }}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                //value={selectedProject}
                options={app}

                fullWidth={biggerThan700}
                sx={{ minWidth: 288 }}
                renderInput={(params) => <TextField error={isMandatory === true && selectedApp === null}  {...params} label={'Aplikácie'}

                />}
            />
            {app === null && isMandatory && <p style={{ marginBottom: '0px', marginLeft: '-10px', marginTop: '4px', color: theme.palette.error.main }}>{t('FieldRequired')}</p>}
        </div>
    );
}