
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { styled, ThemeProvider, useTheme } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import './RecomendationScene.css';
import collectivePic from '../../../Assets/collective.png'; // with import
import moneyPic from '../../../Assets/money.png'; // with import
import giftPic from '../../../Assets/gifts.png'; // with import
import monterkyPic from '../../../Assets/monterky.png'; // with import
import { Box, Button, Card, CardContent, InputAdornment, Slider, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import createThemeStavario from '../../Shared/Themes/CreateTheme';
import { Redeem, Share } from '@mui/icons-material';
import SendInvite from './SendInvite';
import { InvitePeople } from './InvitePeople';
import { useMediaPredicate } from 'react-media-hook';
import { useParams } from 'react-router';
import { Client, GetAllRecommendationsResponseDTO, SendEmailToStavarioRequestDTO } from './OldApi/ApiServer';
import { OldApiClient } from './OldApi/OldApiClientHelper';
import { numberFormatList } from '@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/items';
import GetReward from './GetReward';
import tabProps from '../../Shared/Tabs/TabProps';
import { TabPanel } from '../../Shared/Tabs/TabPanel';
interface RecomendationSceneProps {

}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
export default function Recomendations(props: RecomendationSceneProps) {

    // const [invited, setInvited] = useState<InvitePeople[]>([new InvitePeople('franteefsefsefsefesfefa@sezesfefsesefsesefnam.cz'), new InvitePeople('frantefefa@seznam.cz', true), new InvitePeople('frantefefa@seznam.cz', false, true), new InvitePeople('frantefefa@seznam.cz'), new InvitePeople('frantefefa@seznam.cz'), new InvitePeople('frantefefa@seznam.cz'), new InvitePeople('frantefefa@seznam.cz')]);
    const [inviteOpen, setInviteOpen] = useState(false);
    const [rewardOpen, setRewardOpen] = useState(false);
    const [rewardIndex, setRewardIndex] = useState(-1);
    const [domain, setDomain] = useState<string | null>(null);
    const [tokenOld, setTokenOld] = useState<string | null>(null);
    const [adminUserId, setAdminUserId] = useState<number | null>(null);
    const [inviteData, setInviteData] = useState<GetAllRecommendationsResponseDTO | null>(null);
    const { t, i18n } = useTranslation();
    const theme = createThemeStavario();
    const params = useParams();
    const biggerThan600 = useMediaPredicate("(min-width: 600px)");
    const backColor = '#F5F5F5';
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    // i18n.changeLanguage(params['language']);
    useEffect(() => {
        i18n.changeLanguage(params['language']);
        /* console.log(params['id']);
        console.log(params['token']);
        console.log(params['domain']); */
        if (params['id']!)
            setAdminUserId(parseInt(params['id']!));
        if (params['domain'])
            setDomain(params['domain']!);
        if (params['token'])
            setTokenOld(params['token']!);

        // setInviteData(new GetAllRecommendationsResponseDTO({ paidCount: 1, createdDemoCount: 2, invitedCount: 5 }));
        let cl = new OldApiClient().GetUnauthenticatedOldClient(params['domain']!);
        cl.apiV2_7RecommendationsGetAllRecommendations(parseInt(params['id']!), params['token']!).then((result) => {
            setInviteData(result);
        })
    }, []);
    useEffect(() => {
        if (rewardIndex >= 0) {
            setRewardOpen(true);
        }

    }, [rewardIndex]);
    const handleTabIndexChanged = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTabIndex(newValue);
    };
    function onInviteClose(refresh: boolean) {
        setInviteOpen(false);
        if (refresh && domain !== null) {
            let cl = new OldApiClient().GetUnauthenticatedOldClient(domain!);
            cl.apiV2_7RecommendationsGetAllRecommendations(adminUserId!, tokenOld!).then((result) => {
                setInviteData(result);
            })
        }
    }
    function onRewardClose(refresh: boolean) {
        setRewardOpen(false);

    }
    function getReward(index: number) {
        setRewardIndex(index);
    }
    function onInviteOpen() {
        setInviteOpen(true);
    }
    const fieldStyles = {
        root: {
            background: "black"
        },
        input: {
            color: "white"
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <div style={{ backgroundColor: backColor, width: '100%' }}>
                <div>
                    <Grid container spacing={2} >
                        <Grid item md={4} sx={{ marginLeft: '40px', marginRight: '40px', marginTop: '40px' }}>
                            <div >
                                <h3 style={{ fontFamily: 'Roboto' }}>{t('sales.togetherPull')}</h3>
                                <p style={{ fontFamily: 'Roboto', fontSize: '14' }}><mark style={{ color: theme.palette.primary.main, fontWeight: 'bold', background: backColor, marginLeft: '-4px' }}>
                                    {t('sales.recommendStavario')}</mark> {t('sales.inviteText')}</p>
                                <Button size='medium' sx={{ minWidth: '300px' }} variant="contained" onClick={onInviteOpen} startIcon={<Share />}>
                                    {t('sales.inviteFriends')}
                                </Button>

                                {/* <FacebookShareButtoncc
                                    url='www.stavario.com'    //eg. https://www.example.com
                                    quote={'jste opravud'}
                                    hashtag="#STAVARIO"// #hashTag
                                >
                                    <FacebookIcon />
                                </FacebookShareButton> */}
                            </div>
                        </Grid>
                        <Grid item md={2}>

                        </Grid>
                        {biggerThan600 && <Grid item md={4} sx={{ m: '40px' }}>
                            <img src={collectivePic} width={324} height={216} style={{ borderRadius: '8px', boxShadow: '10px 10px 5px #ccc', margin: '10px' }} />
                        </Grid>}
                        {!biggerThan600 && <Grid item md={4} sx={{ margin: '20px' }}>
                            <img src={collectivePic} width={282} height={188} style={{ borderRadius: '8px', boxShadow: '10px 10px 5px #ccc', margin: '10px' }} />
                        </Grid>}
                    </Grid>
                    <Box >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={selectedTabIndex} onChange={handleTabIndexChanged} aria-label="basic tabs example">
                                <Tab label={t('sales.pricesList')} {...tabProps(0)} />
                                <Tab label={t('sales.invitations')} {...tabProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={selectedTabIndex} index={0}>
                            <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-evenly' }}
                                >
                                    <div style={{ minHeight: '640px', minWidth: '260px', maxWidth: '360px', margin: '12px' }}>
                                        <Card >
                                            <CardContent>
                                                <div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <h4 style={{ textAlign: 'center' }}>{t('sales.marketingThings')}</h4>
                                                        <img src={giftPic} width={90} style={{ marginTop: '48px', marginBottom: '68px' }} height={90} />

                                                    </div>
                                                    {inviteData && inviteData.invitedCount !== undefined && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <p>{t('sales.shared')}</p>
                                                            <div style={{ backgroundColor: theme.palette.primary.main, height: '24px', marginLeft: '6px', borderRadius: '4px', boxShadow: '3px 3px 3px #ccc' }}>
                                                                <p style={{ fontSize: 12, fontWeight: 'bold', marginRight: '6px', marginLeft: '6px', marginTop: '4px' }}>
                                                                    {inviteData.invitedCount}x
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>{inviteData.invitedCount}/5</div>
                                                    </div>}
                                                    <Slider min={0} max={5} step={1} value={inviteData && inviteData.invitedCount !== undefined ? inviteData.invitedCount : 0}></Slider>
                                                    {inviteData && inviteData.invitedCount !== undefined && inviteData.invitedCount > 4 && <Button fullWidth size='medium' variant="contained" onClick={(e) => { getReward(0) }} startIcon={<Share />}>vybrat odměnu</Button>}
                                                    {!(inviteData && inviteData.invitedCount !== undefined && inviteData.invitedCount > 4) && <Button fullWidth size='medium' variant="outlined" onClick={onInviteOpen} startIcon={<Share />}>{t('sales.inviteFriends')}</Button>}
                                                    {/* <Button fullWidth size='medium' variant="outlined" startIcon={<Share />}>{t('sales.inviteFriends')}</Button> */}
                                                </div>
                                            </CardContent>
                                        </Card>
                                        <div style={{ marginLeft: '4px', marginRight: '4px', marginTop: '20px', marginBottom: '12px', textAlign: 'justify' }}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center', borderRadius: '12px', width: 56, height: 24, backgroundColor: theme.palette.primary.main, margin: '8px' }}>
                                                    <p style={{ fontWeight: 'bold' }}>1</p>
                                                </div>
                                                <p style={{ fontWeight: 'bold' }}>{t('sales.inviteYourFriends')}</p>
                                            </div>
                                            <div>{t('sales.fillAdresses')}</div>
                                        </div>
                                    </div>
                                    <div style={{ minHeight: '640px', minWidth: '260px', maxWidth: '360px', margin: '12px' }}>
                                        <Card >
                                            <CardContent>
                                                <div>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <h4 style={{ textAlign: 'center' }}>{t('sales.monterkyByStrauss')}</h4>
                                                        <img src={monterkyPic} width={60} style={{ marginTop: '48px', marginBottom: '28px', marginLeft: '100px', marginRight: '100px' }} height={134} />

                                                    </div>
                                                    {inviteData && inviteData.createdDemoCount !== undefined && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <p>{t('sales.trying')}</p>

                                                        </div>
                                                        <div>{inviteData.createdDemoCount}/5</div>
                                                    </div>}
                                                    <Slider min={0} max={5} step={1} value={inviteData && inviteData.createdDemoCount !== undefined ? inviteData.createdDemoCount : 0}></Slider>
                                                    {inviteData && inviteData.createdDemoCount !== undefined && inviteData.createdDemoCount > 4 && <Button fullWidth size='medium' variant="contained" onClick={(e) => { getReward(1) }} startIcon={<Share />}>vybrat odměnu</Button>}
                                                    {!(inviteData && inviteData.createdDemoCount !== undefined && inviteData.createdDemoCount > 4) && <Button fullWidth size='medium' variant="outlined" onClick={onInviteOpen} startIcon={<Share />}>{t('sales.inviteFriends')}</Button>}
                                                </div>
                                            </CardContent>

                                        </Card>
                                        <div style={{ marginLeft: '4px', marginRight: '4px', marginTop: '20px', marginBottom: '12px', textAlign: 'justify' }}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center', borderRadius: '12px', width: 72, height: 24, backgroundColor: theme.palette.primary.main, margin: '8px' }}>
                                                    <p style={{ fontWeight: 'bold' }}>2</p>
                                                </div>
                                                <p style={{ fontWeight: 'bold' }}>{t('sales.exclusiveMonterky')}</p>
                                            </div>
                                            <div>{t('sales.worldwideBrand')}</div>
                                        </div>
                                    </div>
                                    <div style={{ minHeight: '640px', minWidth: '260px', maxWidth: '360px', margin: '12px' }}>
                                        <Card >
                                            <CardContent>
                                                <div >
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <h4 style={{ textAlign: 'center' }}>{t('sales.repeatableDiscount')}</h4>
                                                        <img src={moneyPic} width={90} style={{ marginTop: '64px', marginBottom: '58px' }} height={90} />

                                                    </div>
                                                    {inviteData && inviteData.paidCount !== undefined && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <p>{t('sales.accepted')}</p>
                                                        </div>
                                                        <div>{inviteData.paidCount}/5</div>
                                                    </div>}
                                                    <Slider min={0} max={5} step={1} value={inviteData && inviteData.paidCount !== undefined ? inviteData.paidCount : 0}></Slider>
                                                    {inviteData && inviteData.paidCount !== undefined && inviteData.paidCount > 4 && <Button fullWidth size='medium' variant="contained" onClick={(e) => { getReward(2) }} startIcon={<Share />}>vybrat odměnu</Button>}
                                                    {!(inviteData && inviteData.paidCount !== undefined && inviteData.paidCount > 4) && <Button fullWidth size='medium' variant="outlined" onClick={onInviteOpen} startIcon={<Share />}>{t('sales.inviteFriends')}</Button>}
                                                </div>
                                            </CardContent>
                                        </Card>
                                        <div style={{ marginLeft: '4px', marginRight: '4px', marginTop: '20px', marginBottom: '12px', textAlign: 'justify' }}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center', borderRadius: '12px', width: 72, height: 24, backgroundColor: theme.palette.primary.main, margin: '8px' }}>
                                                    <p style={{ fontWeight: 'bold' }}>3</p>
                                                </div>
                                                <p style={{ fontWeight: 'bold' }}>{t('sales.discountHeading')}</p>
                                            </div>
                                            <div>{t('sales.discountDescription')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={selectedTabIndex} index={1}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', backgroundColor: 'white', margin: '-24px' }}>
                                {inviteData && inviteData.invitedEmails && inviteData.invitedEmails.map((value, i) => {
                                    if (value["state"] === 1) {
                                        return (

                                            <Tooltip title={value.email ? value.email! : ''}>

                                                <TextField value={value.email} size='small'
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                        "& .MuiInputBase-input": {
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",

                                                        },
                                                        '& .MuiInputBase-root': {
                                                            color: "black",
                                                            borderColor: theme.palette.primary.main,
                                                            borderWidth: '3px'
                                                        },
                                                        '& .MuiInput-underline:after': {
                                                            borderBottomColor: theme.palette.primary.main,
                                                            borderWidth: '3px'
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: theme.palette.primary.main,
                                                                borderWidth: '3px'
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: theme.palette.primary.main,
                                                                borderWidth: '3px',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: theme.palette.primary.main,
                                                                borderWidth: '3px'
                                                            },
                                                        },
                                                        m: '8px',
                                                        minWidth: '288px'
                                                    }}
                                                    InputProps={{
                                                        startAdornment:
                                                            <InputAdornment position="start">
                                                                <Typography color={theme.palette.primary.main} fontWeight='bold'>DEMO</Typography>
                                                            </InputAdornment>
                                                    }}
                                                    placeholder='Zde napište email toho, koho chcete pozvat' />

                                            </Tooltip>
                                        );
                                    }
                                    else if (value["state"] === 2) {
                                        return (
                                            <Tooltip title={value.email ? value.email! : ''}>

                                                <TextField value={value.email} size='small'
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                        "& .MuiInputBase-input": {
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",

                                                        },
                                                        '& .MuiInputBase-root': {
                                                            color: "black",
                                                            backgroundColor: theme.palette.primary.main,
                                                            borderColor: theme.palette.primary.main,
                                                            borderWidth: '3px'
                                                        },
                                                        '& .MuiInput-underline:after': {
                                                            borderBottomColor: theme.palette.primary.main,
                                                            borderWidth: '3px'
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: theme.palette.primary.main,
                                                                borderWidth: '3px'
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: theme.palette.primary.main,
                                                                borderWidth: '3px',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: theme.palette.primary.main,
                                                                borderWidth: '3px'
                                                            },
                                                        },
                                                        m: '8px',
                                                        minWidth: '288px'
                                                    }}
                                                    InputProps={{
                                                        startAdornment:
                                                            <InputAdornment position="start">
                                                                <Redeem />
                                                            </InputAdornment>
                                                    }}
                                                    placeholder={t('sales.emailInviteText')} />

                                            </Tooltip>)
                                    }
                                    else {
                                        return (
                                            <Tooltip title={value ? value['email']! : ''}>

                                                <TextField value={value['email']} size='small' sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                    "& .MuiInputBase-input": {
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",

                                                    },

                                                    m: '8px',
                                                    minWidth: '288px'
                                                }}
                                                    InputProps={{
                                                        startAdornment:
                                                            <InputAdornment position="start">
                                                                <Share />
                                                            </InputAdornment>
                                                    }}
                                                    placeholder='Zde napište email toho, koho chcete pozvat' />

                                            </Tooltip>)
                                    }
                                })}
                            </div>
                        </TabPanel>
                    </Box >
                    <SendInvite domain={domain} tokenOldApi={tokenOld} adminUserId={adminUserId} open={inviteOpen} onClose={onInviteClose}></SendInvite>
                    <GetReward domain={domain} tokenOldApi={tokenOld} adminUserId={adminUserId} open={rewardOpen} rewardIndex={rewardIndex} onClose={onRewardClose} />
                </div >
            </div >

        </ThemeProvider >

    )
}