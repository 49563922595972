import { RemoveRedEyeSharp, VisibilityOff } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export interface PasswordboxProps {
    password: string;
    onEnter?: () => void;
    // open: boolean;
    // options: string[];
    onPasswordChange: (event: any) => void;
}

export default function Passwordbox(props: PasswordboxProps) {

    const { password, onPasswordChange, onEnter } = props;
    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            if (onEnter)
                onEnter();
        }
    }
    const onPassChange = (event: any) => {
        // console.log(event.currentTarget.value);
        onPasswordChange(event!.currentTarget!.value);
    };
    const { t, i18n } = useTranslation();
    let [passwordMode, setPasswordMode] = useState("password");
    return (
        <div>
            <TextField
                className="text-field"
                type={passwordMode}
                variant="outlined"
                value={props.password}
                onChange={onPassChange}
                id="outlined-password-input"
                label={t('login.enterPassword')}
                onKeyDown={handleKeyPress}
                InputProps={{
                    endAdornment:
                        <IconButton onClick={() => {
                            if (passwordMode === 'password')
                                setPasswordMode("text");
                            else setPasswordMode("password");
                        }}> {
                                passwordMode === 'password' ?
                                    <RemoveRedEyeSharp /> : <VisibilityOff />
                            }</IconButton>
                }}

            />
        </div>
    );
}