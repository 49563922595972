import { Button, styled, TextField, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import usePrevious from "../../../Shared/Hooks/usePrevious";
import { DiaryTimelineModel } from "../Models/DiaryTimelineModel";

import 'dayjs/locale/cs';
import 'dayjs/locale/pl';
import 'dayjs/locale/en';
import 'dayjs/locale/sk';

import { LeftArrow, RightArrow } from "./TimelineArrows";
import { TimelineCard } from "./TimelineCard";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import parseDateSafe from "../../../Shared/DateTime/ParseDateTime";
import { Dayjs } from "dayjs";


// NOTE: embrace power of CSS flexbox!
// import "./hideScrollbar.css";
// import "./firstItemMargin.css";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const elemPrefix = "test";
const getId = (index: number | string) => `${elemPrefix}${index}`;

function getItems(records: DiaryTimelineModel[], dateFrom?: Date, dateTo?: Date) {
    var from = new Date();
    if (dateFrom)
        from = dateFrom;
    from.setDate(from.getDate() - 15);
    var to = new Date();
    if (dateTo)
        to = dateTo;
    to.setDate(to.getDate() + 30);
    return getDates(from, to, records);
}

function getDates(startDate: Date, stopDate: Date, records: DiaryTimelineModel[]): DiaryTimelineModel[] {
    console.log(records);
    var dateArray: DiaryTimelineModel[] = [];
    var currentDate = moment(startDate);
    var stopDateInt = moment(stopDate);
    while (currentDate <= stopDateInt) {
        var actDate = moment(currentDate).format('MM-DD-YYYY');
        var currRec = records.find(x => x.date === moment(currentDate).format('MM-DD-YYYY'));
        dateArray.push(new DiaryTimelineModel(actDate, parseDateSafe(actDate), currRec !== undefined ? currRec.isFilled : false,
            currRec ? currRec.hasPhotos : false, currRec ? currRec.hasWorkers : false,
            currRec ? currRec.hasCompletedWorks : false, currRec ? currRec.signatureType : 0,
            currRec ? currRec.hasWeathers : false, currRec ? currRec.isLocked : false, currRec !== undefined ? currRec.recordId : undefined,
        ))
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}
function addDays(days: number) {
    var result = new Date();
    result.setDate(result.getDate() + days);
    return result;
}
interface TimelineProps {
    inputRecordId?: number;
    disabled: boolean;
    inputRecords: DiaryTimelineModel[];
    onSelected: (e: number | undefined, d: Date) => void;
    isInvestor: boolean;
    isSubdodavatel: boolean;
    fromExternal?: boolean;
}
export function Timeline(props: TimelineProps) {
    const { inputRecords, onSelected, inputRecordId, isInvestor, disabled,
        fromExternal, isSubdodavatel } = props;
    const [items, setItems] = React.useState<DiaryTimelineModel[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
    const [fromPicker, setFromPicker] = React.useState<boolean>(false);

    // NOTE: for add/remove item
    // const addItem = () => {
    //     setItems((items) =>
    //         items.concat(["novy"])
    //     );
    // };
    // const removeItem = () => {
    //     setItems((items) => {
    //         const newItems = [...items];
    //         newItems.splice(0, 1);
    //         return newItems;
    //     });
    // };

    const apiRef = React.useRef({} as scrollVisibilityApiType);
    // React.useEffect(() => {
    //     console.log(items);
    //     console.log(itemsPrev);
    //     if (items !== undefined && itemsPrev !== undefined && items.length > itemsPrev.length && apiRef) {

    //         var it = items.slice(-1)!;
    //         var item = apiRef.current!.getItemElementById(it[0]);
    //         console.log(item);
    //         if (item !== null)
    //             apiRef.current!.scrollToItem(
    //                 apiRef.current!.getItemElementById(it[0])!
    //             );
    //     }
    // }, [items, itemsPrev]);
    const [selected, setSelected] = React.useState<string>("");
    function handleItemClick(itemId: any) {
        if (!disabled) {
            selectDate(itemId);
            setSelectedDate(parseDateSafe(itemId));
        }
    }
    function selectDate(itemId: any) {
        setSelected(itemId);
        // setSelectedDate(parseDateSafe(itemId));
        var item = items.find(x => x.date === itemId);
        if (item)
            onSelected(item.recordId, item.dateOrigin);
    }
    function scrollAndSelectToItem(it: DiaryTimelineModel, itScroll: DiaryTimelineModel) {




        if (itScroll !== undefined && itScroll !== null) {
            apiRef.current!.scrollToItem(
                apiRef.current!.getItemElementById(itScroll.date)!
            );

        }


        if (it !== undefined && it !== null && selected !== it.date) {
            setSelected(it.date);
            onSelected(it.recordId, it.dateOrigin);
        }
    }
    const { i18n } = useTranslation();
    const theme = useTheme();
    interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
        isFilled: boolean;
    }
    const CustomPickersDay = styled(PickersDay, {
        shouldForwardProp: (prop) =>
            prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
    })<CustomPickerDayProps>(({ theme, isFilled }) => ({
        ...(isFilled && {
            borderRadius: '50%',
            backgroundColor: theme.palette.success.main,
            color: theme.palette.common.white,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.dark,
            },
        }),
        ...(!isFilled && {
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.dark,
            },
        }),
        // ...(isFirstDay && {
        //     borderTopLeftRadius: '50%',
        //     borderBottomLeftRadius: '50%',
        // }),
        // ...(isLastDay && {
        //     borderTopRightRadius: '50%',
        //     borderBottomRightRadius: '50%',
        // }),
    })) as React.ComponentType<CustomPickerDayProps>;
    const renderWeekPickerDay = (
        date: Dayjs,
        selectedDates: Array<Dayjs | null>,
        pickersDayProps: PickersDayProps<Dayjs>,
    ) => {
        var item = items.find(x => x.date === date.format('MM-DD-YYYY'));
        if (item === undefined) {
            console.log(pickersDayProps);
            return <PickersDay day={pickersDayProps.day}
                disabled
                onDaySelect={pickersDayProps.onDaySelect} outsideCurrentMonth={pickersDayProps.outsideCurrentMonth} />;
        }
        else if (item.recordId === undefined) {
            return <PickersDay {...pickersDayProps} />;
        }


        return (
            <CustomPickersDay
                {...pickersDayProps}
                disableMargin
                isFilled={item.isFilled}
            />
        );
    };

    useEffect(() => {
        var data = inputRecords.map((item) => {
            return Date.parse(item.dateOrigin.toString());
        });
        setItems(getItems(inputRecords, new Date(Math.min(...data)),));
    }, [inputRecords]);
    useEffect(() => {
        if (fromPicker) {
            if (selectedDate) {
                // if (moment(selectedDate).format("MM-DD-YYYY") !== selected) {
                var item = items.find(x => x.date === moment(new Date(selectedDate)).format('MM-DD-YYYY'));
                if (item !== undefined) {
                    scrollAndSelectToItem(item, items[items.findIndex(x => x.date === moment(new Date(selectedDate)).format('MM-DD-YYYY'))]);

                }
                setFromPicker(false);

            }
        }
        // }

    }, [fromPicker]);
    useEffect(() => {
        if (inputRecordId === undefined) {
            // setSelectedDate(new Date());
            console.log('todayautoselect', moment(addDays(biggerThan700 ? 4 : 1)).format('MM-DD-YYYY'));
            var it = items.find(x => x.date === moment(Date()).format('MM-DD-YYYY'));
            var itScroll = items.find(x => x.date === moment(addDays(biggerThan700 ? 4 : 1)).format('MM-DD-YYYY'));
            if (it !== undefined && itScroll !== undefined)
                scrollAndSelectToItem(it, itScroll);
        }
        else {
            // console.log('todayautoselect', moment(addDays(biggerThan700 ? 4 : 1)).format('MM-DD-YYYY'));
            var it2 = items.find(x => x.recordId === inputRecordId);

            var itIndex = items.findIndex(x => x.recordId === inputRecordId);

            if (items.length > itIndex + 1) {
                var itScrollInput = items[itIndex + 1];
                if (it2 !== undefined && itScrollInput !== undefined) {
                    scrollAndSelectToItem(it2, itScrollInput);
                    setSelectedDate(parseDateSafe(it2.date));
                }


            }

        }

    }, [items]);

    return (
        <div>

            <div className="example" style={{
                minHeight: '60px', paddingTop: "8px",
                position: 'fixed',

                left: '0', right: 'auto', width: biggerThan700 ? '85%' : '75%',
                borderBottom: '1px solid',
                borderColor: 'lightgray',
                overflow: 'hidden',
                backgroundColor: 'white', zIndex: 1000
            }}>
                <div>
                    <ScrollMenu
                        LeftArrow={LeftArrow}
                        RightArrow={RightArrow}
                        onWheel={onWheel}
                        apiRef={apiRef}
                    >
                        {items.map((item) => (
                            <TimelineCard
                                disabled={disabled}
                                signatureType={item.signatureType}
                                hasCompletedWorks={item.hasCompletedWorks}
                                hasPhotos={item.hasPhotos}
                                hasWeathers={item.hasWeathers}
                                hasWorkers={item.hasWorkers}
                                isLocked={item.isLocked}
                                isFilled={item.isFilled}
                                date={item.dateOrigin}
                                recordId={item.recordId}
                                title={item.date}
                                itemId={item.date} // NOTE: itemId is required for track items
                                key={item.date}
                                onClick={handleItemClick}
                                selected={item.date === selected}
                            />
                        ))}
                    </ScrollMenu>

                </div>
                <div style={{
                    position: 'fixed', top: isInvestor ? '112px' : (fromExternal === true || isSubdodavatel === true ? '64px' : '12px'),
                    right: biggerThan700 ? '24px' : '12px',
                    maxWidth: biggerThan700 ? '12%' : '20%', marginTop: '2px'
                }}>



                    <LocalizationProvider locale={i18n.language} dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="DD.MM.YYYY"
                            value={selectedDate}
                            disabled={disabled}
                            // minDate={items.find(x =>)}
                            renderDay={renderWeekPickerDay}
                            PopperProps={{ sx: { zIndex: 9999 /* inset: '50px auto auto 0px !important' */ } }}
                            onChange={(e, v) => {
                                setFromPicker(true);
                                // alert(e);
                                if (e)
                                    setSelectedDate(e.toDate());
                            }}

                            renderInput={


                                (params) => <TextField label='' sx={{
                                    '& .MuiInputBase-root': {
                                        color: "black",
                                        fontSize: biggerThan700 ? 13 : 9,
                                        borderColor: theme.palette.primary.main,
                                        backgroundColor: 'white',
                                        borderWidth: '2px',

                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: theme.palette.primary.main,
                                        fontSize: biggerThan700 ? 13 : 9,
                                        borderWidth: '2px'
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: theme.palette.primary.main,
                                            fontSize: biggerThan700 ? 13 : 9,
                                            borderWidth: '2px'
                                        },
                                        '&:hover fieldset': {
                                            borderColor: theme.palette.primary.main,
                                            fontSize: biggerThan700 ? 13 : 9,
                                            borderWidth: '2px',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: theme.palette.primary.main,
                                            fontSize: biggerThan700 ? 13 : 9,
                                            borderWidth: '2px'
                                        }
                                    }
                                }}

                                    size='small' {...params} />
                            }
                        />
                    </LocalizationProvider>
                </div>
            </div>
        </div >
    );
}

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    console.log('onWheel', ev);
    ev.stopPropagation();
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
    // ev.nativeEvent.stopImmediatePropagation();
    // ev.nativeEvent.imm();
    // ev.nativeEvent.preventDefault();
    if (isThouchpad) {

        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}
