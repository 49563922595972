import { AttachFile, Check, Close, CloseFullscreen, ColorLens, Delete, EventRepeat, Fullscreen, History, MoreHorizOutlined, Reply, Save, SaveAs, Troubleshoot, Watch } from '@mui/icons-material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Autocomplete, Box, Button, Dialog, DialogTitle, FormControlLabel, IconButton, makeStyles, Popover, Slider, styled, Tab, Tabs, TextareaAutosize, TextField, Tooltip, Typography, useTheme } from '@mui/material';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { ChangeEventArgs, HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import { BuildingDTO, TodoDTO, TodoListDTO, UserResponsibleDTO } from '../../../ApiOld/ApiServerVov';
import { APITodoOldHelper } from '../../../ApiOld/ApiToDoOldHelper';
import { useNavigate, useParams } from 'react-router';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';
import AdminUserBuildingSelect from '../../Shared/AdminUserBuildingSelect';
import AdminUserSelect from '../../Shared/AdminUserSelect';
import SplitButton from '../../Shared/SplitButton';
import delay from '../../Shared/Functions/Delay';
import BuildingSelect from '../../Shared/BuildingSelect';
import BuildingDiarySelect from '../../Shared/BuildingDiarySelect';
import WealthSelect from '../../Shared/WealthSelect';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './SingleTodoSceneOld.css';
import dayjs, { Dayjs } from "dayjs";
import SingleTodoAssigneesSceneOld from './SingleTodoAssigneesSceneOld';


export interface SingleTodoSceneOldProps {
}


export default function SingleTodoSceneOld(props: SingleTodoSceneOldProps) {
    const [responsible, setResponsible] = useState<UserResponsibleDTO[]>([]);
    const [saveDone, setSaveDone] = useState<boolean>(false);
    const navigate = useNavigate();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const optionsStates = [
        { label: t('singleTodo.new'), id: 0 },
        { label: t('singleTodo.active'), id: 1 },
        { label: t('singleTodo.resolved'), id: 2 },
    ];
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const [todo, setTodo] = useState<Partial<TodoDTO> | undefined>();

    const params = useParams();

    useEffect(() => {
        if (params['id'] && !isNaN(parseInt(params['id']))) {
            if (parseInt(params['id']) > 0) {
                new APITodoOldHelper().GetTodo(parseInt(params['id'])).then(p => {
                    setTodo(p);
                    console.log('Data TODO', p);
                });
            }
            else {
                var n = new TodoDTO({ id: 0, state: 0, progres: 0, assignees: [], showInGantt: false, datetimeCreated: new Date(), origin: 0 });
                //@ts-ignore
                n.buildingDiaryId = null;
                //@ts-ignore
                n.wealthId = null;
                setTodo(n);
            }
        }
    }, [params]);

    async function saveTodo(close: boolean) {
        if (todo != undefined) {
            if (todo.id !== undefined && todo.id > 0) {
                let t = await (new APITodoOldHelper()).UpdateTodo(new TodoDTO({
                    assignees: todo.assignees,
                    buildingDiaryId: todo.buildingDiaryId,
                    buildingId: todo.buildingId,
                    datetimeCreated: todo.datetimeCreated,
                    datetimeDeadline: todo.datetimeDeadline,
                    datetimeEnd: todo.datetimeEnd,
                    datetimeStart: todo.datetimeStart,
                    description: todo.description,
                    id: todo.id,
                    name: todo.name,
                    origin: todo.origin,
                    progres: todo.progres,
                    responsiblePersonId: todo.responsiblePersonId,
                    showInGantt: todo.showInGantt,
                    state: todo.state,
                    wealthId: todo.wealthId
                }));
                if (t) {
                    setTodo({ ...todo, id: t.id });
                }
            }
            else {
                let t = await (new APITodoOldHelper()).AddTodo(new TodoDTO({
                    assignees: todo.assignees,
                    buildingDiaryId: todo.buildingDiaryId,
                    buildingId: todo.buildingId,
                    datetimeCreated: todo.datetimeCreated,
                    datetimeDeadline: todo.datetimeDeadline,
                    datetimeEnd: todo.datetimeEnd,
                    datetimeStart: todo.datetimeStart,
                    description: todo.description,
                    id: todo.id,
                    name: todo.name,
                    origin: todo.origin,
                    progres: todo.progres,
                    responsiblePersonId: todo.responsiblePersonId,
                    showInGantt: todo.showInGantt,
                    state: todo.state,
                    wealthId: todo.wealthId
                }));
                if (t) {
                    setTodo({ ...todo, id: t });
                }
            }
            setSaveDone(true);
        }

        if (close) {
            redirectToList();
        }
    }

    const handleKeyDown = (event: any) => {
        if (todo !== undefined && todo !== null && todo.name !== null && todo.name !== undefined && todo.name !== '') {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if ((event.ctrlKey || event.metaKey) && !(event.shiftKey) && charCode === 's') {
                // console.log("CTRL+S Pressed");
                event.preventDefault();
                saveTodo(false);

            }
            if ((event.ctrlKey || event.metaKey) && (event.shiftKey) && charCode === 's') {
                // console.log("CTRL+SHIFT+S Pressed");
                event.preventDefault();
                saveTodo(false);
            }
        }
    }

    useEffect(() => {
        if (saveDone === true)
            delay(2000).then((d) => { setSaveDone(false); });
    }, [saveDone]);

    const redirectToList = () => {
        navigate('/' + i18n.language + '/' + params['domain']! + '/todos');

    }


    const showGanttOptions = [{ id: 0, value: t('yesL') },
    { id: 1, value: t('noL') }];

    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
    };

    if (todo !== undefined) {
        return (
            <ValidationGroup>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ position: 'fixed', left: '40px', top: '60px', zIndex: 30 }}>
                        <IconButton onClick={redirectToList}>
                            <Reply />
                        </IconButton>
                    </div>
                    <div style={{ position: 'fixed', display: 'flex', right: '40px', top: '60px', zIndex: 30 }}>
                        {saveDone && <Check color='success' sx={{ marginRight: '20px' }} />}
                        <div style={{ marginRight: '10px' }}>
                            <SplitButton selectedIndexInput={0} disabled={false} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                                startIconSplitButton={<Save />}
                                onMenuClick={(e) => {
                                    if (e === 0) {
                                        saveTodo(false);
                                    }
                                    else if (e === 1) {
                                        saveTodo(true);

                                    }
                                }}></SplitButton></div>
                        {/* {(permissions === undefined || permissions.canEditDiary === undefined || permissions.canEditDiary === false) && <div><NotInterested sx={{ marginRight: '12px', marginBottom: '4px' }} />{t('noEditPermissions')}</div>} */}
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h4>{t('buildings.basicInformation')}</h4>
                                {todo.datetimeCreated && <div>
                                    {t('Created') + ': ' + (todo.datetimeCreated as any).toLocaleString(undefined, options)}
                                </div>}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                <Validate name='name-todo-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                    <TextField id="standard-basic"
                                        error={!todo.name}
                                        helperText={''}
                                        sx={{ minWidth: biggerThan700 ? '350px' : '150px', margin: "16px", marginTop: "6px", marginBottom: '6px' }}
                                        onChange={e => setTodo({ ...todo, name: e.target.value })}
                                        label={(t('singleTodo.taskName'))} value={todo.name} variant="outlined" />
                                </Validate>
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px' }}>
                                <Autocomplete
                                    disablePortal

                                    disableClearable
                                    id="combo-box-demo"
                                    size="small"
                                    onChange={(e, v) => {
                                        setTodo({ ...todo, state: (v !== undefined && v !== null ? v!.id : undefined) });
                                        // console.log("contextId: " + v !== undefined && v !== null ? v!.id : "NIC");
                                    }}
                                    value={optionsStates.find((opt) => opt.id === todo.state)}
                                    options={optionsStates}
                                    sx={{ width: '288px', marginLeft: "16px", marginRight: "16px", marginTop: "16px" }}
                                    renderInput={(params) => <TextField
                                        error={false}
                                        helperText={''}
                                        {...params}
                                        label={t('state')} />}
                                />
                                <div style={{ marginLeft: "16px", marginRight: "16px", marginTop: "16px" }}>
                                    <AdminUserSelect isMandatory selectedUserId={todo.responsiblePersonId} onChange={(res) => {
                                        setTodo({ ...todo, responsiblePersonId: res ? res.id : undefined });
                                    }} label='Vyřizuje' />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', marginLeft: '26px' }}>
                                <p style={{ zIndex: 4, marginTop: '10px', marginBottom: '-10px', background: 'white', width: '45px', marginLeft: '10px', paddingLeft: '4px' }}>{todo.description !== '' && t('Description')}</p>

                                <TextareaAutosize aria-label="empty textarea"
                                    placeholder={t('Description')}
                                    minRows={3}
                                    value={todo.description === null ? undefined : todo.description}
                                    onChange={(e) => { setTodo({ ...todo, description: e.target.value }) }}
                                    style={{ width: biggerThan700 ? '608px' : '288px', padding: '8px', marginTop: todo.description === '' ? '20px' : '0px' }} />
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px' }}>
                                <div style={{ marginLeft: "16px", marginRight: "16px", marginTop: "16px" }}>

                                    <BuildingSelect isMandatory onChange={async (e) => {
                                        if (e) {
                                            setTodo({ ...todo, buildingId: e.id });
                                        }
                                        else setTodo({ ...todo, buildingId: undefined });
                                    }} selectedProjectId={todo.buildingId} />
                                </div>
                                <div style={{ marginLeft: "16px", marginRight: "16px", marginTop: "16px" }}>

                                    {todo.buildingId && <BuildingDiarySelect buildingId={todo.buildingId} onChange={async (e) => {
                                        if (e) {
                                            setTodo({ ...todo, buildingDiaryId: e.id });
                                        }
                                        else setTodo({ ...todo, buildingDiaryId: undefined });
                                    }} selectedDiaryId={todo.buildingDiaryId} />}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px' }}>
                                <div style={{ marginLeft: "16px", marginRight: "16px", marginTop: "16px" }}>
                                    <WealthSelect onChange={async (e) => {
                                        if (e) {
                                            setTodo({ ...todo, wealthId: e.id });
                                        }
                                        else setTodo({ ...todo, wealthId: undefined });
                                    }} selectedWealthId={todo.wealthId} />
                                </div>
                                <div style={{ marginLeft: "16px", marginRight: "16px", marginTop: "16px" }}>

                                    <div style={{ minWidth: '156px' }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-show-investors"
                                            options={showGanttOptions}
                                            size="small"
                                            disableClearable
                                            value={todo.showInGantt ? showGanttOptions[0] : showGanttOptions[1]}
                                            onChange={(e, v) => { if (v) { setTodo({ ...todo, showInGantt: v.id == 0 ? true : false }) } }}
                                            getOptionLabel={(option) => option.value}
                                            renderInput={(params) => <TextField {...params} label={'Gantt'} />}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px' }}>
                                <div style={{ marginLeft: "16px", marginRight: "16px", marginTop: "16px" }}>
                                    <LocalizationProvider locale={i18n.language} dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            label={t('todo.startDate')}
                                            inputFormat="DD.MM.YYYY hh:mm"
                                            value={todo.datetimeStart ? dayjs(todo.datetimeStart) : null}
                                            onChange={(e, v) => {
                                                setTodo({ ...todo, datetimeStart: e === null ? undefined : e.toDate() });
                                            }}

                                            renderInput={
                                                (params) => <TextField label=''
                                                    sx={{ width: '288px' }}

                                                    size='small' {...params} />
                                            }
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div style={{ marginLeft: "16px", marginRight: "16px", marginTop: "16px" }}>
                                    <LocalizationProvider locale={i18n.language} dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            label={t('todo.endDate')}
                                            inputFormat="DD.MM.YYYY hh:mm"
                                            value={todo.datetimeEnd ? dayjs(todo.datetimeEnd) : null}
                                            onChange={(e, v) => {
                                                setTodo({ ...todo, datetimeEnd: e === null ? undefined : e.toDate() });
                                            }}

                                            renderInput={
                                                (params) => <TextField label=''
                                                    sx={{ width: '288px' }}

                                                    /* sx={{
                                                        '& .MuiInputBase-root': {
                                                            color: "black",
                                                            fontSize: biggerThan700 ? 13 : 9,
                                                            borderColor: theme.palette.primary.main,
                                                            backgroundColor: 'white',
                                                            borderWidth: '2px',
                                                        },
                                                        '& .MuiInput-underline:after': {
                                                            borderBottomColor: theme.palette.primary.main,
                                                            fontSize: biggerThan700 ? 13 : 9,
                                                            borderWidth: '2px'
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: theme.palette.primary.main,
                                                                fontSize: biggerThan700 ? 13 : 9,
                                                                borderWidth: '2px'
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: theme.palette.primary.main,
                                                                fontSize: biggerThan700 ? 13 : 9,
                                                                borderWidth: '2px',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: theme.palette.primary.main,
                                                                fontSize: biggerThan700 ? 13 : 9,
                                                                borderWidth: '2px'
                                                            },
                                                        }
                                                    }} */

                                                    size='small' {...params} />
                                            }
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px' }}>
                                <div style={{ marginLeft: "16px", marginRight: "16px", marginTop: "16px" }}>
                                    <LocalizationProvider locale={i18n.language} dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            label={t('todo.deadline')}
                                            inputFormat="DD.MM.YYYY hh:mm"
                                            value={todo.datetimeDeadline ? dayjs(todo.datetimeDeadline) : null}
                                            onChange={(e, v) => {
                                                setTodo({ ...todo, datetimeDeadline: e === null ? undefined : e.toDate() });
                                            }}

                                            renderInput={
                                                (params) => <TextField label=''
                                                    sx={{ width: '288px' }}

                                                    /* sx={{
                                                        '& .MuiInputBase-root': {
                                                            color: "black",
                                                            fontSize: biggerThan700 ? 13 : 9,
                                                            borderColor: theme.palette.primary.main,
                                                            backgroundColor: 'white',
                                                            borderWidth: '2px',
                                                        },
                                                        '& .MuiInput-underline:after': {
                                                            borderBottomColor: theme.palette.primary.main,
                                                            fontSize: biggerThan700 ? 13 : 9,
                                                            borderWidth: '2px'
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: theme.palette.primary.main,
                                                                fontSize: biggerThan700 ? 13 : 9,
                                                                borderWidth: '2px'
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: theme.palette.primary.main,
                                                                fontSize: biggerThan700 ? 13 : 9,
                                                                borderWidth: '2px',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: theme.palette.primary.main,
                                                                fontSize: biggerThan700 ? 13 : 9,
                                                                borderWidth: '2px'
                                                            },
                                                        }
                                                    }} */

                                                    size='small' {...params} />
                                            }
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '10px' }}>
                                <div style={{ marginLeft: "16px", marginRight: "16px", marginTop: "16px" }}>
                                    <Typography>{t('todo.progress')}</Typography>
                                    <Slider step={1} sx={{ width: '288px', marginLeft: '4px' }} value={todo.progres} aria-label="Default" valueLabelDisplay="auto" onChange={(e, v) => { setTodo({ ...todo, progres: v as number }) }} />
                                </div>
                            </div>
                        </div>


                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px', minWidth: '312px' }}>
                            <h4>{t('todo.assignees')}</h4>
                            <div style={{ margin: '10px' }}>
                                {todo.buildingId && <SingleTodoAssigneesSceneOld buildingId={todo.buildingId} selectedAssignees={todo.assignees ? todo.assignees : []}
                                    onChange={(sel) => {
                                        setTodo({ ...todo, assignees: sel });
                                    }}
                                />}
                            </div>


                        </div>
                    </div>
                </div>
            </ValidationGroup>
        );
    }
    else return (<></>);
};
