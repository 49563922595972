import { create } from 'zustand'
import { APIVovHelper } from '../ApiOld/ApiOldClient';
import { APIVovSubcontractorHelper } from '../ApiOld/ApiSubcontractorOldClient';
// export const loadDiaryStore = () => {
//     return new APIVovHelper().GetDiaryPermissions();
// }
interface LoginSubcontractorZContext {
    subcontractorName?: string;
    subcontractorId?: number;
    loadSub: (by: string, cd: number) => void;
    serverLoadSub: () => void;
    getSubcontractorNameAndId: () => Promise<{ subcontractorName: string | undefined, subcontractorId: number | undefined }>;
}
export const useLoginSubcontractorStore = create<LoginSubcontractorZContext>()((set, actual) => ({
    userName: undefined,
    id: undefined,
    serverLoadSub: async () => {
        let a = await new APIVovSubcontractorHelper().GetUser();
        if (a && a.userId && a.userName)
            set((state) => ({ subcontractorName: a.userName, subcontractorId: a.userId }))
    },
    loadSub: (by, cd) => set((state) => ({ subcontractorName: by, subcontractorId: cd })),
    getSubcontractorNameAndId: async () => {
        if (actual().subcontractorId && actual().subcontractorName)
            return { subcontractorName: actual().subcontractorName, subcontractorId: actual().subcontractorId };
        else {
            await actual().serverLoadSub();
            return { subcontractorName: actual().subcontractorName, subcontractorId: actual().subcontractorId };
        }
    }
}))