import { Button, TextField } from '@mui/material';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { AutoDisabler, Validate, ValidationGroup } from 'mui-validate';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { APIUsersHelper } from '../../Api/ApiUsersHelper';
import { CreateApplicationRequest } from '../../Api/UsersMicroserviceClient';
import { StavarioGlobalContext, StavarioUpdateContext } from '../../Contexts/LoginMainContext';
import LanguagePicker from '../Shared/LanguagePicker';
import Payment from './Payment';
interface NewAppProps {
    changeAuthMode: () => void;
};
export default function SignUp(props: NewAppProps) {
    const { changeAuthMode } = props;
    const { t, i18n } = useTranslation();
    const [data, setData] = useState<Partial<CreateApplicationRequest> | undefined>();
    const navigate = useNavigate();
    useEffect(() => {
        setData(new CreateApplicationRequest());
    }, []);
    let [paymentOpen, setPaymentOpen] = useState(false);
    const globalContext = React.useContext(StavarioGlobalContext);
    const setGlobal = React.useContext(StavarioUpdateContext);
    function createDemo() {
        new APIUsersHelper().CreateDemo(new CreateApplicationRequest({
            name: data!.name!, email: data!.email!, firstname: data!.firstname!, language: i18n.language, lastname: data!.lastname, source: 'React'
        })).then((t) => {
            localStorage.removeItem('bearer');
            localStorage.removeItem('username');
            localStorage.removeItem('initials');
            localStorage.removeItem('company');
            localStorage.removeItem('refresh-token');
            localStorage.setItem('bearer', t.accessToken!);
            localStorage.setItem('username', t.userName!);
            localStorage.setItem('initials', t.initials!);
            localStorage.setItem('company', t.appName!);
            localStorage.setItem('refresh-token', t.refreshToken!);
            globalContext.name = t.userName;
            globalContext.initials = t.initials;
            globalContext.companyName = t.appName;
            globalContext.isAdmin = t.isAdmin;
            globalContext.users = [];
            globalContext.projects = [];
            setGlobal!(globalContext!);
            navigate('/projects/todos');
        });
    }
    function createFull() {

    }
    let margin = { margin: '12px' };
    if (data)
        return (
            <ValidationGroup>
                <div className="Auth-form-container">
                    <div className="Auth-form">
                        <div className="Auth-form-content">
                            <h3 className="Auth-form-title">{t('login.signIn')}</h3>
                            <div className="text-center">
                                {t('login.alreadyRegistered')}{" "}
                                <span style={{ color: '#FFC600' }} onClick={changeAuthMode}>
                                    {t('login.signIn')}
                                </span>
                                {/* <span className="link-primary" onClick={changeAuthMode}>
                        Sign In
                    </span> */}
                            </div>
                            <Validate name='email-signup-validate' regex={[/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('FieldRequired')]}>
                                <TextField
                                    id="outlined-basic"
                                    sx={margin}
                                    fullWidth
                                    label={t('login.enterEmail')}
                                    size='small'
                                    value={data!.email}
                                    onChange={(e) => { setData({ ...data, email: e.target.value }) }} />
                            </Validate>
                            <Validate name='company-signup-validate' required={[true, t('FieldRequired')]}>
                                <TextField
                                    id="outlined-basic"
                                    sx={margin}
                                    fullWidth
                                    label='xxx company name'
                                    size='small'
                                    value={data!.name}
                                    onChange={(e) => { setData({ ...data, name: e.target.value }) }} />
                            </Validate>
                            <Validate name='name-signup-validate' required={[true, t('FieldRequired')]}>
                                <TextField
                                    id="outlined-basic"
                                    sx={margin}
                                    label={t('name')}
                                    fullWidth
                                    size='small'
                                    value={data!.firstname}
                                    onChange={(e) => { setData({ ...data, firstname: e.target.value }) }} />
                            </Validate>
                            <Validate name='lastname-signup-validate' required={[true, t('FieldRequired')]}>
                                <TextField
                                    id="outlined-basic"
                                    sx={margin}
                                    label={t('YourLastname')}
                                    fullWidth
                                    size='small'
                                    value={data!.lastname}
                                    onChange={(e) => { setData({ ...data, lastname: e.target.value }) }} />
                            </Validate>
                            <AutoDisabler>
                                <Button onClick={createDemo} sx={{ margin: '12px' }} fullWidth variant="contained" type="submit">
                                    xxx demo
                                </Button>
                            </AutoDisabler>
                            <AutoDisabler>
                                <Button onClick={createFull} sx={{ margin: '12px' }} fullWidth variant="contained" type="submit">
                                    {t('sales.payment')}
                                </Button>
                            </AutoDisabler>


                            <p className="text-center mt-2" >
                                {t('login.forgot')} <a href="#" style={{ color: '#ffc107' }}>{t('login.password')}</a><LanguagePicker showText={false} />
                            </p>
                            <Payment open={paymentOpen} setOpen={setPaymentOpen}></Payment>
                        </div>
                    </div>
                </div>
            </ValidationGroup>
        );
    else return <></>;
}