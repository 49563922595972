import { ApiOldBaseHelper } from "./ApiOldBaseHelper";
import { DeleteWealthRequest, GetEmployeeGroupsNamesResponse, GetWealthDetailsResponse, GetWealthExpendituresResponse, GetWealthsForAdminUserResponse, MoveWealthsFromTrashRequest, SaveWealthDetailsRequest, SaveWealthExpenditureReqDTO, SaveWealthExpendituresRequest, WealthForDiaryDialogDTO } from "./ApiServerVov";

export class APIOldWealthClient extends ApiOldBaseHelper {
    public async GetWealthsForAdminUser(deleted: boolean): Promise<GetWealthsForAdminUserResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getWealthsForAdminUser(deleted));
    }
    public async GetWealthDetails(id: number): Promise<GetWealthDetailsResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getWealthDetails(id));
    }

    public async GetEmployeeGroupsNames(): Promise<GetEmployeeGroupsNamesResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getEmployeeGroupsNames());
    }

    public async GetWealthExpenditures(id: number): Promise<GetWealthExpendituresResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getWealthExpenditures(id));
    }

    public async SaveWealthDetails(data: Partial<GetWealthDetailsResponse>): Promise<boolean | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().saveWealthDetails(new SaveWealthDetailsRequest({
            id: data.id,
            name: data.name,
            description: data.description,
            isActive: data.isActive,
            code: data.code,
            serialNumber: data.serialNumber,
            showToEmps: data.showToEmps,
            manufacturer: data.manufacturer,
            priceA: data.priceA,
            priceB: data.priceB,
            priceC: data.priceC,
            priceForRent: data.priceForRent,
            datetimeImage: data.datetimeImage,
            gpsXImage: data.gpsXImage,
            gpsYImage: data.gpsYImage,
            employeeId: data.employeeId,
            buildingId: data.buildingId,
            warehouseId: data.warehouseId,
            employeeGroupsIds: data.employeeGroupsIds
        }))).ok;
    }

    public async saveWealthExpenditures(wealthId: number, wealthExpenditures: SaveWealthExpenditureReqDTO[]): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().saveWealthExpenditures(new SaveWealthExpendituresRequest({
            wealthId: wealthId,
            wealthExpenditures: wealthExpenditures
        }))).ok!;
    }

    public async deleteWealths(wealthsIds: number[]): Promise<boolean> {
        return ((await this.GetAdressedAuthRefreshableClient().deleteWealths(new DeleteWealthRequest({ wealthIds: wealthsIds }))).ok!);
    }
    public async MoveWealthsFromTrash(wealthsIds: number[]): Promise<boolean> {
        return (await this.GetAdressedAuthRefreshableClient().moveWealthsFromTrash(new MoveWealthsFromTrashRequest({ deletedWealthsIds: wealthsIds }))).ok!;
    }
}