import React, { useEffect } from "react";
import { Autocomplete, Dialog, DialogTitle, FormControl, IconButton, SelectChangeEvent, TextField, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { Validate, ValidationGroup } from "mui-validate";
import { SolveInvestorProjectsContext, StavarioOldGlobalContext, StavarioOldUpdateContext } from "../../../Contexts/LoginOldContext";
import { BuildingDTO } from "../../../ApiOld/ApiServerVov";
import { Close } from "@mui/icons-material";

export interface InvestorProjectListDialogProps {
    open: boolean;
    onClose: (value: string) => void;
    selectedProjectId?: number | null;
    isReadOnly: boolean;
    onChange: (event: BuildingDTO | null) => void;
    width?: string;
    backgroundColor?: string;
    emptyText?: string;
    catchError?: boolean;
}
export default function InvestorBuildingSelectDialog(props: InvestorProjectListDialogProps) {
    const { onChange, selectedProjectId, isReadOnly, width, backgroundColor, emptyText, catchError, onClose, open } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const [selectedProject, setSelectedProject] = React.useState<BuildingDTO | null>(null);
    const [projects, setProjects] = React.useState<BuildingDTO[]>([]);
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const globalContext = React.useContext(StavarioOldGlobalContext);
    const setGlobal = React.useContext(StavarioOldUpdateContext)!;

    const handleChange = (event: SelectChangeEvent<typeof selectedProject>) => {
        if (event.target.value !== undefined) {
            setSelectedProject(event.target.value as (BuildingDTO | null));

            onChange(event.target.value as (BuildingDTO | null));
        }

    };
    useEffect(() => {

        SolveInvestorProjectsContext(globalContext, setGlobal).then((res) => {
            setProjects(res)
        });

    }, []);
    useEffect(() => {
        if (projects !== undefined && projects !== null && projects.length > 0
            && selectedProjectId !== undefined &&
            selectedProjectId !== null && selectedProjectId > 0) {
            let q = projects.find(x => x.id === selectedProjectId);
            if (q !== undefined)
                setSelectedProject(q);
        }

    }, [projects, selectedProjectId]);

    function dialogClosed() { onClose(""); }

    return (<Dialog fullWidth maxWidth="lg"
        open={open} onClose={onClose}
        sx={{ width: biggerThan700 ? '60%' : '100%', margin: 'auto', marginTop: '40px' }}>
        <DialogTitle height='40px' sx={{ zIndex: "28" }}>
            <div style={{ backgroundColor: '#F2F2F2', zIndex: "200" }}>
                <div style={{ float: 'left', display: 'flex' }}>
                    <Typography variant='h1' fontWeight='bold' fontSize={20}>{t('Projekty')} </Typography>
                </div>
                <div style={{ float: 'right', display: 'flex', alignContent: 'flex-end' }}>

                    {/* {biggerThan600 && task!.date && <div>
                            <Typography variant='body1' sx={{ marginRight: '12px' }} fontSize={11}>{(task.id! > 0) ? t('Created') + " " + task!.date!.toLocaleString() : ""} </Typography>
                        </div>} */}
                    <div style={{ marginTop: '-14px' }}>
                        <IconButton onClick={dialogClosed} >
                            <Close />
                        </IconButton>
                    </div>
                </div>
            </div>
        </DialogTitle>
        <div style={{ width: width === undefined ? '280px' : width, minHeight: '250px' }}>
            {catchError &&/*  <ValidationGroup> */
                <Validate name={'building-validate-input'} initialValidation={'silent'} required={[true, t('FieldRequired')]}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        size="small"
                        onChange={(e, v) => {
                            setSelectedProject(v);
                            onChange(v);
                        }}
                        readOnly={isReadOnly}
                        getOptionLabel={o => o !== null ? o!.name! : ""}
                        value={selectedProject}
                        options={projects}
                        sx={{ width: width === undefined ? 280 : width, backgroundColor: backgroundColor === undefined ? '#FFFFFF' : backgroundColor, margin: '8px' }}
                        renderInput={(params) => <TextField
                            // error={selectedProject === null}
                            {...params} label={emptyText === undefined ? t('Projekt') : (selectedProject === null ? emptyText : '')}

                        />}
                    /></Validate>
            }
            {catchError === false && <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                onChange={(e, v) => {
                    setSelectedProject(v);
                    onChange(v);
                }}
                readOnly={isReadOnly}
                getOptionLabel={o => o !== null ? o!.name! : ""}
                value={selectedProject}
                options={projects}

                sx={{ width: width === undefined ? 280 : width, backgroundColor: backgroundColor === undefined ? '#FFFFFF' : backgroundColor, margin: '8px' }}
                renderInput={(params) => <TextField {...params} label={emptyText === undefined ? t('Projekt') : (selectedProject === null ? emptyText : '')}


                />}
            />
            }
        </div>
    </Dialog>
    );
}