
import React, { useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, CircularProgress, InputAdornment, Button, Typography, IconButton, Tooltip, useTheme } from '@mui/material';

import { APIVovInvestorHelper } from '../../../ApiOld/ApiInvestorOldClient';
import { PreviousMessagesDTO, PromptDTO, PromptType } from '../../../ApiOld/ApiServerVov';
import delay from '../../Shared/Functions/Delay';
import { Add, Close, ContentCopy, Delete, Edit, Send } from '@mui/icons-material';
import './GptChatScene.css';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import stavarioPic from '../../../Assets/login-logo-stavario.svg'; // with import
import stavarioPicSmall from '../../../Assets/logo512.png'; // with import
import { useMediaPredicate } from 'react-media-hook';
import ReallyCloseGptDialog from './ReallyCloseGptDialog';
import { loadDiaryStore, useDiaryStore } from '../../../Contexts/DiaryZContext';
import AddOrEditPromptDialog from './AddOrEditPromptDialog';
import DeleteDialog from '../../Shared/Layouts/DeleteDialog';
import { APIVovHelper } from '../../../ApiOld/ApiOldClient';

interface GptImageChatSceneProps {
    selectedImagesUrl: string[];
    prompts: PromptDTO[];
    autoMessage: string;
    isSubdodavatel: boolean;
    recordId: number | undefined;
    onCopy?: (text: string) => void;
    onClearAutoMessage?: () => void;
    refresh: () => void;
}
function GptImageChatScene(props: GptImageChatSceneProps) {
    // console.log(useStyles);
    const { selectedImagesUrl, onCopy, autoMessage, isSubdodavatel, recordId, onClearAutoMessage, prompts, refresh } = props;
    const [inputValue, setInputValue] = React.useState('');
    const [messages, setMessages] = React.useState<PreviousMessagesDTO[]>([]);
    const [isLoadingResponse, setIsLoadingResponse] = React.useState(false);
    const [closingChatOpen, setClosingChatOpen] = React.useState(false);
    const [editingPrompt, setEditingPrompt] = React.useState<PromptDTO | undefined>(undefined);
    const [deletingPrompt, setDeletingPrompt] = React.useState<PromptDTO | undefined>(undefined);
    const params = useParams();
    const navigate = useNavigate();
    const theme = useTheme();

    const { permissions, load } = useDiaryStore();

    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    useEffect(() => {
        if (params['lang'] !== i18n.language)
            i18n.changeLanguage(params['lang']!);
    }, [params]);

    useEffect(() => {
        if (permissions === undefined)
            loadDiaryStore(isSubdodavatel === undefined ? false : isSubdodavatel).then((d) => {
                load(d);
            });

    }, []);

    useEffect(() => {
        setMessages([]);
    }, [recordId]);

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };
    useEffect(() => {
        if (messages.length > 0 && messages[0].role === "user" && recordId) {
            setIsLoadingResponse(true);
            new APIVovHelper().SendGptImage(inputValue, selectedImagesUrl.slice(0, 5), messages.filter((p, i) => i !== 0), recordId).then((text) => {
                setMessages([new PreviousMessagesDTO({ content: text, role: "assistant" }), ...messages]);
                setInputValue('');
                setIsLoadingResponse(false);
            });
        }
    }, [messages])

    useEffect(() => {
        if (autoMessage.length > 0 && messages.length === 0 && isLoadingResponse !== true && recordId) {
            setIsLoadingResponse(true);
            new APIVovHelper().SendGptImage(autoMessage, selectedImagesUrl.slice(0, 5), messages, recordId).then((text) => {
                setMessages([new PreviousMessagesDTO({ content: text, role: "assistant" }), ...messages]);
                setInputValue('');
                setIsLoadingResponse(false);
                if (onClearAutoMessage)
                    onClearAutoMessage();
            });
        }
    }, [autoMessage])

    const handleSendMessage = async () => {
        setIsLoadingResponse(true);
        setMessages([new PreviousMessagesDTO({ content: inputValue, role: "user" }), ...messages]);

    };
    const copyAllMessages = () => {
        let text = '';
        let a = messages.reverse();
        a.forEach((m) => {
            text += m.content;
        });
        if (onCopy)
            onCopy(text);
        return text;
    }
    return (
        // <div className={classes.chatContainer}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ReallyCloseGptDialog open={closingChatOpen} handleClose={(r) => { if (r) navigate('/' + params['lang']! + '/' + params['domain']! + '/investor/tabs/tasks'); setClosingChatOpen(false) }} />
            {onCopy === undefined && <div style={{ width: '100%', display: 'flex', height: '48px', justifyContent: 'space-between', background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                <div style={{ display: 'flex', margin: '10px' }}>
                    <img src={biggerThan700 ? stavarioPic : stavarioPicSmall}
                        style={{ cursor: 'pointer' }}
                        onClick={e => { navigate('/' + params['lang']! + '/' + params['domain']! + '/investor/tabs/tasks') }}
                        alt="stavario" width={biggerThan700 ? "128px" : "35px"}
                        height={biggerThan700 ? "26px" : "35px"} ></img>
                    <Typography variant='h5' sx={{ marginTop: '3px', marginLeft: '10px' }} fontWeight={'bold'} fontSize={18}>{t('aiHelper')}</Typography>
                </div>
                <Button color='secondary' sx={{ margin: '10px' }} endIcon={<Close />} onClick={() => {
                    if (messages.length > 0)
                        setClosingChatOpen(true);
                    else navigate('/' + params['lang']! + '/' + params['domain']! + '/investor/tabs/tasks');
                }}>{biggerThan700 ? t('backToInvestorInterface') : ''}</Button>
            </div>}

            <div style={{ display: 'flex', width: '90%', margin: '40px' }} >
                <TextField
                    placeholder={t('enterQuestionForAI')}
                    label={t('hereWriteQuestion')}
                    sx={{ width: '80%' }}
                    disabled={isLoadingResponse}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleSendMessage();
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {!isLoadingResponse && <Button disabled={!selectedImagesUrl || selectedImagesUrl.length === 0} variant='contained' startIcon={<Send />} onClick={handleSendMessage}>
                                    {t('send')}
                                </Button>}
                                {isLoadingResponse && <Button size="small" variant='contained' >
                                    <CircularProgress color="secondary" size={16} /> <Typography sx={{ marginLeft: '8px' }}>{t('loadingAIResponse')}</Typography>
                                </Button>}
                            </InputAdornment>
                        ),
                    }}
                />
                <Button variant='contained' sx={{ marginLeft: '10px', width: '28px', height: '28px', marginTop: '12px' }} size='small' onClick={() => { setMessages([]); }}><Delete style={{ width: '20px', height: '20px' }} /></Button>
                {onCopy && <Button disabled={!permissions || !permissions.writeCommonCompletedWorks} sx={{ marginLeft: '10px' }} size='small' onClick={copyAllMessages} startIcon={<ContentCopy />}>{t('diary.copyToCompletedWork')}</Button>}

            </div>
            <div style={{ marginLeft: '48px', marginTop: '-40px', fontSize: '10px', marginBottom: '4px' }}>{t('chatMaxImages')}</div>
            <div style={{ display: 'flex', width: '90%', marginLeft: '20px', marginRight: '20px' }}>
                {prompts.map((pr, i) => {
                    return <div key={i} style={{
                        display: 'inline-flex', flexWrap: 'wrap', backgroundColor: theme.palette.primary.main,
                        zIndex: '200', border: '1px solid lightgray', borderRadius: '4px', boxShadow: '4px 4px 16px 4px lightgray', padding: '3px 1px 3px 1px', margin: '0px 4px', cursor: 'pointer'
                    }} onClick={() => { if (pr.text) setInputValue(pr.text) }} >
                        <div>
                            <Typography style={{ fontSize: '12px', fontWeight: 'bold', margin: 'auto', marginTop: '8px', marginLeft: '4px', color: 'black' }}>{pr.name}</Typography>
                        </div>
                        <div style={{ marginLeft: '16px', marginTop: '2px' }}>
                            <Tooltip title={t('edit')} >
                                <IconButton sx={{ background: theme.palette.primary.main, height: '24px' }} onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setEditingPrompt(pr);
                                }} >
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div style={{ marginTop: '2px' }}>
                            <Tooltip title={t('Delete')}>
                                <IconButton sx={{ background: theme.palette.primary.main, height: '24px' }} onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setDeletingPrompt(pr);
                                }} >
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>;
                })}
                <div style={{}}>
                    <Tooltip title={t('diary.addPrompt')}>
                        <IconButton sx={{ marginLeft: '24px', marginTop: '0px', background: theme.palette.primary.main }} onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setEditingPrompt(new PromptDTO({ id: 0, name: '', text: '' }));
                        }} >
                            <Add />
                        </IconButton>
                    </Tooltip>
                </div>
                {editingPrompt && recordId && <AddOrEditPromptDialog open={editingPrompt !== undefined} prompt={editingPrompt} type={PromptType._1} relationId={recordId} handleClose={() => { setEditingPrompt(undefined); refresh(); }} />}
                {deletingPrompt && <DeleteDialog data={deletingPrompt} onDeleteAgree={(data) => {
                    new APIVovHelper().DeletePrompt(deletingPrompt.id!).then(res => {
                        setDeletingPrompt(undefined);
                        refresh();
                    });
                }} onClose={() => { setDeletingPrompt(undefined) }} open={deletingPrompt !== undefined} />}
            </div>
            <List>
                {messages.map((message, i) => (
                    <div key={i}>
                        <div key={'message' + i} className={message.role === 'user' ? 'chat-left' : 'chat-right'}>
                            {onCopy && <Tooltip title={t('diary.copyToCompletedWork')}><span><IconButton disabled={!permissions || !permissions.writeCommonCompletedWorks} onClick={e => { if (onCopy && message.content) onCopy(message.content); }}><ContentCopy sx={{ position: 'relative', gridArea: '1/1' }} /></IconButton></span></Tooltip>}
                            <div style={{ gridArea: '1/1' }}> {message.content && message.content.length > 0 ? message.content : t('chatGptError') + ' ' + t('sales.contactSupport')}</div>



                        </div>

                    </div>
                ))}
            </List>
        </div >
    );
}
export default (GptImageChatScene);
