import { Autocomplete, FormControl, FormControlLabel, Grid, IconButton, InputLabel, makeStyles, MenuItem, Popover, Select, Slider, styled, Switch, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { ValidationGroup } from 'mui-validate';
import { useNavigate, useParams } from 'react-router-dom';
import { EmployeeDetailDTO, LoginPhotoEnum } from '../../../../ApiOld/ApiServerVov';
import GroupSelect from '../../../Shared/GroupSelect';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EmployeeSignatureScene from './EmployeeSignatureScene';
import EmployeeGroupSelect from '../../../Shared/EmployeeGroupSelect';

export interface EmployeeBasicDetailSceneProps {
    employee: EmployeeDetailDTO | undefined;
    setEmployee: (value: EmployeeDetailDTO | undefined) => void;
}


export default function EmployeeBasicDetailScene(props: EmployeeBasicDetailSceneProps) {
    const { employee, setEmployee } = props;
    const navigate = useNavigate();
    const theme = useTheme();

    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

    if (employee !== undefined) {
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px'/* , width: '400px' */ }}>
                    <h4>{t('buildings.basicInformation')}</h4>
                    <div style={{ margin: '10px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={1}>
                                <TextField label={t('employee.title')} variant="outlined" fullWidth size='small'
                                    name='title'
                                    value={employee.title}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, title: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('z')} variant="outlined" fullWidth size='small'
                                    name='firstname'
                                    required
                                    value={employee.firstname}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, firstname: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField label={t('lastName')} variant="outlined" fullWidth size='small'
                                    name='lastname'
                                    required
                                    value={employee.lastname}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, lastname: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField label={t('birthLastname')} variant="outlined" fullWidth size='small'
                                    name='birthLastname'
                                    value={employee.birthLastname}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, birthLastname: e.target.value })) }}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <TextField label={t('employee.phonePrefix')} variant="outlined" size='small' required
                                        name='phonePrefix'
                                        sx={{ width: '120px' }}
                                        value={employee.phonePrefix}
                                        onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, phonePrefix: e.target.value })) }}
                                    />
                                    <TextField label={t('employee.phone')} variant="outlined" fullWidth size='small' required
                                        name='phone'
                                        value={employee.phone}
                                        onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, phone: e.target.value })) }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField label={t('employee.email')} variant="outlined" fullWidth size='small' required
                                    name='email'
                                    value={employee.email}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, email: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField label='IČO' variant="outlined" fullWidth size='small'
                                    name='ico'
                                    value={employee.ico}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, ico: e.target.value })) }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField label='DIČ' variant="outlined" fullWidth size='small'
                                    name='dic'
                                    value={employee.dic}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, dic: e.target.value })) }}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <EmployeeGroupSelect alertFontSize={12} minWidth={10}
                                    onChange={(e) => {
                                        if (e)
                                            setEmployee(new EmployeeDetailDTO({ ...employee, employeeGroupId: e.id }));
                                        else setEmployee(new EmployeeDetailDTO({ ...employee, employeeGroupId: undefined }));
                                    }} selectedGroupId={employee.employeeGroupId} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField label={t('code')} variant="outlined" size='small'
                                    name='code'
                                    disabled
                                    value={employee.code}
                                    onChange={(e) => { /* setEmployee({ ...employee, dic: e.target.value } as EmployeeDetailDTO) */ }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormControlLabel control={<Switch checked={employee.isActive}
                                    onChange={(e, checked) => {
                                        setEmployee(new EmployeeDetailDTO({ ...employee, isActive: checked }));
                                    }}
                                />} label={t('active')} />
                            </Grid>
                            <Grid item xs={5}>
                                <FormControlLabel control={<Switch checked={employee.autoEndWorkTime}
                                    onChange={(e, checked) => {
                                        setEmployee(new EmployeeDetailDTO({ ...employee, autoEndWorkTime: checked }));
                                    }}
                                />} label={t('employee.autoWorkTimeEnd')} />
                            </Grid>

                            <Grid item xs={2}>
                                <TextField label={t('hourlyRateInCZK')} variant="outlined" fullWidth size='small' type='number' required
                                    name='mzda'
                                    value={employee.wage}
                                    onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, wage: parseInt(e.target.value) })) }}
                                />
                            </Grid>
                            <Grid item xs={10}>
                                <FormControl>
                                    <InputLabel id="take-photo-label">{t('employee.takePhotoOnLogin')}</InputLabel>
                                    <Select
                                        sx={{ minWidth: '180px' }}
                                        size="small"
                                        labelId="take-photo-label"
                                        id="take-photo"
                                        value={employee.takeLoginPhoto}
                                        label={t('employee.takePhotoOnLogin')}
                                        onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, takeLoginPhoto: e.target.value as LoginPhotoEnum })) }}
                                    >
                                        <MenuItem value={2}>{t('employee.undetermined')}</MenuItem>
                                        <MenuItem value={1}>{t('yesL')}</MenuItem>
                                        <MenuItem value={0}>{t('noL')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Grid container spacing={3} >
                    <Grid item xs={6}>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px'/* , width: '400px' */ }}>
                            <h4>{t('employee.timesSettings')}</h4>
                            <div style={{ margin: '10px' }}>
                                <Grid container spacing={3} >
                                    <Grid item xs={12} >
                                        <FormControlLabel control={<Switch checked={employee.workTimeRound}
                                            onChange={(e, checked) => {
                                                setEmployee(new EmployeeDetailDTO({ ...employee, workTimeRound: checked }));
                                            }}
                                        />} label={t('employee.roundOfWorkingHours')} />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs} >
                                            <TimePicker
                                                minutesStep={30}
                                                ampm={false}
                                                label={t('employee.startOfWorkingHours')}
                                                value={employee.workTimeFrom ? employee.workTimeFrom : null}
                                                onChange={(newValue) => setEmployee(new EmployeeDetailDTO({ ...employee, workTimeFrom: newValue === null ? undefined : newValue }))}
                                                renderInput={(params) => (
                                                    <TextField {...params} />
                                                )} />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs} >
                                            <TimePicker
                                                minutesStep={30}
                                                ampm={false}
                                                label={t('employee.endOfWorkingHours')}
                                                value={employee.workTimeTo ? employee.workTimeTo : null}
                                                onChange={(newValue) => setEmployee(new EmployeeDetailDTO({ ...employee, workTimeTo: newValue === null ? undefined : newValue }))}
                                                renderInput={(params) => (
                                                    <TextField {...params} />
                                                )} />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs} >
                                            <TimePicker
                                                ampm={false}
                                                label={t('employee.notifyArrival')}
                                                disabled
                                                value={employee.workTimeNotifyFrom ? employee.workTimeNotifyFrom : null}
                                                onChange={(newValue) => setEmployee(new EmployeeDetailDTO({ ...employee, workTimeNotifyFrom: newValue === null ? undefined : newValue }))}
                                                renderInput={(params) => (
                                                    <TextField {...params} />
                                                )} />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterDayjs} >
                                            <TimePicker
                                                ampm={false}
                                                label={t('employee.notifyLeave')}
                                                disabled
                                                value={employee.workTimeNotifyTo ? employee.workTimeNotifyTo : null}
                                                onChange={(newValue) => setEmployee(new EmployeeDetailDTO({ ...employee, workTimeNotifyTo: newValue === null ? undefined : newValue }))}
                                                renderInput={(params) => (
                                                    <TextField {...params} />
                                                )} />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField label={t('employee.penaltyForLateArrival')} variant="outlined" fullWidth size='small' type='number'
                                            name='penaltyForLateArrival'
                                            value={employee.lateArrivalPenalty}
                                            onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, lateArrivalPenalty: parseInt(e.target.value) })) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label={t('employee.penaltyForEarlyDeparture')} variant="outlined" fullWidth size='small' type='number'
                                            name='penaltyForEarlyDeparture'
                                            value={employee.earlyExitPenalty}
                                            onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, earlyExitPenalty: parseInt(e.target.value) })) }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label={t('employee.breakLength')} variant="outlined" fullWidth size='small' type='number'
                                            name='breakLength'
                                            inputProps={{ min: 0, step: 5 }}
                                            value={employee.breakLength}
                                            onChange={(e) => { setEmployee(new EmployeeDetailDTO({ ...employee, breakLength: parseInt(e.target.value) })) }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px', minWidth: '200px' }}>
                            <h4>{t('Info')}</h4>
                            <div style={{ margin: '10px' }}>
                                <div style={{ display: 'flex', margin: '2px', gap: 5 }}>
                                    <Typography variant='body2'>{t('employee.readDocs')}: </Typography>
                                    <Typography variant='body2' sx={{ fontWeight: 'bold ' }}>{employee.docsRead ? t('yesL') : t('noL')}</Typography>
                                </div>
                                <div style={{ display: 'flex', margin: '2px', gap: 5 }}>
                                    <Typography variant='body2'>{t('employee.readMinorWealth')}: </Typography>
                                    <Typography variant='body2' sx={{ fontWeight: 'bold ' }}>{employee.wealthRead ? t('yesL') : t('noL')}</Typography>
                                </div>
                                <div style={{ display: 'flex', margin: '2px', gap: 5 }}>
                                    <Typography variant='body2'>{t('employee.bozpTaken')}: </Typography>
                                    <Typography variant='body2' sx={{ fontWeight: 'bold ' }}>xxxTODO</Typography>
                                </div>
                                <EmployeeSignatureScene employeeId={employee.id!} />
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={6}>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px', minWidth: '200px' }}>
                            <h4>{t('employee.changeWageAndRecalculateReport')}</h4>
                            <div style={{ margin: '10px' }}>
                                TODO
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
    else return (<></>);
};
