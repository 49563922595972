
import { LocalSeeOutlined, RequestPageTwoTone } from "@mui/icons-material";
import { ApiInvestorOldBaseHelper, SingletonRefreshClientInvestorOld } from "./ApiInvestorOldBaseHelper";
import { AddImageToProjectAndDiaryRecordResponse, AddImageToProjectResponse, AuthInvestorRequest, AuthInvestorResponse, BuildingInfoDTO, CreateDailyRecordInvestorRequest, DeleteRequestDTO, DeleteRequestsRequest, DeleteRequestsResponse, DiaryLogDTO, DochazkaRecordDTO, FileDiaryDTO, FileDirectoryDTO, FileParameter, FileResponse, FileToTransferDTO, GetDailyFullRecordRequest, GetDailyFullRecordResponse, GetDailyRecordsForUserAndDiaryByRecordIdResponse, GetDiariesForUserDTO, GetDiaryPermissionsInvestorRequest, GetDiaryPermissionsInvestorResponse, GetDiaryRecordDataNotTablesResponse, GetDocumentsForInvestorAndBuildingResponse, GetImagesUrlsForRecordResponse, GetInvestorBuildingsRequest, GetInvestorInfoResponse, GetMachinesForRecordTableDTO, GetMachinesForRecordTableRequest, GetMaterialsForRecordTableDTO, GetMaterialsForRecordTableRequest, GetPhotosFromBuildingToDiaryChooseResponse, GetPrintStatusRequest, GetPrintStatusResponse, GetSignaturesForRecordResponse, GetTodosForDiaryDTO, GetWeatherForRecordDTO, GetWeatherForRecordRequest, GetWorkerForRecordDTO, GetWorkersForRecordRequest, ImageDiaryDTO, InvestorsChatMessageDTO, MarkConversationAsReadRequest, PostDiaryChatMessageInvestorsRequest, PreviousMessagesDTO, PrintSignedDiaryRecordsZIPRequest, RefreshInvestorTokenRequest, RefreshInvestorTokenResponse, RequestConversationDTO, RequestsDTO, ResetInvestorPasswordRequest, ResetInvestorPasswordResponse, SaveDiarySignatureAdminUserResponse, SaveRequestCommentTextRequest, SaveRequestRequest, SendGptImageMessageRequest, SendGptMessageRequest, SendMessageDiaryRequest, TransferPhotosFromProjectRequest, UpdateInvestorCredentialsRequest, UpdateInvestorCredentialsResponse, UpdateInvestorPasswordRequest, UpdateInvestorPasswordResponse, UpdateRequestRequest, UploadDigitalSignedPDFResponse, UploadFileToDiaryDirectResponse } from "./ApiServerVov";

export class APIVovInvestorHelper extends ApiInvestorOldBaseHelper {
    public async LoginInvestor(userName: string, password: string, domain: string): Promise<AuthInvestorResponse> {
        // try {
        let res = (await this.GetClientToAuth().authInvestor(new AuthInvestorRequest({ investorUsername: userName, password: password, application: domain })))!;
        localStorage.setItem('investor_token_old', res.token!);
        localStorage.setItem('investor_token_old_refresh', res.refreshToken!);
        localStorage.setItem('investor_token_old_expires', res.dateTimeExpires!.toString());
        return res;
        // }
        // catch (e) {
        // console.log('shitfuck');
        //     throw e;
        // }
    }

    public async GetPermissions(): Promise<GetDiaryPermissionsInvestorResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getDiaryPermissionsInvestor(new GetDiaryPermissionsInvestorRequest()));
    }

    public async GetRequests(buildingId: number): Promise<RequestsDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getRequests(buildingId)).requests!;
    }

    public async GetRequest(recordId: number): Promise<RequestsDTO> {
        return (await this.GetAdressedAuthRefreshableClient().getRequest(recordId)).request!;
    }

    public async GetDochazka(buildingId?: number): Promise<DochazkaRecordDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getInvestorDochazka(buildingId === undefined ? 0 : buildingId)).dochazkaRecords!;
    }

    public async GetImagesInvestor(base64: boolean, buildingId: number | null): Promise<ImageDiaryDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getImagesInvestor(buildingId === null ? 0 : buildingId, base64)).images!;
    }
    public async GetInvestorDiaryRecords(buildingId?: number): Promise<ImageDiaryDTO[] | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().getInvestorDiaryRecords(buildingId === undefined ? 0 : buildingId)).records;
    }

    public async DeleteRequests(data: DeleteRequestDTO[]): Promise<DeleteRequestsResponse> {
        return (await this.GetAdressedAuthRefreshableClient().deleteRequests(new DeleteRequestsRequest({ deleteRequests: data })))!;
    }

    public async GetInvestorBuildings(): Promise<BuildingInfoDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getInvestorBuildings(new GetInvestorBuildingsRequest())).buildings!;
    }

    public async UpdateInvestorCredentials(newName: string, newEmail: string): Promise<UpdateInvestorCredentialsResponse> {
        return (await this.GetAdressedAuthRefreshableClient().updateInvestorCredentials(new UpdateInvestorCredentialsRequest({ name: newName, email: newEmail })));
    }

    public async UpdateInvestorPassword(oldPass: string, newPass: string): Promise<UpdateInvestorPasswordResponse> {
        return (await this.GetAdressedAuthRefreshableClient().updateInvestorPassword(new UpdateInvestorPasswordRequest({ oldPassword: oldPass, newPassword: newPass })));
    }

    public async GetRequestConversation(id: number): Promise<RequestConversationDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getRequestConversation(id)).conversation!;
    }

    public async GetInvestorInfo(): Promise<GetInvestorInfoResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getInvestorInfo())!;
    }

    public async SaveRequest(req: Partial<RequestsDTO> | undefined, text: string, file: FileParameter | undefined, imgUrl: string): Promise<boolean> {
        if (req !== undefined) {
            if (file !== undefined) {
                let id = (await this.GetAdressedAuthRefreshableClient().saveRequest(new SaveRequestRequest({ name: req.name, description: req.description, buildingId: req.buildingId, dateTo: req.dateTo, imageUrl: imgUrl, buildingDiaryId: req.buildingDiaryId }))).id!;
                return (await this.GetAdressedAuthRefreshableClient().saveRequestComment(id, text, file)).ok!;
            }
            else {
                return (await this.GetAdressedAuthRefreshableClient().saveRequest(new SaveRequestRequest({ name: req.name, description: req.description, buildingId: req.buildingId, dateTo: req.dateTo, imageUrl: imgUrl, buildingDiaryId: req.buildingDiaryId }))).ok!;
            }
        }
        else
            return false;
    }

    public async UpdateRequest(req: Partial<RequestsDTO> | undefined, text: string, file: FileParameter | undefined): Promise<boolean> {
        if (req != undefined) {
            if (file !== undefined) {
                if (!(await this.GetAdressedAuthRefreshableClient().updateRequest(new UpdateRequestRequest({ requestId: req.id, progres: req.progress, description: req.description, dateTo: req.dateTo, isDone: req.isDone }))).ok!)
                    return false;
                return (await this.GetAdressedAuthRefreshableClient().saveRequestComment(req.id, text, file)).ok!;
            }
            else {
                if (!(await this.GetAdressedAuthRefreshableClient().updateRequest(new UpdateRequestRequest({ requestId: req.id, progres: req.progress, description: req.description, dateTo: req.dateTo, isDone: req.isDone }))).ok!)
                    return false;
                if (text !== undefined && text !== null && text.length > 0)
                    return (await this.GetAdressedAuthRefreshableClient().saveRequestCommentText(new SaveRequestCommentTextRequest({ requestId: req.id, text: text }))).ok!;
                else
                    return true;
            }
        }
        else
            return false;
    }

    public async ResetInvestorPassword(domain: string, email: string): Promise<ResetInvestorPasswordResponse> {
        return (await this.GetClientToAuth().resetInvestorPassword(new ResetInvestorPasswordRequest({ domain: domain, email: email })));
    }

    public async GetFullDailyRecordNotTables(recordId: number, sharedOnly: boolean): Promise<GetDiaryRecordDataNotTablesResponse> {
        // console.log(sharedOnly);
        return (await this.GetAdressedAuthRefreshableClient().diaryRecordDataNotTables(recordId, sharedOnly));
    }
    public async GetRecordsForViewingStateless(recordId: number): Promise<GetDailyRecordsForUserAndDiaryByRecordIdResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getDailyRecordsForUserAndDiaryByRecordId(recordId));
    }
    public async GetSignaturesForRecord(recordId: number): Promise<GetSignaturesForRecordResponse | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().getSignaturesForRecord(recordId, false));
    }
    public async UploadSignature(file: FileParameter, diaryIdRecord: number, isInvestor: boolean): Promise<SaveDiarySignatureAdminUserResponse> {
        return (await this.GetAdressedAuthRefreshableClient().uploadSignature(diaryIdRecord, file, true));
    }
    public async GetImagesForRecord(recordId: number): Promise<GetImagesUrlsForRecordResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getDiaryImages(recordId, false));
    }
    public async PrintDiaryRecordInvestor(diaryRecordId: number, hasDigitalSignatureField: boolean): Promise<FileResponse | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().printDiaryRecordInvestor(diaryRecordId, hasDigitalSignatureField));
    }
    public async PrintDiaryInvestor(diaryId: number, hasDigitalSignatureField: boolean): Promise<FileResponse | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().printDiaryInvestor(diaryId, hasDigitalSignatureField));
    }

    public async PrintSignedDiaryRecordsZIP(diaryId: number): Promise<void> {
        return (await this.GetAdressedAuthRefreshableClient().printSignedDiaryRecordsZIP(new PrintSignedDiaryRecordsZIPRequest({ diaryId: diaryId, apiAdress: '' })));
    }

    public async GetPrintStatusInvestor(diaryId: number, onlyMyNotes: boolean, hasDigitalSignatureField: boolean, hasAttachments: boolean): Promise<GetPrintStatusResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getPrintStatus(new GetPrintStatusRequest({ diaryId: diaryId, onlyMyNotes: onlyMyNotes, hasDigitalSignatureField: hasDigitalSignatureField, hasAttachments: hasAttachments })));
    }
    public async PrintWholeDiaryInvestor(diaryId: number): Promise<string | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().printWholeDiaryInvestor(diaryId, false)).downloadUri;
    }
    public async GetAttachmentsForRecord(recordId: number): Promise<FileDiaryDTO[] | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().getFilesForDiary(recordId)).files;
    }
    public async GetFilesFromProject(recordId: number): Promise<FileDirectoryDTO[] | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().getFilesFromBuildingForDiary(recordId)).files;
    }
    public async SendGptMessage(message: string, previousMessages: PreviousMessagesDTO[]): Promise<string | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().gptMessage(new SendGptMessageRequest({ message: message, previousMessages: previousMessages }))).message;
    }
    public async SendGptImage(message: string, images: string[], previousMessages: PreviousMessagesDTO[], recordId: number): Promise<string | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().gptImage(new SendGptImageMessageRequest({ message: message, imageUrls: images, previousMessages: previousMessages, diaryRecordId: recordId }))).message;
    }

    public async GetCompletedWorksRecord(recordId: number): Promise<string | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().getCompletedWorks(recordId)).completedWorks;
    }
    public async GetWeatherForRecord(recordId: number, onlyShared: boolean): Promise<GetWeatherForRecordDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getWeatherForRecord(new GetWeatherForRecordRequest({
            diaryRecordId: recordId,
            onlyShared: true,
            onlyMine: false,
        }))).weathers!;
    }
    public async GetWorkersForRecord(recordId: number, onlyShared: boolean): Promise<GetWorkerForRecordDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getWorkersForRecord(new GetWorkersForRecordRequest({
            diaryRecordId: recordId,
            onlyShared: true,
            onlyMine: false,
        }))).workers!;
    }
    public async GetMaterialsForRecord(recordId: number, onlyShared: boolean): Promise<GetMaterialsForRecordTableDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getMaterialsForRecordTable(new GetMaterialsForRecordTableRequest({
            diaryRecordId: recordId,
            onlyShared: true,
            onlyMine: false,
        }))).materials!;
    }
    public async GetMachinesForRecordTable(recordId: number, onlyShared: boolean): Promise<GetMachinesForRecordTableDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getMachinesForRecordTable(new GetMachinesForRecordTableRequest({
            diaryRecordId: recordId,
            onlyShared: true,
            onlyMine: false,
        }))).machines!;
    }
    public async GetTodosForRecord(recordId: number): Promise<GetTodosForDiaryDTO[] | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().getTodosForDiary(recordId)).todos;
    }

    public async getPhotosBuildingToChoose(diaryRecordId: number): Promise<GetPhotosFromBuildingToDiaryChooseResponse> {
        let data = (await this.GetAdressedAuthRefreshableClient().getPhotosBuildingToChoose(diaryRecordId));
        return data;
    }

    public async TransferPhotosFromProject(recordId: number, images: FileToTransferDTO[]): Promise<boolean | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().transferPhotoFromProject(new TransferPhotosFromProjectRequest({
            diaryRecordId: recordId,
            filenames: images
        }))).ok;
    }

    public async GetInvestorsChatMessages(recordId: number): Promise<InvestorsChatMessageDTO[] | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().getInvestorsChatMessages(recordId)).messages;
    }
    public async PostInvestorsMessage(recordId: number, message: string): Promise<boolean | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().sendInvestorsChatMessage(new PostDiaryChatMessageInvestorsRequest({
            diaryRecordId: recordId,
            text: message
        }))).ok;
    }
    public async GetFullDailyRecord(recordId: number, domain: string, base64: boolean, sharedOnly: boolean, onlyMine?: boolean, subcontractorId?: number): Promise<GetDailyFullRecordResponse> {
        // console.log(sharedOnly);
        return (await this.GetAdressedAuthRefreshableClient().fullDayRecord(
            new GetDailyFullRecordRequest({
                diaryRecordId: recordId,
                domain: domain,
                getBase64Images: base64,
                onlyShared: false,
                onlyMine: false,//onlyMine !== undefined && onlyMine === true,
                subcontractorId: subcontractorId
            })));
    }
    public async UploadPdfFileForSignatureLogged(file: FileParameter, diaryIdRecord: number): Promise<FileResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getOneDayPDFLogged(diaryIdRecord, file));
    }
    public async UploadDigitallySignedPDF(file: FileParameter, diaryIdRecord: number): Promise<UploadDigitalSignedPDFResponse> {
        return (await this.GetAdressedAuthRefreshableClient().uploadDigitallySignedPDF(diaryIdRecord, file));
    }
    public async MarkRequestConversationAsRead(taksId: number): Promise<boolean | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().markConversationAsRead(new MarkConversationAsReadRequest({ taskId: taksId }))).ok;
    }
    public async RingOthersDiary(diaryRecordId: number): Promise<boolean | undefined> {
        return (await this.GetAdressedAuthRefreshableClient().sendMessageDiary(new SendMessageDiaryRequest({ diaryRecordId: diaryRecordId }))).ok;
    }
    public async GetInvestorDocumentByBuilding(buildingId?: number): Promise<GetDocumentsForInvestorAndBuildingResponse> {
        return (await this.GetAdressedAuthRefreshableClient().getDocumentsForInvestorAndBuilding(buildingId === undefined ? 0 : buildingId));
    }
    public async RefreshInvestorToken(): Promise<boolean> {
        SingletonRefreshClientInvestorOld.setRefresh();
        let t = (await this.GetAdressedAuthRefreshableClient()
            .refreshInvestorToken(new RefreshInvestorTokenRequest(
                {
                    accessToken: localStorage.getItem('investor_token_old')!,
                    refreshInvestorToken: localStorage.getItem('investor_token_old_refresh')!
                }
            )));
        localStorage.setItem('investor_token_old', t.accessToken!);
        localStorage.setItem('investor_token_old_refresh', t.refreshToken!);
        localStorage.setItem('investor_token_old_expires', t.expireAt!.toString());
        SingletonRefreshClientInvestorOld.completeRefresh();
        return true;
    }
    public async addImageToDiary(diaryRecordId: number, file: FileParameter): Promise<AddImageToProjectAndDiaryRecordResponse> {
        let data = (await this.GetAdressedAuthRefreshableClient().uploadImage(diaryRecordId, file));
        return data;
    }
    public async addFileToDiary(diaryRecordId: number, file: FileParameter): Promise<UploadFileToDiaryDirectResponse> {
        let data = (await this.GetAdressedAuthRefreshableClient().addFileToRecordDirect(diaryRecordId, file));
        return data;
    }
    // public async RefreshInvestorToken(access: string, refresh: string): Promise<RefreshInvestorTokenResponse> {
    //     let data = (await this.GetAdressedAuthRefreshableClient().refreshInvestorToken(new RefreshInvestorTokenRequest({ accessToken: access, refreshInvestorToken: refresh })));
    //     return data;
    // }


    public async GetDiaryRecordLog(recordId: number): Promise<DiaryLogDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getDiaryRecordLog(recordId)).logs!;
    }

    public async GetDiariesForInvestor(deleted: boolean, onlyMain: boolean, buildingId?: number): Promise<GetDiariesForUserDTO[]> {
        return (await this.GetAdressedAuthRefreshableClient().getDiariesForInvestor(deleted, onlyMain, buildingId ? buildingId : 0)).diaries!;
    }


    public async CreateDiaryRecordInvestor(diaryId: number, date: Date): Promise<number | string> {
        let data = (await this.GetAdressedAuthRefreshableClient().createDiaryRecordInvestor(new CreateDailyRecordInvestorRequest({ date: date, diaryId: diaryId })));
        if (data.newId)
            return data.newId;
        else return data.error!;
    }

    public async AddImageToProjectInvestor(buildingId: number, file: FileParameter): Promise<AddImageToProjectResponse> {
        return (await this.GetAdressedAuthRefreshableClient().addImageToProjectInvestor(buildingId, file));
    }
}