
import { createTheme, Theme } from "@mui/material";

export default function createThemeStavario(): Theme {
    return createTheme({
        typography: {
            fontFamily: "Roboto",
            fontWeightLight: 300,

            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700
        },

        palette: {
            primary: {
                main: '#FFC600',
                light: '#F5F5F5',
                dark: 'orange'


            },
            success: {
                main: '#19CB40',
            },
            action: {

            },
            info: {
                main: '#A5A5A5'
            },
            text: { primary: '#404040' },
            secondary: {
                light: '#F2F2F2',
                main: '#404040',
                // dark: will be calculated from palette.secondary.main,
                contrastText: '#FFFFFF',
            }
        },

        components: {
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: "#FFC600",
                        color: "black",
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        maxHeight: '20px'
                    }
                }
            }
        }
    })
}