import { Close } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide, Snackbar, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { ApiDiaryBaseHelper } from '../../../../Api/ApiDiaryBaseHelper';
import { Validate, ValidationGroup } from 'mui-validate';
import GroupSelect from '../../../Shared/GroupSelect';
import UserRoleSelect from '../../../Shared/UserRoleSelect';

interface CreateUserDialogProps {
    open: boolean;
    onClose: () => void;
    onCreated?: (id: number, name: string) => void,
    isSubdodavatel?: boolean;
    diaryId?: number;
    groupId?: number;
    showGroups: boolean;
}
export default function CreateUserDialog(props: CreateUserDialogProps) {
    const { open, onClose, isSubdodavatel, diaryId, onCreated, groupId, showGroups } = props;

    const [name, setName] = React.useState<string>();
    const [email, setEmail] = React.useState<string>();
    const [group, setGroup] = React.useState<number>();
    const [role, setRole] = React.useState<number>();
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);
    const [openLoginError, setOpenLoginError] = React.useState(false);

    useEffect(() => {
        setGroup(groupId);
    }, [groupId]);

    function isEmail(email: string): boolean {
        const regexp = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        return regexp.test(email);
    }
    function TransitionLeft(props: any) {
        return <Slide {...props} direction='left' />;
    }
    const handleClose = (event: any) => {
        setOpenLoginError(false);
    };

    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={() => { onClose() }}
            aria-labelledby="create-dialog-title"
            aria-describedby="create-dialog-description"
        >
            <DialogTitle id="create-user-dialog-title" >
                <div >
                    <div style={{ marginRight: '20px', minWidth: '200px' }}>{t('createUser')}</div>
                    <IconButton sx={{ width: '30px', height: '30px', right: '20px', top: '18px', float: 'right', position: 'absolute' }}
                        onClick={() => { setName(undefined); setEmail(undefined); setGroup(undefined); onClose(); }}><Close /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <ValidationGroup>
                    <div>
                        <Validate name='name-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                            <TextField sx={{ margin: '12px', width: '526px' }} label={t('z')}
                                size='small'
                                name='userName'
                                variant='outlined'
                                value={name}
                                error={!name}
                                onChange={(e) => { setName(e.target.value) }} />
                        </Validate>
                        <Validate name='email-signup-validate' initialValidation={'noisy'} regex={[/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('FieldRequired')]}>
                            <TextField
                                id="outlined-basic"
                                sx={{ margin: '12px', width: '526px' }}
                                label={'Email'}
                                size='small'
                                variant='outlined'
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }} />
                        </Validate>
                        {/* <Validate name='group-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}> */}
                        {isSubdodavatel !== true && showGroups && <div style={{ margin: '12px', width: '526px' }}>
                            <GroupSelect alertFontSize={12} isMandatory
                                isSubdodavatel={isSubdodavatel} onChange={(e) => {
                                    if (e)
                                        setGroup(e!.id);
                                    else setGroup(undefined);
                                }} selectedGroupId={group} />
                        </div>}
                        <div style={{ margin: '12px', width: '526px' }}>
                            <UserRoleSelect alertFontSize={12}
                                isSubdodavatel={isSubdodavatel} onChange={(e) => {
                                    if (e)
                                        setRole(e!.id);
                                    else setRole(undefined);
                                }} />
                        </div>
                        {/* </Validate> */}
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                            <Button variant='contained' sx={{ margin: 'auto' }} disabled={buttonDisabled || name === undefined || name === '' || email === undefined || isEmail(email) === false || (group === undefined && isSubdodavatel !== true)} onClick={() => {
                                if (name && email && (group || isSubdodavatel === true)) {
                                    setButtonDisabled(true);
                                    new ApiDiaryBaseHelper(isSubdodavatel === undefined ? false : isSubdodavatel, false).CreateSubcontractorUser(name, email, group, diaryId, role).then((ok) => {
                                        if (ok && ok.ok) {
                                            if (onCreated)
                                                onCreated(ok.id!, name);
                                            setName(undefined);
                                            setEmail(undefined);
                                            setGroup(undefined);
                                            onClose();
                                        }
                                        else
                                            setOpenLoginError(true);
                                        setButtonDisabled(false);
                                    });
                                }
                            }}>{t('save')}</Button>
                        </div>
                    </div>
                </ValidationGroup>
                <div className="create-form-popup">
                    <Snackbar
                        TransitionComponent={TransitionLeft}
                        open={openLoginError}
                        autoHideDuration={5000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }} >
                        <Alert onClose={handleClose} severity="warning">
                            <AlertTitle>{t('emailExists')}</AlertTitle>
                            {t('unableToCreateSub')}
                        </Alert>
                    </Snackbar>
                </div>
            </DialogContent>
        </Dialog>
    )
}
