import { Box, Tab, Tabs, IconButton, Checkbox, FormControlLabel, Typography, Tooltip, useTheme } from "@mui/material";
import { Check, Clear, DoneAll, DoneAllOutlined, Reply, Save } from '@mui/icons-material';
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { TabPanel } from "../../Shared/Tabs/TabPanel";
import tabProps from '../../Shared/Tabs/TabProps';
import SplitButton from "../../Shared/SplitButton";
import { loadDiaryStore, useDiaryStore } from '../../../Contexts/DiaryZContext';
import delay from '../../Shared/Functions/Delay';
import { AssignedEmployeeDTO, AssignedUserDTO, GetBuildingDataDetailResponse, UserIdAndNameDTO } from "../../../ApiOld/ApiServerVov";
import { useNavigate } from 'react-router-dom';
import { APIVovHelper } from "../../../ApiOld/ApiOldClient";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextareaAutosize, TextField } from '@mui/material';
import { Validate, ValidationGroup } from 'mui-validate';
import { ChangeEventArgs, HtmlEditor, Image as RTImage, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { useLoadScript } from "@react-google-maps/api";
import ProjectPosition from "./GoogleMap/ProjectPosition";
import { APIOldBuildingsClient } from "../../../ApiOld/ApiOldBuildingsClient";
import ProjectDocumentsScene from "./Components/ProjectDocumentsScene";
import { useMediaPredicate } from "react-media-hook";
import { groupBy } from "../../Shared/GroupBy";
import ProjectPhotosScene from "./Components/ProjectPhotosScene";
import { ThemeContext } from "@emotion/react";



// 1. useLoadScript v parent nebo v child?
// 2. ukladani checkboxu nejde
// 3. projectList layout nevypada jako v deniku
// 4. udelat fotky


interface ProjectDetailProps {
}

export default function ProjectDetail(props: ProjectDetailProps) {

    const [projectData, setProjectData] = useState<Partial<GetBuildingDataDetailResponse> | null>(null);

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [saveDone, setSaveDone] = useState<boolean>(false);

    const [groupedUsers, setGroupedUsers] = useState<[string, AssignedUserDTO[]][]>([]);
    const [groupedEmployees, setGroupedEmployees] = useState<[string, AssignedEmployeeDTO[]][]>([]);

    const { permissions, load } = useDiaryStore();
    const params = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const theme = useTheme();

    // const { assignedUsers } = projectData!;


    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyAUicHcR0cgVPe3nY0bjbrUHMlwk3EVYPU",
        libraries: ["places"]
    });


    useEffect(() => {
        if (params['id']) {
            new APIOldBuildingsClient().GetBuildingDataDetail(parseInt(params['id'])).then((d) => {
                setProjectData(d);

                /* if (d.assignedUsers) {
                    const data = groupBy(d.assignedUsers, i => i.group === undefined ? "" : i.group);

                    let tmp: any[] = [];
                    for (const b in data) {
                        tmp?.push([b, data[b]])
                    }

                    setGroupedUsers(tmp.sort((a, b) => {
                        if (a[0] < b[0]) {
                            return -1;
                        }
                        if (a[0] > b[0]) {
                            return 1;
                        }
                        return 0;
                    }));
                }
                else setGroupedUsers([]);

                if (d.assignedEmployees) {
                    const data = groupBy(d.assignedEmployees, i => i.group === undefined ? "" : i.group);

                    let tmp: any[] = [];
                    for (const b in data) {
                        tmp?.push([b, data[b]])
                    }

                    setGroupedEmployees(tmp.sort((a, b) => {
                        if (a[0] < b[0]) {
                            return -1;
                        }
                        if (a[0] > b[0]) {
                            return 1;
                        }
                        return 0;
                    }));
                }
                else setGroupedEmployees([]); */
            });
        }
        else {
            //setIsCreating(true);
            setProjectData(new GetBuildingDataDetailResponse({ assignedUsers: [], assignedEmployees: [] }));
            /* setGroupedUsers([]);
            setGroupedEmployees([]); */
        }

        if (permissions === undefined)
            loadDiaryStore(false).then((d) => {
                load(d);
            })
    }, []);


    useEffect(() => {
        if (saveDone === true)
            delay(2000).then((d) => { setSaveDone(false); });
    }, [saveDone]);

    useEffect(() => {
        if (projectData !== undefined && projectData !== null) {
            if (projectData.assignedUsers) {
                const data = groupBy(projectData.assignedUsers, i => i.group === undefined ? "" : i.group);

                let tmp: any[] = [];
                for (const b in data) {
                    tmp?.push([b, data[b]])
                }

                setGroupedUsers(tmp.sort((a, b) => {
                    if (a[0] < b[0]) {
                        return -1;
                    }
                    if (a[0] > b[0]) {
                        return 1;
                    }
                    return 0;
                }));
            }
            else setGroupedUsers([]);

            if (projectData.assignedEmployees) {
                const data = groupBy(projectData.assignedEmployees, i => i.group === undefined ? "" : i.group);

                let tmp: any[] = [];
                for (const b in data) {
                    tmp?.push([b, data[b]])
                }

                setGroupedEmployees(tmp.sort((a, b) => {
                    if (a[0] < b[0]) {
                        return -1;
                    }
                    if (a[0] > b[0]) {
                        return 1;
                    }
                    return 0;
                }));
            }
            else setGroupedEmployees([]);
        }
        else {
            setGroupedEmployees([]);
            setGroupedUsers([]);
        }
    }, [projectData]);

    function saveProject() {
        /* if (isCreating && projectData) {
            new APIVovHelper().CreateDiary(projectData).then((d) => {
                navigate('/' + params['lang']! + '/' + params['domain']! + '/diaryolderpnewdetail/' + d);
                setIsCreating(false);
                setSaveDone(true);
            });
        }
        else { */
        if (projectData)
            new APIOldBuildingsClient().SaveBuilding(projectData).then((d) => {
                if (d.ok) {
                    setProjectData({ ...projectData, id: d.id });
                    setSaveDone(true);
                }
            });
        //}
    }

    const redirectToList = () => {
        navigate('/' + i18n.language + '/' + params['domain']! + '/projects')
    }

    const checkAllAdminUser = useCallback((group: string | undefined, isAssigned: boolean) => {
        //setGroupedUsers([]);
        setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) =>
                p.group === group ? new AssignedUserDTO({ id: p.id, isAssigned: isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: p.notificationsEnabledRequests, group: p.group, groupId: p.groupId }) : p
            );

            return {
                ...prev,
                assignedUsers: updatedAssignedUsers,
            } as Partial<GetBuildingDataDetailResponse>;
        });
    }, [setProjectData, setGroupedEmployees, setGroupedUsers]);

    const checkAllEmployees = useCallback((group: string | undefined, isAssigned: boolean) => {
        setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedAssignedEmps: AssignedEmployeeDTO[] = prev.assignedEmployees!.map((p) =>
                p.group === group ? new AssignedEmployeeDTO({ id: p.id, isAssigned: isAssigned, code: p.code, firstName: p.firstName, lastName: p.lastName, group: p.group, groupId: p.groupId }) : p
            );

            return {
                ...prev,
                assignedEmployees: updatedAssignedEmps,
            } as Partial<GetBuildingDataDetailResponse>;
        });
    }, [setProjectData]);


    const checkAllNotificationRequests = (enabled: boolean) => {
        setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) => new AssignedUserDTO({ id: p.id, isAssigned: p.isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: enabled, group: p.group, groupId: p.groupId })
            );

            return {
                ...prev,
                assignedUsers: updatedAssignedUsers,
            } as Partial<GetBuildingDataDetailResponse>;
        });
    }

    const checkAllNotificationDiaryRecords = useCallback((enabled: boolean) => {
        setProjectData((prev) => {
            if (!prev) {
                return prev;
            }

            const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) => new AssignedUserDTO({ id: p.id, isAssigned: p.isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: enabled, notificationsEnabledRequests: p.notificationsEnabledRequests, group: p.group, groupId: p.groupId })
            );

            return {
                ...prev,
                assignedUsers: updatedAssignedUsers,
            } as Partial<GetBuildingDataDetailResponse>;
        });
    }, [setProjectData]);


    if (projectData)
        return (
            <Box>
                <div style={{ position: 'absolute', left: '40px', top: '60px', zIndex: 30 }}>
                    <IconButton color="primary" onClick={redirectToList}>
                        <Reply />
                    </IconButton>
                </div>
                <div style={{ position: 'fixed', display: 'flex', right: '40px', top: '60px', zIndex: 30 }}>
                    {saveDone && <Check color='success' sx={{ marginRight: '20px' }} />}
                    {permissions && permissions.canEditDiary && <div style={{ marginRight: '10px' }}>
                        <SplitButton selectedIndexInput={0} disabled={projectData === undefined || projectData === null} selectOnClick={true} variant='contained' options={[t('save'), t('saveAndClose')]}
                            startIconSplitButton={<Save />}
                            onMenuClick={(e) => {
                                if (e === 0) {
                                    saveProject();
                                }
                                else if (e === 1) {
                                    saveProject();
                                    redirectToList();
                                }
                            }}></SplitButton></div>}
                </div>


                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={(e, n) => { setSelectedTabIndex(n) }} aria-label="basic tabs example">
                        <Tab label={t('buildings.basicInformation')} {...tabProps(0)} />
                        <Tab label={t('buildings.assignedUsers')} {...tabProps(1)} />
                        <Tab label={t('employees')} {...tabProps(2)} />
                        <Tab label={t('buildings.notificationRecipients')} {...tabProps(3)} />
                        <Tab label={t('notes')} {...tabProps(4)} />
                        <Tab label={t('photos')} {...tabProps(5)} />
                        <Tab label={t('Documents')} {...tabProps(6)} />
                    </Tabs>
                </Box>


                <TabPanel value={selectedTabIndex} index={0}>
                    <ValidationGroup>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px'/* , width: '400px' */ }}>
                                <h4>1. {t('Projekt')}</h4>
                                <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Validate name='email-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                                <TextField label={t('buildings.buildingName')} variant="outlined" fullWidth size='small'
                                                    name='name'
                                                    value={projectData.name}
                                                    onChange={(e) => { setProjectData({ ...projectData, name: e.target.value }) }}
                                                />
                                            </Validate>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Validate name='code-signup-validate' initialValidation={'noisy'} required={[true, t('FieldRequired')]}>
                                                <TextField label={t('buildings.buildingCode')} variant="outlined" fullWidth size='small'
                                                    name='code'
                                                    value={projectData.code}
                                                    onChange={(e) => { setProjectData({ ...projectData, code: e.target.value }); }}
                                                />
                                            </Validate>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField label={t('buildings.contractNumber')} variant="outlined" fullWidth size='small'
                                                name='contractNumber'
                                                value={projectData.contractNumber}
                                                onChange={(e) => { setProjectData({ ...projectData, contractNumber: e.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField label={t('hourlyRate')} variant="outlined" fullWidth size='small'
                                                name='hourlyRate'
                                                type="number"
                                                value={projectData.hourlyRate}
                                                onChange={(e) => { setProjectData({ ...projectData, hourlyRate: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">{t('state')}</InputLabel>
                                                <Select
                                                    size="small"
                                                    labelId="demo-simple-select-label"
                                                    id="isActive"
                                                    value={Number(projectData.isActive)}
                                                    label={t('state')}
                                                    onChange={(e) => { setProjectData({ ...projectData, isActive: Boolean(e.target.value) }) }}
                                                >
                                                    <MenuItem value={1}>{t('active')}</MenuItem>
                                                    <MenuItem value={0}>{t('completed')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('showToEmployees')}</InputLabel>
                                                <Select
                                                    size="small"
                                                    labelId="demo-simple-select-label"
                                                    id="showToEmployees"
                                                    value={Number(projectData.showToEmployees)}
                                                    label={t('showToEmployees')}
                                                    onChange={(e) => { setProjectData({ ...projectData, showToEmployees: Boolean(e.target.value) }) }}
                                                >
                                                    <MenuItem value={1}>{t('yesL')}</MenuItem>
                                                    <MenuItem value={0}>{t('noL')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField label={t('buildings.houseType')} variant="outlined" fullWidth size='small'
                                                name='houseType'
                                                value={projectData.houseType}
                                                onChange={(e) => { setProjectData({ ...projectData, houseType: e.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField label={t('address')} variant="outlined" fullWidth size='small'
                                                name='address'
                                                value={projectData?.address1}
                                                onChange={(e) => { setProjectData({ ...projectData, address1: e.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel htmlFor="client-textarea">{t('Investor')}</InputLabel>
                                            <TextareaAutosize style={{ width: '100%', height: '80px', minHeight: '50px', maxHeight: '160px', borderRadius: '4px', borderColor: 'lightgray' }}
                                                minRows={3} maxRows={6}
                                                name='client'
                                                value={projectData.client}
                                                onChange={(e) => { setProjectData({ ...projectData, client: e.target.value }) }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel htmlFor="architect-textarea">{t('Architect')}</InputLabel>
                                            <TextareaAutosize style={{ width: '100%', height: '80px', minHeight: '50px', maxHeight: '160px', borderRadius: '4px', borderColor: 'lightgray' }}
                                                minRows={3} maxRows={6}
                                                name='architect'
                                                value={projectData.architect}
                                                onChange={(e) => { setProjectData({ ...projectData, architect: e.target.value }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>

                            <ProjectPosition
                                projectData={projectData}
                                setProjectData={setProjectData}
                            />

                        </div>
                    </ValidationGroup>
                </TabPanel>


                <TabPanel value={selectedTabIndex} index={1}>
                    <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px', width: '95%' }}>
                        <Box>
                            {groupedUsers.map((adminUser, i) => {
                                return (<div key={i} style={{ boxShadow: '1px 3px 3px 3px lightgray', margin: '16px', padding: '8px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Typography sx={{ fontWeight: 'bold', margin: '8px', fontSize: '14px' }}>{adminUser[0].length > 0 ? adminUser[0] : t('nonAssigned')}</Typography>
                                        <div style={{ display: 'flex' }}>
                                            <Tooltip title={t('chooseAll')} >
                                                <IconButton color="primary" onClick={(e) => { checkAllAdminUser(adminUser[0], true); }}>
                                                    <DoneAll sx={{ height: '16px' }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={t('unchooseAll')} >
                                                <IconButton color="primary" onClick={(e) => { checkAllAdminUser(adminUser[0], false); }}>
                                                    <Clear sx={{ height: '16px' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {
                                            adminUser[1].map((item2, ii) => {
                                                return (<div key={ii} style={{ margin: '10px', marginLeft: '20px' }}> <FormControlLabel
                                                    key={item2.id}
                                                    control={
                                                        <Checkbox
                                                            id={item2.id?.toString()}
                                                            checked={item2.isAssigned}
                                                            onChange={(e) => {
                                                                setProjectData((prev) => {
                                                                    if (!prev) {
                                                                        return prev;
                                                                    }

                                                                    const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) =>
                                                                        item2.id === p.id ? new AssignedUserDTO({ id: p.id, isAssigned: !p.isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: p.notificationsEnabledRequests, group: p.group, groupId: p.groupId }) : p
                                                                    );

                                                                    return {
                                                                        ...prev,
                                                                        assignedUsers: updatedAssignedUsers,
                                                                    } as Partial<GetBuildingDataDetailResponse>;
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label={item2.name}
                                                /> </div>);
                                            })
                                        }
                                    </div>
                                </div>);
                            })}
                        </Box>
                    </div >
                </TabPanel >

                <TabPanel value={selectedTabIndex} index={2}>
                    <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px', width: '95%' }}>
                        <Box>
                            {groupedEmployees.map((employee, iii) => {
                                return (<div key={iii} style={{ boxShadow: '1px 3px 3px 3px lightgray', margin: '16px', padding: '8px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Typography sx={{ fontWeight: 'bold', margin: '8px', fontSize: '14px' }}>{employee[0].length > 0 ? employee[0] : t('nonAssigned')}</Typography>
                                        <div style={{ display: 'flex' }}>
                                            <Tooltip title={t('chooseAll')} >
                                                <IconButton color="primary" onClick={(e) => { checkAllEmployees(employee[0], true); }}>
                                                    <DoneAll sx={{ height: '16px' }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={t('unchooseAll')} >
                                                <IconButton color="primary" onClick={(e) => { checkAllEmployees(employee[0], false); }}>
                                                    <Clear sx={{ height: '16px' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        {
                                            employee[1].map((emp, iiii) => {
                                                return (<div key={iiii} style={{ margin: '10px', marginLeft: '20px' }}> <FormControlLabel
                                                    key={emp.id}
                                                    control={
                                                        <Checkbox
                                                            id={emp.id?.toString()}
                                                            checked={emp.isAssigned}
                                                            onChange={(e) => {
                                                                setProjectData((prev) => {
                                                                    if (!prev) {
                                                                        return prev;
                                                                    }

                                                                    const updatedAssignedEmps: AssignedEmployeeDTO[] = prev.assignedEmployees!.map((p) =>
                                                                        emp.id === p.id ? new AssignedEmployeeDTO({ id: p.id, isAssigned: !p.isAssigned, code: p.code, firstName: p.firstName, lastName: p.lastName, group: p.group, groupId: p.groupId }) : p
                                                                    );

                                                                    return {
                                                                        ...prev,
                                                                        assignedEmployees: updatedAssignedEmps,
                                                                    } as Partial<GetBuildingDataDetailResponse>;
                                                                });
                                                            }}
                                                        />
                                                    }
                                                    label={emp.code + ' ' + emp.firstName + ' ' + emp.lastName}
                                                /> </div>);
                                            })
                                        }
                                    </div>
                                </div>);
                            })}
                            {/* {projectData.assignedEmployees?.map((emp) => (
                                <FormControlLabel
                                    key={emp.id}
                                    control={
                                        <Checkbox
                                            id={emp.id?.toString()}
                                            defaultChecked={emp.isAssigned}
                                            onChange={(e) => {
                                                setProjectData((prev) => {
                                                    if (!prev) {
                                                        return prev;
                                                    }

                                                    const updatedAssignedEmps: AssignedEmployeeDTO[] = prev.assignedEmployees!.map((p) =>
                                                        emp.id === p.id ? new AssignedEmployeeDTO({ id: p.id, isAssigned: !p.isAssigned, code: p.code, firstName: p.firstName, lastName: p.lastName }) : p
                                                    );

                                                    return {
                                                        ...prev,
                                                        assignedEmployees: updatedAssignedEmps,
                                                    } as Partial<GetBuildingDataDetailResponse>;
                                                });
                                            }}
                                        />
                                    }
                                    label={emp.code + ' ' + emp.firstName + ' ' + emp.lastName}
                                />
                            ))} */}
                        </Box>
                    </div>
                </TabPanel>

                <TabPanel value={selectedTabIndex} index={3}>
                    <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px', width: '95%' }}>
                        <div style={{ display: 'flex' }}>
                            <h4>{t('todo.tasks')}</h4>
                            <div style={{ display: 'flex' }}>
                                <Tooltip title={t('chooseAll')} >
                                    <IconButton color="primary" onClick={(e) => { checkAllNotificationRequests(true); }}>
                                        <DoneAll sx={{ height: '16px' }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t('unchooseAll')} >
                                    <IconButton color="primary" onClick={(e) => { checkAllNotificationRequests(false); }}>
                                        <Clear sx={{ height: '16px' }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <Box sx={{ margin: '8px' }}>
                            {projectData.assignedUsers && projectData.assignedUsers.filter(p => p.isAssigned === true).map((adminUser) => {
                                // const { notificationsEnabledRequests } = adminUser;
                                return <FormControlLabel
                                    sx={{ marginLeft: '8px', marginRight: '32px' }}
                                    key={adminUser.id}
                                    label={adminUser.name}
                                    control={
                                        <Checkbox
                                            checked={adminUser.notificationsEnabledRequests}
                                            onChange={(e) => {
                                                setProjectData((prev) => {
                                                    if (!prev) {
                                                        return prev;
                                                    }

                                                    const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) =>
                                                        adminUser.id === p.id ? new AssignedUserDTO({ id: p.id, isAssigned: p.isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: !p.notificationsEnabledRequests, group: p.group, groupId: p.groupId }) : p
                                                    );

                                                    return {
                                                        ...prev,
                                                        assignedUsers: updatedAssignedUsers,
                                                    } as Partial<GetBuildingDataDetailResponse>;
                                                });
                                            }}
                                        />
                                    }
                                />
                            })}
                        </Box>
                        <div style={{ display: 'flex' }}>
                            <h4>{t('diary.dailyRecord')}</h4>
                            <div style={{ display: 'flex' }}>
                                <Tooltip title={t('chooseAll')} >
                                    <IconButton color="primary" onClick={(e) => { checkAllNotificationDiaryRecords(true); }}>
                                        <DoneAll sx={{ height: '16px' }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t('unchooseAll')} >
                                    <IconButton color="primary" onClick={(e) => { checkAllNotificationDiaryRecords(false); }}>
                                        <Clear sx={{ height: '16px' }} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <Box sx={{ margin: '8px' }}>
                            {projectData.assignedUsers?.filter(p => p.isAssigned === true).map((adminUser) =>
                                <FormControlLabel
                                    sx={{ marginLeft: '8px', marginRight: '32px' }}
                                    key={adminUser.id}
                                    label={adminUser.name}
                                    control={
                                        <Checkbox
                                            checked={adminUser.notificationsEnabledBuildingDiaryRecords}
                                            onChange={(e) => {
                                                setProjectData((prev) => {
                                                    if (!prev) {
                                                        return prev;
                                                    }

                                                    const updatedAssignedUsers: AssignedUserDTO[] = prev.assignedUsers!.map((p) =>
                                                        adminUser.id === p.id ? new AssignedUserDTO({ id: p.id, isAssigned: p.isAssigned, name: p.name, notificationsEnabledBuildingDiaryRecords: !p.notificationsEnabledBuildingDiaryRecords, notificationsEnabledRequests: p.notificationsEnabledRequests, group: p.group, groupId: p.groupId }) : p
                                                    );

                                                    return {
                                                        ...prev,
                                                        assignedUsers: updatedAssignedUsers,
                                                    } as Partial<GetBuildingDataDetailResponse>;
                                                });
                                            }}
                                        />
                                    }
                                />
                            )}
                        </Box>
                    </div>
                </TabPanel>


                <TabPanel value={selectedTabIndex} index={4}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px', width: '100%' }}>
                            <RichTextEditorComponent height={600} locale={i18n.language} value={projectData.notes} change={(eve: ChangeEventArgs) => {
                                setProjectData({ ...projectData, notes: eve.value })
                            }}>
                                <Inject services={[Toolbar, RTImage, Link, HtmlEditor, QuickToolbar]} />
                            </RichTextEditorComponent>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={5}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px', width: '100%' }}>
                            <ProjectPhotosScene buildingId={projectData.id} buildingName={projectData.name} onNewRequest={(e) => { }} />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={selectedTabIndex} index={6}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ background: 'white', padding: '10px', boxShadow: '4px 4px 16px 4px lightgray', borderRadius: '4px', margin: '10px', marginTop: '32px', width: biggerThan700 ? '100%' : '' }}>
                            {projectData.id && <ProjectDocumentsScene projectId={projectData.id} />}
                        </div>
                    </div>
                </TabPanel>
            </Box >
        );
    return <div>{t('loading...')}</div>

}