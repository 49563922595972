import { Grading } from '@mui/icons-material';
import { ColumnsDirective, GridComponent, Group, RecordDoubleClickEventArgs, Sort, Filter, Search, Toolbar, Reorder, Resize, ColumnChooser, ColumnDirective, Inject, FilterSettingsModel, Page, } from '@syncfusion/ej2-react-grids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { GridType } from '../../../Api/UsersMicroserviceClient';
import { BuildingBillsForInvoicesPageDTO } from '../../../ApiOld/ApiServerVov';
import { loadDiaryStore, useDiaryStore } from '../../../Contexts/DiaryZContext';
import InitSfGridLocale from '../../../Localization/SfGridLanguageInitializer';
import UpperMenu from '../../Shared/Layouts/UpperMenu';
import TrashGridDialog from '../../Shared/Trash/TrashGridDialog';
import { TrashType } from '../../Shared/Trash/TrashType';
import { ApiOldInvoicesClient } from '../../../ApiOld/ApiOldInvoicesClient';
import CreateInvoice from './CreateInvoice';

InitSfGridLocale();
interface ProjectsListProps {
}

export default function InvoiceList(props: ProjectsListProps) {

    let groupOptions = {
        showDropArea: true,
        captionTemplate: '<span class="groupItems" style="color:#FFC600"> ${key} - ${count} Úkolů</span>'
    };
    let filterOptions: FilterSettingsModel = {
        type: 'Excel',
        ignoreAccent: true
    };


    const [invoices, setInvoices] = useState<BuildingBillsForInvoicesPageDTO[] | undefined>();
    const [trashOpen, setTrashOpen] = useState(false);
    const [newOpen, setNewOpen] = useState(false);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { permissions, load } = useDiaryStore();
    const params = useParams();

    useEffect(() => {

        if (permissions === undefined)
            loadDiaryStore(false).then((d) => {
                load(d);
            })
    }, []);


    function GridTemplate(props: BuildingBillsForInvoicesPageDTO) {
        const [hover, setHover] = useState(false);
        if (permissions && permissions.canViewDiaryRecordsDetail)
            return (<div style={{ cursor: 'pointer' }} onClick={() => {
                navigateToDetail(props);
            }} onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                <a style={{ color: '#5762D5', textDecoration: 'underline', fontWeight: 'bold' }}>{props.name!}</a>
            </div>);
        else return <div>{props.name!}</div>
    }


    function navigateToDetail(data: BuildingBillsForInvoicesPageDTO) {
        navigate(
            { pathname: '/' + i18n.language + '/' + params['domain']! + '/invoiceDetail/' + data.id!.toString(), },
            { state: { id: data.id, inputRecords: invoices } }
        );
    }

    function toolbarClickExcel(props: any) {
        if (grid.current !== undefined && props.item.id.toString().endsWith('excelexport'))
            grid.current!.excelExport();
    }

    const grid = useRef<GridComponent>(null);

    useEffect(() => {
        if (invoices === null || invoices === undefined)
            refresh();
        else setInvoices(undefined);
    }, []);

    function refresh() {
        new ApiOldInvoicesClient().GetBuildingBillsForInvoicesListPage(false).then((r) => {
            setInvoices(r);
        });
    }

    if (invoices)
        return <div style={{ display: "flex", flexDirection: 'column' }}>

            <UpperMenu moduleName={t('Faktury')}
                gridType={GridType.Invoices}
                icon={<Grading />}
                canAdd={true}
                canTrash
                onCreate={(e) => { setNewOpen(true) }}
                canDelete={permissions ? permissions.canDeleteDiaryRecord : false}
                onDelete={(e) => { new ApiOldInvoicesClient().deleteInvoices(e as number[]).then((e) => { if (e) refresh(); }) }}
                canChangeEyeView={false}
                onThrashClicked={() => { setTrashOpen(true); }}
                grid={grid} canChangeGroup canToolbarGrid
                menuName={t('View')}
            />


            <GridComponent ref={grid} id="grid-component-projects" allowSorting={true}
                allowReordering={true} allowResizing={true} showColumnChooser={true}
                allowExcelExport={true} allowMultiSorting={true} allowGrouping={false}
                locale={i18n.language} enablePersistence={true}
                recordDoubleClick={(e: RecordDoubleClickEventArgs) => {
                    navigateToDetail(e.rowData as BuildingBillsForInvoicesPageDTO)
                }}
                filterSettings={filterOptions}
                allowPaging={true}
                style={{ backgroundColor: '#F5F5F5' }}
                pageSettings={{ pageSize: 20, pageSizes: [10, 20, 30, 50, 100] }}
                toolbarClick={toolbarClickExcel}
                selectionSettings={{ checkboxMode: 'ResetOnRowClick' }}
                allowFiltering={true} groupSettings={groupOptions}
                dataSource={invoices}>
                <ColumnsDirective>
                    <ColumnDirective type='checkbox' width={60} />
                    <ColumnDirective field='name' template={GridTemplate} headerText={t('Name')} width={300} />
                    <ColumnDirective field='project' headerText={t('Projekt')} width={250} />
                    <ColumnDirective field='dateTaxable' type='date' format={i18n.language === 'en' ? 'MM/dd/yyyy' : 'dd.MM.yyyy'} headerText={t('invoices.chargeableEvent')} width={180} />
                    <ColumnDirective field='dateExpire' type='date' format={i18n.language === 'en' ? 'MM/dd/yyyy' : 'dd.MM.yyyy'} headerText={t('date_expire')} width={180} />
                    <ColumnDirective field='isApproved' type='boolean' textAlign='Center' headerTextAlign='Center' displayAsCheckBox headerText={t('is_done')} width={150} />
                    <ColumnDirective field='isDone' type='boolean' textAlign='Center' headerTextAlign='Center' displayAsCheckBox headerText={t('is_approved')} width={130} />
                    <ColumnDirective field='isPostponed' type='boolean' textAlign='Center' headerTextAlign='Center' displayAsCheckBox headerText={t('is_postponed')} width={130} />
                    <ColumnDirective field='isPosted' type='boolean' textAlign='Center' headerTextAlign='Center' displayAsCheckBox headerText={t('is_posted')} width={150} />
                    <ColumnDirective
                        template={(props) => (
                            <span>{props.priceWithoutVat.toFixed(2)} Kč</span>
                        )}
                        textAlign='Center'
                        headerTextAlign='Center'
                        field='priceWithoutVat'
                        format={{ format: 'N2' }}
                        headerText={t('invoices.amountExcludingVAT')}
                    />
                    <ColumnDirective
                        template={(props) => (
                            <span>{props.priceWithVat.toFixed(2)} Kč</span>
                        )}
                        textAlign='Center'
                        headerTextAlign='Center'
                        field='priceWithVat'
                        format={{ format: 'N2' }}
                        headerText={t('invoices.amountIncludingVAT')}
                    />
                </ColumnsDirective>
                <Inject services={[Sort, Group, Filter, Search, Toolbar, Reorder, Resize, ColumnChooser, Page]} />
            </GridComponent>
            <div>
                <CreateInvoice open={newOpen} onClose={(e, newId) => {
                    setNewOpen(false);
                    refresh();
                }} />
                <TrashGridDialog trashType={TrashType.Invoices} open={trashOpen} onClose={(e) => { setTrashOpen(false); if (e) refresh(); }} />
            </div>
        </div>

    return <div>{t('noRecords')}</div>;
}